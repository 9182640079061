export const EMAIL_TASK_NAMESPACE = 'emailTask';
export const APPROVAL_MAIL_TASK = 'approvalMailTask';
export const ANSIBLE_TASK_NAMESPACE = 'ansibleTask';
export const REPORT_DESIGNER_TASK = 'reportDesignerTask';
export const TASK_NAMESPACE = 'bpmn:Task';
export const USER_TASK_NAMESPACE = 'bpmn:UserTask';
export const SEQUENCE_FLOW_NAMESPACE = 'bpmn:SequenceFlow';
export const EXTENSTION_ELEMENTS_NAMESPACE = 'bpmn:ExtensionElements';
export const EXPRESSION_NAMESPACE = 'bpmn:Expression';
export const EXCLUSIVE_GATEWAY_NAMESPACE = 'bpmn:ExclusiveGateway';
export const START_EVENT_NAMESPACE = 'bpmn:StartEvent';

export const ERR_MSGS = {
  VARIABLE_INVALID_CHAR: (variableName) => `${variableName} can't start with a number and can't contain space or special character.`,
};

export const LABELS = {
  VARIABLE_NAME: 'Variable Name',
  VALUE: 'Value',
};

export const CAMMUNDA_TAGS = {
  OUTPUT_PARAMETER: 'camunda:OutputParameter', 
  INPUT_PARAMETER: 'camunda:InputParameter',
  TASK_LISTENER: 'camunda:TaskListener',
  EXECUTION_LISTENER: 'camunda:ExecutionListener',
  INPUT_OUTPUT: 'camunda:InputOutput',
  LIST :'camunda:List',
  VALUE: 'camunda:Value',
};

export const iconsSvgMap = {
  APPROVAL_EMAIL_TASK: {
    d: 'M29.37,4.08H9.25a1,1,0,0,0-1,1v5.35a6.62,6.62,0,1,0,8.49,9.29H29.37a1,1,0,0,0,'+
      '1-1V5.13A1,1,0,0,0,29.37,4.08Zm-.9,1.39-9.13,6.06L9.71,5.47ZM29,18.38H17.29a6.41,6.41,'+
      '0,0,0,.28-1.87.69.69,0,1,0-1.37,0,5.26,5.26,0,1,1-5.26-5.26.69.69,0,1,0,0-1.37A6.55,'+
      '6.55,0,0,0,9.6,10v-3l6.88,4.32-6,6h0L8.58,15.25a.68.68,0,0,0-1.21.41.65.65,0,0,0,.18.49L10,'+
      '18.8a.71.71,0,0,0,.64.21.58.58,0,0,0,.34-.19l6.74-6.68,1.08.69a1.1,1.1,0,0,0,.56.16,1,1,0,0,0,.57-.18l9.1-6Z',
  },
  NOTIFICATION_EMAIL_TASK: {
    d:'M29.49,4.72H10.14a1,1,0,0,0-1,1v7.36C7.62,13.33,6,14.52,6,17.74a2.94,2.94,0,0,1-1.7,'+
      '2.69,1.45,1.45,0,0,0,1.43,1.62h2.6a1.44,1.44,0,0,0,2.88,0h2.57a1.45,1.45,0,0,0,1.43-1.62,'+
      '2.93,2.93,0,0,1-.86-.62H29.49a1,1,0,0,0,1-1V5.73A1,1,0,0,0,29.49,4.72Zm-.87,1.34-8.78,'+
      '5.82L10.58,6.06ZM9.75,22.55a.58.58,0,0,1-.57-.5h1.14A.59.59,0,0,1,9.75,22.55Zm3.57-1.75H6.15a.53.53,'+
      '0,0,1-.45-.26h0a3.46,3.46,0,0,0,1.47-2.82c0-2.43,1.12-3.42,2.57-3.42s2.56,1,2.56,3.42a3.62,'+
      '3.62,0,0,0,1.48,2.81A.58.58,0,0,1,13.32,20.8Zm15.83-2.33H13.58a3.26,3.26,0,0,'+
      '1-.08-.73c0-3.13-1.55-4.34-3-4.63V7.58l8.84,5.55a1.06,1.06,0,0,0,.54.16,1,1,0,0,0,.55-.17l8.75-5.81Z',
  },
  ANSIBLE_TASK: {
    d:'M22.57,4.18a1.89,1.89,0,0,0-1.36-.57H9a2,2,0,0,0-2,2V19.12H4.43v2.56A1.92,1.92,0,0,0,6.35,'+
      '23.6H18.62a1.92,1.92,0,0,0,1.92-1.92V8.1h2.59V5.53A1.9,1.9,0,0,0,22.57,4.18Zm-.7,'+
      '1.35V6.84H20.54V5.53a.67.67,0,0,1,.67-.66A.66.66,0,0,1,21.87,5.53ZM16.82,22.35H6.35a.66.66,0,0,'+
      '1-.66-.67V20.37h11v1.31A2.15,2.15,0,0,0,16.82,22.35ZM19.35,5.09l0,.09a2,2,0,0,0,0,.35V21.68a.67.67,'+
      '0,1,1-1.33,0V19.12H8.27V5.57a.7.7,0,0,1,.7-.7H19.41C19.39,4.94,19.37,5,19.35,5.09Z',
  },
  REPORT_DESIGNER_TASK: {
    d:'M23.9,13.8l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0L22,13.9v-2.6c0-0.1,0-0.2-0.1-0.2L15.8,5L5.3,5C5.1,5,5,5.2,5,5.3v20.3'+
    'c0,0.2,0.1,0.3,0.3,0.3h16.4c0.2,0,0.3-0.1,0.3-0.3l0-9.3l1.8-1.8C24,14.3,24,14,23.9,13.8z M16.8,7.8l2.4,2.4h-2.4V7.8z'+
    'M20.7,15.2l-6.1,6.1L14.1,23l1.7-0.5l0.8-0.8l1.1-1.1l3.1-3.1l0,7.1H6.3v-6.9h2.2l0,2.7c0,0.2,0.1,0.3,0.3,0.3h1.1'+
    'c0.2,0,0.3-0.1,0.3-0.3l0-2.7h2.2v2.7c0,0.2,0.1,0.3,0.3,0.3h1.1c0.2,0,0.3-0.1,0.3-0.3v-2.7h1.7v0.6l1.7-1.7v-2.3'+
    'c0-0.2-0.1-0.3-0.3-0.3h-1.1c-0.2,0-0.3,0.1-0.3,0.3v3.5h-1.7V12c0-0.2-0.1-0.3-0.3-0.3h-1.1c-0.2,0-0.3,0.1-0.3,0.3v5.8h-2.2'+
    'l0-8.4c0-0.2-0.1-0.3-0.3-0.3H8.8C8.6,9,8.5,9.2,8.5,9.4l0,8.4H6.3V6.3l9.2,0v3.9v1c0,0.2,0.1,0.3,0.3,0.3h1h3.9L20.7,15.2z',
  },
};