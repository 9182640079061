/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import Panel from './Panel';

export default class PanelGroup extends Component {
  render() {
    return (
      <div className={`panels-container ${this.props.className || ''}`}>
        <div className="panel-group" style={this.props.style}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

PanelGroup.propTypes = {
  children: function (props, propName, componentName) {
    const prop = props[propName]

    let error = null;

    React.Children.forEach(prop, function (child) {
      if (child.type !== Panel) {
        error = new Error('`' + componentName + '` children should be of type `Panel`.');
      }
    });
    return error;
  },
};
