/* Copyright 2019 Greyskies. All Rights Reserved. */

import React  from 'react';
import 'styles/dataVisualization/svgViewer.less';

const renderLinearGradient = (gradientColors, id) => {
  const offsetFactor = 100 / gradientColors.length * 2;

  return (
    <linearGradient id={id}>
    {
      gradientColors.map((color, index) => {
        return <stop offset={index * offsetFactor + '%'} stopColor={color} />;
      })
    }
    </linearGradient>
  );
};
const SVGViewer = (props) => {
  const fillGradientColors = props.fillGradientColors;
  const linearGradientId = fillGradientColors ? `${props.id}gradientFill` : null;
  const fillColor = fillGradientColors ? `url(#${linearGradientId})` : (props.fillColor || 'black');

  return (
    <svg
      width={props.width}
      height={props.height}
      className={`svgViewer ${props.className}`}
      viewBox={props.viewBox}
    >
      {fillGradientColors && renderLinearGradient(fillGradientColors, linearGradientId)}
      {
        props.path ?
        <path 
          d={props.path}
          stroke={props.strokeColor || ''}
          fill={fillColor} 
        /> 
        : <text className='previewText'>
            <tspan>Preview</tspan>
          </text>        
      }
    </svg>
  );
};

export default SVGViewer;