/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import ResolutionInput from 'inputs/compound/ResolutionInput';
import {ResolutionUtils} from 'js-utils';

export default class DataGroupingResolutionInput extends React.Component{

    constructor(props){
      super(props);
      this.onCustomResolutionChange = :: this.onCustomResolutionChange;
      this.onResolutionChange = :: this.onResolutionChange;
    }

    onCustomResolutionChange(e) {
      const unit = e.target.value;
      const obj = this.props.customUnitsValues[unit];
      this.onResolutionChange(obj);
    }
  
    onResolutionChange(value) {
      const res = this.props.getResolutionObj(value.unit, value.interval);
      if(ResolutionUtils.isResolutionIntervalMonths(value.unit)){
       res.resolution = ResolutionUtils.MONTHLY_DEFAULT_RESOLUTION;
      }
      this.props.onChange(res, this.props.index);
    }

    getCustomResolutionInput(currResolution){
        const timeResolutionOptions =  [];
        let unitValue = '';
      
        if(this.props.customUnits.length > 0){
          this.props.customUnits.forEach((customUnit) => {
            const resolutionOption = this.props.customUnitsValues[customUnit];
      
            if(currResolution.interval == resolutionOption.interval && currResolution.unit == resolutionOption.unit){
              unitValue = customUnit;
            }
      
            timeResolutionOptions.push(<option value={customUnit} key={customUnit} >{customUnit}</option>);
          });
      
          // // Fix in case loading resolution not match with custom unit will save empty resolution 
          // if(currResolution.unit && unitValue == ''){
          //   this.props.onChange(this.props.getResolutionObj(), this.props.index);
          // }
        }

        return <MultipleSelect
                filter={false}
                value = {unitValue}
                onChange={this.onCustomResolutionChange}
                className='time-interval margBtm0' 
                options={timeResolutionOptions}
                disabled={this.props.disableComponent} />;
    
      }

      render(){ 
        const currResolution = {
          unit: this.props.resolutionUnit,
          interval: this.props.resolution,
        }  
        const resolutionInput = this.props.showCustomInterval ? 
            this.getCustomResolutionInput(currResolution)
            :
            <ResolutionInput showAutoResolution
              resolution={currResolution}
              onResolutionChange={this.onResolutionChange}
              disableComponent={this.props.disableComponent}
              disableInterval={ResolutionUtils.isResolutionIntervalMonths(this.props.resolutionUnit)}
              validation={this.props.validation}
              isNewNumericInput
              /> ;
              
        return (resolutionInput);
      }
}