/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {DropdownButton, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import 'styles/buttons/floatingBtn.less';

const FloatingBtn = props => {
  const className = props.className ? props.className : "";
  const defaultBtn =
    <OverlayTrigger id={props.componentId} placement="left" trigger={["hover", "focus"]}
      overlay={
        <Tooltip className="in">
          {props.tooltipTxt}
        </Tooltip>
      }>
      {props.isDropDownBtn ?
        <span className="floatingBtn">
          {props.btnContent}
        </span> :
        <Button
          className={`floating-btn ${className}`} disabled={props.disabled}
          onClick={() => props.onClick(props.onClickParams)}>
          {props.btnContent}
        </Button>
      }
    </OverlayTrigger>;

  const dropDownBtn = 
    <DropdownButton id={props.componentId} noCaret className={`floating-btn ${className} `} 
                    title={defaultBtn} disabled={props.disabled}>
      {props.isDropDownBtn ? props.menuItems : null}
    </DropdownButton>;

  return props.isDropDownBtn ? dropDownBtn : defaultBtn;
}
export default FloatingBtn;
