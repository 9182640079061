/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {NETWORK_ELEMENT, ORGANIZATION_GROUP}  from 'utils/defaults';
import {Row, Col} from 'react-bootstrap';
import RadioGroup from 'inputs/simple/RadioGroup';
import Radio from 'inputs/simple/Radio';
import * as filterUtils from 'utils/filterUtils';

const NetworkElementSelectionRadioGroup = props => {
  const colSize = (props.isTemplate || props.hasVariables) && !props.isGroupHidden ? 4 : 6;
  const placeholderColSize = props.isGroupHidden ? 6 : 4;

  return (
    <RadioGroup label="Network Elements Selection">
      <Row>
      {props.isGroupHidden ? null :
        <Col xs={colSize}>
          <Radio
            name="groupSelection"
            disabled={props.disabled}
            checked={props.selectedGroup == ORGANIZATION_GROUP}
            onChange={props.setGroupSelection}
            value={ORGANIZATION_GROUP} label="Group" />
        </Col>}
        <Col xs={colSize}>
          <Radio
            name="groupSelection"
            disabled={props.disabled}
            checked={props.selectedGroup == NETWORK_ELEMENT}
            onChange={props.setGroupSelection}
            value={NETWORK_ELEMENT} label="Elements" />
        </Col>
        {props.isTemplate ?
          <Col xs={placeholderColSize}>
            <Radio
              name="groupSelection"
              disabled={props.disabled}
              checked={props.selectedGroup == filterUtils.PLACEHOLDER}
              onChange={props.setGroupSelection}
              value={filterUtils.PLACEHOLDER} 
              label={filterUtils.PLACEHOLDER} />
            </Col> : null
        }
        {props.hasVariables ?
          <Col xs={placeholderColSize}>
            <Radio
              name="variableSelection"
              disabled={props.disabled}
              checked={filterUtils.isVariableFilter(props.selectedGroup)}
              onChange={props.setGroupSelection}
              value={filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value} 
              label={filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.name} />
            </Col> : null
        }
      </Row>
    </RadioGroup>
  );
};

export default NetworkElementSelectionRadioGroup;
