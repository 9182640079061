/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {OverlayTrigger, Button, Popover, Row,
   Col, FormControl} from 'react-bootstrap';
import TooltippedButton from './TooltippedButton';
import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
import ValidationOutput from 'containers/ValidationOutput';
import Icons from 'templates/Icons';

const nameValidationSchema = {
  'File Name': Joi.string()
    .regex(/^[^#%.=;?/]{3,150}$/)
    .required()
    .trim()
    .error(new Error('Name must be at least 3 characters and at'
      + 'most 150, and can include letters, numbers '
      + 'and special characters except # % . = / ; ?')),
};

export default class DownloadButton extends Component{
  constructor(props){
    super(props);
    this.download = ::this.download;
    this.handleResize = ::this.handleResize;
  }

  state={
    value:this.props.value,
    nameValidation:{
      style: 'success',
    },
  }
  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      value:nextProps.value,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.overlay.hide();
  }

  validate(value) {
    const inputValidator =  ValidationUtils.validateSchema(nameValidationSchema);

    return inputValidator('File Name', value);
  }

  download(){
    if(this.state.value){
      this.props.onDownload(this.state.value);
    }
    this.overlay.hide();
  }

  render(){
    return(
      <OverlayTrigger  rootClose
      ref={ref => this.overlay = ref}
         placement={this.props.placement || 'bottom'} trigger={[]}
        onExited={ () => {
          this.setState({
            value:this.props.value,
            nameValidation:{
              style: 'success',
            },
          });
        }}
        overlay={
          <Popover placement='bottom' title='Choose filename'
          id='download-button'
          style={{minHeight:'10px', maxWidth:'none', width:'500px'}}>
            <Row>
              <Col lg={11}>
              <div className='form-input-row'>
                <div  className='input-container' onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
                  <FormControl
                    type="text"
                    autoFocus={false}
                    className='input-text'
                    value={this.state.value}
                    onChange={
                      ({target: {value}}) => {
                        const nameValidation = this.validate(value);

                        this.setState({value, nameValidation});
                      }
                    }
                    onKeyPress = {(e) => {
                      e.stopPropagation();
                      if(e.charCode == 13) {
                        if(e.target.value.trim()
                        && this.state.nameValidation.style == 'success'){
                          this.download();
                        }
                      }
                    }}
                  />
                  <ValidationOutput validation={this.state.nameValidation} />
                </div>
              </div>
              </Col>
              <Col lg={1} className='paddingLeft0'>
                <TooltippedButton className="btn-action"
                  tooltip='Save with new filename'
                  disabled={this.state.nameValidation.style  != 'success'}
                  onClick={this.download}>
                  <i className={Icons.save}></i>
                </TooltippedButton>
              </Col>
            </Row>
          </Popover>
        }
      >
        { this.props.tooltiped ?
          <TooltippedButton {...this.props}
            onClick={ () => {
              if(this.overlay && !this.overlay.state.isOverlayShown){
                this.overlay.show();
              }
            }}
            onKeyUp = {(e) => {
              e.preventDefault();
            }}
          tooltip={this.props.tooltiped} >
          {this.props.children}
        </TooltippedButton>
        :
        <Button {...this.props} onClick={ () => {
          if(this.overlay && !this.overlay.state.isOverlayShown){
            this.overlay.show();
          }
        }}
        onKeyUp = {(e) => {
          e.preventDefault();
        }}
        >
          {this.props.children}
        </Button>
      }
      </OverlayTrigger>
    );
  }
}
