/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BsDateRangePicker from 'react-bootstrap-daterangepicker';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {DateTimeUtils, RelativeDateConverter} from 'js-utils';
import * as Icons from 'templates/Icons';
import 'styles/inputs/simple/dateRangePicker.less';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import ConditionalRendering from '../../containers/ConditionalRendering';

export default class DateRangePicker extends Component{

  static propTypes = {
    onChange: PropTypes.func,
  };

  constructor(props){
    super(props);
    this.currentRanges = this.getRelativeRanges();

    this.state = {
      ranges: this.currentRanges,
      from: props.from,
      to: props.to,
      relativeFrom: props.relativeFrom,
      relativeTo: props.relativeTo,
      relativeDate: props.relativeDate ? DateTimeUtils.RANGES[props.relativeDate].displayName : null,
    };
    this.onDateRangeChange = ::this.onDateRangeChange;  
    this.getDateFormattedRange = ::this.getDateFormattedRange;
    this.getRelativeRanges = ::this.getRelativeRanges;    
    this.getStartReferenceDate =::this.getStartReferenceDate;
  }

  componentWillReceiveProps(nextProps){
    this.currentRanges = this.getRelativeRanges();
    if(nextProps.from !== this.state.from || nextProps.to !== this.state.to ){
      this.setState({
        from: nextProps.from,
        to: nextProps.to,
        relativeFrom: nextProps.relativeFrom,
        relativeTo: nextProps.relativeTo,
        relativeDate: nextProps.relativeDate ? DateTimeUtils.RANGES[nextProps.relativeDate].displayName : null 
      });
    }
  }

  componentDidUpdate(prevState) {
    if (this.ref && (prevState.from !== this.state.from || prevState.to !== this.state.to)) {
      this.ref.initializeDateRangePicker();
    }
  }

  getRelativeRanges(){
    if(!this.props.noRanges){
      return DateTimeUtils.datePickerRanges(this.props.inLiveMode);
    }    
  }

  onDateRangeChange(event, picker){
    this.currentRanges = this.getRelativeRanges();
    const isRelativeDate = this.props.relativeDateMode 
      && this.currentRanges.hasOwnProperty(picker.chosenLabel);
    let relativeData = {};
    
    if(isRelativeDate){
      picker.startDate = this.currentRanges[picker.chosenLabel][0];
      picker.endDate = this.currentRanges[picker.chosenLabel][1];
    }
    if(this.props.relativeDateMode){
      relativeData = {
        relativeFrom: picker.relativeStart,
        relativeTo: picker.relativeEnd,
        relativeDate: null,
      };
    }
    const result = {
      from: DateTimeUtils.dateToEpoch(picker.startDate),
      to: DateTimeUtils.dateToEpoch(picker.endDate),
      ...relativeData,
    };

    this.setState({
      ...result,
      relativeDate: picker.chosenLabel,
    });

    if(this.props.onChange){
      const dateRange = {...result};

      if(isRelativeDate){
        dateRange.relativeDate = this.getUnifiedStringDate();
      }
      this.props.onChange(dateRange);
    }
  }

  getDateFormattedRange(showLabels){
    const labelFormat = this.props.showDateOnly ? DateTimeUtils.DATE_PICKER_LABEL_DATE_FORMAT : null;

    let start = DateTimeUtils.epochToCustomDateFormat(labelFormat,
      this.state.from);
    let end = DateTimeUtils.epochToCustomDateFormat(labelFormat,
      this.state.to);

    if(showLabels){
      if(this.state.relativeFrom  && this.state.relativeFrom != DateTimeUtils.NOW 
        && this.state.relativeTo == DateTimeUtils.NOW ){
        return RelativeDateConverter.getPastTimeRangeString(this.state.relativeFrom, true);
      }
      if(this.state.relativeFrom == DateTimeUtils.NOW && this.state.relativeTo 
        && this.state.relativeTo != DateTimeUtils.NOW){
        return RelativeDateConverter.getFutureTimeRangeString(this.state.relativeTo, true);
      }

      if(this.state.relativeFrom){
        start = RelativeDateConverter.toRelativeTimeString(this.state.relativeFrom);
      }   
      if(this.state.relativeTo){
        end = RelativeDateConverter.toRelativeTimeString(this.state.relativeTo);
      }
    }

    return `${start}  ${end ? '-' : ''}  ${end}`;
  }


  getDateRangeString(){
    let dateString = this.state.relativeDate;

    if(!dateString || !this.currentRanges[dateString]){
      dateString = this.getDateFormattedRange(true);
      if(this.props.showRangeLabels){
        for (const property in this.currentRanges) {
          if (this.currentRanges.hasOwnProperty(property)
              && DateTimeUtils.dateToCustomDateFormat(null, this.currentRanges[property][0])
                == DateTimeUtils.epochToCustomDateFormat(null, this.state.from)
              && DateTimeUtils.dateToCustomDateFormat(null, this.currentRanges[property][1])
                == DateTimeUtils.epochToCustomDateFormat(null, this.state.to )) {
            dateString = property;
          }
        }
      }
    }

    return dateString;
  }

  getUnifiedStringDate(){
    const dateString = this.getDateRangeString();
    let unifiedDate = dateString;
    const rangesDefaults =  DateTimeUtils.getRanges(this.props.inLiveMode);

    for (const property in rangesDefaults) {
      if(rangesDefaults[property].displayName === dateString){
        unifiedDate = property;
        break;
      }
    }

    return unifiedDate;
  }

  getStartReferenceDate(){
    return DateTimeUtils.getEpochFromEpochString(this.props.startReferenceDate);
  }

  render(){
    const componentClassName = this.props.containerClassName ? this.props.containerClassName : '';
    const tooltipTrigger = (this.props.showRangeLabels) ? ['hover', 'focus'] :  [];
    
    const timeLabel = ( 
      <div className={`ev-auto-timeLabelWrapper ${this.props.disabled ? 'disabled' : '' }`}>
        {UIConstructionUtils.getIcon(Icons.calendar, 'calendarIconWrapper')}
        <ConditionalRendering if={!this.props.hideTimeLabel}>
          <span className="dateText ev-auto-dateText">
            {this.getDateRangeString()}
          </span>
        </ConditionalRendering>
        <ConditionalRendering if={!(this.props.noCaret || this.props.disabled)}>
          <i className="caret caretIcon"></i>
        </ConditionalRendering>
      </div>
    );

    return(
      <div onClick={UIConstructionUtils.stopPropagation} className={`dateRangePickerWrapper ev-auto-dateRangePickerWrapper ${componentClassName}`}>
        <OverlayTrigger
          rootClose placement={this.props.hoverDirection || "left"} trigger={tooltipTrigger}
          overlay={
            <Tooltip placement={this.props.hoverDirection || "left"} className="in" >
              {this.getDateFormattedRange()}
            </Tooltip>
          }
        >
          <div>  {/* we need this tag for the tooltip to work*/}
            {  this.props.disabled ? timeLabel
              : <BsDateRangePicker
                ref={ref => this.ref = ref}
                {...this.props}
                locale={{
                  format: this.props.showDateOnly ? DateTimeUtils.DATE_PICKER_INPUT_DATE_FORMAT : DateTimeUtils.DATE_PICKER_INPUT_DATETIME_FORMAT,
                  relativeDateConverter: RelativeDateConverter,
                  relativeStart: this.state.relativeFrom,
                  relativeEnd: this.state.relativeTo,
                  startReferenceDate: this.getStartReferenceDate,
                  rootClassName: this.props.rootClassName,
                }}
                ranges={this.getRelativeRanges()}
                onApply={this.onDateRangeChange}
                autoUpdateInput={false}
                showDropdowns
                startDate={this.state.from}
                endDate={this.state.to}
              >
                {timeLabel}
              </BsDateRangePicker>
            }
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}
