/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Button, FormControl, Image, Row, Col} from 'react-bootstrap';
import Icons from './Icons';

export default class LoginForm extends React.Component {

  componentDidMount(){
    if (!this.props.expired) {
      this.usernameRef.focus();
    }
  }
  render() {
    const buttonDisabled
      = !(this.props.usernameValid && this.props.passwordValid)
       || this.props.expired 
       || this.props.loading;
    const loginFailed = this.props.loginStatus ? null :
          <Row>
            <div className="alert alert-danger" >
                <strong> <i className={`ace-icon ${Icons.close}`}></i> &nbsp;
                Login Failed!
                </strong>
                <br /> Insert a valid username and password.<br/>
            </div>
          </Row>;
    const usernameLabel = this.props.usernameValid === true
    || this.props.usernameValid === null ? null :
      <span className = "warning-span">Please enter a valid username </span>;
    const passwordLabel = this.props.passwordValid === true
    || this.props.passwordValid === null ? null :
      <span className ="warning-span">Please enter a valid password </span>;

    return (
      <div id="page-content-wrapper" role="main">
          <div className="row">
              <Col lg={12} className='block-center'>
                  <Image className="logo-img center-block"
                    src={this.props.logo}/>
              </Col>
              <form acceptCharset="UTF-8" id="login-form"
                onSubmit={this.props.loginAction}
                className="block-center col-lg-4 col-xs-8 loginForm">
                 <Row>
                    <Col xs={12}>
                      <h5 className="welcome-text break-word">
                        {this.props.loginTitle}
                      </h5>
                    </Col>
                 </Row>
                 {loginFailed}
                 {
                   this.props.expired ? <Row>
                      <div className="alert alert-warning" >
                        Your license has expired. Please contact support.
                      </div>
                    </Row>
                   : <React.Fragment>
                      <Row>
                        <Col xs={12}>
                          <FormControl type="text"
                            placeholder="Username"
                            className="input-text"
                            value = {this.props.username}
                            onChange={this.props.validateName}
                            disabled={this.props.expired}
                            inputRef={(ref)=>{this.usernameRef=ref}}
                            />
                            <span className = "warning-span">
                              {usernameLabel}
                            </span>
                        </Col>
                      </Row>
                      <Row>
                      <Col xs={12}>
                        <FormControl type="password"
                          placeholder="Password"
                          className="input-text"
                          value = {this.props.password}
                          onChange={this.props.validatePassword}
                          disabled={this.props.expired}
                          />
                          <span className = "warning-span">
                            {passwordLabel}
                          </span>
                      </Col>
                    </Row>
                    <div className="separator row"></div>
                    <Row>
                        <Col xs={4} className='pull-right'>
                            <Button disabled={buttonDisabled} type="submit"
                              className="col-xs-12" bsStyle="primary">
                                Login
                            </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                 }
                </form>
             </div>
         </div>
    );
  }
}
