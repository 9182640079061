/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import Icons from 'templates/Icons';

export const tabs = {
  approvalEmail: {
    entryTask: 'approval-email-task',
    icon: Icons.approvalEmail,
    btnText: 'Approval Email',
    scriptTask: 'Approval Email',
    namespace: CommonWorkflowDefaults.APPROVAL_MAIL_TASK,
    taskNamespace: CommonWorkflowDefaults.USER_TASK_NAMESPACE,
    typeOfTask: 'APPROVAL_EMAIL_TASK',
  },
  notificationEmail: {
    entryTask: 'notification-email-task',
    icon: Icons.notificationEmail,
    btnText: 'Notification Email',
    scriptTask: 'Notification Email',
    namespace: CommonWorkflowDefaults.EMAIL_TASK_NAMESPACE,
    taskNamespace: CommonWorkflowDefaults.TASK_NAMESPACE,
    typeOfTask: 'NOTIFICATION_EMAIL_TASK',
  },
  ansibleTask: {
    entryTask: 'ansible-task',
    icon: Icons.ansibleAutomationTask,
    btnText: 'Ansible Script',
    scriptTask: 'Ansible Script',
    namespace: CommonWorkflowDefaults.ANSIBLE_TASK_NAMESPACE,
    taskNamespace: CommonWorkflowDefaults.TASK_NAMESPACE,
    typeOfTask: 'ANSIBLE_TASK',
  },
  reportDesigner: {
    entryTask: 'report-designer-task',
    icon: Icons.reportDesigner,
    btnText: 'Report Designer',
    scriptTask: 'Report Designer',
    namespace: CommonWorkflowDefaults.REPORT_DESIGNER_TASK,
    taskNamespace: CommonWorkflowDefaults.USER_TASK_NAMESPACE,
    typeOfTask: 'REPORT_DESIGNER_TASK',
  },
};