/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import CollapsableComponent from 'containers/CollapsableComponent';
import * as defaults from 'utils/defaults';
import * as FFAGDefaults from 'utils/ffagDefaults';
import FilterInputRow from './FilterInputRow';
import LabelWithListOfDataInput from 'inputs/compound/LabelWithListOfDataInput';
import { fromJS } from 'immutable';
import Icons from 'templates/Icons';
import * as FilterUtils from 'utils/filterUtils';


export default class RoutingSelectionInput extends React.Component{
  constructor(props){
    super(props);

    this.updateSelection = :: this.updateSelection;
    this.clearSelection = ::this.clearSelection;
    this.updateInputValues = :: this.updateInputValues;
    this.onChangeValue = :: this.onChangeValue;
    this.deleteField = :: this.deleteField;
    this.addField = :: this.addField;
    this.onChangeVariableName = :: this.onChangeVariableName;
  }

  updateInputValues(values, checkValidation){
    const routingFilter = fromJS(this.props.routingFilter).toJS();
    
    routingFilter.routingValues = values ;
    routingFilter.operation = defaults.refFilterOperation;
    this.props.onChange({routingFilter}, checkValidation);
  }

  onChangeValue(value, index){
    const routingValue = fromJS(this.props.routingFilter.routingValues[index]).toJS();
    routingValue.routingValue = value;
    this.props.routingFilter.routingValues[index] = routingValue;
    this.updateInputValues(this.props.routingFilter.routingValues, true);
  }

  deleteField(index){
    const values = fromJS(this.props.routingFilter.routingValues).toJS();
    values.splice(index, 1);
    this.updateInputValues(values, true);
  }
  addField(){
    const values = fromJS(this.props.routingFilter.routingValues || []).toJS();
    values.push({routingValue: ''});
    this.updateInputValues(values, false);
  }

  getInputValue(value){
    return value.routingValue;
  }
  getInputWithMultibleValues(item, selectedField){
    return(
      <CollapsableComponent
        title={FFAGDefaults.LABELS.SELECTION_SECTION}
        collapsableComponentIndex={0}
      >
        <LabelWithListOfDataInput
          label={selectedField.fieldName}
          operation={defaults.refFilterOperation}
          inputsvalue={item.routingValues}
          addField={this.addField}
          deleteField={this.deleteField}
          onChangeValue={this.onChangeValue}
          getInputValue={this.getInputValue}
          disableComponent={this.props.disableComponent}
          validation={this.props.validationOutcome.routingList || []}/>
    </CollapsableComponent>
  );
}

  updateSelection(newSelection){
    const routingFilter = fromJS(this.props.routingFilter).toJS();
    
    routingFilter.selection = newSelection;
    routingFilter.operation = defaults.refFilterOperation;
    routingFilter.variable = null;
    routingFilter.filterValueType = FilterUtils.getFilterValueType(newSelection.selectionType);

    this.props.onChange({routingFilter}, false, false, false, {isPlaceholdersChanged: true});
  }

  onChangeVariableName(name){
    if(!_.isEqual(this.props.routingFilter.variable?.name, name)){
      const routingFilter = fromJS(this.props.routingFilter).toJS();
      routingFilter.filterValueType = FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value;
      routingFilter.variable = {name};
      routingFilter.selection = null;

      routingFilter.operation = defaults.refFilterOperation;

      this.props.onChange({routingFilter});
    }
  }

  clearSelection(){
    this.updateSelection();
  }

  getFilterInputRow (item, selectedField){
    return (
      <Row>
        <FilterInputRow
          key={0}
          index={0}
          fieldsList={[selectedField]}
          attribute={selectedField}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          updateSelection={this.updateSelection}
          selection={item.selection}
          isRefFilter={selectedField ? selectedField.ref : null}
          networkElementDataStructure={selectedField ? selectedField.refType : null}
          deleteIcon={Icons.ban}
          componentTitle={this.props.isDataSourceRecordsForAttributes ? FFAGDefaults.LABELS.NE_FILTER_SECTION : FFAGDefaults.LABELS.SELECTION_SECTION}
          buttonTooltip={FFAGDefaults.CLEAR_SELECTION}
          deleteFilter={this.clearSelection}
          disableChange
          disableDelete={item.disableDelete}
          disableComponent={this.props.disableComponent}
          hideDeleteButton={this.props.isDataSourceRecordsForAttributes}
          operations={this.props.operations}
          validationOutcome={this.props.validationOutcome.selection}
          isTemplate={this.props.isTemplate}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          filterValueType={item.filterValueType}
          variable={item.variable}
          hasVariables={this.props.hasVariables}
          onChangeVariableName={this.onChangeVariableName}
          />
      </Row>
    );
  }

  getCollapseConetent(){
    if(this.props.selectedField){
      const isRefField = this.props.selectedField
        && this.props.selectedField.ref;
      const inputRow = isRefField ? this.getFilterInputRow(this.props.routingFilter, this.props.selectedField) :
        this.getInputWithMultibleValues(this.props.routingFilter, this.props.selectedField);

      return (
        <Col>
         {inputRow}
        </Col>
      );
    }
    return null;
 }

  render(){
    const collapsedContent = this.getCollapseConetent();

    return (
      collapsedContent
    );
  }

}
