/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
import * as EmailDefaults from 'utils/EmailDefaults';
import {mail} from 'utils/CommonValidationSchema';

const SCHEMA = {
  subject: Joi.string()
		.label(EmailDefaults.LABELS.SUBJECT)
		.required()
    .error(ValidationUtils.getErrMsgString),
  mail,
  mails: Joi.object()
    .keys({
      mails:Joi.array()
        .label(EmailDefaults.LABELS.EMAIL)
        .error(ValidationUtils.getErrMsgArray(EmailDefaults.LABELS.EMAIL))
        .when('fieldKeyword', [{
          is: 'toMail',
          then: Joi.array().min(1),
          otherwise: Joi.array().min(0),
        }])
        .max(EmailDefaults.MAX_EMAILS_COUNT)
    }).unknown(),
}

export const validationFunction = ValidationUtils.validateSchema(SCHEMA);