/* Copyright 2019 Greyskies. All Rights Reserved. */

import {ORGANIZATION_GROUP}  from 'utils/defaults';
/** @deprecated */

export function getNodeTitle(treeNode) {
  return treeNode.name || treeNode.dataStructureName;
}

export function canBeHighlighted(treeNode, stateSelectedGroup){
  return (
    stateSelectedGroup === ORGANIZATION_GROUP ?
    treeNode.nodeType === ORGANIZATION_GROUP
    : !treeNode.isRoot && treeNode.nodeType !== ORGANIZATION_GROUP
      && !treeNode.organizationNode 
      && (treeNode.networkElementType === 'LIST_NODE' 
        || treeNode.networkElementType === 'CONTAINER')
  );
}