/* Copyright 2019 Greyskies. All Rights Reserved. */

import inherits from 'inherits';
import PropertiesActivator from 'bpmn-js-properties-panel/lib/PropertiesActivator';
import {getBusinessObject} from 'bpmn-js/lib/util/ModelUtil';
import {getTemplate} from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/Helper';
import * as BpnmDiagramUtils from 'utils/BpmnDiagramUtils.js';
import * as PropertiesHelper from './PropertiesHelper';
import * as SequenceFlowProps from './props/SequenceFlowProps';
import * as TabsInfo from './props/TabsInfo';
import * as OutputParameters from './groups/OutputParameters';
import _ from 'lodash';
import {Utils, FieldTypes} from 'js-utils';

function handleOutputProps(group, element, bpmnFactory, elementTemplates, translate, outputsInfo){
  const template = getTemplate(element, elementTemplates);

  if (template) {
    return;
  }

  const outputParametersEntry = OutputParameters.getOutputParametersEntry(
    element, bpmnFactory, translate, outputsInfo);

  group.entries = group.entries.concat(outputParametersEntry.entries);
}

function createTabGroups(element, translate, bpmnFactory, elementTemplates, tabTypeProps) {
  const inputsInfo = tabTypeProps.inputsInfo;
  const outputsInfo = tabTypeProps.outputsInfo;

  const reactHandler = PropertiesHelper.getReactHandler('propview', '__reactEventHandlers');
  // Create groups 
  const inputPropsGroup = {
    id: inputsInfo.id,
    label: inputsInfo.label,
    entries: [],
  };
  const outputPropsGroup = {
    id: outputsInfo.id,
    label: outputsInfo.label,
    entries: [],
  };
  const businessObject = getBusinessObject(element);

  PropertiesHelper.prepareExtensionElements(businessObject, 
    tabTypeProps.className, 
    inputsInfo, 
    outputsInfo, 
    bpmnFactory, tabTypeProps.isUserTask);
  Object.values(inputsInfo.inputs).forEach(input => {
    inputPropsGroup.entries.push(PropertiesHelper.getEntry(input, translate, element, reactHandler, bpmnFactory, null, businessObject));
  });

  if(outputsInfo.outputs){
    handleOutputProps(outputPropsGroup, element, bpmnFactory, 
      elementTemplates, translate, outputsInfo);
  }


  return {
    id: tabTypeProps.id,
    label: tabTypeProps.label,
    groups: [
      inputPropsGroup,
      outputPropsGroup,
    ],
  };
}

function createFlowTabGroups(element, translate, bpmnFactory){  
  const flowTabGroup = SequenceFlowProps.flowProps(element, translate, bpmnFactory);

  return [
    flowTabGroup,
  ];
}

function prepareVariableAsOption(variable){
  return {name: variable.parameterName, value: '${' + variable.parameterName + '}'};
}

function createReportPlaceHolders(element, translate, bpmnFactory, elementTemplates, inputPropsGroup){
  const businessObject = getBusinessObject(element);
  const reportId = PropertiesHelper.getInputParamValueAtIndex(element, 0);
  const reactHandler = PropertiesHelper.getReactHandler('propview', '__reactEventHandlers');
  const reportPlaceHolders = (reactHandler.reportsDetails[reportId] || {}).placeHolders;
  let parentIndex = 0;

  const inputParamsArray = businessObject.get('extensionElements').values[1].inputParameters;
  
  const placeHoldersValueMap = getPlaceHoldersValueMap(inputParamsArray);

  (reportPlaceHolders || []).forEach((item) => {
    const newInput = {
      id: item.placeholder.id,
      label: `${item.placeholder.name} *`,
      inputTag: item.placeholder.id,
      modelProperty: 'val',
      type: 'selectBox',
      defaultValue: null,
      index: ++parentIndex,
      inputOptions: {
        getSelectOptions: (reactHandler) => {
          const options = [];

          if(!FieldTypes.isRef(item.placeholder.type.dataType)){
            (reactHandler.internalVariables || []).forEach(variable => {
              options.push(prepareVariableAsOption(variable));
            });
          }
          (reactHandler.externalVariables || []).forEach(variable => {
            if(!Utils.isEmpty(variable.parameterName)
              && variable.attributeType && isAttributesTypeEqual(variable.attributeType, item.placeholder.type)){
              options.push(prepareVariableAsOption(variable));
            }
          });

          return options;
        },
        indexClearedOnChange: [],
      },
    };
    
    const inputValue = placeHoldersValueMap[item.placeholder.id] || newInput.defaultValue;

    (inputParamsArray || []).splice(newInput.index, 0, 
      PropertiesHelper.createInputParamTag(newInput.inputTag, bpmnFactory, inputValue, null));
    inputPropsGroup.entries.push(PropertiesHelper.getEntry(newInput, translate, element, reactHandler, bpmnFactory, null,businessObject));
  });

  return inputPropsGroup;
}

function getPlaceHoldersValueMap(inputParamsArray){
  const placeHoldersValueMap = {};
  const placeHolders = (inputParamsArray || []).splice(1, inputParamsArray.length - 1);

  placeHolders.forEach(inputParam => {
    placeHoldersValueMap[inputParam.name] = inputParam.value;
  });

  return placeHoldersValueMap;
}

function isAttributesTypeEqual(variableType, placeHolderType){
  if(FieldTypes.isRef(variableType.dataType) && FieldTypes.isRef(placeHolderType.dataType)){
    return _.isEqual(variableType.refType, placeHolderType.refType);
  }

  return variableType.dataType == placeHolderType.dataType;
}

export default function CustomPropertiesProvider(eventBus, bpmnFactory, canvas,
  elementRegistry, elementTemplates, translate) {

  PropertiesActivator.call(this, eventBus);

  this.getTabs = function(element) {
    if(BpnmDiagramUtils.isAnEmailTask(element)){
      return [
        createTabGroups(element, translate, bpmnFactory, elementTemplates, TabsInfo.notificationEmailProps),
      ];
    } else if(BpnmDiagramUtils.isAnApprovalMail(element)){
      return [
        createTabGroups(element, translate, bpmnFactory, elementTemplates, TabsInfo.approvalEmailProps),
      ];
    }else if(BpnmDiagramUtils.isAnAnsibleTask(element)){
      return [
        createTabGroups(element, translate, bpmnFactory, elementTemplates, TabsInfo.ansibleProps),
      ];
    }else if(BpnmDiagramUtils.isAReportDesigner(element)){
      const tabGroups = createTabGroups(element, translate, bpmnFactory, elementTemplates, TabsInfo.reportDesignerProps);
     
      createReportPlaceHolders(element, translate, bpmnFactory, elementTemplates, tabGroups.groups[0]);
      
      return [tabGroups];
    } else if(BpnmDiagramUtils.isSequenceFlow(element)){
      return [{
        id: 'flowTab',
        label: 'Sequence Flow',
        groups: createFlowTabGroups(element, translate, bpmnFactory)
      }];
    }

    return [];
  };
}

inherits(CustomPropertiesProvider, PropertiesActivator);