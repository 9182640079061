/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import 'styles/dataSelection/trees/commonTree/commonRCTree.less';
import { Row, Col } from 'react-bootstrap';
import * as TreeNodesStructureHelper from './TreeNodesStructureHelper';

export default class CommonRCTree extends React.Component {

  constructor(props) {
    super(props);
    this.onDrop = :: this.onDrop;
    this.renderTitle = :: this.renderTitle;
    this.renderNode =:: this.renderNode;
    this.renderTreeNodes =:: this.renderTreeNodes;
    this.expandedKeys = [];
    TreeNodesStructureHelper.getAllNodeKeysWithChildren(props.treeData, this.expandedKeys);
  }

  onDrop(info) {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;

    if (dropKey !== dragKey) {
      const dropPos = info.node.pos.split("-");
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const data = [...this.props.treeData];

      // Find dragObject
      let dragObj;
      let dropObj;

      TreeNodesStructureHelper.traverseTreeNodes(data, dragKey, 'key', (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (dropPosition === 0) {
        // Drop on the content
        TreeNodesStructureHelper.traverseTreeNodes(data, dropKey, 'key', (item) => {
          // eslint-disable-next-line no-param-reassign
          item.children = item.children || [];
          // where to insert
          item.children.unshift(dragObj);
          dropObj = item;
        });
      } else {
        // Drop on the gap (insert before or insert after)
        let ar = [];
        let i = -1;

        TreeNodesStructureHelper.traverseTreeNodes(data, dropKey, 'key', (item, index, arr) => {
          ar = arr;
          i = index;
          dropObj = item;
        });
        if (ar) {
          if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
          } else {
            ar.splice(i + 1, 0, dragObj);
          }
        }
      }
      this.expandedKeys = [];
      TreeNodesStructureHelper.getAllNodeKeysWithChildren(data, this.expandedKeys);
      this.props.onDropChange(data, dragObj, dropObj, dropPosition);
    }
  }

  renderTitle(dataNode, isRoot) {
    if (typeof this.props.renderTitle == 'function') {
      return this.props.renderTitle(dataNode, isRoot);
    }

    return dataNode.title;
  }

  renderNode(item, isRoot = false) {
    return (
      <TreeNode
        className={`treeNodeWrapper ${isRoot ? 'parentPlaceholder' : 'childPlaceholder'}`}
        switcherIcon={this.props.switcherIcon}
        key={item.key} isLeaf={!item.children} title={() => this.renderTitle(item, isRoot)}
      />
    );
  }

  renderTreeNodes(data, treeLevel) {
    return data.map((item) => {
      const isRoot = treeLevel == 1;

      if (item.children) {
        const newTreeLevel = treeLevel + 1;

        return (
          <TreeNode title={() => this.renderTitle(item, isRoot)} key={item.key}
            switcherIcon={this.props.switcherIcon}
            className={`treeNodeWrapper ${isRoot ? 'parentPlaceholder' : 'childPlaceholder'}`}
          >
            {this.renderTreeNodes(item.children, newTreeLevel)}
          </TreeNode>
        );
      }

      return this.renderNode(item, isRoot);
    });
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Tree
            onDrop={this.onDrop}
            expandedKeys={this.expandedKeys}
            draggable={this.props.draggable}
            className={'treeWrapper'}
            autoExpandParent={this.props.autoExpandParent}
            selectable={this.props.selectable}
            showLine={this.props.showLine}
            showIcon={this.props.showIcon}
            switcherIcon={this.props.switcherIcon}
            virtual={this.props.virtual}
            allowDrop={this.props.allowDrop}
            height={this.props.height}
            itemHeight={this.props.itemHeight}
            onDragEnd={this.props.onDragEnd}
            onDragStart={this.props.onDragStart}
          >
            {this.renderTreeNodes(this.props.treeData, 1)}
          </Tree>
        </Col>
      </Row>
    );
  }
}


