/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import  CollapsableComponent from 'containers/CollapsableComponent';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import OptionInput from './OptionInput';
import {MsgDefaults} from 'js-utils';


/** @deprecated */
class OptionsListInput extends Component {
  constructor(props) {
    super(props);

    this.renderOptions = ::this.renderOptions;
    this.renderOption = ::this.renderOption;
  }

  renderOption(options){
    return options.map( (option, index) => {
      return( 
      <div className="row-padding">
        <OptionInput
          index={index}
          value={this.props.valueName? option[this.props.valueName] : option}
          onChange={this.props.onChangeOption}
          entityType={this.props.entityType}
          validation={this.props.validation[index]}
          prefixLabel={this.props.prefixLabel}
          suffixLabel={this.props.suffixLabel}
          buttons={this.props.buttons}
          placeholder={this.props.placeholder}
          disabledOption={this.props.disabledOption}
        />
      </div>
      );
    });
  }

  renderOptions(options){
    const buttons = this.props.disableAddOption ? [] : [
      ButtonsConstructor.add(
        this.props.addNewOption,
        MsgDefaults.getAddNewTitle(this.props.entityType),
      ),
    ];

    return (
      <Col xs={12} >
        <CollapsableComponent
          title={this.props.collapseLabel} 
          initiallyCollapsed={(options || []).length < 1} 
          validation={this.props.collapsaleValidation}
          buttons={buttons}
        >
          <div className={`${(options || []).length > 0 ?  "expandableArea" : "" }`}>
            {this.renderOption(options || [])}
          </div>
        </CollapsableComponent>
      </Col>
    );
  }
  render(){
    return (
      <Row className={this.props.className}>
        {this.renderOptions(this.props.options)}
      </Row>
    );
  }
}

export default OptionsListInput;
