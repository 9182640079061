/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import ValidationOutput from 'containers/ValidationOutput';

const SortbyAggregate = (props) => {
  const aggregates = props.fields.filter((aggregate) => aggregate.selectedField && aggregate.aggregationType);
  const aggregateNamesArray = aggregates.map(aggregate => {
    const displayedFieldName = DataSelectionCommonUtils.getCompoundAggregateFieldName(aggregate, props.repeatedAggFieldsData);
    const aggregateId = UIConstructionUtils.getKeyFromId(aggregate);

    return (
      <option
        key={`${aggregateId}-${displayedFieldName}`}
        value={aggregateId} >
        {displayedFieldName}
      </option>
    );
  });
  const validationOutput = <ValidationOutput validation={props.validation} />;

  return (
    <Row>
      {aggregates && aggregates.length > 0 ?
        <Col xs={12}>
          <table className="full-width">
            <thead>
              <tr>
                {aggregates && aggregates.length === 0 ? null : <th>Sort By</th>}
              </tr>
            </thead>
            <tbody>
              {
                aggregateNamesArray && aggregateNamesArray.length === 0 ? null :
                  <MultipleSelect
                    filter={false}
                    value={props.topNField}
                    placeholder='Sort By'
                    multiple={false}
                    onChange={props.topNAggregateChangeHandler}
                    options={aggregateNamesArray}
                  />
              }
            </tbody>
          </table>
          {validationOutput}
        </Col> : null}
    </Row>
  );
};

export default SortbyAggregate;
