/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';

/* We add this wrapper to avoid performance issues 
   for inline functions in rendering. */
export default class ButtonWrapper extends React.Component{
  constructor(props) {
    super(props);
    this.onClick = ::this.onClick;
    this.getButtonComponent = ::this.getButtonComponent;
  }
  
  onClick(){
    this.props.onClick(this.props.item);
  }

  getButtonComponent(){
    switch(this.props.type){
      case 'TooltippedButton':
        return ButtonsConstructor.getTooltippedButton(
          this.props.tooltip,
          this.props.icon,
          this.props.className,
          this.props.disabled,
          this.onClick);
      default: 
        return null;
    }
  }

  render(){
    return (
      this.getButtonComponent()
    );
  }

}
