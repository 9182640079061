/* Copyright 2019 Greyskies. All Rights Reserved. */

import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import {getRoundRectPath} from 'bpmn-js/lib/draw/BpmnRenderUtil';
import {is} from 'bpmn-js/lib/util/ModelUtil';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import * as CustomTabs from './CustomTabs';

const HIGH_PRIORITY = 1500,
      TASK_BORDER_RADIUS = 2;

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, pathMap) {
    super(eventBus, HIGH_PRIORITY);
    this.bpmnRenderer = bpmnRenderer;
    this.pathMap = {
      ...pathMap,
      pathMap:{
        ...pathMap.pathMap,
        ...CommonWorkflowDefaults.iconsSvgMap,
      },
    };
  }

  canRender(element) {
    // only render tasks and events (ignore labels)
    return !element.labelTarget;
  }

  drawShape(parentGfx, element) {
    if(is(element, CommonWorkflowDefaults.TASK_NAMESPACE)){
      const selectedTab = Object.values(CustomTabs.tabs).filter(tab =>
         tab.namespace == element.businessObject.taskAction)[0];
      
      const typeOfTask = selectedTab.typeOfTask;

      return this.drawCustomTask(parentGfx, element, typeOfTask);
    } else{
      const shape = this.bpmnRenderer.drawShape(parentGfx, element);

      return shape;
    }
  }
 
  drawCustomTask(parentGfx, element, typeOfTask) {
    const task = this.bpmnRenderer.handlers[CommonWorkflowDefaults.TASK_NAMESPACE](parentGfx, element);
    const pathData = this.pathMap.getScaledPath(typeOfTask, {
      abspos: {
        x: 25,
        y: 25,
      },
    });

    this.bpmnRenderer._drawPath(parentGfx, pathData, {
      strokeWidth: 0.2,
      fill: 'black',
    });

    return task;
  }

  getShapePath(shape) {
    if (is(shape, CommonWorkflowDefaults.TASK_NAMESPACE)) {
      return getRoundRectPath(shape, TASK_BORDER_RADIUS);
    }

    return this.bpmnRenderer.getShapePath(shape);
  }

}