/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Row, Col, FormControl, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ValidationOutput from 'containers/ValidationOutput';
import {Utils} from 'js-utils';

const AddonLabelWrapper = (props) => (
  <InputGroup>
    <OverlayTrigger
      rootClose placement="bottom" trigger={['hover', 'focus']}
      overlay={<Tooltip placement="bottom" className="in">{props.labelTooltip}</Tooltip>}
    >
      <InputGroup.Addon className="data-input-label textEllipsis">{props.label}</InputGroup.Addon>
    </OverlayTrigger>
    {props.children}
  </InputGroup>
);

const bootstrapWrapper = (props) => {
  const suffixClassName = `${Utils.isBlank(props.inputSuffix) ? '' : 'hasPrefixSuffix'}`;

  return (
    <Col xs={props.xsSize || 12} sm={props.size || 6} className={props.componentClassName || ''}>
      <div className={`data-input ${props.className || ''} ${suffixClassName}`} data-prefix={props.inputPrefix ||''} data-suffix={props.inputSuffix ||''}>
        <Row>
        {props.label?
          <Col lg={props.labelSize || 3} xs={props.labelXsSize || 12}>
            <label className='data-input-label'>
            {props.label}
            </label>
          </Col>:null}
          <Col lg={props.inputSize || 9} xs={props.inputXsSize || 12} className={`data-input-field ${props.formControlContainerClassName ||'' } ` }>
            {props.children}
          </Col>
        </Row>
      </div>
    </Col>
  );
}
const DataInput = props => {
  const value = props.value;
  const InputType = props.isTextarea ? 'textarea' : FormControl;
  const InputTypeComponent 
    = <InputType
        className={ `input-text ${props.isTextarea ? 'form-control' : ''} ${props.disabled ? 'disabled-input' : ''}  ${props.formControlClassName || ''} `}
        type={props.password ? 'password' : props.type ? props.type : 'text'}
        value={props.hideValue ? '' : value}
        min={props.min}
        max={props.max}
        name={props.name}
        autocomplete={props.autocomplete}
        placeholder={props.placeholder && !props.disabled ? props.placeholder : null}
        disabled={props.disabled || false}
        onBlur={props.onBlur ? props.onBlur : null}
        onChange = {props.onChange} 
        onKeyPress={props.onKeyPress}
      />;

  if(props.pureInput){
    return InputTypeComponent;
  }

  let InputWrapper = bootstrapWrapper;

  if(props.addonLabel){
    InputWrapper = AddonLabelWrapper;
  }

  return(
    <InputWrapper {...props}>
      <Fragment>
        {InputTypeComponent}
        <ValidationOutput validation={props.validation}/>
      </Fragment>
    </InputWrapper>
  );
};

export default DataInput;
