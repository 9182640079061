/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import { Button} from 'react-bootstrap';
import Modal from 'containers/Modal';
import TreeItemView from 'dataSelection/records/TreeItemView';
import TreeWithPlaceholderOption from 'dataSelection/records/TreeWithPlaceholderOption';
import * as defaults from 'utils/defaults';
export default class RefFilterRow extends React.Component {

  constructor(props) {
    super(props);
    this.componentPlaceholder = this.props.componentPlaceholder || defaults.SELECT_NE;
    this.state = {
      showModal: false,
    };
    this.renderFilterOfTypeRef = :: this.renderFilterOfTypeRef;
    this.saveSelection = :: this.saveSelection;
    this.updateSelection = :: this.updateSelection;
    this.closeTreeModal = ::this.closeTreeModal;
    this.openTreeModal = :: this.openTreeModal;
    this.renderTreeModal = :: this.renderTreeModal;
  }

  closeTreeModal(){
    this.setState({showModal: false});
  }
  
  openTreeModal(selection){
    this.setState({showModal: true});
  }

  updateSelection(selection){
    this.setState({showModal: false}, () => {
      this.props.updateSelection(selection, this.props.index);
    });
  }

  saveSelection(){
    this.treeModelRef.checkValidationOnSave();
  }

  renderTreeModal(){
    return(
      <Modal bsSize="large"
        onHide={this.closeTreeModal}
        title={this.componentPlaceholder}
         show={!this.props.disableComponent && this.state.showModal}
         footerButtons={[
             <Button
             bsStyle="primary"
             onClick={this.saveSelection}
             disabled={this.props.disableComponent}>
               Select
             </Button>
         ]}>
            <TreeWithPlaceholderOption
              ref={(ref => this.treeModelRef = ref)}
              index={this.props.index}
              selection={this.props.selection}
              disabled={this.props.disableComponent}
              networkElementDataStructure={this.props.networkElementDataStructure}
              listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
              entityOptions={this.props.refFilterTreeOptions}
              getTreeNodesAction={this.props.getRoots}
              getSelectionFinalForm={this.props.getSelectionFinalForm}
              getNodePath={this.props.getNodePath}
              getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
              isTemplate={this.props.isTemplate}
              updateSelection={this.updateSelection}
              showNESelection={this.props.showNESelection}
              neSelectionPlaceholder={this.props.neSelectionPlaceholder}
              hideGroupElementSelection={this.props.hideGroupElementSelection}
              isGroupHidden={this.props.isGroupHidden}
              neDataStructures={this.props.neDataStructures}
              hasVariables={this.props.hasVariables}
              onChangeVariableName={this.props.onChangeVariableName}
              filterValueType={this.props.filterValueType}
              variable={this.props.variable}
              />
    </Modal>
    );
  }

  renderFilterOfTypeRef(){
    return (
      <TreeItemView
        selection={this.props.selection || {}}
        openSelection={this.openTreeModal}
        componentPlaceholder={this.componentPlaceholder}
        disableComponent={this.props.disableComponent}
        maxShownSelections={this.props.maxShownSelections}
        filterValueType={this.props.filterValueType}
        variable={this.props.variable}/>
    );
  }

  render() {
    return (
      <Fragment>
          {this.renderFilterOfTypeRef()}
          {this.renderTreeModal()}
      </Fragment>);
  }
}