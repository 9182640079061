/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {DropdownButton, MenuItem, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {getTooltippedIcon} from 'utils/UIConstructionUtils';

const DropdownButtonWrapper = (props) => {
  const menuItems = props.menuItems.map( (item, index) => {
    const getItem = (item) => (
      <MenuItem 
        key={index}
        disabled={item.disabled}
        onClick={item.disabled ? null : item.action}
        className={item.className}>
          {item.icon ? <i className={item.icon}></i> : null}
          {item.display}
      </MenuItem>
    );
    if(item.tooltip){
      return getTooltippedIcon(item.tooltip, getItem(item), item.tooltipPlacement);
    }    

    return getItem(item);    
  });

  const dropdownButton = (
    <DropdownButton noCaret id={props.dropdownButtonId}
      className={props.dropdownButtonClassName}
      title={props.dropdownButtonTitle}
      disabled={props.dropdownButtonDisabled}
    >
      {menuItems}
    </DropdownButton>
  );

  return (
    <OverlayTrigger
      rootClose placement="bottom" trigger={props.dropdownButtonTooltip ? ['hover', 'focus'] : []}
      overlay={
      <Tooltip className="in" >
        {props.dropdownButtonTooltip || ''}
      </Tooltip>
      }
    >
      {dropdownButton}
    </OverlayTrigger>
  );
};
export default DropdownButtonWrapper;