/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import CollapsableComponent from 'containers/CollapsableComponent';
import {Col} from 'react-bootstrap';
import {MAX_SHOWN_ITEMS}  from 'utils/defaults';
import {fromJS} from 'immutable';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import _ from 'lodash';

export default class RecordSelection extends Component {

  constructor(props) {
    super(props);
    this.getFields = this.getFields.bind(this);
    this.getSelections = this.getSelections.bind(this);
    this.getCollapsedContent = this.getCollapsedContent.bind(this);
  }

  getFields(fields){
    return fields.filter((field, index) => index < MAX_SHOWN_ITEMS).map(field => {
      return (
        <div className="paddBfive">
          {field.fieldName}
        </div>
      );
    });
  }

  getCollapsedContent(recordType, fields, seeMore){
    const collapsedContent 
    = <Col xs={12}>
        <div className="expandableArea break-word horizontal-divider">
          <Col xs={12} className="paddSides0 margBtm10">
            <label>Record Type:</label>
            <Col xs={12} md={11} mdOffset={1}>
              {recordType.name}
            </Col>
          </Col>
          {
            fields.length > 0 ? 
              <Col xs={12} className="paddSides0 margBtm10">
                <label>Selected Fields:</label>
                <Col xs={12} md={11} mdOffset={1}>
                {fields}
                </Col>
              </Col> 
            : null 
          } 
          <span className="pull-right text-right">
            {seeMore}
          </span>
        </div>
      </Col>;

    return collapsedContent;
  }

  getSelections(){
    const selections = this.props.selections.map((selection, index) => {
      const selectedItemsCount = selection.fields && selection.fields.length > 0 ?
      selection.fields.length : 0;
      const collapsedTitle = selection.recordType.name;
      const seeMoreCount = selectedItemsCount - MAX_SHOWN_ITEMS;
      const seeMore =  (seeMoreCount > 0) ? `${seeMoreCount} more` : null;
      const fields = this.getFields(selection.fields);
      const collapsedContent = this.getCollapsedContent(selection.recordType, fields, seeMore);

      return (
        <CollapsableComponent
          collapsableComponentIndex={index}
          title={collapsedTitle}
          initiallyCollapsed
          buttons={[ 
            ButtonsConstructor.remove(
              this.props.deleteSelection, this.props.deleteTooltip, false, true),
            ButtonsConstructor.edit(
              this.props.editSelection, this.props.editTooltip, false, true),
          ]}
        >
          {collapsedContent}
        </CollapsableComponent>
      );
    });

    return selections;
  }

  render(){
    return (this.getSelections());
  }
}
