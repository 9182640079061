/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import Modal from 'containers/Modal';
import {LABELS, PLACE_HOLDERS} from './DataModelTreeDefaults';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';

import MultipleSelect from 'inputs/simple/MultipleSelect';

const INPUT_ID_ATT_NAME = 'id';
const INPUT_TREEPATH_ATT_NAME = 'treePath';
const INPUT_NAME_ATT_NAME = 'name';

export default class DataModelTreeSelectionPopUp extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            selectedAttribute: props.selectedAttribute,
            selectedNetworkElement: props.selectedNetworkElement,
        };

        this.getNetworkElementTypesAsOptions = ::this.getNetworkElementTypesAsOptions;
        this.handleNetworkLevelChange = ::this.handleNetworkLevelChange;
        this.getAttributesAsOptions = ::this.getAttributesAsOptions;
        this.handleAttributeChanged = ::this.handleAttributeChanged;
        this.saveSelection = ::this.saveSelection;
        this.hideModal = ::this.hideModal;
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.initial){
            this.setState({
                selectedAttribute: nextProps.selectedAttribute,
                selectedNetworkElement: nextProps.selectedNetworkElement
            });
        }
    }

    getNetworkElementTypesAsOptions(){
        return UIConstructionUtils.wrapArrayInToSelectOptions(this.props.networkElementTypes,
                                               INPUT_ID_ATT_NAME,
                                               INPUT_ID_ATT_NAME,
                                               INPUT_TREEPATH_ATT_NAME);
    }

    getAttributesAsOptions(){
        return UIConstructionUtils.getCategorizedAttributesOptions(this.props.attributes,
                                               INPUT_ID_ATT_NAME,
                                               INPUT_ID_ATT_NAME,
                                               INPUT_NAME_ATT_NAME);
    }

    

    handleNetworkLevelChange(e){
        if(e.target.value != (this.state.selectedNetworkElement || {}).id){
            const selectedNetworkElement= this.props.networkElementTypes
                    .filter(NE => NE.id == e.target.value)[0];
            this.props.handleNetworkLevelChange(selectedNetworkElement);
            this.setState({selectedNetworkElement, selectedAttribute: null});
        }
    }

    handleAttributeChanged(e){
        if(e.target.value != (this.state.selectedAttribute || {}).id){
            const selectedAttribute = this.props.attributes.filter(
                attribute => attribute.id == e.target.value)[0];
            this.setState({selectedAttribute});
        }
    }

    saveSelection(){
        this.props.save(this.state.selectedAttribute, this.state.selectedNetworkElement);
    }

    hideModal(){
        this.props.showModal(false); 
    }

    render(){
        return (
            <Modal bsSize="large"
                onHide={this.hideModal}
                title={this.props.popupTitle}
                show={this.props.show}
                footerButtons={[
                <Button
                    bsStyle="primary"
                    onClick={this.saveSelection}
                    disabled={this.state.selectedAttribute == null}>
                    {LABELS.SELECT_BUTTON_LABEL}
                </Button>
                ]}
            >
                <Row>
                    <Col xs={12}>
                        <MultipleSelect placeholder={PLACE_HOLDERS.SELECT_NETWORK_ELEMENT}
                                        options={this.getNetworkElementTypesAsOptions()}
                                        value={this.state.selectedNetworkElement ? this.state.selectedNetworkElement.id : null}
                                        onChange={this.handleNetworkLevelChange}/>
                    </Col>                
                </Row>
                <Row>
                    <Col xs={12}>
                        <MultipleSelect placeholder={PLACE_HOLDERS.SELECT_ATTRIBUTE}
                                        options={this.getAttributesAsOptions()}
                                        value={this.state.selectedAttribute ? this.state.selectedAttribute.id : null}
                                        onChange={this.handleAttributeChanged}
                                        grouped/>
                    </Col>
                </Row>
            </Modal>
        );
    }
}
    