/* Copyright 2020 Greyskies. All Rights Reserved. */

import cmdHelper  from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import {query} from 'min-dom';
import {validationFunction} from 'utils/validations/VariableValidationSchema';
import * as PropertiesHelper from '../PropertiesHelper';

function getNameFunctions(parameter, isOpen, id){
  return {
    id,
    getProperty: function(element, node) {
      return parameter.name;
    },
    setProperty: function(element, values, node) {
      return cmdHelper.updateBusinessObject(element, parameter, values);
    },
    validate: function(element, values, node) {
      const validation = {};
      const nameValue = values.name;

      validation.name = validationFunction('variableName', nameValue).label;

      return validation;
    },
    hidden: function(element, node) {
      return !isOpen();
    }
  }
}

function getValueFunctions(parameter, isOpen, options, id){
  return {
    id,
    get: function(element, node) {
      return {
        value: parameter.value,
      };
    },
    set: function(element, values, node) {
      values.value = values.value || undefined;
      return cmdHelper.updateBusinessObject(element, parameter, values);
    },
    hidden: function(element, node) {
      return !isOpen();
    },
    disabled: function() {
      return options.isDisabled;
    },
  };
}

export function getOutputParameterEntry(parameter, bpmnFactory, options, translate, outputsEntries) {
  
  const idPrefix = options.idPrefix; 
  const collapsible = entryFactory.collapsible({
    id: idPrefix + 'collapsible',
    cssClasses: [ 'bpp-collapsible--with-mapping' ],
    open: false,
    onRemove: options.onRemove,
    onToggle: options.onToggle,
    title: parameter.name,
    description: parameter.value,
    get: function() {
      return {
        title: parameter.name,
        description: parameter.value,
      };
    }
  });

  const isOpen = collapsible.isOpen;
  const nameProperty = outputsEntries.collapsibleItems.name;
  const valueProperty = outputsEntries.collapsibleItems.value ;
  const nameParam = PropertiesHelper.getEntry(nameProperty, 
    translate, null, null, null,
    getNameFunctions(parameter, isOpen, idPrefix + nameProperty.id));

  const valueParam = PropertiesHelper.getEntry(valueProperty, 
    translate, null, null, null,
    getValueFunctions(parameter, isOpen, options, idPrefix + valueProperty.id));

  return {
    setOpen : function(value) {
      const entryNode = query('[data-entry="' + collapsible.id + '"]');
      collapsible.setOpen(value, entryNode);
    },
    entries: [
      collapsible,
      nameParam,
      valueParam,
    ],
  };
};