/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import $ from 'jquery';
import multipleSelect from 'multiple-select';
import 'styles/inputs/simple/multipleSelect.less';

export default class MultipleSelect extends React.Component{
  constructor(props){
    super(props);
    
    this.currentOpts = props.options ? props.options : null ;
    this.onClose = ::this.onClose;
    this.onClick = ::this.onClick;
    this.onCheckAll = ::this.onCheckAll;
    this.onUncheckAll = ::this.onUncheckAll;
    this.latestValue = null;
  }

  componentDidMount(){
    const filter = typeof this.props.filter !== 'undefined' ?
      this.props.filter : true;
    const self = this;
    const single = !this.props.multiple;

    this.multipleSelect = $(this.select);

    const onCheckAll = !single && !this.props.limit ? this.onCheckAll : null ;
    const onUncheckAll = !single && !this.props.limit ? this.onUncheckAll  : null;
    const multipleValueDisplay = this.props.multipleValueDisplay || false;
    const selectAll = (this.props.selectAll !== null &&  this.props.selectAll !== undefined) ? this.props.selectAll : !this.props.limit;
    const allSelected = single || multipleValueDisplay || !selectAll? false : 'All Selected.';

    this.multipleSelect.multipleSelect({
      placeholder:self.props.placeholder,
      width: '100%',
      filter,
      single,
      selectAll,
      onClick: this.onClick,
      onClose: this.onClose,
      onCheckAll,
      onUncheckAll,
      allSelected,
      hideOptgroupCheckboxes:true,
    });

    this.onDisableOrEnable();
    this.onSetSelects();
  }


  componentDidUpdate(){
    if(this.currentOpts){
      if(this.currentOpts.length !== this.props.options.length){
        this.multipleSelect.multipleSelect('refresh');
        this.currentOpts = this.props.options;
      }else{
        for(let index = 0; index < this.currentOpts.length; index++){
          if(this.currentOpts[index].key !== this.props.options[index].key){
            this.multipleSelect.multipleSelect('refresh');
            this.currentOpts = this.props.options;
            break;
          }
        }
      }
    }else {
      this.multipleSelect.multipleSelect('refresh');
    }
    if(this.props.grouped|| this.props.alwaysUpdate){
      this.multipleSelect.multipleSelect('refresh');
    }

    this.onDisableOrEnable();
    this.onSetSelects();

  }

  onDisableOrEnable(){
    const multipleSelectDisable = this.props.disabled ? 'disable' : 'enable';

    this.multipleSelect.multipleSelect(multipleSelectDisable);
  }

  onSetSelects(){
    if(this.props.value == null){
      this.multipleSelect.multipleSelect('setSelects', null);
      this.latestValue = null;
    }else if(this.props.value != null || this.props.value != undefined){
      const value = this.props.multiple ? this.props.value : [this.props.value];

      this.multipleSelect.multipleSelect('setSelects', value);
      this.latestValue = value;
    }else{
      this.latestValue = this.multipleSelect.multipleSelect('getSelects');
    }
  }

  onClose(){
    const result = {target:{}};
    const selected = this.multipleSelect.multipleSelect('getSelects');

    result.target.name = this.multipleSelect[0].name;
    if(this.props.multiple){
      result.target.value = selected;
      if(this.props.limit && selected.length > this.props.limit){
        this.multipleSelect.multipleSelect('setSelects', this.latestValue);

        return;
      }
    }else if(selected.length > 1){
      result.target.value = selected.filter((val) => {
        return val != this.latestValue[0];
      })[0];
    } else if(selected.length === 1){
      result.target.value = selected[0];
    }
    this.latestValue = this.props.multiple ?
    result.target.value
    : [result.target.value];
    if(this.props.onClose){
      this.props.onClose(result);
    }
  }

  onClick(){

    const result = {target:{}};
    const selected = this.multipleSelect.multipleSelect('getSelects');

    result.target.name = this.multipleSelect[0].name;
    if(this.props.multiple){
      result.target.value = selected;
      if(this.props.limit && selected.length > this.props.limit){
        this.multipleSelect.multipleSelect('setSelects', this.latestValue);

        return;
      }
    }else if(selected.length > 1){
      result.target.value = selected.filter((val) => {
        return val != this.latestValue[0];
      })[0];
    } else if(selected.length === 1){
      result.target.value = selected[0];
    }
    this.latestValue = this.props.multiple ?
     result.target.value : [result.target.value];
    this.multipleSelect.multipleSelect('setSelects', this.latestValue);
    if(this.props.onChange){
      this.props.onChange(result);
    }
  }

  onCheckAll(){
    const result = {target:{}};

    result.target.value = this.multipleSelect.multipleSelect('getSelects');
    result.target.name = this.multipleSelect[0].name;
    if(this.props.onChange){
      this.props.onChange(result);
    }
  }

  onUncheckAll(){
    if(this.props.onChange){
      this.props.onChange({
        target : {
          value : this.multipleSelect.multipleSelect('getSelects'),
          name: this.multipleSelect[0].name,
        },
      });
    }
  }

  render(){
    return(
      <div className={`multipleSelect ${this.props.mainClassName || ''}`}>
        <select name={this.props.name} ref={ref => this.select = ref}
                multiple='multiple'
                className={`input-select ${this.props.className || ''}`}>
          {this.props.options}
        </select>
        {
          this.props.limit && !this.props.unit?
          <p>{this.props.limit} selects maximum.</p>
          : null
        }
        {
          this.props.limit && this.props.unit?
          <p>Maximum {this.props.limit} {this.props.unit}</p>
          : null
        }
      </div>
    );
  }

}
