/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Radio from 'inputs/simple/Radio';
import RadioGroup from 'inputs/simple/RadioGroup';
import NumericInputComponent from 'inputs/simple/NumericInputComponent';

export default class TopNSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nValue: props.nValue,
    };
  }

  render() {
    return <React.Fragment>
      <Row>
        <Col xs={12}>
          <RadioGroup >
            <Row>
              <Col xs={6}>
                <Radio
                  checked={this.props.nDirection == 'DESC'}
                  onChange={this.props.setTopNDirection}
                  value='DESC'
                  label='Top N'
                />
              </Col>
              <Col xs={6}>
                <Radio
                  checked={this.props.nDirection == 'ASC'}
                  onChange={this.props.setTopNDirection}
                  value='ASC' label='Bottom N' />
              </Col>
            </Row>
          </RadioGroup>
        </Col>
      </Row>
      <Row className='margBtm10'>
        <NumericInputComponent
          size={12}
          label='N value'
          labelSize={3}
          inputSize={9}
          min={1}
          max={100}
          inputType='Integer'
          className='datainput-no-margin bold-text nValue-input'
          onChange={(e) => {
            this.setState({
              nValue: e.target.value,
            });
          }}
          onBlur={this.props.setTopNValue}
          value={this.state.nValue}
          validation={this.props.validation}
        />
      </Row>
    </React.Fragment>
  }
}