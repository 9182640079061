/* Copyright 2019 Greyskies. All Rights Reserved. */

import { is, getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import * as ElementHelper from 'bpmn-js-properties-panel/lib/helper/ElementHelper';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import * as TabsInfo from './TabsInfo';
import * as PropertiesHelper from '../PropertiesHelper';

export function flowProps(element, translate, bpmnFactory) {
  const businessObject = getBusinessObject(element);

  if(!businessObject.conditionExpression){
    const conditionExpressionTag = 
    ElementHelper.createElement(CommonWorkflowDefaults.EXPRESSION_NAMESPACE, 
      { 'xsi:type': 'bpmn2:tFormalExpression'}, null, bpmnFactory);
    
    businessObject.conditionExpression = conditionExpressionTag;
  }
  
  return {
    id: 'flowCondition',
    label: 'Flow Condition',
    entries: [
      PropertiesHelper.getEntry(TabsInfo.sequenceFlowProps.inputs.condition, 
        translate, null, null, null,
        getConditionFunctions(businessObject)),
    ],
  };
}

function getConditionFunctions(businessObject){
  return {
    get: function(element, node) {
      const val = businessObject.conditionExpression.get('body');
          
      return { val };
    },
    set: function(element, values, node) {
      businessObject.conditionExpression.body = values.val;
    },
  };
}

