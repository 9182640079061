/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import {MsgDefaults} from 'js-utils';
import {is} from 'bpmn-js/lib/util/ModelUtil';
import Icons from 'templates/Icons';
import * as CustomTabs from './CustomTabs';

export default class CustomContextPad {
  constructor(config, contextPad, create, elementFactory, injector, translate) {
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    this.appendTaskStart = :: this.appendTaskStart;
    this.appendTask = :: this.appendTask;
    this.createShapeAndSetItsAttributes = :: this.createShapeAndSetItsAttributes;
    this.appendTaskByTab = :: this.appendTaskByTab;
    this.appendTaskStartByTab = :: this.appendTaskStartByTab;
    this.getContextPadEntries = :: this.getContextPadEntries;
    this.getEntries = :: this.getEntries;
    if (config.autoPlace !== false) {
      this.autoPlace = injector.get('autoPlace', false);
    }

    contextPad.registerProvider(this);
  }

  createShapeAndSetItsAttributes(taskName, taskAction, taskType){
    const shape = this.elementFactory.createShape({ type: taskType});

    shape.businessObject.name = taskName;
    shape.businessObject.taskAction = taskAction;
    return shape;
  }

  appendTask(event, element, taskName, taskAction, taskType) {
    if (this.autoPlace) {
      const shape = this.createShapeAndSetItsAttributes(taskName, taskAction, taskType);
      this.autoPlace.append(element, shape);
    } else {
      this.appendTaskStart(event, element, taskType);
    }
  }

  appendTaskByTab = (tab) => (event, element) => {
    this.appendTask(event, element, tab.scriptTask,
      tab.namespace, tab.taskNamespace);
  }

  appendTaskStart(event, element, taskName, taskAction, taskType) {
    const shape = this.createShapeAndSetItsAttributes(taskName, taskAction, taskType);

    this.create.start(event, shape, element);
  }

  appendTaskStartByTab = (tab, element) => (event) => {
    this.appendTaskStart(event, element, tab.scriptTask, tab.namespace, tab.taskNamespace);
  }

  shouldShowCustomTasks(element){
    return (
      is(element, CommonWorkflowDefaults.TASK_NAMESPACE) 
    || is(element, CommonWorkflowDefaults.EXCLUSIVE_GATEWAY_NAMESPACE)
    || is(element, CommonWorkflowDefaults.START_EVENT_NAMESPACE)
    );
  }

  getEntries = (element) => (entries) => {
    delete entries['append.append-task'];
    delete entries['append.intermediate-event'];
    delete entries['replace'];
    delete entries['append.text-annotation'];

    entries['delete'] ? (entries['delete'].className = Icons.delete) : null;
    entries['connect'] ? (entries['connect'].className = Icons.workflowArrow) : null;
    entries['append.end-event'] ? (entries['append.end-event'].className = Icons.boldCircle) : null;
    entries['append.gateway'] ? (entries['append.gateway'].className = Icons.diamond) : null;
    
    if(this.shouldShowCustomTasks(element)){
      Object.values(CustomTabs.tabs).forEach( tab => {
        const appendTaskFun = this.appendTaskByTab(tab);
        const appendTaskStartFun = this.appendTaskStartByTab(tab, element);

        entries[`append.${tab.entryTask}`] = {
          group: 'model',
          className: tab.icon,
          title: MsgDefaults.getAppendButtonText(tab.btnText),
          action: {
            click: appendTaskFun,
            dragstart: appendTaskStartFun,
          },
        };
      });
    }

    return entries;
  }

  getContextPadEntries(element){
    return this.getEntries(element);
  }
}