/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {NumericInput, NumericInputComponent} from '../simple/SimpleInputs';

const PercentileInput = props =>{
	const Component = props.newComponent ? NumericInputComponent : NumericInput;

  return(
    <div style={{marginTop : '5px'}} className={`${props.className || ""}`}>
      <Component
         placeholder={props.placeholder}
         label={props.label? props.label : 'Value'}
         step= {props.step || 0.1}
         precision={props.precision || 1}
         size={props.size || 12}
         labelSize={props.labelSize || 4}
         inputSize={props.inputSize || 8}
         min={0}
         max={100}
         defaultValue={props.defaultValue}
         disabled={props.disabled}
         onChange={props.onChange}
         value={props.value}
         validation={props.validation}
         onBlur={props.onBlur}  
       />
    </div>
  );
};

export default PercentileInput;
