/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';

const SVGTree = props => {
let fullTitleLength = 0;

const tree = props.data.map((datum, index) => {
  let expanderSymbol = '';
  const hasChildren = datum.children.length > 0;

  if(hasChildren) {
    expanderSymbol = props.shouldHideChildren(datum) ? '▶' : '▼';
  }
  const y = (props.rowHeight * index) + (props.rowHeight / 2) + props.rowHeight + 1;
  const childIndent = props.getNodeDepth(datum) * 20 + (expanderSymbol == '' ? 5 : 0);
  const titleLength = childIndent + props.getTooltip(datum).length * 14/2 + 7 + 5 + 5;

  fullTitleLength = Math.max(fullTitleLength, titleLength);

  return <g className='serviceName'>
    <text 
      x={childIndent} y={y} 
      onClick={() => hasChildren ? props.onToggleChildren(datum) : null} 
      cursor={ hasChildren ? 'pointer' : null} fill={'var(--mainText)'}>
      <title>{props.getTooltip(datum)}</title>
      <tspan fontWeight={'bold'}>{expanderSymbol}</tspan>
      {props.getNodeDisplay(datum)}
    </text>
  </g>;
});

return <svg className={props.className} width={fullTitleLength} height={(props.data.length + 1) * props.rowHeight}>
    <g>
      <text x={0} y={17} fontWeight={'bold'} fill={'var(--mainText)'}>
        <tspan>{props.title}</tspan>
      </text>
    </g>
    <line x1={0} y1={27} x2='100%' y2={27} stroke={'#888'} strokeWidth={1} opacity={1}/>
    {
      tree
    }
  </svg>;
};

export default SVGTree;
