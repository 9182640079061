/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import propertiesProviderModule from './custom/propertiesProvider/PropertiesProviderModule.js';
import customTaskDescriptor from './custom/descriptors/taskDescriptor.json';
import Modeler from 'bpmn-js/dist/bpmn-modeler.development.js'; 
import customControlsModule from './custom/controls/CustomControlsModule.js';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import emptyBpmn from 'assets/EmptyBpmn.bpmn';
import {Row, Col} from 'react-bootstrap';
import 'web-style-guide/css/workflow.less';
import * as BpnmDiagramUtils from 'utils/BpmnDiagramUtils.js';
import {getProcessVariables} from '@bpmn-io/extract-process-variables';
import { fromJS } from 'immutable';
import _ from 'lodash';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import * as TabsInfo from './custom/propertiesProvider/props/TabsInfo.js';

export default class BpmnDiagram extends Component{

  constructor(props){
    super(props);
    this.getNewModeler = :: this.getNewModeler;
    this.openBpmnDiagram = :: this.openBpmnDiagram;
    this.setNameAndInternalVariables = ::this.setNameAndInternalVariables;
    this.modeler = null;
    this.elementName = null;
    this.rootDiagramWorkflowSelected = true;
    this.setRootDiagramWorkflowFlag = ::this.setRootDiagramWorkflowFlag;
    this.getInternalVariables = ::this.getInternalVariables;
    this.getSelectedEntitiesIds = ::this.getSelectedEntitiesIds;
    this.getCanvas = ::this.getCanvas;
    this.getRootElement = ::this.getRootElement;
    this.detachPropertiesPanel = ::this.detachPropertiesPanel;
    this.state = {
      taskName : '',
      internalVariables: [],
    };
  }

  componentDidMount(){
    const bpmnDiagram = this.props.bpmnDiagram ? this.props.bpmnDiagram : emptyBpmn;

    this.modeler = this.getNewModeler();
    this.openBpmnDiagram(bpmnDiagram);
  }

  componentWillReceiveProps(nextProps){
    if(this.props.bpmnDiagram != nextProps.bpmnDiagram && nextProps.bpmnDiagram){
      this.openBpmnDiagram(nextProps.bpmnDiagram);
    }
  }

  getNewModeler(){
    return new Modeler({
      container: '#bpmnview',
      keyboard: {
        bindTo: window,
      },
      propertiesPanel: {
        parent: '#propview',
      },
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        customControlsModule,
      ],
      moddleExtensions: {
        camunda: camundaModdleDescriptor,
        customTaskDescriptor: customTaskDescriptor,
      },
    });
  }

  setNameAndInternalVariables(event){
    const oldInternalVars = fromJS(this.state.internalVariables).toJS();

    this.setState({
      taskName : event.element ? event.element.businessObject.name : this.state.taskName,
      internalVariables: this.getInternalVariables(),
    }, () => {
      if(!_.isEqual(this.state.internalVariables, oldInternalVars)){
        this.props.updateInternalVariables(this.state.internalVariables);
      }
    });
  }

  detachPropertiesPanel(){
    const propertiesPanel = this.modeler.get('propertiesPanel');

    this.modeler.get('eventBus').fire('element.click', {element: null});
    propertiesPanel.detach();
  }

  setRootDiagramWorkflowFlag(event){
    const element = event.element;

    if (element){
      const rootElement = this.getRootElement();
      const propertiesPanel = this.modeler.get('propertiesPanel');

      this.rootDiagramWorkflowSelected = (rootElement === element);

      if(this.rootDiagramWorkflowSelected){        
        propertiesPanel.detach();
      } else {
        propertiesPanel.attachTo('#propview');
      }
    } 
  }

  getCanvas(){
    return this.modeler.get('canvas');
  }

  getRootElement(){
    return this.getCanvas().getRootElement();
  }

  openBpmnDiagram(xml){
    this.modeler.importXML(xml).then(result => {
      const self = this;
      this.props.updateInternalVariables(this.getInternalVariables());
      const canvas = this.getCanvas();
      const propertiesPanel = this.modeler.get('propertiesPanel');

      canvas.zoom('fit-viewport');
      propertiesPanel.detach();
      this.modeler.on('directEditing.activate', function(event) {
        self.setNameAndInternalVariables(event);
      });
      // set properties panel title name corresponding to the changed task 
      this.modeler.on('element.changed', function(event) {
        self.setNameAndInternalVariables(event);
      });
      // showing/hiding properties panel & setting it's title 
      // corresponding to the clicked task
      this.modeler.on('element.click', function(event) {
        self.setNameAndInternalVariables(event);
        self.setRootDiagramWorkflowFlag(event);
      });
    });
  }
  
  // call from parent component by ref
  updateAndReturnProcessName(){
    const rootElement = this.getRootElement();
    const modeling = this.modeler.get('modeling');
    const propertiesPanel = this.modeler.get('propertiesPanel');
    const workflowGenratedName = BpnmDiagramUtils.getWorkflowGeneratedName(this.props.workflowName);

    propertiesPanel.detach();
    modeling.updateProperties(rootElement, {
      id: workflowGenratedName,
      name: workflowGenratedName,
    });

    return workflowGenratedName;
  }

  getInternalVariables(){
    if(this.modeler){
      const rootElement = this.getRootElement();

      return getProcessVariables(rootElement.businessObject);
    } 

    return [];
  }

  // call from parent component by ref
  saveXML(){
    return this.modeler.saveXML({ format: true });
  }

  // call from parent component by ref
  getSelectedEntitiesIds(){
    const ansibleProjectsIds = [];
    const reportsIds = [];
    const rootElement = this.getRootElement();

    if(rootElement.businessObject.flowElements){
      rootElement.businessObject.flowElements.forEach(businessObject => {
        if(businessObject.taskAction === CommonWorkflowDefaults.ANSIBLE_TASK_NAMESPACE){
          const extensionElements = businessObject.extensionElements;
          const ansibleNameIndex = TabsInfo.ansibleProps.inputsInfo.inputs.ansibleName.index;
          const selectedAnsibleName = extensionElements.values[1].inputParameters[ansibleNameIndex].value;
          
          if(selectedAnsibleName && this.props.ansibleProjectsDetails[selectedAnsibleName]){
            ansibleProjectsIds.push(this.props.ansibleProjectsDetails[selectedAnsibleName].id);
          }
        }else if(businessObject.taskAction === CommonWorkflowDefaults.REPORT_DESIGNER_TASK){
          const extensionElements = businessObject.extensionElements;
          const reportIdIndex = TabsInfo.reportDesignerProps.inputsInfo.inputs.reportId.index;
          const selectedReportId = extensionElements.values[1].inputParameters[reportIdIndex].value;

          if(selectedReportId){
            reportsIds.push(selectedReportId);
          }
        }
      });
    } 

    return {ansibleProjectsIds, reportsIds};
  }


  render() {
    return(
      <div className='systemMood'>
      <Row id="bpmncontainer">
        <Col id="propview" xs={12} md={3} className="bpmnProp-area pull-right" 
          title={this.state.taskName} ansibleProjects={this.props.ansibleProjects} 
          reportsOptions={this.props.reportsOptions}
          ansibleProjectsDetails={this.props.ansibleProjectsDetails}
          reportsDetails={this.props.reportsDetails}
          externalVariables={this.props.externalVariables}
          internalVariables={this.props.internalVariables}>
        </Col>
        <Col id="bpmnview" xs={12} md={9} className="bpmnView-area"></Col>
      </Row>
      </div>
    );
  }
}