/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';

const TreeDefaults = {
  LABELS: {
    Filter: {
      SELECTED_FIELD: 'Attribute',
      OPERATION: 'Operation',
      VALUE: 'Value',
      RELATION_TO_NEXT_FILTER: 'Relation to next filter',
      FILTER: 'Filter',
    },
    SELECTION: 'Selection',
    NESelection: 'Network Element',
  }
}
const filterValidation = Joi.object()
    .keys({
        selectedAttribute: Joi.object().required()
        .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.Filter.SELECTED_FIELD)),
        operation: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.Filter.OPERATION))
            .required(),
        compareValue: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.Filter.VALUE))
            .required(),
        lastFilter:  Joi.bool().allow(null),
        relationToNext: Joi.string()
            .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.Filter.RELATION_TO_NEXT_FILTER))
            .when('lastFilter',
            [{
                is: true,
                then: Joi.string().optional().allow(null),
                otherwise: Joi.string().required(),
            }]),
    }).unknown();

const filtersValidation = Joi.object().keys({
  filters: Joi.array()
      .label(TreeDefaults.LABELS.Filter.FILTER)
      .error(ValidationUtils.getErrMsgArray(TreeDefaults.LABELS.Filter.FILTER))
      .items(filterValidation),
}).unknown();

const arrayRequired = Joi.array()
  .min(1)
  .required();

const arrayOptional = Joi.array().optional();
  
const selectedNodes = Joi.alternatives().try(
    Joi.object().keys({
      organizationGroups: arrayRequired,
      treeNodes: arrayOptional,
    }).unknown(),
    Joi.object().keys({
      treeNodes: arrayRequired,
      organizationGroups: arrayOptional,
    }).unknown()
  ).label(TreeDefaults.LABELS.SELECTION)
  .error( ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.SELECTION));

const selectedNodesOptional = Joi.object().keys({
  organizationGroups: arrayOptional,
  treeNodes: arrayOptional,
})
  .unknown()
  .label(TreeDefaults.LABELS.SELECTION)
  .error( ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.SELECTION));

const selectedNodesSchema = Joi.object({
  isOptional: Joi.boolean().required(),
  selectedNodes: Joi.alternatives().conditional('isOptional', {
    is: true,
    then: selectedNodesOptional,
    otherwise: selectedNodes,
  }),
});

const SCHEMA = {
  NESelection: Joi.number().required()
    .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.NESelection)),
  filters: filtersValidation,
  filter: filterValidation,
  selectedNodes :selectedNodesSchema,
  selectedNodeId: Joi.number().required()
    .error(ValidationUtils.ERR_MSGS.required(TreeDefaults.LABELS.SELECTION)),
};
  
function validator(fieldType, value){
  return ValidationUtils.validateSchema(SCHEMA)(fieldType, value);
}
export function validateAdvancedFilter(advancedFilters, validation, validationOptions){
  let isValid = true;
  if(advancedFilters){
    if(validationOptions.checkValidation){
      validation.filterInput = {};
      validation.filterInput.filterList = [];
      if(advancedFilters.length > 0){
        advancedFilters[advancedFilters.length - 1].lastFilter = true;
      }
      advancedFilters.forEach((filter, index) => {
        validation.filterInput.filterList[index] = validator('filter', filter);
        isValid = isValid && ValidationUtils.isValid(validation.filterInput.filterList[index]);
      });
    }
    
    if(validationOptions.checkListValidation){
      validation.filterInput.filters = {};
      validation.filterInput.filters = validator('filters', {filters: advancedFilters});
      isValid = isValid && ValidationUtils.isValid(validation.filterInput.filters);
    }  
  }
  
  return isValid;
}

export function validateNELevel(networkElementDataStructure, validation){
  validation.NELevelOption = {};
  validation.NELevelOption = validator('NESelection', networkElementDataStructure ?
      networkElementDataStructure.id : null);
      
  return ValidationUtils.isValid(validation.NELevelOption);
}

export function validateSelection(treeNodes, organizationGroups, validation, isOptional = false){
  validation.selectedNodes = {};
  validation.selectedNodes = validator('selectedNodes', {isOptional, selectedNodes:{treeNodes, organizationGroups}});

  return ValidationUtils.isValid(validation.selectedNodes);
}

export function validateSingleSelection(selection, validation){
  validation.selectedNodes = {};
  validation.selectedNodes = validator('selectedNodeId', selection.selectedNodeId);

  return ValidationUtils.isValid(validation.selectedNodes);
}

export function validateTreeSelection(selection, validation){
  if(selection){
    validation.selectedNodes = {};
    validation.selectedNodes = validator('selectedNodes', {
      isOptional: false,
      selectedNodes: {
        treeNodes: selection.treeNodes || [],
        organizationGroups: selection.organizationGroups || [],
      },
    });
    if(!ValidationUtils.isValid(validation.selectedNodes)){
      validation.selectedNodes = validator('selectedNodeId', selection.selectedNodeId);
      
      return ValidationUtils.isValid(validation.selectedNodes);
    }

    return true;
  }

  return false;
}