/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import {MultipleSelect} from './simple/SimpleInputs';
import Form from './Form';
import {INPUTS} from './FormInputs';
import FormPositioningHelper from './FormPositioningHelper';
import {wrapArrayInToSelectOptions} from 'utils/UIConstructionUtils';
import $ from 'jquery';
import Icons from 'templates/Icons';
import 'styles/inputs/filtersForm.less'

//Extendting the embedded form, the Form class has the same functionalities
// of the embedded form, adding to it the footer and footer buttons.
// Form component is made to exist alone with the help header and
//all inside a single page

export default class FiltersForm extends Form {
  constructor(props) {
    super(props);
    this.wrapInput = ::this.wrapInput;
    this.setShownControls = ::this.setShownControls;
    this.getDisplayValue = ::this.getDisplayValue;
    this.clearAllHiddenFields = ::this.clearAllHiddenFields;
    this.constructFilterViewControl = ::this.constructFilterViewControl;
    this.getInputCount = ::this.getInputCount;
    const state = {
      shownControls: props.shownControls,
      validation: props.validation ? props.validation : {},
    };

    this.state = this.getInitialValueState(props.inputs, state);
  }

  componentDidMount(){
    $('.filter-form-control .ms-choice').append(
      '<i class="ev ev-filter" aria-hidden="true"></i>');
  }

  getClearButton(input){
    return (
      <TooltippedButton className='col-xs-12 float-none btn-action'
        tooltip='Clear'
        onClick={(e) => {
          e.stopPropagation();
          if(input.onClear){
            this.setState({shownControls: this.state.shownControls.filter(e => e !== input.keyword)}, () => {
              input.onClear();
            });
          } else {
            this.setState({
              [input.keyword]: INPUTS[input.type].initialValue,
              shownControls: this.state.shownControls.filter(e => e !== input.keyword),
            }, this.onChange);
          }
        }}
      >
        <i className={Icons.close}></i>
      </TooltippedButton>
    );
  }

  clearAllHiddenFields(){
    const hiddenInputResults = {};

    this.props.inputs.forEach(input => {
      if((this.state.shownControls || []).indexOf(input.keyword) < 0
      && !input.mandatoryFilter){
        hiddenInputResults[input.keyword] = INPUTS[input.type].initialValue;
      }
    });

    this.setState(hiddenInputResults, this.onChange);
  }

  getDisplayValue(input){
    if(input.getFilterDisplayValue){
      return input.getFilterDisplayValue(this.state[input.keyword]);
    }else if(INPUTS[input.type].getFilterDisplayValue){
      return INPUTS[input.type].getFilterDisplayValue(this.state[input.keyword]);
    }

    return this.state[input.keyword];
  }

  getInputCount(input){
    if((input.showFilterCount || INPUTS[input.type].showFilterCount)
    && (input.getFilterCount(this.state[input.keyword])
    || (this.state[input.keyword] || []).length) > 1){
      return (
        <div className="selectionCount">
          {`+ ${(input.getFilterCount(this.state[input.keyword]) || (this.state[input.keyword] || []).length) - 1}`}
        </div>
      );
    }

    return null;
  }

  wrapInput(input, renderedInput){
    const triggerBtnSize = input.triggerBtnSize || INPUTS[input.type].triggerBtnSize;
    const triggerBtnClass = input.triggerBtnClass || INPUTS[input.type].triggerBtnClass;


    if(input.pureInput){
      return (
        <OverlayTrigger
          trigger="hover"
          placement="top"
          rootClose
          overlay={<Tooltip>{input.label}</Tooltip>}
          key={input.keyword}
        >
          <div className={`filter-form-popup-button  ${triggerBtnSize} ${triggerBtnClass}`}
            key={input.keyword}>
            {this.getInputCount(input)}
              {renderedInput}
            {input.mandatoryFilter ? null : this.getClearButton(input)}
          </div>
        </OverlayTrigger>
      );
    }

    const overlayContent = (
      <Popover
        id={`popover-basic${input.keyword}`}
        className={`filter-form-popover ${triggerBtnSize} ${triggerBtnClass}`}
      >
          {renderedInput}
      </Popover>
      );

    return(
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={overlayContent}
        key={input.keyword}>
        <OverlayTrigger
          trigger="hover"
          placement="top"
          rootClose
          overlay={<Tooltip>{input.label}</Tooltip>}
          key={input.keyword}
        >
          <div className={`filter-form-popup-button ${triggerBtnSize} ${triggerBtnClass}`}>
            {this.getInputCount(input)}
              <span title= {this.getDisplayValue(input) || input.label}>
                {this.getDisplayValue(input) || input.label}
              </span>
            {this.getClearButton(input)}
          </div>
        </OverlayTrigger>
      </OverlayTrigger>
    );
  }

  setShownControls(e){
    this.setState({
      shownControls: e.target.value,
    }, this.clearAllHiddenFields);
  }

  constructFilterViewControl(){
    const options = wrapArrayInToSelectOptions(
      this.props.inputs.filter(input => !input.mandatoryFilter),
      'keyword', 'keyword', 'label');

    return <div style={{display: 'inline-block'}}>
      <MultipleSelect
        className='input-text filter-form-control filterOptions-btn'
        value={this.state.shownControls}
        placeholder='Select Shown Filters'
        multiple
        disabled={this.props.disabled}
        options={options}
        onClose={this.setShownControls}
        onChange={this.setShownControls}
      />
  </div>;
  }

  render() {
    const shownFilters = this.props.inputs.filter(input => (this.state.shownControls || []).indexOf(input.keyword) > -1
      || input.mandatoryFilter);

    return (
      <FormPositioningHelper
        expandFullWidth
        className={`filter-form ${this.props.className}`}
      >
        {this.constructRows(shownFilters)}
        {this.constructFilterViewControl()}
        {this.props.children ? this.props.children : null}
      </FormPositioningHelper>
    );
  }
}
