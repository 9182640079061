/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
import * as CommonValidationSchema from 'utils/CommonValidationSchema';

const nValue = CommonValidationSchema.getNumericInputValidationSchema({
  min: 1,
  label: 'N Value',
  required: true,
  max: 100,
});
const topNField = Joi.object().required()
  .error(ValidationUtils.ERR_MSGS.required('Sort By'));

const SCHEMA = {
  nValue,
  topNField,
  topNSettings: Joi.object()
  .required()
  .keys({
    nValue,
    nDirection: Joi.string().required()
      .error(ValidationUtils.ERR_MSGS.required('N Direction')),
    topNField,
  })
  .unknown(),
};

export default ValidationUtils.validateSchema(SCHEMA);