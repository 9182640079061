/* Copyright 2019 Greyskies. All Rights Reserved. */

export const MAX_EMAILS_COUNT = 100;
export const EMAILS_PASTE_SEPARATORS = [' ', ',', ';', '\n', '\r'];
export const Email_VALIDATION_REGEX = /^(?=^( )*.{0,255}( )*$)(( )*([a-zA-Z0-9&_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z]{2,})( )*$)/;
export const LABELS = {
  SUBJECT: 'Subject',
  TO: 'To',
  CC: "Cc",
  BCC: 'Bcc',
  EMAIL: 'Email',
  AN_EMAIL: 'an Email',
  EMAILS: 'Email(s)',
};
export const ERR_MSGS = {
  EMAIL_FORMAT: 'Email: example@email.com (should be at most 255 characters)',
};
