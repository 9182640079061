/* Copyright 2019 Greyskies. All Rights Reserved. */

import React , {Component} from 'react';
import 'styles/inputs/simple/checkbox.less';

export default class Checkbox extends Component{
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(){
    this.checkbox.click();
  }
  render(){
    return(
      <div className="checkbox-group" style={this.props.style}>
        <div className="checkbox-container">
          <input checkState={this.props.checkState ? this.props.checkState:null}
            type="checkbox" className="checkbox"
            onChange={this.props.onChange} checked={this.props.checked}
            disabled={this.props.disabled}
            defaultChecked={this.props.defaultChecked} ref={ref=>this.checkbox = ref}/>
          <label className={`checkbox-label ${this.props.checked? 'checked':'unChecked'}`} style={this.props.labelStyle} onClick={this.onClick}>
            <span></span>
            {this.props.label}
          </label>
        </div>
      </div>
    )
  }
}
