/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import MultipleSelect from '../simple/MultipleSelect';
import {Row} from 'react-bootstrap';
import LabeledComponent from 'containers/LabeledComponent';
import {UnitConversionUtils as ConversionUtils, MsgDefaults} from 'js-utils';

const PERCENTAGE_UNIT = '%';
const UNIT_DEFAULT_LABEL = 'Unit';

const UnitConversionDropdown = (props) => {
  const disabled = props.baseUnit === PERCENTAGE_UNIT;
  let selectOptions = [];

  if(disabled && (!props.scaleDetails || props.scaleDetails.length < 1)){
    selectOptions = [
      <option value={PERCENTAGE_UNIT} >
        {PERCENTAGE_UNIT}
      </option>,
    ];
  } else if(props.scaleDetails) {
    const sortedScaleDetails = ConversionUtils.sortScaleDetails(props.scaleDetails);
   
    selectOptions = (sortedScaleDetails || []).map((select) => {
      const optionLabel = props.fullLabel || select.unit == PERCENTAGE_UNIT ? '' : props.baseUnit || '';

      return <option key={select.id} value={select.id}>
              {select.unit + optionLabel}
             </option>;
    });
  } else{
    const availableUnits = props.availableUnits ? props.availableUnits 
      : ConversionUtils.getAvailableUnits(props.dataType);

    selectOptions = (availableUnits || []).map((select) => {
      if(!select.label && select.label.length == 0){
        const optionLabel = props.fullLabel ? '' : props.baseUnit || '';

        return <option value={1}
          className='emptySearchValue' key='emptySearchValue'>{optionLabel}</option>;
      }

      const optionLabel = props.fullLabel ? 
            `${select.label}` 
            : ConversionUtils.getUnitLabel(select.value, props.baseUnit, props.dataType);

      return <option key={select.label} value={select.value}>
              {optionLabel}
             </option>;
    });
  }

  return (
    <Row>
      <LabeledComponent 
        hideLabel={props.hideLabel} 
        label={props.labelName ? props.labelName : UNIT_DEFAULT_LABEL} 
        size={12} 
        labelSize={props.labelSize || 4} 
        inputSize={props.inputSize || 8} 
        componentClassName={props.className}
      >
        <MultipleSelect
          filter={false}
          disabled={disabled || props.disabled}
          className='unitConversion-menu'
          onChange={props.onUnitChange}
          value={disabled && (!props.scaleDetails || props.scaleDetails.length < 1) ? PERCENTAGE_UNIT : props.unit}
          options={selectOptions}
          alwaysUpdate
          placeholder={props.hidePlaceholder ? '' : MsgDefaults.getSelectMsg('Unit')} />
      </LabeledComponent>
    </Row>
  );
};

export default UnitConversionDropdown;
