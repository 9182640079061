/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import 'web-style-guide/css/dashboardAssociation.less';
import {fromJS} from 'immutable';
import {MsgDefaults, ArrayUtils, Utils as jsUtils} from 'js-utils';
import * as Icons from 'templates/Icons';
import ValidationOutput from 'containers/ValidationOutput';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import TreeItemView from 'dataSelection/records/TreeItemView';
import CollapsableComponent from 'containers/CollapsableComponent';
import TreeWithPlaceholderOption from 'dataSelection/records/TreeWithPlaceholderOption';
import Modal from 'containers/Modal';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';

export default class SelectionList extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.addNewSelection = :: this.addNewSelection;
    this.closeTreeModal = :: this.closeTreeModal;
    this.openTreeModal = :: this.openTreeModal;
    this.updateSelection = :: this.updateSelection;
    this.renderTreeModal = :: this.renderTreeModal;
    this.getSelectionsComponent = :: this.getSelectionsComponent;
    this.saveSelection = :: this.saveSelection;
    this.editSelection = :: this.editSelection;
    this.deleteSelection = :: this.deleteSelection;
    this.idCounter = 0; 
  }

  componentDidMount() {
    if(this.props.listAllNetworkElementTypes){
      this.props.listAllNetworkElementTypes().then(networkElementTypes => {
        this.setState({
          networkElementTypes,
        });
      });
    }
  }
  
  closeTreeModal(){
    this.setState({showModal: false});
  }
  
  openTreeModal(selection, index){
    this.currentIndex = index;
    this.setState({showModal: true});
  }

  updateSelection(selection, index){
    this.setState({showModal: false}, () => {
      const selections = fromJS(this.props.selections).toJS();
      if(jsUtils.isBlank(index)){
        selection.idCounter = this.idCounter++;
        selections.push(selection);
      }else{
        selections[index] = selection;
      }
      this.props.updateSelection(selections);
    });
  }

  saveSelection(){
    this.treeModelRef.saveSelection();
  }

  renderTreeModal(){
    const selection = !jsUtils.isBlank(this.currentIndex) ? this.props.selections[this.currentIndex] : undefined;
    return (
      <Modal bsSize="large"
        onHide={this.closeTreeModal}
        title={this.props.componentPlaceholder}
        show={!this.props.disableComponent && this.state.showModal}
        footerButtons={[
          ButtonsConstructor.getButtonComponent({
              disabled: this.props.disableComponent,
              onClick: this.saveSelection,
              bsStyle: 'primary'}, 'Select'),
        ]}>
        <TreeWithPlaceholderOption
          ref={(ref => this.treeModelRef = ref)}
          index={this.currentIndex}
          selection={selection}
          disabled={this.props.disableComponent}
          networkElementDataStructure={this.props.selectionNEDataStructure || (selection || {}).networkElementDataStructure}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          entityOptions={this.props.entityOptions}
          getTreeNodesAction={this.props.getTreeNodesAction}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          isTemplate={this.props.isTemplate}
          updateSelection={this.updateSelection}
          hideGroupElementSelection={this.props.hideGroupElementSelection}
          networkElementTypes={this.state.networkElementTypes}/>
      </Modal>
    );
  }

  addNewSelection(){
    this.openTreeModal();
  }

  deleteSelection(index){
    const selections = ArrayUtils.deleteItem(this.props.selections, index);

    this.props.updateSelection(selections);
  }

  editSelection(index){
    this.openTreeModal(null, index);
  }

  getSelectionsComponent(){   
    return (
      <Row>
        <Col xs={12} className='evc-nesting-indentation'>
          {this.props.selections.map((selection, index) => {
            return (
              <CollapsableComponent
                key={UIConstructionUtils.getKeyFromId(selection)}
                collapsableComponentIndex={index}
                title={this.props.selectionTitle + ' ' + (index+1)}
                initiallyCollapsed
                buttons={[    
                  ButtonsConstructor.remove(
                    this.deleteSelection, MsgDefaults.getDeleteTitle(this.props.selectionTitle), false, true
                  ),
                  ButtonsConstructor.edit(
                    this.editSelection, MsgDefaults.getEditTitle(this.props.selectionTitle), false, true),
                ]}>
                  <TreeItemView selection={selection} showNEDS={this.props.showNEDS} showSelectionLabel ={this.props.showSelectionLabel}/>
              </CollapsableComponent>
            );
          })}

          <ValidationOutput validation={this.state.validation && this.state.validation.selections} />
        </Col>
      </Row>
    );
  }
  render(){
    const isSourceTypeSelected = this.props.entityOptions.showNESelection || (this.props.selectionNEDataStructure && this.props.selectionNEDataStructure.id);
    const isMaxNumberSelected = !this.props.maxNumberOfSelections && this.props.selections.length >= this.props.maxNumberOfSelections;
    return (
      <Row>
        <Col xs={12}>
          {!!this.props.addNewLabel ? 
            <label className="marginRight10" >{this.props.addNewLabel}</label>: null
          }
          {
            ButtonsConstructor.getTooltippedButton(
              MsgDefaults.getAddNewTitle(this.props.selectionTitle),
              Icons.add,
              'btn-action addSelection-btn',
              !isSourceTypeSelected || isMaxNumberSelected,
              this.addNewSelection)
          }
          {this.getSelectionsComponent()}
          {this.renderTreeModal()}
        </Col>
    </Row>
    );
  }

}
