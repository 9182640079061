/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import SavingWidgetModel from 'dataSelection/SavingWidgetModel';

export default {
  showSavingWidgetPopup: (caller) => () => {
    return (
      <SavingWidgetModel
        hideModal={caller.updateState}
        showModal={caller.state.showSavingWidgetModal}
        designSpacesTobeAssigned={caller.state.designSpacesTobeAssigned}
        dataSelectionUsages={caller.state.dataSelectionUsages}
        saveWidget={caller.save}
      />
    );
  },



}