/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment }  from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import SpecificPeriodComponent from 'inputs/compound/SpecificPeriodComponent';
import {validationFunction} from 'utils/validations/SpecificPeriodValidationSchema';
import {capitalize} from 'utils/UIConstructionUtils';
import {ValidationUtils, DateTimeUtils, Utils} from 'js-utils';
import Modal from 'containers/Modal';
import Icons from 'templates/Icons';
import * as defaults from 'utils/defaults';

const NUMBER_OF_SHOWN_SELECTIONS =  3;

export default class DateFilterRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filter: props.filter || {
        validation: {},
      },
    };

    this.closeModal = ::this.closeModal;
    this.openModal = :: this.openModal;
    this.renderDateModal = :: this.renderDateModal;
    this.renderSpecificPeriodComponent = :: this.renderSpecificPeriodComponent;
    this.updateSpecificPeriod = :: this.updateSpecificPeriod;
    this.saveDateFilter = :: this.saveDateFilter;
  }

  closeModal(){
    const newState = {
      filter: {
        ...this.props.filter,
        validation: {},
      },
      showModal: false,
    };

    this.setState(newState);
  }
  
  openModal(){
    this.setState({showModal: true});
  }

  renderSpecificPeriodComponent(){
    return (
        <SpecificPeriodComponent
          isServerZoneTime
          resolution={this.state.filter.resolution}
          selectedDays={this.state.filter.selectedDays}
          dayOfMonth={this.state.filter.dayOfMonth}
          timeRanges={((this.state.filter || {}).timeRanges)}
          handleDateFilterChange={this.props.handleDateFilterChange}
          validation={this.state.filter.validation || {}}
          updateSpecificPeriod={this.updateSpecificPeriod}
          validationFunction={validationFunction}
          serverTimeZone={this.props.serverTimeZone}/>
    );
  }

  updateSpecificPeriod(tempFilter){
    const dateFilter = Object.assign({}, this.state.filter, tempFilter);

    this.setState({filter: dateFilter});
  }

  validateFilter(filter){
    filter.validation.resolution = validationFunction('resolution', filter);
    filter.validation.timeRanges = validationFunction('timeRanges', filter.timeRanges || []);
   
    return filter;
  }

  saveDateFilter(){
    const filter = this.validateFilter(this.state.filter);
    const isValidFilter = ValidationUtils.isValid(filter.validation.resolution) 
      && ValidationUtils.isValid(filter.validation.timeRanges);

    if(isValidFilter){
      this.setState({
        filter,
        showModal: false,
      });
      this.props.handleDateFilterChange(filter, this.props.filterIndex);
    } else {
      this.setState({filter});
    }
  }
  
  renderDateModal(){
    return (
      <Modal bsSize="large"
        onHide={this.closeModal}
        title={defaults.SELECT_DATE}
         show={this.state.showModal}
         footerButtons={[
             <Button
             bsStyle="primary"
             onClick={this.saveDateFilter}>
               Select
             </Button>
         ]} >
          {this.renderSpecificPeriodComponent()}
        </Modal>

      );
  }

  getDisplayedDayOfMonth(){
    const dayOfMonth = this.state.filter.dayOfMonth;
    let displayedDayOfMonth = ''; 

    if(!Utils.isEmpty(dayOfMonth)){
      const daySuffix = dayOfMonth % 10 == 1 && dayOfMonth != 11  ? 'st' 
      : (dayOfMonth % 10 == 2 && dayOfMonth != 12 ? 'nd'
        : (dayOfMonth % 10 == 3 && dayOfMonth != 13 ? 'rd' : 'th'));
        displayedDayOfMonth = `${dayOfMonth}${daySuffix}`;
    }
        
    return displayedDayOfMonth; 
  }

  getDisplayedTimeRanges(timeZoneOffset){
    const timeRangesList = [];

    for (let i = 0; i < NUMBER_OF_SHOWN_SELECTIONS 
      && i < this.state.filter.timeRanges.length ; i++) {
      const timeRange = this.state.filter.timeRanges[i];
      const className = i == 0 ? '' : 'timeRangePadding';
      const startTime = DateTimeUtils.convert24hrToTime12hrFormat(timeRange.startTime);
      const endTime = DateTimeUtils.convert24hrToTime12hrFormat(timeRange.endTime);

      timeRangesList.push(
        <li className={className}>
          {i == 0 && <span className='bold-text'>Range(s) : </span>}
          <span>{`${startTime}  to  ${endTime} (${timeZoneOffset})`}</span>
        </li>
      );
    }

    return timeRangesList;
  }

  getDisplayedSelectedDays(){
    const selectedWeekDays = [];

    this.state.filter.selectedDays.map((selectedDay) => {
      selectedWeekDays.push(DateTimeUtils.SHORT_WEEK_DAYS_DEFAULT[selectedDay.day - 1]);
    });

    return selectedWeekDays.join(', ');
  }

  renderFilterDateSelection(){
    const resolution = this.state.filter.resolution;
    let resolutionDisplayedInfo = '';

    if(resolution == defaults.WEEKLY){
      resolutionDisplayedInfo =  this.getDisplayedSelectedDays();
    } else if(resolution == defaults.MONTHLY){
      resolutionDisplayedInfo = this.getDisplayedDayOfMonth();
    }
    
    return (
      <ul className="selectionList NE-list">
        <li>
          <span className='bold-text'>{`${capitalize(resolution)} : `}</span>
          <span>{resolutionDisplayedInfo}</span>
        </li>
        <li>
          <ul className='timeRangeUlPadding'>{this.getDisplayedTimeRanges(
            this.props.serverTimeZone)}</ul>
        </li>
      </ul>
    )
  }

  getHiddenTimeRangesCount(){
    const timeRanges = this.state.filter.timeRanges;

    if(timeRanges && timeRanges.length > NUMBER_OF_SHOWN_SELECTIONS){
      return timeRanges.length - NUMBER_OF_SHOWN_SELECTIONS;
    }

    return 0;
  }

  render(){
    const hiddenTimeRangesCount = this.getHiddenTimeRangesCount();
    
    return (
      <Col xs={12}>
        <div className="treeSelections">
          {
            this.state.filter && this.state.filter.timeRanges.length > 0 ?
              this.renderFilterDateSelection() :
              <ul className="selectionList NE-list">
                <li>{defaults.SELECT_DATE}</li>
              </ul>
          }
          <TooltippedButton
            className="btn-primary tree-btn filterSelectNE-btn"
            tooltip={defaults.SELECT_DATE}
            disabled={this.props.disableComponent}
            onClick={this.openModal}>
              {hiddenTimeRangesCount > 0 && <span className='selectionCount'>+ {hiddenTimeRangesCount}</span>}
              <i className={Icons.calendar}></i>
          </TooltippedButton>
        </div>
        {this.renderDateModal()}
      </Col>
    );
  }
}