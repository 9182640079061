/* Copyright 2019 Greyskies. All Rights Reserved. */

import {fromJS} from 'immutable';
import {Utils} from 'js-utils';

const SEPERATOR = '-';

function isChildPath(parentPath, childPath){
  return childPath && parentPath && childPath.startsWith(parentPath + SEPERATOR) 
  && childPath.split(SEPERATOR).length > parentPath.split(SEPERATOR).length;
}

export function filterChildToSelectedPaths(SelectednodePaths, currNodePath){
  if(Array.isArray(SelectednodePaths)){
    return SelectednodePaths.filter(path => isChildPath(path, currNodePath)).length > 0;
  }

  return isChildPath(SelectednodePaths, currNodePath);
}

export function filterParentToSelectedPaths(SelectednodePaths, currNodePath){
  if(Array.isArray(SelectednodePaths)){
    return SelectednodePaths.filter(path => isChildPath(currNodePath, path)).length > 0;
  }

  return isChildPath(currNodePath, SelectednodePaths);
}


// This function is used to handle expansion for nodes in case dynamiclly or static selection or matched in search
function expandNodeChildren(caller, treeNode, props, staticallySelectedTreeNodes){
  let expanded = false;
  const nodePath = treeNode.path;

  treeNode.children.forEach((child) => {
    if(expandNodeChildren(caller, child, props, staticallySelectedTreeNodes)){
      treeNode.expanded = true;
      if(nodePath && caller.expandedIds.indexOf(nodePath) == -1){
        caller.expandedIds.push(nodePath);
      }
      expanded = true;
    }
  });

  if(!expanded){
    const isAcceptedType = caller.props.entityOptions.treeHelper.canSearch(caller, treeNode);
    let isContainsSearch = false;

    try {
      // indexOf to fix special character not handled in search
      isContainsSearch = treeNode.title.toLowerCase().indexOf(props.searchQuery.toLowerCase()) != -1
      || treeNode.title.toLowerCase().search(props.searchQuery.toLowerCase()) != -1;
    } catch(err) {
      isContainsSearch = false;
    }
    expanded = ((isAcceptedType && isContainsSearch) 
      || (staticallySelectedTreeNodes || []).indexOf(treeNode[caller.props.entityOptions.nodeId]) != -1
      || (props.dynamicallySelectedTreeNodes || []).indexOf(treeNode[caller.props.entityOptions.nodeId]) != -1
      || (props.selectedOrganizationGroups || []).indexOf(treeNode[caller.props.entityOptions.nodeId]) != -1);
  }

  return expanded;
}

export function adjustTreeDataExpandedNodes(caller, treeData, props, staticallySelectedTreeNodes){
  treeData.forEach((treeNode) => {
    expandNodeChildren(caller, treeNode, props, staticallySelectedTreeNodes);
  });

  return treeData;
}


export function getLeafChildrenNodesIds(treeNodesChildern, id){
  const children = treeNodesChildern[id];

  if(!!children && children.length > 0){
    const nextLevelChildren = [];

    children.forEach(childId => {
      nextLevelChildren.push(...getLeafChildrenNodesIds(treeNodesChildern, childId));
    });

    return nextLevelChildren;
  }

  return [id];
}

function addChildrentoTreeNodesChildren(caller, treeNode){
  const treeNodeId = treeNode[caller.props.entityOptions.nodeId];

    const currentChildren = treeNode.children.filter(caller.props.entityOptions.treeHelper.isListNode)
    .map(child => child[caller.props.entityOptions.nodeId]);
 
   if(Utils.isBlank(caller.treeNodesChildern[treeNodeId])){
     caller.treeNodesChildern[treeNodeId] = currentChildren;
   }else{
     caller.treeNodesChildern[treeNodeId]
       = [...new Set([...caller.treeNodesChildern[treeNodeId], ...currentChildren])];
   }
}

function getNodeData(treeNode, nodePos, isRoot, title, expandedIds, path){
  return {
    ...treeNode,
    title,
    children: [],
    expanded: expandedIds ? expandedIds.indexOf(path) > -1 : false,
    nodePos,
    path,
    isLeaf: treeNode.leaf,
    isRoot,
  };
}


export function getNode(treeData, nodePos){
  const nodePosition = (nodePos ).split(SEPERATOR);

  let root = treeData[nodePosition[0]];
  let index = 1;

  while(index < nodePosition.length){
    root = root.children[nodePosition[index]];
    index++;
  }

  return root;
}

function addNodeToTreeData(root, treeNode, nodePos, title, index, expandedIds, path){
  root.children[index] = getNodeData(treeNode, nodePos, false, title, expandedIds, path);
}

export function appendChildren(caller, treeNodes, root, nodePos, expandedIds, path){
  root.expanded = (expandedIds && expandedIds.indexOf(path) > -1) ? true : root.expanded;

  treeNodes.forEach((treeNode, index) => {
    const treeNodekey = caller.getNodeIdentifier(treeNode);
    const childPosition = nodePos + SEPERATOR + index;
    const newPath = path + SEPERATOR + treeNodekey;
    const title = caller.props.entityOptions.treeHelper.getNodeTitle(treeNode);

    if(treeNode.children && treeNode.children.length != 0 ){
      addNodeToTreeData(root, treeNode, childPosition, title, index, expandedIds, newPath);
      appendChildren(caller, treeNode.children, root.children[index], childPosition, expandedIds, newPath);
    } else {
      addNodeToTreeData(root, treeNode, childPosition, title, index, expandedIds, newPath);
    }
  });
}

export function buildTreeData(caller, treeNodes){
  const treeData = [];

  if(treeNodes){
    treeNodes.forEach((treeNode, index) => {
      treeNode.isRoot = true;
      const treeNodekey = caller.getNodeIdentifier(treeNode);
      const path = treeNodekey + '';
      const title = caller.props.entityOptions.treeHelper.getNodeTitle(treeNode);

      const newTreeNode = getNodeData(treeNode, '' + index, true, title, caller.expandedIds, path);
  
      treeData.push(newTreeNode);
      appendChildren(caller, treeNode.children, newTreeNode, '' + index, caller.expandedIds, path);
    });
  }

  return treeData;
}
export function handleStaticSelections(caller, staticallySelectedTreeNodes, treeNodeId){
  let selectedIndex;

  if(staticallySelectedTreeNodes && (selectedIndex = staticallySelectedTreeNodes.indexOf(treeNodeId)) > -1){
    staticallySelectedTreeNodes.splice(selectedIndex, 1);
    staticallySelectedTreeNodes.push(...getLeafChildrenNodesIds(caller.treeNodesChildern, treeNodeId));
  }
}

function fillTreeNodePath(caller, treeNodekey, path){
  const paths = caller.treeNodesPaths[treeNodekey];
  
  if(paths){
    caller.treeNodesPaths[treeNodekey] = [...new Set(Array.isArray(paths) ? [...paths, path] : [paths, path])];
  }else{
    caller.treeNodesPaths[treeNodekey] = path;
  }
}

export function appendChildrenAndHandleSelections(caller, treeNodes, nodePos, root, expandedIds, staticallySelectedTreeNodes, path){
  root.expanded = (expandedIds && expandedIds.indexOf(path) > -1) ? true : root.expanded;
  
  treeNodes.forEach((treeNode, index) => {
    const treeNodekey = caller.getNodeIdentifier(treeNode);
    const childPosition = nodePos + SEPERATOR + index;
    const newPath = path + SEPERATOR + treeNodekey;
    const title = caller.props.entityOptions.treeHelper.getNodeTitle(treeNode);

    fillTreeNodePath(caller, treeNodekey, newPath);
    if(caller.props.entityOptions.treeHelper.isNE(treeNode.nodeType)){
      addChildrentoTreeNodesChildren(caller, treeNode);
    }
    if(treeNode.children.length == 0){
      addNodeToTreeData(root, treeNode, childPosition, title, index, expandedIds, newPath);
    }else{
      addNodeToTreeData(root, treeNode, childPosition, title, index, expandedIds, newPath);
      appendChildrenAndHandleSelections(caller, treeNode.children, childPosition, root.children[index], 
        expandedIds, staticallySelectedTreeNodes, newPath);
      handleStaticSelections(caller, staticallySelectedTreeNodes, treeNode[caller.props.entityOptions.nodeId]);
    }
  });
}

export function buildTreeDataWithSelections(caller, oldStaticallySelectedTreeNodes, treeNodes){
  const treeData = [];
  const staticallySelectedTreeNodes = fromJS(oldStaticallySelectedTreeNodes).toJS();

  if(treeNodes){
    treeNodes.forEach((treeNode, index) => {
      if(caller.props.entityOptions.treeHelper.isNE(treeNode.nodeType)){
        addChildrentoTreeNodesChildren(caller, treeNode);
      }
      const title = caller.props.entityOptions.treeHelper.getNodeTitle(treeNode);
      treeNode.isRoot = true;
      const treeNodekey = caller.getNodeIdentifier(treeNode);
      const path = treeNodekey + '';
      const newTreeNode = getNodeData(treeNode, '' + index, true, title, caller.expandedIds, path);

      fillTreeNodePath(caller, treeNodekey, path);
      treeData.push(newTreeNode);
      appendChildrenAndHandleSelections(caller, treeNode.children, '' + index, 
        newTreeNode, caller.expandedIds, staticallySelectedTreeNodes, path);
      handleStaticSelections(caller, staticallySelectedTreeNodes, treeNode[caller.props.entityOptions.nodeId]);
    });
  }

  return {treeData, 
    staticallySelectedTreeNodes: [...new Set(staticallySelectedTreeNodes)],
  };
}

export function  traverseTreeNodes(data, key, keyAttribute, callback){
  data.forEach((item, index, arr) => {
    if (item[keyAttribute] == key) {
      callback(item, index, arr);

      return;
    }
    if (item.children) {
      traverseTreeNodes(item.children, key, keyAttribute, callback);
    }
  });
}

export function getAllNodeKeysWithChildren(data, nodes) {
  data.forEach((item) => {
    if (item.children && item.children.length > 0) {
      nodes.push(item.key.toString());
      this.getAllNodeKeysWithChildren(item.children, nodes);
    }
  });

  return nodes;
}
