class CytoscapeDomNode {
  constructor (cy, params = {}) {
    this._cy       = cy;
    this._params   = params;
    this._node_dom = {};

    let cy_container = cy.container();

    if (params.dom_container) {
      this._nodes_dom_container = params.dom_container;
    } else {
      let nodes_dom_container = document.createElement('div');

      nodes_dom_container.style.position = 'absolute';
      nodes_dom_container.style.zIndex = 10;

      let cy_canvas = cy_container.querySelector('canvas');

      cy_canvas.parentNode.appendChild(nodes_dom_container);

      this._nodes_dom_container = nodes_dom_container;
    }

    cy.on('add', 'node', (ev) => {
      this._add_node(ev.target);
    });

    cy.on('layoutstop', (evt)=> {
      while (this._nodes_dom_container.firstChild) {
        this._nodes_dom_container.removeChild(this._nodes_dom_container.firstChild);
      }
      for (const n of cy.nodes()){
        this._add_node(n);
      }
    });

    cy.on('render', (ev) => {
      const pan  = cy.pan();
      const zoom = cy.zoom();
      const transform = `translate(${pan.x}px,${pan.y}px) scale(${zoom})`;

      this._nodes_dom_container.style.msTransform = transform;
      this._nodes_dom_container.style.transform = transform;
    });

    cy.on('position bounds', 'node', (ev) => {
      const cy_node = ev.target;
      const id      = cy_node.id();

      if (!this._node_dom[id])
        return;

      this.fixNodeLayerPosition(this._node_dom[id], cy_node);
    });
  }

  fixNodeLayerPosition(dom, node){
    const style_transform 
      = `translate(-50%, -50%) translate(${node.position('x').toFixed(2)}px, ${node.position('y').toFixed(2)}px)`;
    
    dom.style.webkitTransform = style_transform;
    dom.style.msTransform     = style_transform;
    dom.style.transform       = style_transform;

    dom.style.display = 'inline';
    dom.style.position = 'absolute';
  }

  _add_node (n) {
    const data = n.data();

    if (!data.dom)
      return;

    if (data.skip_node_append !== true) {
      this._nodes_dom_container.appendChild(data.dom);
    }
    data.dom.__cy_id = n.id();

    this._node_dom[n.id()] = data.dom;
    this.fixNodeLayerPosition(this._node_dom[n.id()], n);
  }

  node_dom (id) {
    return this._node_dom[id];
  }
}


function register (cy) {
  if (!cy)
    return;

  cy('core', 'domNode', function (params, opts) {
    return new CytoscapeDomNode(this, params, opts);
  });
}


if (typeof(cytoscape) !== 'undefined') {
  register(cytoscape);
}


module.exports = register;