/* Copyright 2019 Greyskies. All Rights Reserved. */
import {DataModelTypeUtils} from 'js-utils';

export function getFilterableFields(attributeOptions, filtersOptions = {}){
  return attributeOptions.filter(field =>
      !field.hidden
      && DataModelTypeUtils.isFilterableAttributeType(field.attributeType.dataType, filtersOptions));
}

export const PLACEHOLDER = 'Placeholder';

export const NE_PLACEHOLDER = 'Network Element Placeholder';
export const CHOOSE_PLACEHOLDER = 'Choose Placeholder';

export const FILTER_TYPE_OPTIONS = {
  VALUE: {
    name: 'Value',
    value: 'VALUE',
  },
  PLACEHOLDER: {
    name: 'Placeholder',
    value: 'PLACEHOLDER',
  },
  VARIABLE: {
    name: 'Variable',
    value: 'VARIABLE',
  },
};

export const FILTER_BASIC_TYPES = {
  OBJECT: 'OBJECT',
  REF: 'REF',
  DATE: 'DATE',
};

export function isPlaceholderFilter(filterValueType){
  return filterValueType == FILTER_TYPE_OPTIONS.PLACEHOLDER.value
}

export function isVariableFilter(filterValueType){
  return filterValueType == FILTER_TYPE_OPTIONS.VARIABLE.value
}

export function getFilterInputOptions(isTemplate, hasVariables){
  const options = [FILTER_TYPE_OPTIONS.VALUE];
  if(isTemplate){
    options.push(FILTER_TYPE_OPTIONS.PLACEHOLDER)
  }else if(hasVariables){
    options.push(FILTER_TYPE_OPTIONS.VARIABLE)
  }
  return options;
}

export function getFilterValueType(selectionType){
  if(selectionType == PLACEHOLDER){
    return FILTER_TYPE_OPTIONS.PLACEHOLDER.value;
  }else if(selectionType == FILTER_TYPE_OPTIONS.VARIABLE.value){
    return FILTER_TYPE_OPTIONS.VARIABLE.value;
  }
  return FILTER_TYPE_OPTIONS.VALUE.value;
}

export function getFilterBasicType(selectedField){
  if(DataModelTypeUtils.isObjectFieldType(selectedField)){
    return FILTER_BASIC_TYPES.OBJECT;
  }

  return null;
}

export function isObjectFilter(filterBasicType){
  return FILTER_BASIC_TYPES.OBJECT == filterBasicType;
}
