/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import 'web-style-guide/css/loaders.min.css';

const Loader = (props) => {
  return(
    <div className={`loader-overlay ${props.fixed ? 'fixedLoader':''}`}>
        <div className="loader">
            <div className="loader-inner line-scale">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
  )
};

export default Loader;
