/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import Modal from 'containers/Modal';
import Form from 'inputs/Form';


export default class TestSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render(){ 
    const footerButtons = [
        <Button
          onClick={this.props.testSettings}
          bsStyle="primary"
          disabled={this.props.disabled}>
            {this.props.testButtonLabel}
        </Button>
    ];

    return (
      <Modal 
      bsSize="medium"
      onHide={this.props.onHide}
      title={this.props.title}
      show={this.props.show}
      footerButtons={footerButtons}>
        <Form
        ref={ref => this.form = ref}
        inputs={this.props.getTestFormInputs()}
        onChange={this.props.onTestFormChange}
        validationFunction={this.props.validateTestInputs}
        expandFullWidth
      />
      </Modal>);
  }
}