/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import { fromJS } from 'immutable';
import { Row, Col } from 'react-bootstrap';
import DataSelection from './DataSelection';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import {DEFAULT_TOPN_SETTINGS} from 'utils/DataSelectionUtils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import Checkbox from 'inputs/simple/Checkbox';
import DataInput from 'inputs/simple/DataInput';
import AggregationInput from 'inputs/compound/AggregationInput';
import UnitConversionDropdown from 'inputs/compound/UnitConversionDropdown';
import FFAGValidationSchema from 'utils/validations/FFAGValidationSchema';
import {AggregationUtils, DataModelTypeUtils,
  UnitConversionUtils} from 'js-utils';
import {LABELS}  from 'utils/ffagDefaults';
import DataSelectionTopNSettings from 'inputs/compound/TopN/DataSelectionTopNSettings';
import LabeledComponent from 'containers/LabeledComponent';
import ToggleButtonsWithCustomContent from 'buttons/ToggleButtonsWithCustomContent';
import 'styles/dataSelection/records/dataSelectionWithType.less';

export default class DataSelectionWithType extends React.Component {
  constructor(props) {
    super(props);
    this.validateSettings = :: this.validateSettings;
    this.onSeriesAggregationChanged = :: this.onSeriesAggregationChanged;
    this.getSeriesAggregationType = :: this.getSeriesAggregationType;
    this.updateLastValue = :: this.updateLastValue;
    this.updatePercentileValue = :: this.updatePercentileValue;
    this.nthPercentileProps = :: this.nthPercentileProps;
    this.onDataSelectionChange = :: this.onDataSelectionChange;
    this.switchFgaAggregationType = :: this.switchFgaAggregationType;
    this.getFfagTypeSwitch = :: this.getFfagTypeSwitch;
    this.onChangePercentileValue = :: this.onChangePercentileValue;
    this.getComponentBasedOnFFAGType = :: this.getComponentBasedOnFFAGType;
    this.onUnitChange = ::this.onUnitChange;
    this.updateLastValue = ::this.updateLastValue;
    this.getSeriesAggregationComponent = ::this.getSeriesAggregationComponent;
    this.setDefaultTopNFieldFromFFAG = ::this.setDefaultTopNFieldFromFFAG;
    this.getSettingsValidation = ::this.getSettingsValidation;
    this.onNameChange = ::this.onNameChange;
    this.onNameBlur = ::this.onNameBlur;

    this.state = {
      dataSelectionValidation: DataSelectionCommonUtils.getDataSelectionValidationState(),
      percentileValue: this.props.nthPercentileValue || AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE,
      name: props.dataSelectionFga.name,
    };
    if(this.props.ffagType == DataSelectionCommonUtils.FGA_TYPE_TOPN && (this.props.dataSelectionFga || {}).fields != null){
      this.repeatedAggFieldsData 
      = DataSelectionCommonUtils.getRepeatedAggFieldsData(props.dataSelectionFga.fields);
    }  
  }

  validateSettings() {
    const fgaSettings = {
      ...this.props.dataSelectionFga,
      topNSettings: this.props.topNSettings, 
      ffagType: this.props.ffagType
    };
      
    const dataSelectionValidation = this.getSettingsValidation(fgaSettings,
      this.props.dataSelectionOptions);

    this.setState({
      dataSelectionValidation,
    }, () => {
      this.dataSelectionWithTypeRef ? 
          this.dataSelectionWithTypeRef.validateSettings() : () => {};
    });

    return dataSelectionValidation;
  }

  getSettingsValidation(dataSelectionFga, dataSelectionOptions, validationOptions) {
    const defaultValidationOptions = {
      checkValidation: true,
      checkListValidation: true,
      checkListItemsValidation: true,
    };

    return DataSelectionCommonUtils.validateDataSelection(dataSelectionFga, 
      dataSelectionOptions, validationOptions ? validationOptions : defaultValidationOptions, 
      this.props.EsQueryMaxTerms, this.state.dataSelectionValidation || {}, FFAGValidationSchema, 
      this.props.ffagNames, this.props.selectedFGAResolution);
  }

  onDataSelectionChange(newdataSelectionArgs) { 
    const validationOptions = {
      checkValidation: true,
      checkListValidation: true,
      checkListItemsValidation: false,
    };
    let dataSelectionValidation = this.getSettingsValidation(newdataSelectionArgs, 
      this.props.dataSelectionOptions, validationOptions);

    if (newdataSelectionArgs.sourceType && this.props.dataSelectionFga.sourceType
      && newdataSelectionArgs.sourceType.id != this.props.dataSelectionFga.sourceType.id) {
      dataSelectionValidation = {
        ...dataSelectionValidation,
        ...DataSelectionCommonUtils.getDataSelectionValidationState(),
      };
    }

    const newDataSelectionValidationState = {
      ...this.state.dataSelectionValidation,
      ...dataSelectionValidation,
    };
    const newDataSelectionArguState = {
      ...this.props.dataSelectionFga,
      ...newdataSelectionArgs,
    };

    const groupByFields = newDataSelectionArguState.groupByFields;
    let lastValue = false;

    if (groupByFields) {
      const hasDateInGrouping = DataSelectionCommonUtils.hasDateInGrouping(groupByFields);

      newDataSelectionArguState.hasDateInGrouping = hasDateInGrouping;
      lastValue = this.props.lastValue && hasDateInGrouping;
    }
    if(newDataSelectionArguState.fields){
      this.repeatedAggFieldsData 
      = DataSelectionCommonUtils.getRepeatedAggFieldsData(newDataSelectionArguState.fields);
    }

    const seriesAggregationchange = {};
    const topNSettingsChange = {};
    const unitChange = {};
    let resetUnit = false;

    if (this.props.ffagType == DataSelectionCommonUtils.FGA_TYPE_SINGLE_VALUE) {
      const nodeTooltipFfagAggregateFields = DataSelectionCommonUtils.getValidAggregations(((newDataSelectionArguState || {}).fields || []));
      const fieldsTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor(nodeTooltipFfagAggregateFields);
      const isNonNumeric = !DataModelTypeUtils.isNumericType(fieldsTypeDescriptor.fieldType);

      if (nodeTooltipFfagAggregateFields.length > 0) {
        if (isNonNumeric) {
          seriesAggregationchange[this.props.seriesAggregationKeyword]
            = AggregationUtils.NON_NUMERIC_AGGREGATION_DEFAULT;
        } else if (this.props.seriesAggregation == null || this.props.seriesAggregation == AggregationUtils.NON_NUMERIC_AGGREGATION_DEFAULT) {
          seriesAggregationchange[this.props.seriesAggregationKeyword] = AggregationUtils.DEFAULT_NUMERIC_AGGREGATION;
        }
      }
      const oldFFAGFields = DataSelectionCommonUtils.getValidAggregations(((this.props.dataSelectionFga || {}).fields || []));
      const oldFieldsTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor(oldFFAGFields);

      resetUnit = isNonNumeric || DataSelectionCommonUtils.shouldResetUnit(fieldsTypeDescriptor, oldFieldsTypeDescriptor);
      if (resetUnit) {
        unitChange[this.props.unitKeyword] = UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
      }
    }
    if (this.props.ffagType == DataSelectionCommonUtils.FGA_TYPE_TOPN && (((newDataSelectionArguState || {}).fields) || [])[0]) {
      topNSettingsChange[this.props.topNSettingsKeyword] = {
        ...this.props.topNSettings,
      };
      this.setDefaultTopNFieldFromFFAG(newDataSelectionArguState, topNSettingsChange);
    }
    if (!this.props.dataSelectionOptions.hasFFAGName && newDataSelectionArguState) {
      delete newDataSelectionArguState.name;
    }
    const dataSettingsChange = {
      [this.props.dataSelectionKeyword]: newDataSelectionArguState,
      ...seriesAggregationchange,
      ...topNSettingsChange,
      [this.props.isLatestKeyword]: lastValue,
      ...unitChange,
    };

    newDataSelectionArguState.isValid = newDataSelectionValidationState.isValid && this.getSettingsValidation(newDataSelectionArguState,
       this.props.dataSelectionOptions, validationOptions).isValid;
    const oldTopNSettings = this.props.topNSettings;

    this.props.onDataSelectionChange(dataSettingsChange, newDataSelectionArguState.isValid);
    this.setState({
      dataSelectionValidation: newDataSelectionValidationState,
    }, () => {
      if(topNSettingsChange[this.props.topNSettingsKeyword] && topNSettingsChange[this.props.topNSettingsKeyword].topNField
        && oldTopNSettings.topNField && !oldTopNSettings.topNField.selectedField){
        this.dataSelectionTopNSettingsRef.validateSettings();
      }
    });
  }

  setDefaultTopNFieldFromFFAG(newDataSelectionArguState, topNSettingsChange){
    const currentField = (this.props.topNSettings || {}).topNField ? newDataSelectionArguState.fields.filter(field =>
      UIConstructionUtils.getKeyFromId(field) == UIConstructionUtils.getKeyFromId((this.props.topNSettings || {})))[0] : null;

    topNSettingsChange[this.props.topNSettingsKeyword].topNField = currentField ? currentField : newDataSelectionArguState.fields[0];
  }

  onSeriesAggregationChanged(e) {
    const seriesAggregation = e.target.value;
    const newDataSelectionArguState = {
      ...this.props.dataSelectionFga,
      [this.props.seriesAggregationKeyword]: seriesAggregation,
    };
    const isValid = this.getSettingsValidation(newDataSelectionArguState, 
      this.props.dataSelectionOptions).isValid;

    this.props.onDataSelectionChange({
      [this.props.seriesAggregationKeyword]: seriesAggregation,
      isValid
    }, isValid);
  }


  switchFgaAggregationType(newType) {
      const extraSettings = {[this.props.topNSettingsKeyword]: null};
      let newDataSelectionObject = {};
      let newDataSelectionValidation = {};
  
      switch (newType) {
        case DataSelectionCommonUtils.FGA_TYPE_TOPN:
          extraSettings[this.props.topNSettingsKeyword] = {
            ...DEFAULT_TOPN_SETTINGS,
          };
          if (this.props.ffagType == DataSelectionCommonUtils.FGA_TYPE_AGGREGATE) {
            newDataSelectionObject = fromJS(this.props.dataSelectionFga).toJS();
            newDataSelectionObject = this.resetCollectionAggregation(newDataSelectionObject);
            this.setDefaultTopNFieldFromFFAG(newDataSelectionObject, extraSettings);
            newDataSelectionValidation = this.getSettingsValidation(newDataSelectionObject,
              this.props.getDetailsDataSelectionOption(newType));
            break;
          }
        case DataSelectionCommonUtils.FGA_TYPE_AGGREGATE:
          if (this.props.ffagType == DataSelectionCommonUtils.FGA_TYPE_TOPN) {
            newDataSelectionObject = fromJS(this.props.dataSelectionFga).toJS();
            newDataSelectionValidation = this.getSettingsValidation(newDataSelectionObject,
               this.props.getDetailsDataSelectionOption(newType));
            break;
          }
        default:
          newDataSelectionObject = DataSelectionCommonUtils.getDataSelectionState();
          newDataSelectionValidation = DataSelectionCommonUtils.getDataSelectionValidationState();
          break;
      }

      if(this.props.dataSelectionOptions.hasFFAGName){
        newDataSelectionObject.name = this.props.dataSelectionFga.name;
      }
      newDataSelectionObject.id = this.props.dataSelectionFga.id;
      newDataSelectionObject.version = this.props.dataSelectionFga.version;
  
      this.props.onDataSelectionChange({
        [this.props.ffagTypeKeyword]: newType,
        [this.props.dataSelectionKeyword]: newDataSelectionObject,
        ...extraSettings,
      }, false);
      this.setState({
        dataSelectionValidation: newDataSelectionValidation,
      });
  }

  resetCollectionAggregation(newDataSelectionObject){
    const updatedDataSelectionObject = {
      ...newDataSelectionObject,
      ...DataSelectionCommonUtils.resetCollectionAggregation(false),
    };

    updatedDataSelectionObject.postAggregationFilterEnabled = false;
    updatedDataSelectionObject.postAggregationFilters = [];
    
    return updatedDataSelectionObject;
  }

  getFfagTypeSwitch() {

    return (
      <Row>
        <LabeledComponent  label='Widget Type' 
          size={12} labelSizeSm={12} inputSizeSm={12}
          labelSize={this.props.isAssociation? 4: 3}
          inputSize={this.props.isAssociation? 8: 9}
          componentClassName='widgetLabeledComponent'>
          <ToggleButtonsWithCustomContent
            selectedOption={this.props.ffagType}    
            options={DataSelectionCommonUtils.FFAG_TYPE_OPTIONS}
            onChangeToggle={this.switchFgaAggregationType}                  
          />
        </LabeledComponent>
      </Row>
    );
  }

  getSeriesAggregationType(isSeriesAggregationDisabled, isSeriesAggregationNonNumeric) {
    const aggregationOptions = UIConstructionUtils.wrapArrayInToSelectOptions(
      isSeriesAggregationNonNumeric ?
        AggregationUtils.NONNUMERIC_BASIC_AGGREGATIONS : AggregationUtils.VALUE_AGGREGATIONS,
      'name',
      'value',
      'name');

    return (
        <AggregationInput
          label={'Aggregation'}
          filter={false}
          labelSize={4}
          dropdownSize={8}
          className='linkSettingValueAgg'
          placeholder={AggregationUtils.AGGREGATION_PLACEHOLDER}
          aggregation={this.props.seriesAggregation}
          onChange={this.onSeriesAggregationChanged}
          options={aggregationOptions}
          percentile={this.nthPercentileProps()}
          disabled={isSeriesAggregationDisabled}
        />
    );
  }

  updatePercentileValue(e) {
    const percentileValue = e.target.value;

    this.props.onDataSelectionChange({
      [this.props.nthPercentileKeyword]: percentileValue,
      isValid: this.state.dataSelectionValidation.isValid,
    }, this.state.dataSelectionValidation.isValid);
  }

  onChangePercentileValue(e) {
    this.setState({ percentileValue: e.target.value });
  }
  nthPercentileProps() {
    return {
      onChange: this.onChangePercentileValue,
      value: this.state.percentileValue,
      defaultValue: AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE,
      onBlur: this.updatePercentileValue,
    };
  }

  updateLastValue (e){
    this.props.updateDataSettings({
      [this.props.isLatestKeyword]: e.target.checked,
    }, true);
  }

  onUnitChange(e){
    this.props.updateDataSettings({
      [this.props.unitKeyword]: Number(e.target.value),
    });
  }

  renderLastValue() {
    if(!this.props.dataSelectionOptions.removeLastValueOption){
      const ffag = this.props.dataSelectionFga;
      const hasDateInGrouping = ffag.hasDateInGrouping;

      return (
        <Row>
          <Col xs={12}>
            <Checkbox label={'Last Value'} bsClass="checkbox-container"
              checked={hasDateInGrouping && (this.props.lastValue || false)}
              disabled={!hasDateInGrouping}
              onChange={this.updateLastValue}
            />
          </Col>
        </Row>
      );
    }
  }

  getSeriesAggregationComponent(){
    const nodeTooltipFfagAggregateFields 
    = ((this.props.dataSelectionFga || {}).fields || []).filter(field => field.aggregationType && field.selectedField);
    const fieldsTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor(nodeTooltipFfagAggregateFields);
    const isUnitSelectionDisabled = fieldsTypeDescriptor.isMixedDataType
      || !DataModelTypeUtils.isNumericType(fieldsTypeDescriptor.dataType);
    const isSeriesAggregationNonNumeric = fieldsTypeDescriptor.isMixedFieldType
      || !DataModelTypeUtils.isNumericType(DataSelectionCommonUtils.getFieldsDataType(nodeTooltipFfagAggregateFields));
    const isSeriesAggregationDisabled = isSeriesAggregationNonNumeric
      || !DataSelectionCommonUtils.shouldHaveSeriesAggregation(this.props.dataSelectionFga || {}, this.props.dataSelectionOptions);
    
    const unitSelection = {
      unit: this.props.unitSelection || UnitConversionUtils.DEFAULT_UNIT_DIVIDER,
      dataType: fieldsTypeDescriptor.dataType,
      baseUnit: fieldsTypeDescriptor.baseUnit, 
      disabled: isUnitSelectionDisabled,
    };

    if(isUnitSelectionDisabled){
      unitSelection.availableUnits = [];
    }

    return (<React.Fragment>
      <Row>
        {this.getSeriesAggregationType(isSeriesAggregationDisabled, isSeriesAggregationNonNumeric)}
      </Row>
      <UnitConversionDropdown
        key={`${unitSelection.dataType}-${unitSelection.baseUnit}-${unitSelection.unit}`}
        labelSize={4}
        inputSize={8}
        {...unitSelection}
        labelName='Unit'
        onUnitChange={this.onUnitChange}
      />
    </React.Fragment>);
  }

  getComponentBasedOnFFAGType(ffagType) {
    switch (ffagType) {
      case DataSelectionCommonUtils.FGA_TYPE_SINGLE_VALUE:
        return this.getSeriesAggregationComponent();
      case DataSelectionCommonUtils.FGA_TYPE_TOPN:
        return (
          <DataSelectionTopNSettings
            ref={(ref => this.dataSelectionTopNSettingsRef = ref)}
            dataSelectionFga={this.props.dataSelectionFga}
            topNSettings={this.props.topNSettings}
            validationOutcome={this.state.dataSelectionValidation}
            onDataSelectionChange={this.props.onDataSelectionChange}
            dataSelectionOptions={this.props.dataSelectionOptions}
            topNSettingsKeyword={this.props.topNSettingsKeyword}
            ffagType={this.props.ffagType}
            ffagTypeKeyword={this.props.ffagTypeKeyword}
            repeatedAggFieldsData={this.repeatedAggFieldsData}
            getSettingsValidation={this.getSettingsValidation}
          />
        );
      default:
        return null;
    }
  }

  onNameChange(e){
    this.setState({
      name: e.target.value,
    });
  }

  onNameBlur(e){
    const name = e.target.value;
    const dataSelectionFga = {
      ...this.props.dataSelectionFga,
      name,
    };
    const dataSelectionValidation = this.getSettingsValidation(dataSelectionFga, 
      this.props.dataSelectionOptions);

    this.setState({
      dataSelectionValidation,
    }, () => {
      this.props.onDataSelectionChange({[this.props.dataSelectionKeyword]: dataSelectionFga}, 
        dataSelectionValidation.isValid);  
    });
  }

  renderNameInput(){
    if(this.props.dataSelectionOptions.hasFFAGName){
      return(
        <Row>
          <DataInput
            size={12}
            label={LABELS.MANDATORY_NAME}
            labelSize={3}
            inputSize={9}
            onChange={this.onNameChange}
            onBlur={this.onNameBlur}
            value={this.state.name}
            validation={this.state.dataSelectionValidation.name}
          />
        </Row>
      );
    }

    return null;
  }

  render() {
    return (
      <div className='DataSelectionWithTypeWrapper dataSelectionWrapper'>
        {this.renderNameInput()}
        {this.props.dataSelectionOptions.hasFFAGType ? this.getFfagTypeSwitch() : null}
          <DataSelection
            isAssociation={this.props.isAssociation}
            key={this.props[this.props.valueSourceTypeKeyword] + this.props.selectedNEDataStructure}
            id={this.props.dataSelectionFga ? this.props.dataSelectionFga.id : null}
            dataSelectionArgs={this.props.dataSelectionFga}
            validationOutcome={this.state.dataSelectionValidation}
            onChange={this.onDataSelectionChange}
            getRecordSourceFieldsById={this.props.getRecordSourceFieldsById}
            getRecordSources={this.props.getAllRecordSources}
            getFilteredRecordSourcesByDataType={this.props.getFilteredRecordSourcesByDataType}
            getRoots={this.props.getRoots}
            refFilterTreeOptions={this.props.refFilterTreeOptions}
            getSelectionFinalForm={this.props.getSelectionFinalForm}
            listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
            dataSelectionOptions={this.props.dataSelectionOptions}
            serverTimeZone={this.props.serverTimeZone}
            selectedNEDataStructure={this.props.selectedNEDataStructure}
            selectedSourceNEDataStructure={this.props.selectedSourceNEDataStructure}
            selectedDestinationNEDataStructure={this.props.selectedDestinationNEDataStructure}
            filteredAttributeType={this.props.filteredAttributeType}
            filteredSourceAttributeType={this.props.filteredSourceAttributeType} 
            filteredDestinationAttributeType={this.props.filteredDestinationAttributeType} 
            getAutoCompleteSuggestions={this.props.getAutoCompleteSuggestions}
            placeholderOptions={this.props.placeholderOptions}
            isTemplate={this.props.isTemplate}
            getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          />
          {this.getComponentBasedOnFFAGType(this.props.ffagType)}
          {this.renderLastValue()}
      </div>
    );
  }
}