/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {CompareUtils} from 'js-utils';
import { renderToString } from 'react-dom/server';
import ErrorPage from 'templates/ErrorPage';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export function getHeightByDivClass(classNames){
  const element = document.querySelector(classNames);
  if(element){
    return parseInt(element.clientHeight);
  }
  return 0;
}

export function wrapArrayInToSelectOptions(options, keyAttName, valueAttName, displayAttName, checkDisabled, constructName, constructKey){
  return options? options.map(option => {
    const constructedKey = constructKey ? constructKey(option) : null;
    const value = constructedKey ? constructedKey : ((option[valueAttName] == undefined) ? option : option[valueAttName]);

    return (
    <option 
      key={constructedKey ? constructedKey : ((option[keyAttName] == undefined) ? option : option[keyAttName])}
      className={`${(displayAttName || '').replace(/ +/g, "")}-menuItem`}
      value={value}
      disabled={checkDisabled && checkDisabled(value)}
    >
      {constructName ? constructName(option) : (option[displayAttName] == undefined && typeof option !== 'object') ? option : option[displayAttName]}
    </option>
    );
  }) : [];
}

export function capitalize(string) {
  return string.replace(/\w\S*/g, (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  });
}

export function getValidationWrapper(label){
  return (
    <span className="warning-span">
      {label}
    </span>
  );
}

export function getComparatorsOptions(){
  return wrapArrayInToSelectOptions(
    CompareUtils.COMPARATORS,
    'value',
    'value',
    'name'
  );
}

export function renderDomToString(message){
  return renderToString(message);
}

function getOptGroupForMultipleSelect(groupList, title){
  if(groupList && groupList.length > 0){
    return  <optgroup label={title}>
    {groupList}
  </optgroup>;
  }

  return null;
}

export function getCategorizedAttributesOptions(fieldsList, keyAttName, 
  valueAttName, displayAttName, optionalList, nonOptionalList, constructName, constructKey, title, optionalTitle){
  let optional = [];
  let nonOptional = [];

  if(optionalList || nonOptionalList){
    optional = optionalList;
    nonOptional = nonOptionalList;
  } else{
    optional = fieldsList == undefined ? null 
      : wrapArrayInToSelectOptions(fieldsList.filter((item) => item.optional), keyAttName, valueAttName, displayAttName, null, constructName, constructKey);
    nonOptional = fieldsList == undefined ? null 
      : wrapArrayInToSelectOptions(fieldsList.filter((item) => !item.optional), keyAttName, valueAttName, displayAttName, null, constructName, constructKey);
  }

  return (
    <Fragment>
      {getOptGroupForMultipleSelect(nonOptional, title ? title : 'Attributes')}
      {getOptGroupForMultipleSelect(optional, optionalTitle ? optionalTitle : 'Optional')}
    </Fragment>);
}


export function constrcutErrorMsg(response, hideKeys){
  const errorObject = JSON.parse(response);

  let relatedEntitiesMsg = `<p> ${errorObject.exceptionMessage} </p>`;
  const errorDetailsMap = errorObject.errorDetailsMap;
  if(errorDetailsMap){
    Object.keys(errorDetailsMap).sort().forEach(keyData => {
      let itemsData = '';

      for (let index = 0; index < errorDetailsMap[keyData].length; index++) {
        itemsData += `<li>${errorDetailsMap[keyData][index]}</li>`;
      }
      relatedEntitiesMsg = hideKeys ? `${relatedEntitiesMsg}<div class="row"><div class="col-sm-12 col-xs-12"><ul>${itemsData}</ul></div> </div>` : 
        `${relatedEntitiesMsg}<div class="row"><div class="col-sm-4 col-xs-12 col-sm-offset-1"> ${keyData}:</div> <div class="col-sm-7 col-xs-12"><ul>${itemsData}</ul></div> </div>`;
    });
  }

  return relatedEntitiesMsg; 
}

export function getKeyFromId(entity){
  return entity.idCounter ? `${entity.idCounter}_counter` : `${entity.id}`;
}

export function getEmptyRow(message) {
  return <div className="no-data">
    <h5>{message}</h5>
  </div>;
}

export function getIcon(iconName, wrapperClass, style){
  return <span className={wrapperClass || ''}> <i className={iconName} style={style}></i></span>;
}
export function getCounterIcon(iconName, count, style, parentClassname, childClassname){
  return <span className={parentClassname || 'posRelative'}>
      {count ?
          <span className={childClassname || 'iconCounter'}>{count}</span>
        : null}
      <i className={iconName} style={style}></i>
    </span>;
}

export function getTooltippedIcon(tooltip, iconComponent, placement){
  return (
    <OverlayTrigger placement={placement? placement : "left"} trigger={tooltip ? ['hover', 'focus'] : []}
      overlay={
        <Tooltip className="in">{tooltip}</Tooltip>
      }>
      {iconComponent}
    </OverlayTrigger>
  );
}

export function stopPropagation(e){
  e.stopPropagation();
}

export function getFieldDataTypeComparators (fieldDataType, comparators){
  const availableComparators = [];
  (comparators || CompareUtils.COMPARATORS).forEach( comp => {
    if(CompareUtils.TypesComparator[comp.value].has(fieldDataType) || !fieldDataType){
      availableComparators.push(<option key={comp.value} value={comp.value}>{comp.name}</option>);
    }
  });

  return availableComparators;
}

export const NOT_FOUND_COMPONENET = () => <ErrorPage params={{error:404}}/>;
export const UNAUTHORIZED_COMPONENET = () => <ErrorPage params={{error:403}}/>;