/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import  DataInput  from 'inputs/simple/DataInput';
import  Icons  from 'templates/Icons';
import {Utils, ValidationUtils} from 'js-utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';

/** @deprecated */
const OptionInput = props => {
  
  function onDataInputChange(e){
    props.onChange(e.target.value, props.index);
  }

  function renderButtons(){
    return (props.buttons || []).map((button) => {
      if(button.type === 'copy'){
        return (
          <CopyToClipboard text={`${props.prefixLabel}${props.value}${props.suffixLabel}`}
            onCopy={() => button.onCopyHandler(props.value)}>
            {ButtonsConstructor.getTooltippedButton(
              button.label, Icons.copy, "btn-action",
              !ValidationUtils.isValid(props.validation), ()=>{})}
          </CopyToClipboard>
        );
      } else if (button.type === 'toolTip'){
        const onClick = (e) => {
          e.stopPropagation();
          button.onClick(props.index, e.target.value);
        };
       
        return (
          ButtonsConstructor.getTooltippedButton(
          button.label, button.icon, "float-none btn-action",
          button.disabled, onClick)
        );
      }
    });
  }

  return (
    <Row>
        <DataInput
          inputSuffix={props.suffixLabel}
          inputPrefix={props.prefixLabel}
          size={10}
          xsSize={10}
          componentClassName="optionInput"
          key={props.index}
          inputSize={12}
          validation={props.validation}
          formControlClassName='repeated-input'
          value={!Utils.isBlank(props.value) ? props.value : ''}
          onChange={onDataInputChange}
          placeholder={props.placeholder}
          disabled={props.disabledOption}
        />
        {renderButtons()}
    </Row>
  );
};

export default OptionInput;
