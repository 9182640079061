/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import Vega from 'react-vega';
import * as vegaTooltip from 'vega-tooltip';
import {NETWORK_ELEMENT}  from 'utils/defaults';
import {UnitConversionUtils, WidgetTypes, FieldTypes, DateTimeUtils, Utils} from 'js-utils';
import 'web-style-guide/css/vega-wrapper.less';
import { fromJS } from 'immutable';
import * as vega from 'vega';

const d3 = require('d3');

vega.expressionFunction('timeFormat', function(value, format) {
 
  return DateTimeUtils.epochToCustomDateFormat(format, value / 1000);
}); 

export default class VegaWrapper extends Component{

  constructor(props){
    super(props);
    this.handleNewView = :: this.handleNewView;
    this.handleZooming = ::this.handleZooming;
    this.onChangeInteractiveLegendsVisibility = ::this.onChangeInteractiveLegendsVisibility;
    this.onSignalSelectedBar = ::this.onSignalSelectedBar;
    this.handlePieSliceDrillDown = ::this.handlePieSliceDrillDown;
    this.handleOverlayDrillDown = ::this.handleOverlayDrillDown;
  }


  onChangeInteractiveLegendsVisibility(name, data){
    if( this.props.setWidgetSelectedData){
      this.props.setWidgetSelectedData(data);
    }
  }

  handleNewView(view){
    this.view = view;
    const vegaData = this.view._runtime.data;

    if(vegaData && vegaData.hasOwnProperty('shownSerieses')){
      this.view.addDataListener('shownSerieses', this.onChangeInteractiveLegendsVisibility);
    }
    const options
    = {
      showAllFields: false,
      delay: 0,
      colorTheme: 'light',
    };
    const fields = [];

    if(this.props.showLegend){
      fields.push({
        field: 'label',
        title: 'Series',
        formatType: 'string',

      });
    }
    if(this.props.xAxis && !WidgetTypes.isPieChart(this.props.widgetType)){
      if(WidgetTypes.isTopNChart(this.props.widgetType)){
        fields.push({
          field: 'concatenatedXaxes',
          title: 'Grouping',
        });
      }
      else if(this.props.xAxis[0] === FieldTypes.DATE_FIELD_NAME){
        fields.push({
          field: 'dateForTooltipxAxis',
          title: 'Date',
          format: (value) => {
            return DateTimeUtils.epochToCustomDateFormat(this.props.dateFormat, value / 1000);
          },
        });
      }
      else{
        fields.push({
          title: this.props.xAxis[0],
          field: this.props.xAxis[0],
          valueAccessor: (itemData) => {
            if(this.props.xAxis[0] == NETWORK_ELEMENT){
              return itemData[FieldTypes.NETWORK_ELEMENT]?.[FieldTypes.NETWORK_ELEMENT_TREE_PATH];
            }

            return itemData[this.props.xAxis[0]];
          },
        });
      }
    }
    
    if (this.props.isOverlayExists){
      fields.push(...[{
          field: 'yOverlayForTooltip',
          title: 'Value',
          formatType: 'string',
        },
        {
          field: 'yUnitOverlayForTooltip',
          title: 'Unit',
        },
        {
          field : 'overlayTooltipTitle',
          formatType: 'string',
          title : 'Title',
        }
        
      ]);
    }
    
    if(WidgetTypes.isPieChart(this.props.widgetType) || this.props.yAxis){
      fields.push(...[{
          field: 'yForTooltip',
          title: 'Value',
          formatType: 'number',
          format: this.props.valueFormat,
        }, {
          field: 'yUnitForTooltip',
          title: 'Unit',
        },
      
      ]);
    }
    if(this.props.seriesDifferentiator){
      fields.push({
        field: this.props.seriesDifferentiator,
        title: 'Series',
      });
    }
    
    if (this.props.isThresholdExist){
      fields.push(...[{
        field : 'thresholdToolTip',
        formatType: 'number',
        title : 'Value',
        format: this.props.valueFormat,
      }, {
        field : 'thresholdToolTipTitle',
        formatType: 'string',
        title : 'Title',
      }]);
    }
    if(this.props.hasCustomFields && this.props.getTooltipFields){
      fields.push(...this.props.getTooltipFields());
    }else {
      fields.push(...[{
        field: 'upperForTooltip',
        title: 'Upper value',
        formatType: 'number',
        format: UnitConversionUtils.DEFAULT_VALUE_FORMAT,
      }, {
        field: 'lowerForTooltip',
        title: 'Lower value',
        formatType: 'number',
        format: UnitConversionUtils.DEFAULT_VALUE_FORMAT,
      }]);
    }

    if(this.props.isMlJob){
      fields.push(...this.getConfidenceAreaFields());
    }else if(WidgetTypes.isMLAnomaly(this.props.widgetType)){
      if(this.props.showExpectedLine){
        fields.push({
          field: `${FieldTypes.RESERVED_NAMES.ML_ENSEMBLE_EXPECTED_FIELD_NAME}ForTooltip`,
          title: 'Expected Signal',
          formatType: 'number',
          format: this.props.valueFormat,
        });
      }
      if(this.props.showBands){
        fields.push(...this.getConfidenceAreaFields());
      }
    }
    options.fields = fields;
    vegaTooltip.vega(view, options);
  }

  
  getConfidenceAreaFields(){
    return [
      {
        field: 'LOW_BOUND_TOOLTIP',
        title: 'Confidence range',
        formatType: 'string',
      },
      {
        field: 'MID_BOUND_TOOLTIP',
        title: 'Low Range',
        formatType: 'string',
      }, 
      {
        field: 'HIGH_BOUND_TOOLTIP',
        title: 'Medium Range',
        formatType: 'string',
      },
    ]
  }
  

  handleZooming(name, value){
    if(value){
      this.props.onChartxAxisChanged(+(value[0])/1000, +(value[value.length - 1])/1000);
    }
  }

  onSignalSelectedBar(name, value){
    if(value){
      this.props.onSignalSelectedBar(value);
    }
  }

  handleOverlayDrillDown(name, value) {
    if(value) {
      this.props.onOverlayDrillDown(value.datum[FieldTypes.DATE_FIELD_NAME] / 1000);
    }
  } 

  handlePieSliceDrillDown(name, value){
    if(value){
      this.props.onPieSliceDrillDown([value]);
    }
  }

  componentWillUnmount(){
    d3.select(this.ref).selectAll("*").remove();
    d3.select(this.ref).remove();
    this.ref = null;
  }


  render() {
    const specs = fromJS(this.props.chartOptions).toJS();
    const divStyle = this.props.inAnnotationMode ? {} : {
      overflow: 'auto',
      width: this.props.preview ? '100%' : `${this.props.chartWidth}px`,
      height: this.props.preview ? '100%' : `${this.props.chartHeight}px`,
    };

    return (
      <div> 
        <div ref={ ref => this.ref = ref } 
          style={divStyle} 
          className={`vegaWrapper ${this.props.enablePointDrillDown ? 'pointDrilldown' : ''} ${this.props.enableOverlayDrillDown ? 'pointOverlay' : ''} ${this.props.isDrillable ? 'drillable' : ''}`}
        >
          <Vega spec={specs} 
            renderer='svg' 
            onNewView={this.handleNewView} 
            onSignalDetailDomain={this.handleZooming} 
            onSignalSelectedBar={this.onSignalSelectedBar}
            onSignalPieSliceClicked={this.handlePieSliceDrillDown}
            onSignalOverlayClicked={this.handleOverlayDrillDown}
          />
        </div>
      </div>
    );
  }
}
