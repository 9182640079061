/* Copyright 2019 Greyskies. All Rights Reserved. */

import {MsgDefaults} from 'js-utils';
import Icons from 'templates/Icons';
import * as CustomTabs from './CustomTabs';

export default class CustomPalette {
  constructor(create, elementFactory, palette) {
    this.create = create;
    this.elementFactory = elementFactory;

    this.createTask = :: this.createTask;
    this.createTaskByTab = :: this.createTaskByTab;
    this.getEntries = :: this.getEntries;

    palette.registerProvider(this);
  }

  createTaskByTab = (tab) => (event) => {
    this.createTask(event, tab.scriptTask, tab.namespace, tab.taskNamespace);
  }

  createTask(event, taskName, taskAction, taskType) {
    const shape = this.elementFactory.createShape({ type: taskType});
    
    shape.businessObject.name = taskName;
    shape.businessObject.taskAction = taskAction;
    this.create.start(event, shape);
  }

  getEntries(entries){
    delete entries['create.data-object'];
    delete entries['create.data-store'];
    delete entries['create.intermediate-event'];
    delete entries['create.subprocess-expanded'];
    delete entries['create.task'];
    delete entries['space-tool'];
    delete entries['create.participant-expanded'];
    delete entries['create.group'];

    entries['hand-tool'].className = Icons.handTool;
    entries['lasso-tool'].className = Icons.artBoard;
    entries['global-connect-tool'].className = Icons.workflowArrow;
    entries['create.start-event'].className = Icons.circle;
    entries['create.end-event'].className = Icons.boldCircle;
    entries['create.exclusive-gateway'].className = Icons.diamond;

    Object.values(CustomTabs.tabs).forEach( tab => {
      const createTaskFun = this.createTaskByTab(tab);

      entries[`create.${tab.entryTask}`] = {
        group: 'activity',
        className: tab.icon,
        title: MsgDefaults.getCreateButtonText(tab.btnText),
        action: {
          dragstart: createTaskFun,
          click: createTaskFun,
        },
      };
    });

    return entries;
  }

  getPaletteEntries(element) {
    return this.getEntries;
  }

}