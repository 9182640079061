/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';

const BusyLinearLoader = props =>
  <div className='busyLinearLoader'>
    <div className='freeze'></div>
  </div>  ;

export default BusyLinearLoader;
