/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { ValidationUtils } from 'js-utils';

const ValidationOutput = (props) => {
  if(!ValidationUtils.isValid(props.validation)){
    return (
      <span className={`warning-span ${props.className ? props.className : ''}`}>
        {props.validation.label}
      </span>
    );
  }

  return null;
};

export default ValidationOutput;