/* Copyright 2019 Greyskies. All Rights Reserved. */

import bootbox  from 'bootbox';
import 'bootstrap/js/modal';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';

export default {
  confirm: ({message, cb, title, isReactBody, className, buttons, onCancel, fullCB}) => {
    bootbox.confirm({
      message: isReactBody ? UIConstructionUtils.renderDomToString(message) : message,
      title,
      className,
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-primary',
        },
        cancel: {
          label: 'No',
          className: 'btn-cancel',
        },
        ...buttons,
      },
      callback: (result) => {
        if(typeof fullCB === 'function'){
          fullCB(result);
        }else if (result && typeof cb === 'function') {
          cb();
        }else if(typeof onCancel === 'function'){
          onCancel();
        }

        return true;
      },
    });
  },
  alert: ({ message, title, isReactBody, className, size, cb }) => {
    bootbox.alert({
      message: isReactBody ? UIConstructionUtils.renderDomToString(message) : message,
      title,
      className,
      size,
      buttons: {
        ok: {
          label: 'Ok',
          className: 'btn-primary',
        },
      },
      callback: () => {
        if (typeof cb === 'function') {
          cb();
        }
      },
    });
  }
};
