import Joi from '@hapi/joi';
import {DateTimeUtils, ValidationUtils} from 'js-utils';
import ElementSizes from 'utils/ElementSizes';

const requiredStringValidation = Joi.string().required().error(errors => ValidationUtils.ERR_MSGS.required(errors[0].local.label));
const requiredNumberValidation = Joi.number().required().error(errors => ValidationUtils.ERR_MSGS.required(errors[0].local.label));
const requiredArrayValidation = Joi.array().required().min(1).error(errors => ValidationUtils.ERR_MSGS.required(errors[0].local.label));
const requiredResolutionValidation = Joi.object().keys({
  interval: requiredNumberValidation, unit : requiredStringValidation,
});
// To define a new input type, Add its key and value inside INPUT_TYPES
// to use it globally, and define its props inside INPUTS
//INPUT_TYPES value and INPUTS key must match


// exported Input types that any module importing the form components 
// should import to define the form input types
export const INPUT_TYPES = {
  text: 'text',
  textArea: 'textArea',
  tagsInput: 'tagsInput',
  checkbox: 'checkbox',
  toggle: 'toggle',
  multipleSelect: 'multipleSelect',
  radio: 'radio',
  fileUpload: 'fileUpload',
  resolutionInput: 'resolutionInput',
  sharingCircle: 'sharingCircle',
  custom: 'custom',
  itemsList: 'itemsList',
  separator: 'separator',
  checkboxGroup: 'checkboxGroup',
  datePicker: 'datePicker',
  dateRangePicker: 'dateRangePicker',
  editor: 'editor',
  number: 'number',
  tree: 'tree',
  treeSelectorPopup: 'treeSelectorPopup',
  popupInput: 'popupInput',
  inputGroup: 'inputGroup', 
};

// Used internally inside form components and not exposed to other modules
// To define a new input type, please add all its props
// type and contstructor name are mandatory, getValueFromEvent is mandatory
// if the input uses the setField function to set its value.
//Contstructor name must match a function name in FormConstructors

export const INPUTS = {
  text: {
    type: INPUT_TYPES.text,
    requiredSchema: requiredStringValidation,
    initialValue: '',
    constructorName: 'constructText',
    getValueFromEvent: (e) => e.target.value,
    updateOnBlur: true,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  textArea: {
    type: INPUT_TYPES.textArea,
    requiredSchema: requiredStringValidation,
    initialValue: '',
    constructorName: 'constructTextArea',
    getValueFromEvent: (e) =>  e.target.value,
    updateOnBlur: true,
    triggerBtnSize: ElementSizes.MEDIUM, 
  },
  tagsInput: {
    type: INPUT_TYPES.tagsInput,
    requiredSchema: requiredArrayValidation,
    initialValue: [],
    constructorName: 'constructTagsInput',
    getValueFromEvent: (e) =>  e,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  checkbox: {
    type: INPUT_TYPES.checkbox,
    requiredSchema: null,
    initialValue: false,
    constructorName: 'constructCheckbox',
    getValueFromEvent: (e) =>  e.target.checked,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  toggle: {
    type: INPUT_TYPES.toggle,
    requiredSchema: null,
    initialValue: false,
    constructorName: 'constructToggle',
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  multipleSelect: {
    type: INPUT_TYPES.multipleSelect,
    requiredSchema: Joi.alternatives().try(requiredArrayValidation, 
      Joi.alternatives().try(requiredStringValidation, requiredNumberValidation)).error(errors => ValidationUtils.ERR_MSGS.required(errors[0].local.label)),
    initialValue: null,
    constructorName: 'constructMultipleSelect',
    getValueFromEvent: (e) => e.target.value,
    getFilterDisplayValue: (result) => result && result.length > 0 ? result[0] : null,
    triggerBtnSize: ElementSizes.MEDIUM,
    updateOnBlur: true,
  },
  radio: {
    type: INPUT_TYPES.radio,
    requiredSchema: null,
    initialValue: '',
    constructorName: 'constructRadio',
    getValueFromEvent: (e) => e.target.value,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  fileUpload: {
    type: INPUT_TYPES.fileUpload,
    requiredSchema: Joi.alternatives().try(Joi.object().required(), Joi.string().min(1).required()).error(errors => ValidationUtils.ERR_MSGS.required(errors[0].local.label)),
    initialValue: null,
    constructorName: 'constructFileUpload',
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  resolutionInput: {
    type: INPUT_TYPES.resolutionInput,
    requiredSchema: requiredResolutionValidation,
    initialValue: null,
    constructorName: 'constructResolutionInput',
    getValueFromEvent: (e) => e,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  sharingCircle: {
    type: INPUT_TYPES.sharingCircle,
    requiredSchema: null,
    initialValue: [],
    constructorName: 'constructSharingCircle',
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  custom: {
    type: INPUT_TYPES.custom,
    requiredSchema: null,
    constructorName: 'constructCustomComponent',
    hideLabel: true,
    hideValidation: true,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  itemsList: {
    type: INPUT_TYPES.itemsList,
    requiredSchema: null,
    initialValue: [],
    constructorName: 'constructItemsList',
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  separator: {
    type: INPUT_TYPES.separator,
    requiredSchema: null,
    constructorName: 'constructSeparator',
    hideLabel: true,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  checkboxGroup: {
    type: INPUT_TYPES.checkboxGroup,
    requiredSchema: null,
    initialValue: false,
    constructorName: 'constructCheckboxGroup',
    getValueFromEvent: (e) =>  e.target.checked,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  datePicker: {
    type: INPUT_TYPES.datePicker,
    requiredSchema: requiredNumberValidation,
    initialValue: null,
    constructorName: 'constructDatePicker',
    getValueFromEvent: (e) => e.date,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  dateRangePicker: {
    type: INPUT_TYPES.dateRangePicker,
    requiredSchema: null,
    initialValue: {},
    constructorName: 'constructDateRangePicker',
    getValueFromEvent: (e) => e,
    getFilterDisplayValue: result => `${DateTimeUtils.epochToCustomDateFormat(null,
      result.from)} -  ${DateTimeUtils.epochToCustomDateFormat(null,
        result.to)}`,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  editor: {
    type: INPUT_TYPES.editor,
    requiredSchema: requiredStringValidation,
    initialValue: '',
    constructorName: 'constructEditor',
    getValueFromEvent: (e) => e,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  number: {
    type: INPUT_TYPES.number,
    requiredSchema: requiredNumberValidation,
    initialValue: null,
    constructorName: 'constructNumber',
    getValueFromEvent: (e) => e.target.value,
    updateOnBlur: true,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  treeSelectorPopup: {
    type: INPUT_TYPES.treeSelectorPopup,
    requiredSchema: null,
    initialValue: null,
    constructorName: 'constructTreeSelectorPopup',
    showFilterCount: true,
    triggerBtnSize: ElementSizes.AUTO,
  },
  tree: {
    type: INPUT_TYPES.tree,
    requiredSchema: null,
    initialValue: null,
    constructorName: 'constructTree',
    getValueFromEvent: (e) => e,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  popupInput: {
    type: INPUT_TYPES.popupInput,
    requiredSchema: null,
    initialValue: null,
    constructorName: 'constructPopupInput',
    triggerBtnSize: ElementSizes.MEDIUM,
  },
  inputGroup: {
    type: INPUT_TYPES.inputGroup,
    requiredSchema: null,
    initialValue: null,
    constructorName: 'constructInputGroup',
	getValueFromEvent: (e) => e.target.value,
	updateOnBlur: true,
    triggerBtnSize: ElementSizes.MEDIUM,
  },
};