/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Row, Col } from 'react-bootstrap';
import {fromJS} from 'immutable';
import CommonTreeWrapper from 'dataSelection/trees/CommonTree/CommonTreeWrapper';
import * as CommonTreeValidation from 'dataSelection/trees/CommonTree/CommonTreeValidation';
import * as defaults from 'utils/defaults';
import FilterInput from 'dataSelection/records/FilterInput';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as filterUtils from 'utils/filterUtils';
import {ValidationUtils, CompareUtils} from 'js-utils';
import NetworkElementSelectionRadioGroup from 'dataSelection/NetworkElementSelectionRadioGroup';
import * as advancedFilterValidator from 'utils/AdvancedFilterValidationSchema';
import ValidationOutput from 'containers/ValidationOutput';
import * as CommonValidationSchema from 'utils/CommonValidationSchema';
import LabeledComponent from 'containers/LabeledComponent';
import * as FFAGValidationSchema from 'utils/validations/FFAGValidationSchema';
import DataInput from 'inputs/simple/DataInput';

export default class TreeWithPlaceholderOption extends React.Component {

  constructor(props) {
    super(props);
    const selection = this.adjustSelection(props);
    this.state = {
      selection,
      networkElementDataStructure: selection.networkElementDataStructure,
      placeholderAdvFilterValidation: {isValid: true},
      placHolderValidation: {},
    };
    
    this.saveSelection = :: this.saveSelection;
    this.checkValidationOnSave = :: this.checkValidationOnSave;
    this.updateSelection = :: this.updateSelection;
    this.renderTreeModal = :: this.renderTreeModal;
    this.getPlaceholderTab = :: this.getPlaceholderTab;
    this.setGroupSelection = :: this.setGroupSelection;
    this.getDynamicTree = :: this.getDynamicTree;
    this.onSelectInCommonTree = :: this.onSelectInCommonTree;
    this.onClick = :: this.onClick;
    this.getNEPlaceholderOptions = :: this.getNEPlaceholderOptions;
    this.updateAdvancedFilters = :: this.updateAdvancedFilters;
    this.clearPreviousSelection = :: this.clearPreviousSelection;
    this.onChangeNEDataStructures = :: this.onChangeNEDataStructures;
    this.onChangeNEPlaceholder = :: this.onChangeNEPlaceholder;
    this.canClickNode = :: this.canClickNode;
    this.getVariableTab = :: this.getVariableTab;
    this.onChangeVariableName = :: this.onChangeVariableName;
    this.onBlurVariableName = :: this.onBlurVariableName;
    this.clearPreviousSelection();
  }

  componentDidMount(){
    this.afterChangeNE(this.props, this.props.networkElementDataStructure);
  }

  componentWillReceiveProps(newProps){
    const newState = fromJS(this.state).toJS();
    let isStateChanged = false;

    if(!_.isEqual(this.props.selection, newProps.selection)){
      newState.selection = this.adjustSelection(newProps);
      isStateChanged = true;
    }

    if((this.props.networkElementDataStructure && this.props.networkElementDataStructure.id) 
        !== (newProps.networkElementDataStructure && newProps.networkElementDataStructure.id)){
      newState.selection = this.adjustSelection(newProps);
      isStateChanged = true;
      this.afterChangeNE(newProps, newProps.networkElementDataStructure);
    } 
    
    if(isStateChanged){
      newState.networkElementDataStructure = newState.selection.networkElementDataStructure;
      this.clearPreviousSelection();
      this.setState(newState);
    }
  }

  afterChangeNE(props, networkElementDataStructure){
    this.getNEPlaceholderOptions(props, networkElementDataStructure);
    this.getAdvancedFilterOptions(networkElementDataStructure);
  }
  getAdvancedFilterOptions(selectedNetworkElement){
    if(this.props.listAttributesOfAdvancedFiltersComponent && selectedNetworkElement && selectedNetworkElement.id){
      this.props.listAttributesOfAdvancedFiltersComponent(selectedNetworkElement.id).then( attributesFilterOptions => {
        this.setState({attributesFilterOptions});
      });
    }
  }

  getNEPlaceholderOptions(props, networkElementDataStructure){
    if(props.getNEPlaceholderOptions && networkElementDataStructure && networkElementDataStructure.id){
      props.getNEPlaceholderOptions(networkElementDataStructure.id).then(placeholdersOptions => {
        this.setState({placeholdersOptions});
      });
    }
  }

  adjustSelection(props){
    let newSelection;

    if(filterUtils.isVariableFilter(props.filterValueType)){
      newSelection = {
        selectionType:  props.filterValueType,
        variableName: props.variable?.name,
      }
    }else{
      newSelection = fromJS(props.selection || {}).toJS();
  
      newSelection.selectionType = newSelection.selectionType || defaults.NETWORK_ELEMENT;
    }
    newSelection.networkElementDataStructure = props.networkElementDataStructure;

    return newSelection;
  }

  updateSelection(selection){
    this.props.updateSelection(selection, this.props.index);
  }
  
  validatePlaceHolder(nePlaceholder){
    return this.validateString(filterUtils.NE_PLACEHOLDER, nePlaceholder && nePlaceholder.name);
  }

  validateString(stringLabel, fieldValue){
    const placeholderSchema = CommonValidationSchema.getStringValidationSchema(stringLabel);
    const validation = ValidationUtils.validateAttributeSchema(placeholderSchema, fieldValue);

    return {validation, isValid: ValidationUtils.isValid(validation)};
  }

  validateVariableName(fieldValue){
    const validation = ValidationUtils.validateAttributeSchema(FFAGValidationSchema.routingSelectionValidation, {
      filterValueType: filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
      variable: {name: fieldValue},
    });

    return {validation, isValid: ValidationUtils.isValid(validation)};
  }

  checkValidationOnSave(){
    const neLevelValidation = {};
    if(!this.props.showNESelection 
      || CommonTreeValidation.validateNELevel(this.state.networkElementDataStructure, neLevelValidation)){
      this.saveSelection();
    }else{
      this.setState({neLevelValidation});
    }
  }

  onChangeNEDataStructures(e){
    const neLevelValidation = {};
    const networkElementDataStructure = this.props.neDataStructures[e.target.value];
    const selection = {
      selectionType: (this.state.selection && this.state.selection.selectionType) || defaults.NETWORK_ELEMENT,
      networkElementDataStructure,
    };
    this.clearPreviousSelection();
    this.afterChangeNE(this.props, networkElementDataStructure);

    CommonTreeValidation.validateNELevel(networkElementDataStructure, neLevelValidation);
    this.setState({neLevelValidation, 
      networkElementDataStructure,
      selection,
    });
  }

  saveSelection(){
    if(this.state.selection.selectionType == filterUtils.PLACEHOLDER){
      const placHolderValidation = this.validatePlaceHolder(this.state.selection.nePlaceholder);

      if(placHolderValidation.isValid){
        if(this.state.placeholderAdvFilterValidation.isValid){
          this.updateSelection(this.state.selection);
        }
      }else{
        this.setState({placHolderValidation});
      }
    }else if(filterUtils.isVariableFilter(this.state.selection.selectionType)){
      const variableValidation = this.validateVariableName(this.state.selection?.variableName);
      if(variableValidation.isValid){
        this.updateSelection(this.state.selection);
      }else{
        this.setState({variableValidation});
      }
    } else {
      this.treePanel.saveSelection();
    }
  }

  onSelectInCommonTree(selectionState){
    this.clearPreviousSelection();
    this.setState({
      selection: selectionState.selection,
    });
  }
  canClickNode(node){
    return node.isLeaf && this.props.entityOptions.singleSelection;
  }
  onClick(node, selectionState){
    this.clearPreviousSelection();
    this.setState({
      selection: selectionState.selection,
    });
  }
  getDynamicTree(){
    return (<CommonTreeWrapper
      ref={(ref => this.treePanel = ref)}
      entityOptions={this.props.entityOptions}
      getTreeNodesAction={this.props.getTreeNodesAction}
      attributesFilterOptions={this.state.attributesFilterOptions}
      getSelectionFinalForm={this.props.getSelectionFinalForm}
      updateSelection={this.updateSelection}
      selection={this.state.selection}
      isTemplate={this.props.isTemplate}
      getPlaceholderTab={this.getPlaceholderTab}
      onSelect={this.onSelectInCommonTree}
      getNodePath={this.props.getNodePath}
      canClick={this.canClickNode}
      onClick={this.onClick}
      networkElementTypes={this.props.networkElementTypes}
      listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
    />);
  }

  onChangeNEPlaceholder(e){
    const selection = fromJS(this.state.selection).toJS();

    this.clearPreviousSelection();
    selection.nePlaceholder = this.state.placeholdersOptions.find(nePlaceholder => nePlaceholder.id == e.target.value);
    const placHolderValidation = this.validatePlaceHolder(selection.nePlaceholder);
    this.setState({selection, placHolderValidation});
  }

  getAdvancedFilterValidation(advancedFilters, validationOptions = {}){
    const validation = {};
    const advancedFiltersValidation = fromJS(this.state.placeholderAdvFilterValidation).toJS();

    advancedFiltersValidation.isValid = advancedFilterValidator.validateAdvancedFilter(
      advancedFilters, validation, {
        checkValidation: true,
        checkListValidation: validationOptions.checkListValidation,
        minFilters: 0,
      });

    if(validationOptions.checkListValidation || validationOptions.checkValidation){
      advancedFiltersValidation.filterInput = validation.filterInput;
    }

    return advancedFiltersValidation;
  }

  updateAdvancedFilters(newAdvancedFilters, checkValidation, checkListValidation){
    const selection = fromJS(this.state.selection).toJS();

    selection.advancedFilters = newAdvancedFilters.filters;
    if(!_.isEqual(this.state.advancedFilters, selection.advancedFilters)){
      const placeholderAdvFilterValidation = this.getAdvancedFilterValidation(selection.advancedFilters, {checkValidation: true,
        checkListValidation});

      this.setState({selection, placeholderAdvFilterValidation});
    }
  }

  getPlaceholderTab(){
    const nePlaceholderOptions = this.state.placeholdersOptions 
    && UIConstructionUtils.wrapArrayInToSelectOptions(this.state.placeholdersOptions, 'id', 'id', 'name');
    
    const operations = UIConstructionUtils.wrapArrayInToSelectOptions(CompareUtils.COMPARATORS, 'value', 'value', 'name');

    return (
    <Fragment>
      <Row className='margBtm10'>
        <Col xs={12}>
          <FilterInput
            operations={operations}
            updateFiltersList={this.updateAdvancedFilters}
            attributesList={this.state.attributesFilterOptions}
            selectionAdvancedFilters
            validationOutcome={this.state.placeholderAdvFilterValidation.filterInput}
            filters = {this.state.selection.advancedFilters}
          />
        </Col>
      </Row>
      <Row>
        <LabeledComponent 
            label={filterUtils.NE_PLACEHOLDER} 
            size={12} 
            labelSize={4} 
            inputSize={8} >
          <MultipleSelect
            options={nePlaceholderOptions}
            placeholder={ filterUtils.CHOOSE_PLACEHOLDER}
            onChange={this.onChangeNEPlaceholder}
            value={ this.state.selection.nePlaceholder 
              && (this.state.selection.selectionType == filterUtils.PLACEHOLDER) ? 
              this.state.selection.nePlaceholder.id : null}
          />
        </LabeledComponent>
        <ValidationOutput validation={this.state.placHolderValidation.validation} />
      </Row>
    </Fragment>
    );
  }

  clearPreviousSelection(){
    this.groupChanged = false;
    this.previousSelection = undefined;
  }

  setGroupSelection(e) {
    if (e.target.value !== this.state.selection.selectionType) {
      let selection;

      if(this.groupChanged && this.previousSelection && this.previousSelection.selectionType == e.target.value) {
        selection = {
          ...this.previousSelection,
          networkElementDataStructure: this.state.networkElementDataStructure,
        };
    
        this.clearPreviousSelection();
      } else {
        if(!this.groupChanged){
          this.previousSelection = fromJS(this.state.selection).toJS();
        }
        selection = {
          selectionType: e.target.value,
          networkElementDataStructure: this.state.networkElementDataStructure,
        };
        this.groupChanged = true;
      }
      this.setState({
        selection,
      });
    }
  }
  getModelBody(){
    if(this.state.selection.selectionType == filterUtils.PLACEHOLDER){
      return this.getPlaceholderTab()
    } else if(filterUtils.isVariableFilter(this.state.selection.selectionType)){
      return this.getVariableTab()
    } else {
      return this.getDynamicTree()
    }
  }

  renderTreeModal(){
    const neDataStructuresOptions = this.props.neDataStructures 
    && UIConstructionUtils.wrapArrayInToSelectOptions(Object.values(this.props.neDataStructures), 'id', 'id', 'name');
    const networkElementDataStructureId = this.state.networkElementDataStructure && this.state.networkElementDataStructure.id;

    return(
      <Fragment>
        {this.props.showNESelection?
          <Row>
            <Col xs={12}>
              <MultipleSelect
              value={networkElementDataStructureId}
              placeholder={this.props.neSelectionPlaceholder}
              options={neDataStructuresOptions}
              onChange={this.onChangeNEDataStructures}/>
              <ValidationOutput validation={ this.state.neLevelValidation && this.state.neLevelValidation.NELevelOption } />
            </Col>
          </Row>
          : null
        }
        {this.props.hasVariables || networkElementDataStructureId || (!this.props.showNESelection && this.props.entityOptions.showNESelection) ?
        <Row>
          <Col xs={12}>
          {this.props.hideGroupElementSelection || (!this.props.isTemplate && this.props.isGroupHidden) ? null : 
            <NetworkElementSelectionRadioGroup
              selectedGroup={this.state.selection.selectionType}
              setGroupSelection={this.setGroupSelection}
              isTemplate={this.props.isTemplate}
              isGroupHidden={this.props.isGroupHidden}
              hasVariables={this.props.hasVariables}
            />
          }

          {
            this.getModelBody()
          }  
          </Col> 
        </Row> : null}

      </Fragment>
    );
  }

  onBlurVariableName(e){
    const variableValidation = this.validateVariableName( e.target.value);
    this.clearPreviousSelection();
    this.setState({
      selection: {
        selectionType: filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
        variableName: e.target.value,
      },
      variableValidation
    });
  }

  onChangeVariableName(e){
    this.setState({
      selection: {
        selectionType: filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
        variableName: e.target.value,
      },
    });
  }
  
  getVariableTab(){
    const variableLabel = filterUtils.FILTER_TYPE_OPTIONS.VARIABLE.name;
    return (
      <Row>
        <DataInput
            key='filterWithVariables'
            label={`Network Element ${variableLabel }`} 
            size={12} labelSize={4} inputSize={8}
            className='margBtm0 filterWithVariables'
            disabled={this.props.disableComponent}
            onChange={this.onChangeVariableName}
            onBlur={this.onBlurVariableName}
            value={this.state.selection?.variableName}
            validation={this.state.variableValidation?.validation}
          />
      </Row>


    );
  }

  render() {
    return this.renderTreeModal();
  }
}