/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment } from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import {CompareUtils} from 'js-utils';
import * as defaults from 'utils/defaults';
import Icons from 'templates/Icons';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import {getCompoundAggregateFieldName} from 'utils/DataSelectionCommonUtils';

const getFilterElement = (content, onRemoveQuickFilter, id) => {
  return (
    <div className='expandableArea pull-right'>
      <Row>
        <Col xs={12}>
          <label className='data-input-label filterLabel'>
            {content}
          </label>
          <Button className='btn btn-action pull-right'
            onClick={() => onRemoveQuickFilter(id)}>
            <i className={Icons.close}></i>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const getFieldName = (filter) => {
  return filter.field ? getCompoundAggregateFieldName(filter.field)
  : filter.selectedField.fieldName;
};

const getFilterText = (filter) => {
  const operatorSymbol = CompareUtils.COMPARATORS.find(comparator => { 
    return comparator.value == filter.operation;
  }).operatorSymbol;
  const filterElement = getFieldName(filter) + ' ' + operatorSymbol + ' ' + filter.compareValue;

  return filterElement;
};

const WidgetQuickAppliedFilters = props => {
  let filterToRender = '';
  const appliedFiltersCount = props.filters.length;

  return(
    <Fragment>
      {
        appliedFiltersCount ? 
          <Row>
            <Col xs={12}>
              <label className='data-input-label'>
                {defaults.APPLIED_FILTERS}
              </label>
              {
                ButtonsConstructor.getButtonComponent(
                  {className: 'btn btn-action',
                    onClick: () => {props.onRemoveQuickFilter()}},
                  <i className={Icons.close}></i>)
              }
            </Col>
          </Row> : null}
      <ul className="filters-list">
        {props.filters.map(filter => {
          filterToRender = getFilterText(filter);

          return(
            <li key={filter.idCounter}>
              {getFilterElement(filterToRender, props.onRemoveQuickFilter, filter.idCounter)}
            </li>
          );
        })}
      </ul>
    </Fragment>);
};

export default WidgetQuickAppliedFilters;
