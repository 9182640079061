/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import 'styles/containers/collapsibleComponent.less';
import Icons from 'templates/Icons';
import 'styles/containers/collapsableBarWrapper.less'

export default class CollapsableBarWrapper extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      collapsed: this.props.initiallyCollapsed,
    };

    this.reverseCollapse = ::this.reverseCollapse;
  }

  getCaret(collapsed) {
    return collapsed ? Icons.chevronDown : Icons.chevronUp;
  }

  reverseCollapse() {
    const collapsed = !this.state.collapsed;

    this.setState({collapsed});
    if(this.props.reverseCollapse){
      this.props.reverseCollapse();
    }
  }

  render(){
    const caret = this.getCaret(this.state.collapsed); 

    return (
      <div className={`${ this.state.collapsed ? '' : 'expandedPlaceHolder expandAnimate'} fixedPlaceholder `}>
        <Row className={`${ this.state.collapsed ? 'hidden' : ''} `}>
            <div className="systemMood collapsablePlaceholder"> 
              {this.props.children} 
            </div>
        </Row>
        <Row>
          <Col xs={12} className={`${ this.state.collapsed ? 'btnCollapsedMargin' : ''} collapsableBarWrapper`} onClick={this.reverseCollapse}>      
            <div className={`placeholderExpand-btn`}>
              <i className={caret}></i>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
