/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import { Button} from 'react-bootstrap';
import SelectionButtonWithIcon from 'buttons/SelectionButtonWithIcon';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import DataSelectionWithType from './DataSelectionWithType';
import Modal from 'containers/Modal';
import Icons from 'templates/Icons';
import {MsgDefaults, AggregationUtils, Utils} from 'js-utils';
import { fromJS } from 'immutable';
import {FFAG_SETTINGS_KEYWORDS as KEYWORDS} from 'utils/ffagDefaults';

export default class DataSelectionPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSelection:{
        ffagType: props.ffagType,
        dataSelectionFga: props.dataSelectionFga,
        seriesAggregation: props.seriesAggregation,
        nthPercentileValue: props.nthPercentileValue,
        unitSelection: props.unitSelection,
        topNSettings: props.topNSettings,
        lastValue: props.lastValue,				
      },
      dataSelectionOptions: props.dataSelectionOptions,
    };

    this.openModal = :: this.openModal;
    this.closeModal = :: this.closeModal;
    this.renderButton = :: this.renderButton;
    this.renderDataSelectionModal = :: this.renderDataSelectionModal;
    this.saveDataSelection = :: this.saveDataSelection;
    this.onDataSelectionChange = :: this.onDataSelectionChange;
    this.onResetClick = :: this.onResetClick;
    this.getAutoCompleteSuggestions = ::this.getAutoCompleteSuggestions;
  }
  openModal(){
    const dataSelection = {
      ffagType: this.props.ffagType,
      dataSelectionFga: this.props.dataSelectionFga,
      seriesAggregation: this.props.seriesAggregation,
      nthPercentileValue: this.props.nthPercentileValue,
      unitSelection: this.props.unitSelection,
      topNSettings: this.props.topNSettings,
      lastValue: this.props.lastValue,				
    };

    this.setState({
      showModal: true,
      dataSelection,
      dataSelectionOptions: this.props.dataSelectionOptions,
    });
  }  
  
  closeModal(){
    this.setState({showModal: false});
  }

  getAutoCompleteSuggestions(query, extraParams){
    const newExtraParams = {
      ...extraParams,
      mappingFilters: this.state.dataSelection.dataSelectionFga.mappingFilter ?
        [this.state.dataSelection.dataSelectionFga.mappingFilter] : [],
      recordType: this.state.dataSelection.dataSelectionFga.sourceType,
      routingFilters: this.state.dataSelection.dataSelectionFga.routingFilter ?
        [this.state.dataSelection.dataSelectionFga.routingFilter] : [],
    };

    return this.props.dataSelectionParams.getAutoCompleteSuggestions(query, newExtraParams);
  }
  
  renderButton(){
    const fgaName = this.props.dataSelectionFga && this.props.dataSelectionFga.name ? 
      this.props.dataSelectionFga.name : MsgDefaults.getAddNewTitle("FGA");
    const isResetBtnDisabled = this.props.dataSelectionFga &&
     Utils.isEmpty(this.props.dataSelectionFga.name);
    const hasPlaceholders = this.props.dataSelectionFga?.placeholders ? Object.values(this.props.dataSelectionFga?.placeholders).length > 0 : false;
    
    return (   
      <SelectionButtonWithIcon
        displayName={fgaName}
        entityName='FGA'
        disable={this.props.disableComponent}
        icon={Icons.fga + Icons.styles.evLg}
        validation={this.props.validation}
        onClick={this.openModal}
        isResetBtnAvailable={this.props.isResetBtnAvailable || false}
        onResetClick={this.onResetClick || {}}
        isResetBtnDisabled={isResetBtnDisabled}  
        notificationIcon={hasPlaceholders ? Icons.placeholder : null}
      />
    );
  }

  onDataSelectionChange(newDataSelection){
    const newState = {
      dataSelection: {
        ...this.state.dataSelection,
        ...newDataSelection,
      },
    };

    if(newDataSelection.ffagType && newDataSelection.ffagType != this.state.dataSelection.ffagType){
      newState.dataSelectionOptions 
        = this.props.getDetailsDataSelectionOption(newDataSelection.ffagType);
    }

    this.setState(newState);
  }

  saveDataSelection(){
    const dataSelectionValidation = this.dataSelectionWithTypeRef.validateSettings();
    const props = this.props;
    
    if(dataSelectionValidation.isValid){
      const stateDataSelection = this.state.dataSelection;

      this.setState({showModal: false}, () => {
        const dataSelection = {
          [props.ffagTypeKeyword || KEYWORDS.ffagTypeKeyword]: stateDataSelection.ffagType,
          [props.dataSelectionKeyword || KEYWORDS.dataSelectionKeyword]: stateDataSelection.dataSelectionFga,
          [props.seriesAggregationKeyword || KEYWORDS.seriesAggregationKeyword]: stateDataSelection.seriesAggregation,
          [props.nthPercentileKeyword || KEYWORDS.nthPercentileKeyword]: stateDataSelection.nthPercentileValue,
          [props.unitKeyword || KEYWORDS.unitKeyword]: stateDataSelection.unitSelection,
          [props.topNSettingsKeyword || KEYWORDS.topNSettingsKeyword]: stateDataSelection.topNSettings,
          [props.isLatestKeyword || KEYWORDS.isLatestKeyword]: stateDataSelection.lastValue,
        };

        props.onDataSelectionChange(dataSelection);
      });
    } else {
      const dataSelection = fromJS(this.state.dataSelection).toJS();

      dataSelection.dataSelectionFga.hideValidation = false;
      this.setState({dataSelection});
    }
  }

  onResetClick(){

    const dataSelection = DataSelectionCommonUtils.getDataSelectionState();

    this.onDataSelectionChange({
        ffagType: this.props.defaultFfagType || null,
        dataSelectionFga: dataSelection,
        seriesAggregation: null,
        nthPercentileValue: null,
        unitSelection: null,
        topNSettings: null,
        lastValue: false,
      
    });
    this.props.onDataSelectionChange({
        [this.props.ffagTypeKeyword]: this.props.defaultFfagType || null,
        [this.props.dataSelectionKeyword]: dataSelection,
        [this.props.seriesAggregationKeyword]: null,
        [this.props.switchKeyword]: false,
        [this.props.unitKeyword]: null,
        [this.props.isLatestKeyword]: 'latest',
        [this.props.nthPercentileKeyword]: AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE,
        [this.props.topNSettingsKeyword]: null,
        [this.props.mappingsKeyword]: [],
      }, true);
    }
  
  renderDataSelectionModal(){
      const otherFfagNames = this.props.dataSelectionFga ? 
        this.props.ffagNames?.filter(name => name != this.props.dataSelectionFga.name)
        : this.props.ffagNames;
   
      return(
      <Modal bsSize="large"
        onHide={this.closeModal}
        title={MsgDefaults.getAddNewTitle('FGA')}
        show={this.state.showModal}
        footerButtons={[
          <Button
            bsStyle="primary"
            onClick={this.saveDataSelection}>
            Save
          </Button>
        ]}
      >
        <div className='dataSelectionPopupBody'>
          <DataSelectionWithType
            {...this.props.dataSelectionParams}
            {...this.state.dataSelection}
            ref={(ref => this.dataSelectionWithTypeRef = ref)}
            getDetailsDataSelectionOption={this.props.getDetailsDataSelectionOption}
            dataSelectionOptions={this.state.dataSelectionOptions}
            onDataSelectionChange={this.onDataSelectionChange}
            updateDataSettings={this.onDataSelectionChange}		
            dataSelectionKeyword='dataSelectionFga'
            ffagTypeKeyword='ffagType'
            seriesAggregationKeyword='seriesAggregation'
            nthPercentileKeyword='nthPercentileValue'
            unitKeyword='unitSelection'
            isLatestKeyword='lastValue'
            topNSettingsKeyword='topNSettings'
            ffagNames={otherFfagNames}
            getAutoCompleteSuggestions={this.getAutoCompleteSuggestions}
            placeholderOptions={this.props.placeholderOptions}
            isTemplate={this.props.isTemplate}
            getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          />
        </div>
      </Modal>
    );
  }

  render() {
    return(
      <Fragment>
        {this.renderButton()}
        {this.renderDataSelectionModal()}
      </Fragment>
    );
  }
}