/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import SidebarElement from './SidebarElement';
import $ from 'jquery';
import Scrollbar from 'react-scrollbar';
import 'styles/templates/sidebar.less';
import Icons from './Icons';

export default class Sidebar extends React.Component{

  constructor(props) {
    super(props);
    this.onSideBarEelementChange = ::this.onSideBarEelementChange;
  }
  componentDidMount(){
    if(!this.props.inNavbar){
      const wrapperHeight = $(window).height();

      $('#page-content-wrapper').css('min-height', wrapperHeight);

      $(window).resize(function () {
        const wrapperResizedHeight = $(window).height();

        $('#page-content-wrapper').css('min-height', wrapperResizedHeight);
      });
    }
  }

  refreshScrollbar(){
    setTimeout(() => {
      if(this.ScrolBoundRef){
        this.ScrolBoundRef.scrollArea.refresh();
      }
    }, 250);
  }

  componentDidUpdate(){
    this.refreshScrollbar();
  }

  onSideBarEelementChange(){
    this.refreshScrollbar();
  }

  render() {
    const sidebarElements = this.props.sidebarElements.map((element) => {
      return(
         <SidebarElement key = {element.name} element={element}
           onSideBarEelementChange = {this.onSideBarEelementChange}
            selectedPage = {this.props.selectedPage}
            inNavbar = {this.props.inNavbar}
            context = {this.props.context}/>
        );
    });

    if(!this.props.inNavbar){

      return (
        <div className='sidebar-wrapper' >
            <ul className="list-group panel sidebar-nav">
              {sidebarElements}
            </ul>
          </div>
      );
    }

    return (
      <ul className="list-group responsive-navbar">
        {sidebarElements}
        {this.props.switchThemeMode}
        {this.props.contextButton} 
        <li>
          <a title="Logout" href="logout">
            <i className={Icons.signOut + Icons.styles.ev2x}></i>
            <span className="navbar-item-title">
              Logout
            </span>
          </a>
        </li>
      </ul>
    );
  }
}
