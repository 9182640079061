/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import CollapsableComponent from '../../containers/CollapsableComponent';
import Panel from '../../containers/Panel';
import PanelGroup from '../../containers/PanelGroup';

const CategorizedArgumentsPanel = props => {
  return (
    <div className='form-horizontal'>
      <PanelGroup>
        <Panel panelTitle={props.panelTitle}
          defaultOpen
          classes={{ panelIdentifier: props.panelIdentifier }}>
          {props.arguments.map(argument => {
            return(
              <CollapsableComponent
                title={argument.title}
                wrapInRow
              > 
                {argument.renderArguments()}
              </CollapsableComponent>
            );
          })}
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default CategorizedArgumentsPanel;
