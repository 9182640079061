/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {FormGroup, FormControl, Col, Row} from 'react-bootstrap';
import { fromJS } from 'immutable';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import NumericInput from 'inputs/simple/NumericInput';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import UnitConversionDropdown from 'inputs/compound/UnitConversionDropdown';
import ValidationOutput from '../containers/ValidationOutput';
import {validationFunction} from 'utils/validations/ConditionComponentValidationSchema';
import {ValidationUtils, DataModelTypeUtils, FieldTypes as Types} from 'js-utils';
import CommonTreeInputWithModel from 'dataSelection/trees/CommonTree/CommonTreeInputWithModel';

const SERV_GEN_RESPONSE_UNIQUE_NAME = 'uniqueLabel';
const SERV_GEN_RESPONSE_DISPLAY_NAME = 'displayLabel';
const INPUT_ID_ATT_NAME = 'id';
const INPUT_ID_RECORD_FIELD = 'id';
const INPUT_FIELD_NAME = 'fieldName';
const INPUT_TREEPATH_ATT_NAME = 'treePath';
const INPUT_NAME_ATT_NAME = 'name';
const EVENT_NON_PAIRED_CONDITION_ATTRIBUTE_LABEL = {
  BULK_NAME : 'Attribute*',
  SINGLE_NAME :'Attribute Path*',
};

export default class ConditionComponent extends React.Component{

  constructor(props){
    super(props);
    const stateTemp = { typesOptions: [],
                      operationsOptions: [],
                      ancestorsOfNetworkElement:[],
                      attributesForBulkEvent: [],
                      selectedAttributeId: this.props.isBulkEvent ? this.props.selectedDataStructureId : this.props.selectedAttributeId? this.props.selectedAttributeId : '',
                      selectedAttributeTreePath: this.props.selectedAttributeTreePath? this.props.selectedAttributeTreePath : '',
                      selectedAttributeUnit: this.props.selectedAttributeUnit? this.props.selectedAttributeUnit : '',
                      selectedUnitScaleDetailId: this.props.selectedUnitScaleDetailId? this.props.selectedUnitScaleDetailId : '',
                      selectedOperation: this.props.selectedOperation? this.props.selectedOperation : '',
                      selectedAttributeCategory: this.props.selectedAttributeCategory? this.props.selectedAttributeCategory : '',
                      selectedFieldCategory: this.props.selectedFieldCategory? this.props.selectedFieldCategory : '',
                      selectedOperationLimit: this.props.selectedOperationLimit? this.props.selectedOperationLimit : "0",
                      validation: {},
                      selectedAppliedDataStructureLevelId: this.props.selectedAppliedDataStructureLevelId ? this.props.selectedAppliedDataStructureLevelId : '',
                      sourceFields: [],
                      selectedFieldId: this.props.selectedFieldId ? this.props.selectedFieldId : '',
                      selectedDataStructureId: this.props.selectedDataStructureId? this.props.selectedDataStructureId : '',
                    };
    stateTemp.validation = this.checkComponentValidationAndUpdate(stateTemp, true);

    stateTemp.isComponentValid = this.isConditionValid(stateTemp.validation);

    this.state = stateTemp;
    this.getTypesOptions = :: this.getTypesOptions;
    this.getOperationsOptions = :: this.getOperationsOptions;
    this.onSelectTreeAttribute = :: this.onSelectTreeAttribute;
    this.handleOperationLimitChange = :: this.handleOperationLimitChange;
    this.handleOperationLimitBlur = :: this.handleOperationLimitBlur;
    this.handleOperationChange = :: this.handleOperationChange;
    this.handleUnitChange = :: this.handleUnitChange;
    this.checkComponentValidationAndUpdate = :: this.checkComponentValidationAndUpdate;
    this.updateCondition = :: this.updateCondition;
    this.updateStateFromStore = :: this.updateStateFromStore;
    this.isConditionValid = :: this.isConditionValid;
    this.checkOperationFields = :: this.checkOperationFields;
    this.handleNetworkLevelChange = :: this.handleNetworkLevelChange;
    this.getAncestorsOfNetworkElementOptions = :: this.getAncestorsOfNetworkElementOptions;
    this.getAttributesForBulkEventOptions = :: this.getAttributesForBulkEventOptions;
    this.handleAttributeForBulkChanged = :: this.handleAttributeForBulkChanged;
    this.handleRecordFieldSelectionChange = :: this.handleRecordFieldSelectionChange;
    this.setStateAndUpdateCondition = :: this.setStateAndUpdateCondition;
    this.getAttributeComponentForAttributeEventSource = :: this.getAttributeComponentForAttributeEventSource;
    this.getFieldComponentForRecordEventSource = :: this.getFieldComponentForRecordEventSource;
    this.getScaleDetailsAndSetState = :: this.getScaleDetailsAndSetState;
    this.updateSelection = :: this.updateSelection;
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.isBulkEvent && nextProps.updateAncestorNetworkELements){
      this.props.conditionActions.
      listAncestorsOfNetworkElementType(this.props.selectedNetworkElementId);
    }
    
    if(this.state.scaleDetails &&
         this.state.scaleDetails.length > 0 && nextProps.selectedAttributeUnit == DataModelTypeUtils.DEFAULT_PERCENT_UNIT){
      this.setState({
        selectedUnitScaleDetailId: this.getEmptyScaleDetailsUnitId(this.state.scaleDetails),
      });
    } 
    
    if(nextProps.showValidation){
      let validation = fromJS(this.state.validation).toJS();

      validation = this.checkComponentValidationAndUpdate(this.state);
      const isComponentValid = this.isConditionValid(validation);

      this.setState({
        validation: validation,
        isComponentValid: isComponentValid,
      },
      () => {this.getOperationsOptions()});
    }
  }

  componentDidMount(){
    const conditionStore = this.props.conditionStore;
    conditionStore.addChangeListener(this.updateStateFromStore);

    if(this.props.isEventSourceRecord){
      this.props.conditionActions.listFieldsOfRecordType(this.props.selectedRecordTypeId);
    }

    const validateAttributesForBulk = this.props.isBulkEvent ? (this.state.selectedAppliedDataStructureLevelId &&
      conditionStore.getAttributesOfNetworkElementType(
      this.state.selectedAppliedDataStructureLevelId).length > 0) : true;

    if(conditionStore.getConditionTypes().length > 0 &&
        Object.keys(conditionStore.getConditionOperations()).length > 0 &&
        validateAttributesForBulk){
      this.updateStateFromStore();
    }else{
      this.props.conditionActions.listConditionTypes();
      this.props.conditionActions.listConditionOperations();
      if(this.props.isBulkEvent && this.state.selectedAppliedDataStructureLevelId){
        this.props.conditionActions.listAttributesOfNetworkElementType(
          this.state.selectedAppliedDataStructureLevelId);
      }
    }

    const scaleDetailsOwnerId = this.props.isEventSourceRecord ? this.props.selectedFieldId 
    : this.props.selectedDataStructureId;
    const selectedCategory = this.props.isEventSourceRecord ? this.state.selectedFieldCategory 
    : this.state.selectedAttributeCategory;

    if(scaleDetailsOwnerId && this.isNumericField(selectedCategory)){
      this.getScaleDetailsAndSetState(scaleDetailsOwnerId, null, false, false);
    }
  }

  componentWillUnmount() {
    this.props.conditionStore.removeChangeListener(this.updateStateFromStore);
  }

  isNumericField(selectedCategory){
    return selectedCategory != Types.FieldTypes.NonNumeric;
  }

  updateStateFromStore(){
    const attributesForBulkEvent = this.props.conditionStore.getAttributesOfNetworkElementType(
      this.state.selectedAppliedDataStructureLevelId);
    const attributesDataStructures = attributesForBulkEvent ?
      attributesForBulkEvent.filter(
        attribute => attribute.id == this.state.selectedAttributeId)[0]
      : null;

    this.updateCondition({...this.state, attributesDataStructures});

    this.setState({
      typesOptions: this.props.conditionStore.getConditionTypes(),
      operationsOptions: this.props.conditionStore.getConditionOperations(),
      sourceFields:  this.props.conditionStore.getFieldsOfRecordType(),
      ancestorsOfNetworkElement:this.props.conditionStore.getAncestorsOfNetworkElementType(),
      attributesForBulkEvent,
      attributesDataStructures,
    });
  }

  isConditionValid(validation){
    const isOperationValid = ValidationUtils.isValid(validation.operation);
    if(this.props.isEventSourceRecord){
      return ValidationUtils.isValid(validation.selectedFieldId) && isOperationValid;
    }
    else{
      return  ValidationUtils.isValid(validation.attributePath)
      && isOperationValid && (this.props.isBulkEvent ?
        ValidationUtils.isValid(validation.networkElementLevel)
        && ValidationUtils.isValid(validation.attributeNameForBulk) : true);
    }
  }

  checkOperationFields(stateTemp, hideMessage){
    let fieldCategory = stateTemp.selectedFieldCategory == Types.FieldTypes.Time ?
    Types.FieldTypes.Numeric : stateTemp.selectedFieldCategory;

    fieldCategory = this.props.selectedAttributeUnit == DataModelTypeUtils.DEFAULT_PERCENT_UNIT ?
      DataModelTypeUtils.PERCENT_DATA_TYPE : fieldCategory;
    const operationFields = {
      operation: stateTemp.selectedOperation,
      limit: stateTemp.selectedOperationLimit,
      unitScaleId: stateTemp.selectedUnitScaleDetailId,
      fieldCategory,
    };
    const operationValidation = validationFunction('operationFields', operationFields);

    if(hideMessage){
      operationValidation.label = '';
    }

    return operationValidation;
  }


  checkComponentValidationAndUpdate(stateTemp, hideMessage = false){
    const validation = stateTemp.validation;

    validation.attributePath = hideMessage ? {} : validationFunction('attributePath', stateTemp.selectedAttributeTreePath);
    validation.operation = this.checkOperationFields(stateTemp, hideMessage);
    if(this.props.isBulkEvent){
      validation.networkElementLevel = hideMessage ? {} 
        : validationFunction('networkElementLevel', stateTemp.selectedAppliedDataStructureLevelId);
      validation.attributeNameForBulk = hideMessage ? {} : validationFunction('attributeId', stateTemp.selectedAttributeId);
    }
    if(this.props.isEventSourceRecord){
      validation.selectedFieldId = hideMessage ? {} : validationFunction('fieldId', stateTemp.selectedFieldId);
    }
    return validation;
  }


  getTypesOptions(){
    return this.wrapArrayInToSelectOptions(this.state.typesOptions,
                                           SERV_GEN_RESPONSE_UNIQUE_NAME,
                                           SERV_GEN_RESPONSE_UNIQUE_NAME,
                                           SERV_GEN_RESPONSE_DISPLAY_NAME);
  }

  getOperationsOptions(){
    let operationsOptions = [];
    if(this.props.isEventSourceRecord){
      operationsOptions = this.state.operationsOptions[this.state.selectedFieldCategory]?
      this.state.operationsOptions[this.state.selectedFieldCategory] : [];
    }else{
      operationsOptions = this.state.operationsOptions[this.state.selectedAttributeCategory]?
      this.state.operationsOptions[this.state.selectedAttributeCategory] : [];
    }

    return this.wrapArrayInToSelectOptions(operationsOptions,
                                           SERV_GEN_RESPONSE_UNIQUE_NAME,
                                           SERV_GEN_RESPONSE_UNIQUE_NAME,
                                           SERV_GEN_RESPONSE_DISPLAY_NAME);
  }

  getAncestorsOfNetworkElementOptions(){
    return this.wrapArrayInToSelectOptions(this.state.ancestorsOfNetworkElement,
                                          INPUT_ID_ATT_NAME,
                                          INPUT_ID_ATT_NAME,
                                          INPUT_TREEPATH_ATT_NAME);
  }
  getAllFieldsInRecordOptions(){
    let listOfFields = [];
    if(this.state.sourceFields){
      listOfFields = this.state.sourceFields;
    }
    return this.wrapArrayInToSelectOptions(listOfFields,
                                          INPUT_ID_RECORD_FIELD,
                                          INPUT_ID_RECORD_FIELD,
                                          INPUT_FIELD_NAME);
  }

  getAttributesForBulkEventOptions(){
    return UIConstructionUtils.getCategorizedAttributesOptions(this.state.attributesForBulkEvent,
      INPUT_ID_ATT_NAME,
      INPUT_ID_ATT_NAME,
      INPUT_NAME_ATT_NAME);
  }

  wrapArrayInToSelectOptions(options, keyAttName, valueAttName, displayAttName){
    return options.map(option => {
      return (
        <option key={(option[keyAttName] == undefined)? option : option[keyAttName]}
                value={(option[valueAttName] == undefined)? option : option[valueAttName]}>
          {(option[displayAttName] == undefined)? option : option[displayAttName]}
        </option>
      );
    });
  }

  getConditionAttributes(state){
    const condition = {};

    condition.appliedNetworkElementId = state.selectedAttributeId;
    condition.appliedDataStructureId = state.selectedDataStructureId;
    condition.operation = state.selectedOperation;
    condition.attributeTreePath = state.selectedAttributeTreePath;
    condition.value = state.selectedOperationLimit;
    condition.scaleDetailsId = Number(state.selectedUnitScaleDetailId);
    condition.unit = state.selectedAttributeUnit;
    condition.isConditionValid = state.isComponentValid;
    condition.order = 0;
    if(this.props.isBulkEvent){
      condition.appliedDataStructureLevelId
        = state.selectedAppliedDataStructureLevelId;
      condition.appliedNetworkElementId = null;
      condition.appliedDataStructureId = state.selectedAttributeId;
      condition.attributesDataStructures = state.attributesDataStructures;
    }
    if(this.props.isEventSourceRecord){
      condition.appliedFieldId = Number(state.selectedFieldId);
      condition.category = state.selectedFieldCategory;
    }
    else{
      condition.category = state.selectedAttributeCategory;
    }

    return condition;
  }

  updateCondition(stateTemp){
    stateTemp.isComponentValid = this.isConditionValid(stateTemp.validation);
    this.props.onChange(this.getConditionAttributes(stateTemp), this.props.conditionIndex);
  }

  getEmptyScaleDetailsUnitId = (sortedScaleDetails) => {
    return sortedScaleDetails.filter(scaleDetail => {
      return scaleDetail.unit == '';
    })[0].id;
  }

  updateSelection(selection){
    this.props.conditionsAPI.getAttributeType(selection.selectedNodeId, selection.selectedDataStructureId).then(res => {
      if(res.category){
        this.onSelectTreeAttribute(selection.selectedNodeId, selection.selectedDataStructureId, res.category, 
          res.treePath, res.unit);
      }
    });
  }

  onSelectTreeAttribute(networkElementId, dataStructureId, attributeCategory, attributeTreePath, attributeUnit){
    let stateTemp = fromJS(this.state).toJS();
    stateTemp.selectedAttributeId = networkElementId;
    stateTemp.selectedDataStructureId = dataStructureId;
    stateTemp.selectedAttributeCategory = attributeCategory;
    stateTemp.selectedFieldCategory = attributeCategory;
    stateTemp.selectedAttributeTreePath = attributeTreePath;
    stateTemp.selectedAttributeUnit = attributeUnit;
    stateTemp.validation.attributePath = validationFunction('attributePath', stateTemp.selectedAttributeTreePath);
    stateTemp.selectedOperation = '';
    stateTemp.selectedOperationLimit = "0";
    stateTemp.selectedUnitScaleDetailId = '';
    stateTemp.validation.operation = this.checkOperationFields(stateTemp, true);
    if(dataStructureId && this.isNumericField(attributeCategory)){
      this.getScaleDetailsAndSetState(dataStructureId, stateTemp, true, true);
    } else {
      this.setStateAndUpdateCondition(stateTemp, true);
    }
  }


  toggleTreeModal = (showTreeModal) => () => {
    this.setState({
      showTreeModal,
    });
  }

  canClickNode(node){
    return node.isLeaf;
  }

  handleNetworkLevelChange(e){
    let stateTemp = fromJS(this.state).toJS();
    stateTemp = {
      ...stateTemp,
      selectedAppliedDataStructureLevelId: e.target.value,
                      selectedAttributeId: '',
                      selectedAttributeTreePath: '',
                      selectedAttributeUnit: '',
                      selectedAttributeCategory: '',
                      selectedOperation: '',
                      selectedOperationLimit: "0",
                      selectedUnitScaleDetailId: '',
                      validation: {},
                    };

    stateTemp.attributesForBulkEvent = this.props.conditionStore.
      getAttributesOfNetworkElementType(e.target.value);
    stateTemp.validation = this.checkComponentValidationAndUpdate(stateTemp, true);
    stateTemp.isComponentValid = this.isConditionValid(stateTemp.validation);
    this.setState(stateTemp,
      () => {
        if(stateTemp.attributesForBulkEvent.length === 0){
          this.props.conditionActions.listAttributesOfNetworkElementType(
            this.state.selectedAppliedDataStructureLevelId);
        }
        this.updateCondition(this.state);
      });
  }

  handleOperationLimitBlur(e){
    const stateTemp = fromJS(this.state).toJS();

    stateTemp.selectedOperationLimit = e.target.value;
    stateTemp.validation.operation = this.checkOperationFields(stateTemp, false);
    this.setStateAndUpdateCondition(stateTemp);
  }

  handleOperationLimitChange(e){
    this.setState({ selectedOperationLimit: e.target.value });
  }

  handleOperationChange(e){
    const stateTemp = fromJS(this.state).toJS();
    if(this.props.selectedAttributeUnit != DataModelTypeUtils.DEFAULT_PERCENT_UNIT) {
      stateTemp.selectedUnitScaleDetailId = '';
    }
    stateTemp.selectedOperation = e.target.value;
    stateTemp.validation.operation = this.checkOperationFields(stateTemp, false);
    this.setStateAndUpdateCondition(stateTemp);
  }

  handleUnitChange(e){
    const stateTemp = fromJS(this.state).toJS();
    
    stateTemp.selectedUnitScaleDetailId = e.target.value;
    stateTemp.validation.operation = this.checkOperationFields(stateTemp, false);
    this.setStateAndUpdateCondition(stateTemp);
  }

  setStateAndUpdateCondition(stateTemp, hideTreeNode){
    this.setState(stateTemp, () => {
      if(hideTreeNode){
        this.toggleTreeModal(false)();
      }
      this.updateCondition(this.state);
    });
  }

  isTimeRecordField(selectedField){
    return selectedField.attributeType && selectedField.attributeType.dataType == Types.FieldTypes.Time;
  }

  handleRecordFieldSelectionChange(e){
    let stateTemp = fromJS(this.state).toJS();

    const selectedField = stateTemp.sourceFields.filter(
      sourceField => sourceField.id == e.target.value)[0];
     stateTemp = {
      ...stateTemp,
      selectedFieldId: e.target.value,
      selectedField:selectedField,
      selectedFieldCategory : this.isTimeRecordField(selectedField) ? selectedField.attributeType.dataType : selectedField.type,
      selectedAttributeUnit: selectedField.unit || '',
      selectedOperation: '',
      selectedOperationLimit: "0",
      selectedUnitScaleDetailId: '',
      validation: {}
    }
    stateTemp.validation = this.checkComponentValidationAndUpdate(stateTemp, true);
    stateTemp.isComponentValid = this.isConditionValid(stateTemp.validation);

    if(selectedField.id && this.isNumericField(selectedField.type)){
      this.getScaleDetailsAndSetState(selectedField.id, stateTemp, false, true);
    } else {
      this.setStateAndUpdateCondition(stateTemp);
    }
  }

  handleAttributeForBulkChanged(e){
    const selectedNetworkElementLevelName = this.state.ancestorsOfNetworkElement.
      filter(networkElement =>
            networkElement.id == this.state.selectedAppliedDataStructureLevelId)[0].treePath;
    const selectedAttribute = this.state.attributesForBulkEvent.filter(
      attribute => attribute.id == e.target.value)[0];
    const selectedAttributeName = selectedAttribute.name;

    const selectedAttributeTreePath = selectedNetworkElementLevelName
      + ' > ' + selectedAttributeName;

    this.props.conditionActions.getAttributeTypeByDataStructureId(
      e.target.value).then(res => {
        if(res.category){
          let stateTemp = fromJS(this.state).toJS();

          stateTemp = {
            ...stateTemp,
            selectedAttributeId: e.target.value,
            selectedAttributeTreePath,
            selectedAttributeUnit: res.unit,
            selectedAttributeCategory: res.category,
            selectedFieldCategory: res.category,
            selectedOperation: '',
            selectedOperationLimit: "0",
            selectedUnitScaleDetailId: '',
            validation: {},
          };
          const dataStructureId = e.target.value;
          const attributeCategory = stateTemp.selectedAttributeCategory;
          if(dataStructureId && this.isNumericField(attributeCategory)){
            this.getScaleDetailsAndSetState(dataStructureId, stateTemp, false, true);
          }

          stateTemp.validation = this.checkComponentValidationAndUpdate(
              stateTemp, true);
          stateTemp.isComponentValid = this.isConditionValid(
            stateTemp.validation);
          stateTemp.attributesDataStructures = selectedAttribute;
          
          this.setStateAndUpdateCondition(stateTemp);
        }
      });
  }

  getFieldComponentForRecordEventSource(){
    return(
      <FormGroup>
        <Col sm={3} xs={12} className="respCondGrp">
          <label>Field*</label>
        </Col>
        <Col className="respCondGrp" sm={9} xs={12}>
          <MultipleSelect placeholder='Select Record Field'
                          className='recordFields-menu'
                          disabled={this.props.disabled}
                          options={this.getAllFieldsInRecordOptions()}
                          value={this.state.selectedFieldId}
                          onChange={this.handleRecordFieldSelectionChange}/>
          <ValidationOutput validation ={this.state.validation.selectedFieldId}/>
        </Col>
      </FormGroup>
    );
  }

  getScaleDetailsAndSetState(fieldId, state, hideTreeNode, updateCondition) {
    this.props.scaleActions.getAttributeOrFieldScaleDetails(fieldId, this.props.isEventSourceRecord).then(res => {
      if(updateCondition) {
        state.scaleDetails = res;
        this.setStateAndUpdateCondition(state, hideTreeNode); 
      } else {
        this.setState({scaleDetails:res});
      }
    });
  }

  getAttributeComponentForAttributeEventSource(){
    return (
      <Row className="margBtm15" >
        <Col sm={3} xs={12} className="respCondGrp">
          <label>{this.props.isBulkEvent ?
            EVENT_NON_PAIRED_CONDITION_ATTRIBUTE_LABEL.BULK_NAME
            : EVENT_NON_PAIRED_CONDITION_ATTRIBUTE_LABEL.SINGLE_NAME}</label>
        </Col>
        {this.props.isBulkEvent ?
          <Col className="respCondGrp" sm={9} xs={12}>
            <MultipleSelect placeholder='Select Attribute'
                            options={this.getAttributesForBulkEventOptions()}
                            value={this.state.selectedAttributeId}
                            disabled={this.props.disabled}
                            onChange={this.handleAttributeForBulkChanged}
                            grouped/>
                       <ValidationOutput validation={this.state.validation.attributeNameForBulk}/>
          </Col>
          :
          <Col className="respCondGrp" sm={9} xs={12}>
            <CommonTreeInputWithModel
                entityOptions={this.props.eventConditionEntityOptions}
                getTreeNodes={this.props.networkTreeActions.getNodes}
                canClickNode={this.canClickNode}
                updateSelection={this.updateSelection}
                toggleTreeModal={this.toggleTreeModal}
                showTreeModal={this.state.showTreeModal}
                treeNodeId={this.state.treeNodeId}
                treeNodePath={this.state.selectedAttributeTreePath}
                validation={this.state.validation && this.state.validation.attributePath}
                title='Select Attribute'   
                disabled={this.props.disabled}
            />
          </Col>

        }
      </Row>
    );
  }

  getComponentAccordingToSourceTypeSelection(isEventSourceRecord){
    if(isEventSourceRecord){
      return this.getFieldComponentForRecordEventSource();
    }else{
      return this.getAttributeComponentForAttributeEventSource();
    }
  }

  render(){
    const operationLimitInput 
    = (this.state.selectedAttributeCategory === Types.FieldTypes.Numeric) 
      || (this.state.selectedAttributeCategory === Types.FieldTypes.Time)
      || (this.state.selectedFieldCategory === Types.FieldTypes.Numeric)
      || (this.state.selectedFieldCategory === Types.FieldTypes.Time) ?
      <NumericInput labelSize={0}
                    inputSize={12}
                    size={12}
                    className='operationValue-input'
                    disabled={this.props.disabled}
                    acceptNegativeValues={true}
                    value={this.state.selectedOperationLimit}
                    defaultValue={0}
                    onChange={this.handleOperationLimitChange}
                    onBlur={this.handleOperationLimitBlur}/> :
      <Col xs={12}>
        <FormControl type="text"
                    className='operationValue-input'
                    disabled={this.props.disabled}
                     value={this.state.selectedOperationLimit}
                     onChange={this.handleOperationLimitChange}
                     onBlur={this.handleOperationLimitBlur}/>
      </Col>;

    const selectedCategory = this.props.isEventSourceRecord ? 
      this.state.selectedFieldCategory : this.state.selectedAttributeCategory;
    const baseUnit = selectedCategory == Types.FieldTypes.Time ? '' : this.props.selectedAttributeUnit;

    return (
      <div className='form-horizontal'>
        {/* Network Element level selection */}
        {this.props.isBulkEvent ?
          <FormGroup>
            <Col className="respCondGrp padRight0" sm={3} xs={12}>
              <label>Network Element*</label>
            </Col>
            <Col className="respCondGrp" sm={9} xs={12}>
              <MultipleSelect placeholder='Select Network Element'
                              className='NE-menu'
                              options={this.getAncestorsOfNetworkElementOptions()}
                              value={this.state.selectedAppliedDataStructureLevelId}
                              onChange={this.handleNetworkLevelChange}
                              disabled={this.props.disabled || this.props.isBulkEvent && this.props.isPairedEvent}/>
                            <ValidationOutput validation={this.state.validation.networkElementLevel}/>
            </Col>
          </FormGroup> : null
        }
        {/* Attribute/Record field Selection */}
        {this.getComponentAccordingToSourceTypeSelection(this.props.isEventSourceRecord)}
        {/* Operation Selection */}
        <FormGroup>
          <Col sm={3} xs={12}>
            <label>Operation*</label>
          </Col>
          <Col className="respCondGrp" sm={9} xs={12}>
            <Row>
              <Col md={4} sm={3} xs={4}>
                <MultipleSelect placeholder='Select Operation'
                                className='operation-menu'
                                options={this.getOperationsOptions()}
                                value={this.state.selectedOperation}
                                disabled={this.props.disabled}
                                onChange={this.handleOperationChange}/>
              </Col>
              <Col md={4} sm={5} xs={4} className="paddSides0">
                {operationLimitInput}
              </Col>
              <Col sm={3} xs={4}>
                <UnitConversionDropdown
                  key={baseUnit}
                  scaleDetails={this.state.scaleDetails}
                  unit={this.state.selectedUnitScaleDetailId}
                  baseUnit= {baseUnit}
                  disabled={this.props.disabled || !this.isNumericField(selectedCategory) || (this.state.scaleDetails || []).length < 1}
                  onUnitChange={this.handleUnitChange}
                  hideLabel
                />
              </Col>
            </Row>
            <ValidationOutput validation={this.state.validation.operation}/>
          </Col>
        </FormGroup>
      </div>
    );
  }
}