/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BsDateRangePicker from 'react-bootstrap-daterangepicker';
import {DateTimeUtils, RelativeDateConverter} from 'js-utils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as Icons from 'templates/Icons';
import 'styles/inputs/simple/datePicker.less';
import ConditionalRendering from '../../containers/ConditionalRendering';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export default class DatePicker extends Component {

  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.onDateRangeChange = ::this.onDateRangeChange;
    this.getDateFormatted = ::this.getDateFormatted;
    this.onClearDate = ::this.onClearDate;
    this.getStartReferenceDate = ::this.getStartReferenceDate;
    this.state = {
      cleared: !props.date,
      picker: {
        startDate: props.date ? 
        DateTimeUtils.epochToMoment(props.date) 
        : DateTimeUtils.getCurrentDateTimeinMoment(),
      },
      relativeDate: props.relativeDate,
    };
  }

  componentWillReceiveProps(nextProps){
    const date = DateTimeUtils.dateToEpoch(this.state.picker.startDate);

    if(nextProps.date !== date || nextProps.relativeDate !== this.state.relativeDate){
      this.setState({
        picker: {
          startDate: nextProps.date ? 
          DateTimeUtils.epochToMoment(nextProps.date) 
          : DateTimeUtils.getCurrentDateTimeinMoment(),
        },
        relativeDate: nextProps.relativeDate,
      });
    }
  }

  getDateFormatted(showLabels){
    let start = '';
    const date = DateTimeUtils.dateToEpoch(this.state.picker.startDate);

    if(this.state.cleared){
      start = this.props.placeholder || '';
    }else{      
      if(showLabels && this.state.relativeDate){
        start = RelativeDateConverter.toRelativeTimeString(this.state.relativeDate);
      }else{
        start = DateTimeUtils.epochToCustomDateFormat(null, date);
      }
    }

    return `${start}`;
  }

  onDateRangeChange(event, picker) {
    this.setState({
      cleared: false,      
      picker: {startDate: picker.startDate}, 
      relativeDate: picker.relativeStart,
    }, () => {
      if(this.props.onChange){
        this.props.onChange({
          relativeDate: this.state.relativeDate,
          date: DateTimeUtils.dateToEpoch(this.state.picker.startDate),
        });
      }
    });
  }

  onClearDate(){
    this.setState({
      cleared: true,
      picker: {startDate: DateTimeUtils.getCurrentDateTimeinMoment()}, 
      relativeDate: null,
    }, () => {
      this.props.onChange({
        date: null,
        relativeDate: null,
      });
    });
  }

  getStartReferenceDate(){
    return DateTimeUtils.getEpochFromEpochString(this.props.startReferenceDate);
  }

  render() {
    const componentClassName = this.props.containerClassName ? this.props.containerClassName : '';
    const dateFormatted = this.getDateFormatted(true);
    const tooltipTrigger = (this.state.relativeDate) ? ['hover', 'focus'] :  [];

    return (
      <div onClick={UIConstructionUtils.stopPropagation} className={`datePickerWrapper ev-auto-datePickerWrapper ${componentClassName}`}>
        <OverlayTrigger
          rootClose placement={this.props.hoverDirection || "left"} trigger={tooltipTrigger}
          overlay={
            <Tooltip placement={this.props.hoverDirection || "left"} className="in" >
              {this.getDateFormatted()}
            </Tooltip>
          }
        >
          <div>
            <BsDateRangePicker
              {...this.props}
              locale={{
                format: DateTimeUtils.DATE_PICKER_INPUT_DATETIME_FORMAT,
                relativeDateConverter: RelativeDateConverter,
                relativeStart: this.state.relativeDate,
                relativeInputSingleDatePicker: this.props.relativeInputSingleDatePicker,
                startReferenceDate: this.getStartReferenceDate,
                rootClassName: this.props.rootClassName, // Not used anywhere so far
              }}
              onApply={this.onDateRangeChange}
              singleDatePicker
              showDropdowns
              autoUpdateInput={false}
              autoApply
              startDate={this.state.picker.startDate}
              endDate={this.state.picker.startDate}
            >
              <div className='ev-auto-timeLabelWrapper'>
                {UIConstructionUtils.getIcon(Icons.calendar, 'calendarIconWrapper')}
                <ConditionalRendering if={!!dateFormatted}>
                  <span className="dateText ev-auto-dateText">
                    {dateFormatted}
                  </span>
                </ConditionalRendering>
                <ConditionalRendering if={!this.props.noCaret}>
                  <i className="caret caretIcon"></i>
                </ConditionalRendering>
              </div>
            </BsDateRangePicker>
          </div>
        </OverlayTrigger>
        <ConditionalRendering if={this.props.date}>
          <ConditionalRendering if={!this.props.hideClear}>
            <span onClick={this.onClearDate}  className="clearIconWrapper ev-auto-clearIconWrapper">
              {UIConstructionUtils.getIcon(Icons.ban)}
            </span>
          </ConditionalRendering>
        </ConditionalRendering>
      </div>
    );
  }
}
