/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import 'web-style-guide/css/drilldown.less';
import Reactable from 'dataVisualization/Table/Reactable';
import Loader from 'templates/Loader';
import Modal from 'containers/Modal';

const TopologyDetailsPopup = (props) => {
  const detailsContent = props.drillDownWidgetParameterStatus ? <div className="no-data">
    <p className="widget-image-event">
      {
        <React.Fragment><i className={props.icon}> </i> {props.drillDownWidgetParameterStatus} </React.Fragment>
      }
    </p>
  </div> : <Reactable
    idx='Link'
    tableData={props.data}
    columns={props.columns}
    rowKey={props.rowKey}
    paginated
  />;

  return (
    <Modal bsSize="large"
      onHide={props.onHide}
      title={props.title}
      show={props.show}
      noFooter
      backdrop='static'
      backdropClassName='backdropTransparent'
      className='topologyPopup'>
      {
        props.data ?
          detailsContent
          : <Loader />
      }
    </Modal>
  );
};

export default TopologyDetailsPopup;