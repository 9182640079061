/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import LC from './literallycanvas-core.js';
import DownloadButton from 'buttons/DownloadButton';
import TooltippedButton from 'buttons/TooltippedButton';
import ColorPicker from 'inputs/simple/ColorPicker';
import Icons from 'templates/Icons';

const ELLIPSE = 'ELLIPSE';
const RECTANGLE = 'RECTANGLE';
const TEXT = 'TEXT';

class AnnotationDrawingArea extends Component{
  componentDidMount(){
    this.lc = LC.init(this.rootDiv, {
      secondaryColor: 'transparent',
    });
    this.tools = {
      [ELLIPSE]: new LC.tools.Ellipse(this.lc),
      [RECTANGLE]: new LC.tools.Rectangle(this.lc),
      [TEXT]: new LC.tools.Text(this.lc),
    };
    const onDrawingChange = () => this.props.onDrawingChange(this.lc.canUndo(),
                                                             this.lc.canRedo());

    this.onDrawingChangeSub = this.lc.on('drawingChange', onDrawingChange);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.currentTool){
      this.lc.setTool(this.tools[nextProps.currentTool]);
    }
    if(nextProps.color){
      this.lc.setColor('primary', nextProps.color);
    }
  }

  shouldComponentUpdate = () => false;

  componentWillUnmount(){
    this.lc.teardown();
    this.onDrawingChangeSub();
  }

  undo = () => this.lc.undo();
  redo = () => this.lc.redo();
  getCanvas = () => this.rootDiv.querySelectorAll('canvas')[1];

  finalizeText(){
    const clickEvent = document.createEvent('MouseEvent');
    const canvas = this.rootDiv.querySelector('canvas');

    clickEvent.initEvent('mousedown', true, true);
    canvas.dispatchEvent(clickEvent);
    canvas.dispatchEvent(clickEvent);
  }

  render = () => <div className="literally" ref={ ref => this.rootDiv = ref }/>;
}

const SVG_PADDING = 0;

class AnnotatableContainer extends Component {
  constructor(props){
    super(props);
    this.overflow = null;
  }

  undo = () => this.drawingArea.undo();
  redo = () => this.drawingArea.redo();
  getCanvas = () => this.drawingArea.getCanvas();
  finalizeText = () => this.drawingArea.finalizeText();

  render = () =>
    <div className="annotatable-container" ref={ ref => this.container = ref } style={{width:'100%', height: '100%'}}>
      {
        this.props.initialized ?
      <React.Fragment><div className="annotation-drawing-area-wrapper"
           style={{
             pointerEvents: this.props.currentTool ? 'all' : 'none',
             width: '100%',
             height: '100%',
             zIndex: '100',
           }}>
        <AnnotationDrawingArea currentTool={this.props.currentTool}
                               color={this.props.color}
                               onDrawingChange={this.props.onDrawingChange}
                               ref={ ref => {this.drawingArea = ref;} }/> 
      </div>
      </React.Fragment> : null
      }
      {this.props.children}
    </div>;
}

const NewAnnotationTools = props =>
  <div className='annotation-toolbar'>
       <TooltippedButton  className="btn-action" tooltip='Circle'
                onClick = {props.onToolSelect(ELLIPSE)} >
         <i className={Icons.circle}/>
       </TooltippedButton>
       <TooltippedButton  className="btn-action" tooltip='Rectangle'
         onClick={props.onToolSelect(RECTANGLE)} >
         <i className={Icons.square}/>
       </TooltippedButton>
       <TooltippedButton className="btn-action" tooltip='Text'
               onClick={props.onToolSelect(TEXT)} >
          <i className={Icons.font}/>
       </TooltippedButton>
       <ColorPicker
       openleft tooltiped
        color={props.color}
        colors={props.colors.slice(0, 30)}
        onChange={(color) => props.onColorSelect(color)}/>
       <TooltippedButton   className="btn-action" tooltip='Undo'
         disabled={!props.canUndo} onClick={() => props.onUndo()}>
          <i className={Icons.undoReset}/>
       </TooltippedButton>
       <TooltippedButton  className="btn-action" tooltip='Redo'
         disabled={!props.canRedo} onClick={() => props.onRedo()}>
          <i className={Icons.refresh}/>
       </TooltippedButton>
       <TooltippedButton className="btn-action" tooltip='Send Email'
         onClick={props.onEmailClick}>
          <i className={Icons.sendEmail}/>
       </TooltippedButton>
       <DownloadButton className="btn-action" value='annotation' tooltiped='Save image'
               placement='top'
               onDownload={props.onSaveClick}>
          <i className={Icons.download}/>
       </DownloadButton>
       <TooltippedButton className="btn-action" tooltip='Exit Annotation'
         onClick={props.onExitAnnotation}>
          <i className={Icons.ban}/>
       </TooltippedButton>
  </div>;

export default class AnnotationModal extends Component{
  constructor(props){
    super(props);
    this.onSaveClick = ::this.onSaveClick;
    this.onEmailClick = ::this.onEmailClick;
    this.size = 'lg';
  }

  state = {color: 'black'};

  onDrawingChange = (canUndo, canRedo) =>
    this.setState({
      currentTool: undefined,
      canUndo,
      canRedo,
    });

  activateTool = currentTool => () => this.setState({currentTool});
  setColor = color => this.setState({color});

  undo = () => this.annotatableContainer.undo();
  redo = () => this.annotatableContainer.redo();

  onSaveClick(name){
    if(this.state.currentTool == TEXT){
      this.annotatableContainer.finalizeText();
    }
    this.props.onSaveClick(this.annotatableContainer.getCanvas(), name);
  }

  onEmailClick(){
    if(this.state.currentTool == TEXT){
      this.annotatableContainer.finalizeText();
    }
    this.props.onEmailClick(this.annotatableContainer.getCanvas());
  }
  render (){
    const result = <React.Fragment>
            {this.props.initialized ?            
            <NewAnnotationTools  onToolSelect={this.activateTool}
                              onColorSelect={this.setColor}
                              color={this.state.color}
                              canUndo={this.state.canUndo}
                              canRedo={this.state.canRedo}
                              onUndo={this.undo}
                              onEmailClick={this.onEmailClick}
                              onExitAnnotation={this.props.onExitAnnotation}
                              onSaveClick={this.onSaveClick}
                              colors={this.props.colors}
                              onRedo={this.redo}/>
          : null}
            <AnnotatableContainer initialized={this.props.initialized}
                                  currentTool={this.state.currentTool}
                                  color={this.state.color}
                                  padding={this.props.padding}
                                  onDrawingChange={this.onDrawingChange}
                                  height={this.props.height}
                                  width={this.props.width}
                                  ref={ ref =>
                                    this.annotatableContainer = ref
                                  }>
              {this.props.children}
            </AnnotatableContainer>
          </React.Fragment>;

    return result;
  }
}
