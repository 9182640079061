/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';

const BootboxMessage = (props) => {
  return(
    <React.Fragment>
      <p>
          {props.title} 
      </p>
     {props.body}
    </React.Fragment>
  );
};

export default BootboxMessage;