/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component, Fragment } from 'react';
import {fromJS} from 'immutable';
import {OverlayTrigger, Popover, Button, ButtonGroup} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import FilterInputRow from './FilterInputRow';
import {CompareUtils, DataModelTypeUtils, FieldTypes} from 'js-utils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import {getInitializedQuickFilter, getDataSelectionValidationState,
  validate, getPostAggregationFilterAggregatesList} from 'utils/DataSelectionCommonUtils';
import WidgetQuickAppliedFilters from './WidgetQuickAppliedFilters';
import * as defaults from 'utils/defaults';
import * as FFAGDefaults from 'utils/ffagDefaults';
import Icons from 'templates/Icons';
import * as filterUtils from 'utils/filterUtils';

export default class WidgetActionbarFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesFilterOptions: undefined,
      inProgressFilter: getInitializedQuickFilter(),
      disableSaveButton: true,
      FilterValidation: getDataSelectionValidationState().filterInput,
      quickFilterType: FFAGDefaults.LABEL_OPTIONS[0],
    };
    this.getInProgressFilter = ::this.getInProgressFilter;
    this.checkValidation = ::this.checkValidation;
    this.onChangeAttribute = ::this.onChangeAttribute;
    this.onChangeOperation = ::this.onChangeOperation;
    this.onChangeValue = ::this.onChangeValue;
    this.updateFilterAndValidate = ::this.updateFilterAndValidate;
    this.addQuickFilter = ::this.addQuickFilter;
    this.closePopover = ::this.closePopover;
    this.removeQuickFilter = ::this.removeQuickFilter;
    this.idCounter = 1;
    this.getLabelSelecionOptions = ::this.getLabelSelecionOptions;
    this.resetFilterFields = ::this.resetFilterFields;
    this.isApplyingPostAggregationFilter = ::this.isApplyingPostAggregationFilter;
  }

  addQuickFilter(){
    const inProgressFilter = fromJS(this.state.inProgressFilter).toJS();

    inProgressFilter.idCounter =  this.idCounter++;
    this.props.addQuickFilter(inProgressFilter);
    this.setState({
      inProgressFilter: getInitializedQuickFilter(),
      disableSaveButton: true,
    });
  }

  checkValidation(){
    const filters = [this.state.inProgressFilter];
    const FFAGValidationSchema = this.props.FFAGValidationSchema;
    let FilterValidation = {filterInput: fromJS(this.state.FilterValidation).toJS()};
    if(this.isApplyingPostAggregationFilter()){
      FilterValidation = this.checkValidationPostAggregationFilter(FFAGValidationSchema);
    } else{
      FilterValidation = validate({filters},
        FilterValidation, FFAGValidationSchema,
        {checkValidation: true, checkListValidation: true}, null).filterInput;
    }
    const disableSaveButton = FilterValidation.filterList[0].hasOwnProperty('label');
    
    this.setState({ FilterValidation, disableSaveButton});
  }

  checkValidationPostAggregationFilter(FFAGValidationSchema){
    const postAggregationFiltersValidations = {
      postAggregationFilters: [this.state.inProgressFilter],
      postAggregationFilterEnabled: true,
    };

    let FilterValidation = {
      postAggregationFilter: fromJS(this.state.FilterValidation).toJS(),
    };

    const validationConfigs = {
      postAggregationFilters: {
        showPostAggregationFilter: true,
      },
    };

    FilterValidation = validate(postAggregationFiltersValidations,
      FilterValidation, FFAGValidationSchema,
      {checkValidation: true, checkListValidation: true}, validationConfigs).postAggregationFilter;

    return FilterValidation;
  }

  onChangeAttribute(attribute){
    const inProgressFilter = fromJS(this.state.inProgressFilter).toJS();

    if (this.isApplyingPostAggregationFilter()) {
      if (!_.isEqual(this.state.inProgressFilter.field, attribute)) {
        inProgressFilter.field = attribute;
        this.resetFilterFields(inProgressFilter);
      }
    } else if (!_.isEqual(this.state.inProgressFilter.selectedField, attribute)) {
      inProgressFilter.selectedField = attribute;
      this.resetFilterFields(inProgressFilter);
    }
  }

  resetFilterFields(inProgressFilter, quickFilterType){
    inProgressFilter.compareValue = '';
    inProgressFilter.operation = '';
    inProgressFilter.selection = null;
    this.updateFilterAndValidate(inProgressFilter, quickFilterType);
  }

  onChangeOperation(operation){
    if(!_.isEqual(this.state.inProgressFilter.operation, operation)){ 
      const inProgressFilter = fromJS(this.state.inProgressFilter).toJS();

      inProgressFilter.operation = operation;
      this.updateFilterAndValidate(inProgressFilter);
    }
  }

  onChangeValue(value){
    const inProgressFilter = fromJS(this.state.inProgressFilter).toJS();

    inProgressFilter.compareValue = value ;
    this.updateFilterAndValidate(inProgressFilter);
  }
  
  updateFilterAndValidate(inProgressFilter, newQuickFilterType){
    const quickFilterType = newQuickFilterType ? newQuickFilterType : this.state.quickFilterType;

    this.setState({inProgressFilter, quickFilterType}, () => {
      this.checkValidation();
    });
  }

  closePopover(){
    this.overlay.hide();
    const FilterValidation = fromJS(this.state.FilterValidation).toJS();

    FilterValidation.filterList[0] = {};
    this.setState({inProgressFilter: getInitializedQuickFilter(),
      FilterValidation});
  }

  isApplyingPostAggregationFilter(){
    return this.props.showPostAggregationFilter 
     && this.state.quickFilterType === FFAGDefaults.LABEL_OPTIONS[1];
  }

  getInProgressFilter(){
    let filterOptions = [];
    const operations = UIConstructionUtils.wrapArrayInToSelectOptions(CompareUtils.COMPARATORS,
      'value', 'value', 'name');
    const isApplyingPostAggregationFilter = this.isApplyingPostAggregationFilter();

    if (this.props.sourceFields && this.props.sourceFields.length > 0) {
      const dateFieldName = FieldTypes.DATE_FIELD_NAME;

      filterOptions = this.props.sourceFields
      .filter(field => field.fieldName != dateFieldName && !DataModelTypeUtils.isObjectFieldType(field));
    }

    if(isApplyingPostAggregationFilter){
      filterOptions = getPostAggregationFilterAggregatesList(this.props.aggregationFields);
    }
    const validationOutcome = this.state.FilterValidation;
    const item = this.state.inProgressFilter;
    const isPostAggregationFilter = this.state.quickFilterType === FFAGDefaults.LABEL_OPTIONS[1];
    const component = <div>
        <label>{defaults.NEW_FILTER}</label>
        <FilterInputRow
        key={item.filterKey}
        fieldsList={isApplyingPostAggregationFilter ? filterOptions : filterUtils.getFilterableFields(filterOptions)}
        operations={operations}
        index={1}
        onChangeAttribute={this.onChangeAttribute}
        onChangeOperation={this.onChangeOperation}
        onChangeValue={this.onChangeValue}
        attribute={isApplyingPostAggregationFilter ? item.field : item.selectedField}
        operation={item.operation}
        compareValue={item.compareValue}
        relationToNext={item.relationToNext}
        validationOutcome={validationOutcome && validationOutcome.filterList ? validationOutcome.filterList[0] : null}
        updateSelection={this.updateSelection}
        selection={item.selection}
        isRefFilter={item.selectedField ? item.selectedField.ref : null}
        networkElementDataStructure={item.selectedField ? item.selectedField.refType : null}
        disableChange={item.disableChange}
        disableComponent={item.disableComponent}
        isPostAggregationFilter={isPostAggregationFilter}
        showPostAggregationFilter={this.props.showPostAggregationFilter}
        quickFilterType={this.state.quickFilterType}
        dropdownOptions={this.getLabelSelecionOptions()}
        onLabelSelect={this.onLabelSelect}
        dropdownTextField='text'
        dropdownValueField='value'
        isQuickFilter/></div>;

    return component;
  }

  onLabelSelect = (event) => {
    const state = fromJS(this.state).toJS();

    if(state.quickFilterType !== event){
      state.inProgressFilter.field = null;
      state.inProgressFilter.selectedField = null;
      this.resetFilterFields(state.inProgressFilter, event);
    }
  };

  getButtonsGroup(){
    return (
      <div className="evc-btns-footer">
        <Button
          onClick={this.closePopover}
          bsStyle='cancel'>
          Close
        </Button>
        <Button
          onClick={this.addQuickFilter}
          disabled={this.state.disableSaveButton}
          bsStyle='primary'>
          Add
        </Button>
      </div>
    );
  }

  removeQuickFilter(id){
    this.props.removeQuickFilter(id);
  }
  
  getLabelSelecionOptions(){
    return FFAGDefaults.LABEL_OPTIONS.map(label => {
      return {
        value: label,
        text: UIConstructionUtils.capitalize(label),
      };
    });
  }

  render(){ 
    return(
      <Fragment>
        <OverlayTrigger ref={ ref => this.overlay = ref} 
          rootClose 
          onExited={this.closePopover}
          onEnter={this.props.loadQuickFiltersFields} 
          placement="bottom" trigger="click"
          overlay={<Popover
            className='quickFilter-popover'
            id={"Filters-popover"}
            title={ defaults.QUICK_FILTER }>
              <Fragment>
                <WidgetQuickAppliedFilters 
                  filters={this.props.quickFilters}
                  onRemoveQuickFilter={this.removeQuickFilter} />
                {this.getInProgressFilter()}
                {this.getButtonsGroup()}
              </Fragment>
          </Popover>
          }
        >
          <div className='widget-action'>
            <TooltippedButton className='btn-action quickFilter-btn' tooltip={defaults.QUICK_FILTER}>
              <i className={Icons.filter}></i>
            </TooltippedButton>
          </div>
        </OverlayTrigger>
      </Fragment>
      );
  }
}
