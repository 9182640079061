/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import { fromJS } from 'immutable';
import * as defaults from 'utils/defaults';
import ResolutionInput from './ResolutionInput';
import {Col} from 'react-bootstrap';
import {ResolutionUtils} from 'js-utils';

export default class ResolutionForDateRange extends React.Component {

  constructor(props) {
    super(props);
    this.onDateRangeChange = :: this.onDateRangeChange;
    this.onResolutionChange = :: this.onResolutionChange;
  }

  onDateRangeChange(newResolution){
    const resolutionForDateRange = fromJS(this.props.resolutionForDateRange).toJS();

    resolutionForDateRange.dateRangeDeltaValue = newResolution.interval;
    resolutionForDateRange.dateRangeDeltaUnit = newResolution.unit;
    this.props.onResolutionForDateRangeChange(this.props.index, resolutionForDateRange);
  }

  onResolutionChange(newResolution){
    const resolutionForDateRange = fromJS(this.props.resolutionForDateRange).toJS();

    if(ResolutionUtils.isResolutionIntervalMonths(newResolution.unit))
    {
      resolutionForDateRange.resolutionIntervalValue = ResolutionUtils.MONTHLY_DEFAULT_RESOLUTION;
    }
    else{
      resolutionForDateRange.resolutionIntervalValue = newResolution.interval;
    }
    resolutionForDateRange.resolutionIntervalUnit = newResolution.unit;

    this.props.onResolutionForDateRangeChange(this.props.index, resolutionForDateRange);
  }

  render() {
    const resolutionInputColSm =  this.props.label? 7 : 4;
    
    return (
      <Fragment>
        {this.props.label?
          <Col md={4} xs={3}>
            <label className="data-input-label">
              {this.props.label}
            </label>
          </Col>
        : null}
        <Col md={resolutionInputColSm} xs={this.props.label? 12 :7} mdOffset={0} xsOffset={this.props.label? 0 :3}>
          <ResolutionInput
            onResolutionChange={this.onResolutionChange}
            resolution={{
              interval: this.props.resolutionForDateRange.resolutionIntervalValue,
              unit: this.props.resolutionForDateRange.resolutionIntervalUnit,
            }}
            stopValidation
            disableInterval = {ResolutionUtils.isResolutionIntervalMonths(this.props.resolutionForDateRange.resolutionIntervalUnit)}
            isNewNumericInput
          />
        </Col>
        {this.props.label? <div className="clearfix"></div> : null}
        <Col md={this.props.label? 4 : 1} xs={3}>
          <label className="data-input-label">
            {defaults.FOR}
          </label>
        </Col>
        <Col md={resolutionInputColSm} xs={12}>
          <ResolutionInput
            onResolutionChange={this.onDateRangeChange}
            resolution={{
              interval: this.props.resolutionForDateRange.dateRangeDeltaValue,
              unit: this.props.resolutionForDateRange.dateRangeDeltaUnit,
            }}
            stopValidation
          />
        </Col>
        {this.props.label? <div className="clearfix"></div> : null}
        {this.props.hideMargin ? null : <div className='margBtm10'></div>}
      </Fragment>
    );
  }
}