/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import Modal from 'containers/Modal';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';

export default class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.hideModal = :: this.hideModal;
    this.save = :: this.save;
  }

  getFooterButtons() {
    return ButtonsConstructor.getButtonComponent({
      onClick: this.save,
      bsStyle: 'primary',
    }, this.props.saveButtonLabel || 'Save');
  }

  save() {
    this.props.save(this.props.saveData);
  }

  hideModal() {
    this.props.hideModal();
  }

  getMessage(){
    switch (this.props.type) {
      case 'array':
        return this.getLinkedEntitiesMessage();
      default:
        return this.getUsagesMessage();
    }
  }

  getLinkedEntitiesMessage() {
    return  <div>
      <label>{this.props.label}</label>
      {
        this.props.usages ? 
        <ul>
          {
            (this.props.usages).map(entity => {
              return (
                <li>
                  <label>{entity}{this.props.subLabel}</label>
                </li>
              );
            })
          }
        </ul> : null
      }
    </div>;
  }

  getUsagesMessage() {
    return  <div>
      <label>{this.props.label}</label>
      {
        this.props.usages ? 
        <ul>
          {
            Object.keys(this.props.usages).map(keyData => {
              return (
                <li>
                  <label>{keyData}{this.props.subLabel} </label>
                  <ul>
                    {
                      this.props.usages[keyData].map(keyMessage => {
                        return <li>{keyMessage}</li>;
                      })
                    }
                  </ul>
                </li>
              );
            })
          }
        </ul> : null
      }

    </div>;
  }

  render() {
    return (
      <Modal bsSize={this.props.bsSize}
        className='confirmationModal bootbox modelHeaderWarning'
        onHide={this.hideModal}
        title={'Warning'}
        show={this.props.showModal}
        footerButtons={this.getFooterButtons()}>
        {this.getMessage()}
        {this.props.warning}
      </Modal>
    );
  }

}
