import React from 'react';
import { Row} from 'react-bootstrap';
import _ from 'lodash';

import { fromJS } from 'immutable';
import { FileDownloadUtils, ValidationUtils, Utils} from 'js-utils';
import ValidationOutput from 'containers/ValidationOutput';
import LabeledComponent from 'containers/LabeledComponent';

import FormConstructors from './FormConstructors';
import {INPUTS} from './FormInputs';
import FormPositioningHelper from './FormPositioningHelper';


// Form constructs a form that will exist inside containers handling the
// submit button and functionality, i.e. wizards and steps, extending the Form constructor,
// it has all the constructors bound to it, and this file will only handle the
// functionalities of: validation, on change, on blur,value propagation, and the final render

export default class Form extends FormConstructors {
  constructor(props) {
    super(props);
    this.getNextStateForComponentwillReceiveProps = ::this.getNextStateForComponentwillReceiveProps;
    this.getInitialValueState = ::this.getInitialValueState;
    this.getInputValue = ::this.getInputValue;
    this.getInitialValue = ::this.getInitialValue;

    this.onChange = ::this.onChange;
    this.getFormOutput = ::this.getFormOutput;

    this.wrapInput = ::this.wrapInput;
    this.constructInput = ::this.constructInput;
    this.constructRows = ::this.constructRows;
    this.getChildren = ::this.getChildren;

    this.checkInputValueInvalid = ::this.checkInputValueInvalid;
    this.checkInputValidationFunction = ::this.checkInputValidationFunction;
    this.validateAllInputs = ::this.validateAllInputs;
    this.triggerValidationRequest = ::this.triggerValidationRequest;
    this.validateCheckboxGroup = ::this.validateCheckboxGroup;
    this.validateFile = ::this.validateFile;
    this.clearUploadedFile = ::this.clearUploadedFile;
    this.updateFormValidationResult = ::this.updateFormValidationResult;
    this.openPopupInputModal = ::this.openPopupInputModal;
    this.closePopupInputModal = ::this.closePopupInputModal;
    this.getInputValueFromState :: this.getInputValueFromState;

    const state = {
      validation: props.validation ? props.validation : {},
    };

    this.state = this.getInitialValueState(props.inputs, state);
  }

  componentWillMount(){
    if(this.props.validateOnFirstLoad){
      const formOutput = this.getFormOutput();

      this.updateFormValidationResult(formOutput.isFormValidated);
    }
  }

  componentWillReceiveProps(nextProps) {
    const state = this.getNextStateForComponentwillReceiveProps(nextProps);

    this.setState(this.getInitialValueState(nextProps.inputs, state), () => {
      const formOutput = this.getFormOutput();

      if(this.state.isFormValidated != formOutput.isFormValidated){
        this.updateFormValidationResult(formOutput.isFormValidated);
      }
    });
  }

  updateFormValidationResult(isFormValidated){
    this.setState({
      isFormValidated,
    }, () => {
      if (this.props.updateFormValidationResult) {
        this.props.updateFormValidationResult({isFormValidated});
      }
    });
  }

  getNextStateForComponentwillReceiveProps(nextProps){
    return {
      validation: nextProps.validation ?
        nextProps.validation : this.state.validation,
    };
  }

  getInitialValueState(inputs, state){
    inputs.forEach(input => {
      this.getInitialValue(input, state);
    });

    return state;
  }

  getDisplayValue(input) {
    if (input.getFilterDisplayValue) {
      return input.getFilterDisplayValue(this.state[input.keyword]);
    } else if (INPUTS[input.type].getFilterDisplayValue) {
      return INPUTS[input.type].getFilterDisplayValue(this.state[input.keyword]);
    }

    return this.state[input.keyword];
  }

  getInitialValue(input, state){
    if(input.type !== INPUTS.custom.type){
      if (input.type == INPUTS.resolutionInput.type) {
        state[input.resolutionIntervalKeyword] = input.resolutionInterval || '';
        state[input.resolutionUnitKeyword] = input.resolutionUnit || null;
      } else if(input.type == INPUTS.checkboxGroup.type){
        input.options.forEach(option => {
          option.type = INPUTS.checkboxGroup.type;
          option.parent = input.keyword;

          state[option.keyword] = this.getInputInitialValue(option);
        });
      } else {
        const value = this.getInputInitialValue(input);

        state[input.keyword] = value;
      }

      if(input.children && input.children.length > 0){
        input.children.forEach(child => {
          this.getInitialValue(child, state);
        });
      }
    }
  }

  getInputInitialValue(input) {
    let value = null;
    const textInitial =  input.emptyStringIsNull ? null : '';
    switch (input.type) {
    case INPUTS.toggle.type:
      value = input.initialValue && input.initialValue == input.onValue;
      break;
    case INPUTS.radio.type:
      value = input.initialValue || '';
      if(input.initialValue === false){
        value = false;
      }
      break;
    case INPUTS.text.type:
    case INPUTS.textArea.type:
      value = (input.initialValue) ? `${input.initialValue}` : textInitial;
      break;
    case INPUTS.number.type:
      value = Utils.isBlank(input.initialValue) ? null : input.initialValue;
      break;
    default:
      value =  input.initialValue || INPUTS[input.type].initialValue;
      break;
    }

    return value;
  }

  checkInputValueInvalid(input) {
    let isValidInput = true;

    if(INPUTS[input.type].requiredSchema){
      let value = this.state[input.keyword];

      if(input.type == INPUTS.resolutionInput.type){
        value =  {
          interval: this.state[input.resolutionIntervalKeyword],
          unit : this.state[input.resolutionUnitKeyword],
        } ;
      }else if(input.type == INPUTS.fileUpload.type){
        value = this.state[input.keyword] || this.state[input.name] || null;
      }

      const validation = ValidationUtils.validateSingleSchema(
        INPUTS[input.type].requiredSchema.label(input.validationLabel || input.label), value);
      
      isValidInput = ValidationUtils.isValid(validation);
    }

    return !isValidInput;
  }

  getInputValueFromState(input, state){
    return (state ? state[input.keyword] || state[input.name] 
          : this.state[input.keyword] || this.state[input.name] || null);
  }

  checkInputValidationFunction(input, state){
    let validation = {};

    if(input.validation){
      if(input.type == INPUTS.checkboxGroup.type){
        validation = this.validateCheckboxGroup(input, state);
      }else if(input.type == INPUTS.fileUpload.type){
        validation = ValidationUtils.validateSingleSchema(
          INPUTS.fileUpload.requiredSchema.label(input.validationLabel || input.label), 
          this.getInputValueFromState(input, state));
      }else if(input.type == INPUTS.resolutionInput.type){
        const value =  {
          interval: this.state[input.resolutionIntervalKeyword],
          unit : this.state[input.resolutionUnitKeyword],
        } ;
        validation = ValidationUtils.validateSingleSchema(
          INPUTS[input.type].requiredSchema.label(input.validationLabel || input.label), value);
      }else if(input.type == INPUTS.tagsInput.type){
        if(state) {
          validation = this.props.validationFunction(input.keyword, state[input.keyword]);
        }else{
          validation = this.state.validation[input.keyword];
          if(ValidationUtils.isValid(validation)){
            validation = this.props.validationFunction(input.keyword, this.state[input.keyword]);
          }
        }
      }else{
        validation = this.props.validationFunction(input.keyword,
          state ? state[input.keyword] : this.state[input.keyword]);
      }
    }else if(input.required &&  INPUTS[input.type].requiredSchema){
      validation = ValidationUtils.validateSingleSchema(
        INPUTS[input.type].requiredSchema.label(input.validationLabel || input.label), 
          this.getInputValueFromState(input, state));
    }

    return validation;
  }

  isInputInvalid(input){
    const shouldBeValidated = input.validation;
    const hasSchemaAndValid
      = ValidationUtils.isValid(this.checkInputValidationFunction(input));
    const hasOnBlurValidation = input.onBlurValidationPromise;
    const hasValidValidationState
      = ValidationUtils.isValid(this.state.validation[input.keyword]);
    const isRequired = input.required;
    const hasInvalidBaseValue = this.checkInputValueInvalid(input);

    return(
      (shouldBeValidated && (
        !hasSchemaAndValid
        || (hasOnBlurValidation && !hasValidValidationState)
      ))
      || (isRequired && hasInvalidBaseValue)
    );
  }

  validateAllInputs(stopSetState){
    const validation = {};
    const promises = [];
    this.props.inputs.forEach(input => {
      validation[input.keyword] = this.checkInputValidationFunction(input);
      if (input.validation && input.onBlurValidationPromise) {
        promises.push(new Promise((resolve) => {
          this.triggerValidationRequest(input, resolve);
        }));
      }
      if(input.children && input.children.length > 0){
        input.children.forEach(child => {
          validation[child.keyword] = this.checkInputValidationFunction(child);
        });
      }
    });
    if(!stopSetState){
      this.setState({validation}, this.onChange);
    }

    return {
      validation,
      isFormValidated: this.getFormOutput().isFormValidated,
      promises,
    };
  }

  getInputValue(input, result, state){
    if(input.keyword && input.type !== INPUTS.checkboxGroup.type && input.type !== INPUTS.inputGroup.type){
      result[input.keyword] = state[input.keyword];
    }

    if(input.type == INPUTS.fileUpload.type){
      result[input.name] = state[input.name];
    }else if (input.type == INPUTS.toggle.type) {
      result[input.keyword] = state[input.keyword] ?
        input.onValue : input.offValue;
    }else if (input.type == INPUTS.resolutionInput.type) {
      result[input.resolutionIntervalKeyword]
        = state[input.resolutionIntervalKeyword];
      result[input.resolutionUnitKeyword]
        = state[input.resolutionUnitKeyword];
    }else if (input.type == INPUTS.checkboxGroup.type) {
      input.options.forEach(option => {
        result[option.keyword] = state[option.keyword];
      });
    }
  }

  getFormOutput(state) {
    const result = {
      validation: this.state.validation,
    };
    const currentState = state ? state : this.state;
    let isValidated = true;

    this.props.inputs.forEach(input => {
      if (input.type !== INPUTS.custom.type){
        this.getInputValue(input, result, currentState);

        if(input.children){
          input.children.forEach(child => {
            this.getInputValue(child, result, currentState);
            const isChildInvalid = this.isInputInvalid(child);

            if(isChildInvalid){
              isValidated = false;
            }
          });
        }
        const inInputInvalid = this.isInputInvalid(input);

        if(inInputInvalid){
          isValidated = false;
        }
      }
    });

    if (!this.props.removeValidationFromResult) {
      result.isFormValidated = isValidated;
    } else {
      delete result.validation;
    }

    return result;
  }

  onChange(){
    if (this.props.onChange) {
      this.props.onChange(this.getFormOutput());
    }
  }

  validateCheckboxGroup(input, state){
    const value = [];
    const mainInput = input.parent ? input.parent : input;

    mainInput.options.forEach(option => {
      if(input.keyword == option.keyword && state[option.keyword]){
        value.push(state[option.keyword]);
      }else if(input.keyword !== option.keyword && this.state[option.keyword]){
        value.push(this.state[option.keyword]);
      }
    });

    return this.props.validationFunction(mainInput.keyword, value);
  }

  getValueFromEvent = (input, e) => {
    let value = null;

    if(INPUTS[input.type].getValueFromEvent){
      value = INPUTS[input.type].getValueFromEvent(e);
    }

    if (input.type == INPUTS.radio.type) {
      if(Utils.isStringBoolean(value)){
        value = Utils.stringToBoolean(value);
      }
    }else if (input.type == INPUTS.toggle.type) {
      value = !this.state[input.keyword];
    }

    if (input.trimmed) {
      if(input.type == INPUTS.tagsInput.type) {
        if(value.length > 0){
          value[value.length - 1] = value[value.length - 1].trim();
        }
      } else {
        value = value.trim();
      }
    }

    return value;
  }

  setField = (input) => (e) => {
    let state = {};
    let validation = fromJS(this.state.validation).toJS();
    let value = this.getValueFromEvent(input, e);

    if(input.emptyStringIsNull && value === ''){
      value = null;
    }

    if(this.state[input.keyword] != value
      && input.children
      && input.getChildsValues ){
      const childValues = input.getChildsValues(input.children);

      state = {
        ...childValues,
      };

      validation = {
        ...validation,
        ...childValues.validation,
      };
    }

    if (input.type == INPUTS.resolutionInput.type) {
      state[input.resolutionIntervalKeyword] = value.interval;
      state[input.resolutionUnitKeyword] = value.unit;
      const validation
        = this.checkInputValidationFunction(input, state, value);

      state.validation = validation;
      this.setState(state, this.onChange);
         
    } else {
      state[input.keyword] = value;
      if (input.validation || input.required) {
        const inputValidation
          = this.checkInputValidationFunction(input, state, value);

        if(input.type == INPUTS.checkboxGroup.type){
          validation[input.parent.keyword] = inputValidation;
        }else{
          validation[input.keyword] = inputValidation;
        }
      }
      if (input.validateBefore && input.validateBefore.length > 0) {
        input.validateBefore.forEach(key => {
          validation[key]
          = this.props.validationFunction(key, this.state[key], state);
        });
      }
      state.validation = validation;
      this.setState(state, () => {
        if (!INPUTS[input.type].updateOnBlur || input.forceUpdateOnChange) {
          this.onChange();
        }
      });
    }
  }

  onTagValidationReject = (input) => (evt) => {
    const validation = fromJS(this.state.validation).toJS();
    const newTag = evt[0].trim();
    const tags =  fromJS(this.state[input.keyword] || []).toJS();

    if(newTag){
      tags.push(newTag);
    }
    validation[input.keyword] = this.props.validationFunction(input.keyword, tags);
    if(ValidationUtils.isValid(validation[input.keyword])){
      validation[input.keyword] = this.props.validationFunction(input.tagKeyword, newTag);
    }
    this.setState({validation}, this.onChange);
  }

  triggerValidationRequest(input, promiseResolve){
    const validation = fromJS(this.state.validation).toJS();

    validation[input.keyword]
      = ValidationUtils.labelToValidationObject('Validating...');

    this.setState({validation}, () => {
      this.onChange();
      input.onBlurValidationPromise(this.state[input.keyword]).then(value => {
        promiseResolve?.();
        const validation = fromJS(this.state.validation).toJS();

        validation[input.keyword] = value;
        this.setState({validation}, this.onChange);
      });
    });
  }

  onBlurValidation = (input) => () => {
    if(input.validation && input.onBlurValidationPromise){
      this.triggerValidationRequest(input);
    }else if(input.validation && input.autoValidateOnBlur){
      const validation = fromJS(this.state.validation).toJS();

      validation[input.keyword] = this.checkInputValidationFunction(input);
      this.setState({validation}, this.onChange);
    }else{
      this.onChange();
    }
  }

  validateFile(file, input){
    const validation = fromJS(this.state.validation).toJS();
    const fileDescriptor = file ? {
      size: file.size,
      extension: FileDownloadUtils.getFileExtension(file.name),
      name: file.name,
      type: file.type,
    } : undefined;

    validation[input.keyword]
    = this.props.validationFunction(input.keyword, fileDescriptor);
    const state = {
      validation,
      [input.keyword] : null,
      [input.name] : null,
    };

    if(ValidationUtils.isValid(validation[input.keyword])){
      const reader = new FileReader();

      reader.onloadend = () => {
        state[input.keyword] = input.readAsDataURL ? reader.result : file;
        state[input.name] = file.name;
        this.setState(state, this.onChange);
      };
      reader.readAsDataURL(file);
    }else {
      this.setState(state, this.onChange);
    }
  }

  clearUploadedFile(input){
    const state = {
      validation: {},
      [input.name]: '',
    };

    if (input.default) {
      state[input.keyword] = input.default;
    } else {
      state[input.keyword] = '';
      if(input.validation){
        state.validation = fromJS(this.state.validation).toJS();
        state.validation[input.keyword]
          = this.props.validationFunction(input.keyword, undefined);
      }
    }
    this.setState(state, this.onChange);
  }

  uploadFile = (input) => (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      this.validateFile(file, input);
    }
    e.target.value = null;
  }


  openPopupInputModal = (keyword) => () => {
    this.setState({ [`${keyword}showPopupInputModal`]: true});
  }

  closePopupInputModal = (keyword) => () => {
    this.setState({ [`${keyword}showPopupInputModal`]: false});
  }

  getChildren(input) {
    if(input.children){
      const children = input.children.map(child => {
        child.isChild = true;
        if(child.type !== INPUTS.multipleSelect.type){
          child.hideLabel = true;
        }

        return this.wrapInput(child, this.constructInput(child));
      });
      const Wrapper = input.type == INPUTS.inputGroup.type ? Row: React.Fragment;
      return <Wrapper>{children}</Wrapper>;
    }

    return null;
  }

  constructInput(input) {
    if(INPUTS[input.type].hideLabel && !input.label){
      input.hideLabel = true;
    }
    const constructorName = INPUTS[input.type].constructorName;
    const inputResult = this[constructorName](input);

    return inputResult ;
  }

  wrapInput(input, renderedInput){
    return this.getLabeledInput(input, renderedInput);
  }


  getLabeledInput(input, renderedInput){
    const children = this.getChildren(input);
    const inputSize = input.isChild ? 6 : 9;
	  const Wrapper = input.groupChildren ? React.Fragment : Row ;
    
	
    return(
      <Wrapper>
        <LabeledComponent
          key={input.keyword}
          label={input.label}
          size={12}
          componentClassName={`${input.class || ''} ${
            (input.keyword ? `${input.keyword}-formElement` : '')}`}
          labelSize={input.isChild ? 6 : 3}
          labelSizeSm={input.isChild ? 6 : 12}
          inputSize={input.hideLabel ? 12 : inputSize}
          inputSizeSm={input.isChild ? 6 : 12}
          {...input.variableSizes}
          hint={input.labelHint}
        >
          {renderedInput}
          {input.buttons ? input.buttons : null}
          { children ? children : null }
          <ValidationOutput validation={INPUTS[input.type].hideValidation ? null : this.state.validation[input.keyword]}/>
        </LabeledComponent>
      </Wrapper>
    );
  }

  constructRows(inputs) {
    return inputs.map((input) => {
	 
      const renderedInput = this.constructInput(input);

      return this.wrapInput(input, renderedInput);
    });
  }

  render() {
    return (
      <FormPositioningHelper expandFullWidth={this.props.expandFullWidth} className={this.props.className}>
        {this.constructRows(this.props.inputs)}
        {this.props.children ? this.props.children : null}
      </FormPositioningHelper>
    );
  }
}
