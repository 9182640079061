/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import LabeledComponent from 'containers/LabeledComponent';
import PercentileInput from './PercentileInput';
import MultipleSelect from '../simple/MultipleSelect';
import {AggregationUtils} from 'js-utils';

const AggregationInput = props => {
  return(
    <Col xs={12} className={props.className}>
      <Row>
        <LabeledComponent label={props.label} size={12} labelSize={props.label? props.labelSize || 12 : 0 } inputSize={props.label? props.dropdownSize || 12 : 12}>
          <MultipleSelect
            className={`${props.className}-menu`}
            filter={props.filter || false}
            placeholder={props.placeholder || null}
            value = {props.aggregation ? props.aggregation : -1}
            onChange={props.onChange}
            options={props.options} 
            disabled={props.disabled}
          />
        </LabeledComponent>
      </Row>
      {props.aggregation === AggregationUtils.NTH_PERCENTILE ?
      <Row>
        <PercentileInput
          className={`${props.className}-percentileInput`}
          {...props.percentile}
        /> 
      </Row>
        : null }
    </Col>
  );
};

export default AggregationInput;
