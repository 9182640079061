/* Copyright 2019 Greyskies. All Rights Reserved. */

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'styles/inputs/simple/autoComplete.less';

export default class AutoComplete extends React.Component {
  constructor(props){
    super(props);
    this.onSearch = ::this.onSearch;
    this.onChange = ::this.onChange;

    this.state = {
      options: [],
      loading: false,
    };
  }

  onSearch(query){
    this.setState({loading: true}, () => {
      this.props.getAutoCompleteSuggestions(query, this.props.extraParams)
      .then(results => {
        this.setState({
          loading: false,
          options: results,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          options: [],
        });
      });
    });
  }

  onChange(selected){
    if(selected.length != 0){
      this.props.onBlur({target: {value: selected[0]}});
    }
  }

  render(){
    return(
      <div className={`ac-Wrapper ${this.props.className || ''}`}>
        <AsyncTypeahead
          id={this.props.id}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onSearch={this.onSearch}
          onInputChange={(text, e) => {
            if(!text || text == ''){
              this.onSearch('');
            }
          }}
          onFocus={(e)=> {
            if (e.type === 'focus') {
              this.onSearch(e.target.value);
            }
          }}
          minLength={0}
          useCache={false}
          defaultInputValue={this.props.value || ''}
          isLoading={this.state.loading}
          labelKey={option => `${option}`}
          options={this.state.options}
          onBlur= {(e) => {
            if(e.target.value != this.props.value){
              this.props.onBlur(e);
            }
          }}
          disabled={this.props.disabled}
        />
      </div>
		);
  }
}
