/* Copyright 2019 Greyskies. All Rights Reserved. */

import React , {Component} from 'react';
import 'styles/inputs/simple/radio.less';

export default class Radio extends Component{
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(){
    this.radio.click();
  }
  render(){
    let containerClassname = 'radiobutton-container ';

    if(this.props.className)
      containerClassname += this.props.className;
    return(
      <div className={`${containerClassname} ${this.props.card ? 'cardGroup':'' } ${this.props.checked? 'checked':'unChecked'}`}>
        <input type="radio" className="radiobutton" name={this.props.name}
          checked={this.props.checked} value={this.props.value}
          readOnly
          onClick={this.props.onChange}
          ref={ref=>this.radio=ref}
          disabled={this.props.disabled}/>
        <label className={`radiobutton-label radGroup1 ${this.props.checked? 'checked':'unChecked'}`} onClick={this.onClick}>
          <span></span>
          {this.props.label}
        </label>
        {this.props.card ?
          <div className={`${this.props.checked? 'checked':'unChecked'} card`} onClick={this.onClick}>
            {this.props.card}
          </div> : null}
      </div>
    )
  }
}
