/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils, FieldTypes} from 'js-utils';

const requiredStringSchema = Joi.string()
  .required()
  .error(ValidationUtils.getErrMsgString);

const requiredNumberSchema = Joi.number()
  .required()
  .error(ValidationUtils.getErrMsgString);

const requiredStringOrNumber = Joi.alternatives().try(Joi.string(), Joi.number())
  .required()
  .error(ValidationUtils.getErrMsgString);
const SCHEMA = {
  networkElementLevel: requiredStringOrNumber.label("Network Element"),
  attributePath: requiredStringSchema.label("Attribute Path"),
  attributeId: requiredStringOrNumber.label("Attribute"),
  fieldId: requiredStringOrNumber.label("Field"),
  operationFields: Joi.object()
    .keys({
      operation: requiredStringSchema.label("Operation"),
      limit: requiredStringOrNumber.label("Limit"),
      unitScaleId: Joi.number()
        .when('fieldCategory', [{
          is: FieldTypes.FieldTypes.Numeric,
          then: requiredNumberSchema.label("Unit factor"),
          otherwise: Joi.number().optional().allow(''),
        }]),
    })
    .unknown(),
};


export const validationFunction = ValidationUtils.validateSchema(SCHEMA);

