/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { fromJS } from 'immutable';
import * as defaults from 'utils/defaults';
import CollapsableComponent from 'containers/CollapsableComponent';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import DataGroupingResolutionInput from 'inputs/compound/DataGroupingResolutionInput';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import {MsgDefaults, FieldTypes, UnitConversionUtils} from 'js-utils';
import UnitConversionDropdown from 'inputs/compound/UnitConversionDropdown';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import FgaAttributeSelection from './FgaAttributeSelection';

export default class RecordsGroupByInputRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.orderUp = :: this.orderUp;
    this.orderDown = :: this.orderDown;
    this.deleteGroupBy = :: this.deleteGroupBy;
    this.onChangeGroupBy = :: this.onChangeGroupBy;
    this.onUnitChange = ::this.onUnitChange;
    this.onChangeMultipleSelectGroupBy = ::this.onChangeMultipleSelectGroupBy;
  }

  orderUp(order){
    const fields = fromJS(this.props.groupByRowsList).toJS();

    if (order > 0) {
      const temp = fromJS(fields[order]).toJS();
      fields[order] = fields[order - 1];
      fields[order - 1] = temp;
    }
    this.props.setGroupByRowsListState(fields, true, true, true);
  }

  orderDown(order){
    const fields = fromJS(this.props.groupByRowsList).toJS();

    if (order < fields.length - 1) {
      const temp = fromJS(fields[order]).toJS();
      fields[order] = fields[order + 1];
      fields[order + 1] = temp;
    }
    this.props.setGroupByRowsListState(fields, true, true, true);
  }

  deleteGroupBy(index) {
    const groupByRowsList = fromJS(this.props.groupByRowsList).toJS();

    if (this.props.groupByRowsList.length >= 0) {
      groupByRowsList.splice(index, 1);
    }

    this.props.groupByRowsList[index].selectedField != undefined ? this.props.setSelectedFieldsSet(this.props.groupByRowsList[index].selectedField.id, false) : null;
    this.props.setGroupByRowsListState(groupByRowsList, true, true, true);
  }

  onChangeGroupBy(value, index) {
    const groupByRowsList = this.props.groupByRowsList != undefined ? fromJS(this.props.groupByRowsList).toJS() : [{ selectedField: null }];
    groupByRowsList[index] = {
      ...groupByRowsList[index],
      ...value,
    };

    const fieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([groupByRowsList[index]]);
    const oldFieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([(this.props.groupByRowsList || [])[index]]);
    const resetUnit = DataSelectionCommonUtils.shouldResetUnit(fieldTypeDescriptor, oldFieldTypeDescriptor);
    
    if(resetUnit){
      groupByRowsList[index].fieldUnitFactor = UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
    }
    this.props.setSelectedFieldsSet(groupByRowsList[index].selectedField, true);
    this.props.setGroupByRowsListState(groupByRowsList, true, true, true);
  }

  onUnitChange(e){
    const groupByRowsList = this.props.groupByRowsList != undefined ? 
      fromJS(this.props.groupByRowsList).toJS() : [{ selectedField: null }];
    
    groupByRowsList[this.props.index] = {
      ...groupByRowsList[this.props.index],
      fieldUnitFactor: e.target.value,
    };

    this.props.setGroupByRowsListState(groupByRowsList, true, true, true);
  }

  getRowShownAccordingToSelectedGroup(){
    const resolutionInput = <DataGroupingResolutionInput
        showCustomInterval={this.props.showCustomInterval}
        customUnits={this.props.customUnits}
        customUnitsValues={this.props.customUnitsValues}
        onChange={this.onChangeGroupBy}
        index={this.props.index}
        resolutionUnit={this.props.resolutionUnit}
        resolution={this.props.resolution}
        getResolutionObj={DataSelectionUtils.getResolutionObj}
        disableComponent={this.props.disableComponent}
        validation={this.props.validationOutcome}
        />;

    return resolutionInput;
  }

  onChangeMultipleSelectGroupBy(selectedField){
    const groypByField = {};

    groypByField.selectedField = selectedField;
                  
    DataSelectionUtils.updateDateResolution(groypByField, this.props.showCustomInterval,
      this.props.customUnitsValues, this.props.customUnits);
    this.onChangeGroupBy(groypByField, this.props.index);
  }

  getGroupByRow(){
    const categorizedAttributes = UIConstructionUtils.getCategorizedAttributesOptions(
      this.props.fieldsList, 'id', 'id', 'fieldName');

    const rowShownAccordingToSelectedGroup = FieldTypes.isDateField(this.props.selectedField) && !this.props.hideResolutionInterval? 
        this.getRowShownAccordingToSelectedGroup(): null;
    const selectedUnit = this.props.fieldUnitFactor;

    return (
      <div className='evc-container-sys-bg'>
        <Row className="groupingInputContainer">
          <OverlayTrigger shouldUpdatePosition
            rootClose placement='bottom' trigger={this.props.child ? [] : ['hover', 'focus']}
            overlay={
              <Tooltip placement='bottom' className='in'>
                {defaults.SELECT_ATTR}
              </Tooltip>
            }
            >
              <React.Fragment>
            <Col xs={12} className="groupingInput">
              <FgaAttributeSelection
                disabled={this.props.disableComponent}
                placeholder={defaults.SELECT_ATTR}
                className='grouping'
                options={categorizedAttributes}
                subFields={this.props.subFields}
                hasWholeObject={this.props.hasWholeObject}
                attribute={this.props.selectedField}
                grouped
                fieldsList={this.props.fieldsList}
                onChange={this.onChangeMultipleSelectGroupBy}
                tooltip={defaults.SELECT_ATTR}
              />
            </Col>
            { this.props.hasUnit
            && this.props.selectedField
            && !FieldTypes.isDateField(this.props.selectedField) 
            && FieldTypes.isNumericField(this.props.selectedField) ? 
            <Col xs={12} className='margTop5'>
              <UnitConversionDropdown
                unit={selectedUnit || UnitConversionUtils.DEFAULT_UNIT_DIVIDER}
                key={`${selectedUnit}-${this.props.selectedField.id}`}
                baseUnit={this.props.selectedField.attributeType.unit}
                dataType={this.props.selectedField.attributeType.dataType}
                onUnitChange={this.onUnitChange}
                hideLabel
                inputSize={12}
              />
            </Col> : null
          }
          </React.Fragment>
          </OverlayTrigger>
          {rowShownAccordingToSelectedGroup ? 
            <Col xs={12} className="groupingDependency margTop5">
              {rowShownAccordingToSelectedGroup}
            </Col>
            : null
          }
        </Row>
      </div>
    )}
  render() {
    const shownIndex = this.props.index + 1;
    const collapsedTitle = (this.props.singleEntityTitle || DataSelectionUtils.LABELS.GROUPING) + ' ' + shownIndex;
    const collapsedContent = this.getGroupByRow();
    return (
      <CollapsableComponent
        title={collapsedTitle}
        collapsableComponentIndex={this.props.index}
        validation={this.props.validationOutcome}
        buttons={[
          ButtonsConstructor.orderUp(
            this.orderUp, 'Order Up', this.props.disableComponent),
          ButtonsConstructor.orderDown(
            this.orderDown, 'Order Down', this.props.disableComponent),
            ButtonsConstructor.remove(
            this.deleteGroupBy, MsgDefaults.getDeleteTitle(this.props.singleEntityTitle), this.props.disableComponent
          ),
        ]}
        >
        {collapsedContent}
      </CollapsableComponent>
    );
  }
}
