/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';


// all form positioning inside components or panels, or wrapping forms in
// other components if necessary should be done here, 
//form components will not handle form size or position

export default function FormPositioningHelper(props){
  return (
    <Row className={`form-container ${props.className || ''}`}>
      <Col className='block-center' xs={12} sm={props.expandFullWidth ? 12 : 8}>
        {props.children}
      </Col>
    </Row>
  );
}
