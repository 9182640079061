/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from "react";
import ValidationOutput from 'containers/ValidationOutput';
import { fromJS } from 'immutable';
import 'web-style-guide/css/dragNdropUpload.less';
import Icons from 'templates/Icons';
import {FileDownloadUtils, ValidationUtils, ArrayUtils} from 'js-utils';


export default class DragAndDropUpload extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: props.files || [],
    };

    this.preventDefault = ::this.preventDefault;
    this.fileDrop = ::this.fileDrop;
    this.filesSelected = ::this.filesSelected;
    this.removeFile = ::this.removeFile;
    this.handleFiles = ::this.handleFiles;
    this.inputValidator = ::this.inputValidator;
    this.validate = ::this.validate;
    this.validateFilesArrayLength = ::this.validateFilesArrayLength;

  }

  componentWillReceiveProps(nextProps) {
    if(!_.isEqual(nextProps.files, this.props.files)){
      this.setState({ selectedFiles: nextProps.files });
    }
  }

  handleFiles(files) {
    let selectedFiles = fromJS(this.state.selectedFiles).toJS();
    const filesArray = Array.from(files);

    filesArray.forEach((file) => {
      file.extension = FileDownloadUtils.getFileExtension(file.name);
    });

    if(this.props.multiple){
      selectedFiles.push(...filesArray);
    } else {
      selectedFiles = [filesArray[0]];
    }
    const filesValidation = fromJS(this.props.filesValidation).toJS();

    const validationsResult = this.validate(filesArray);
    
    filesValidation.push(...validationsResult.filesValidation);


    this.setState({
      selectedFiles,
    });
    this.fileInputRef.value = null;
    this.props.onAddFiles(filesArray, validationsResult.filesArrayLengthValidation, filesValidation);
  }

  removeFile(index) {
    const files = ArrayUtils.deleteItem(this.state.selectedFiles, index);
    const filesValidation = ArrayUtils.deleteItem(this.props.filesValidation, index);

    const filesArrayLengthValidation = this.validateFilesArrayLength(files);

    this.setState({
      selectedFiles: files,
    });
    this.props.onRemoveFile(index, filesArrayLengthValidation, filesValidation);
  }

  preventDefault(e) {
    e.preventDefault();
  }

  fileDrop(e) {
    this.preventDefault(e);
    const files = e.dataTransfer.files;

    if (files.length) {
      this.handleFiles(files);
    }
  }

  filesSelected(e) {
    if (this.fileInputRef.files.length) {
      this.handleFiles(this.fileInputRef.files);
    }
  }

  inputValidator(attribute, value){
    return this.props.validationFunction(attribute, value);
  }

  validate(files){
    const ArrayLengthvalidation = this.validateFilesArrayLength(files);

    let isAllFilesValid = true;
    const filesValidation = [];

    files.forEach((file, index) => {
      filesValidation.push(this.inputValidator('file', {size: file.size, extension: file.extension}));
      isAllFilesValid = isAllFilesValid && ValidationUtils.isValid(filesValidation[index]);
    });

    return {ArrayLengthvalidation, filesValidation, isAllFilesValid};
  }

  validateFilesArrayLength(files){
    return this.inputValidator(`file${this.props.multiple ? 's' : ''}`, files);
  }

  render() {
    return (
      <div className="uploadContent">
        <label className="drop-container"
          onDragOver={this.preventDefault}
          onDragEnter={this.preventDefault}
          onDragLeave={this.preventDefault}
          onDrop={this.fileDrop}
        >
          <div className="drop-message">
            <i className={`upload-icon ${Icons.cloudUpload} ${Icons.styles.ev4x}`}></i>
            Drop your file{this.props.multiple ? 's' : ''} here or, 
            <a>Browse</a>
            <input
              ref={(ref) => (this.fileInputRef = ref)}
              className="file-input"
              type="file"
              multiple={this.props.multiple}
              onChange={this.filesSelected}
              accept={this.props.accept}
            />
          </div>
        </label>
        <div className="file-display-container">
          {
            (this.state.selectedFiles || []).map((data, index) =>
              <div className="file-status-bar" key={index}>
                <div>
                  <div className="file-type">{FileDownloadUtils.getFileExtension(data.name)}</div>
                  <span className={'file-name'}>{data.name}</span>
                </div>
                <div className="file-remove" onClick={() => this.removeFile(index)}>
                  <i className={Icons.close}></i>
                </div>
                <ValidationOutput validation={this.props.filesValidation[index]}/>
              </div>
            )
          }
        </div>
        <ValidationOutput className='margTop10' validation={this.props.filesArrayLengthValidation}/>
      </div>
    );
  }
}

