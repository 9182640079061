/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Button, Row, Col} from 'react-bootstrap';

import Form from './Form';
import {INPUTS} from './FormInputs';
import FormPositioningHelper from './FormPositioningHelper';

//Extendting the embedded form, the Form class has the same functionalities
// of the embedded form, adding to it the footer and footer buttons.
// Form component is made to exist alone with the help header and 
//all inside a single page

export default class FormWithFooter extends Form {
  constructor(props) {
    super(props);

    this.onSubmit = ::this.onSubmit;
    this.getFooterButtons = ::this.getFooterButtons;
    this.onKeyDown = ::this.onKeyDown;
  }

  componentDidMount(){
    if(this.props.submitOnEnter){
      window.addEventListener('keydown', this.onKeyDown);
    }
  }

  componentWillUnmount(){
    if(this.props.submitOnEnter){
      window.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onKeyDown(e){
    if(e.key === 'Enter'){
      this.onSubmit();
    }
  }
  
  onSubmit() {
    const result = {};
    const isValid = this.validateAllInputs().isFormValidated;
  
    if(isValid || this.props.skipValidation){
      this.props.inputs.forEach(input => {
        if (input.type !== INPUTS.custom.type){
          this.getInputValue(input, result, this.state);
          if(input.children){
            input.children.forEach(child => {
              this.getInputValue(child, result, this.state);
            });
          }
        }
      });

      this.props.onSubmit(result);
    }
  }

  getFooterButtons(){
    return (
      <Row>
        <Col xs={12} sm={12} className={`block-center ${this.props.footerClass || ''}`}>
          <div className={`evc-btns-footer ${this.props.noFooterBorder ? 'noFooterBorder' : ''}`}>
            {
              this.props.hideCancelButton ? null : 
                <Button 
                  onClick={this.props.onCancel} 
                  bsStyle='cancel'
                  className='cancelForm-btn'
                >
                  {this.props.cancelButtonText || 'Cancel'}
                </Button>
            } 
            {this.props.footerButtons}
            <Button
              className='submitForm-btn'
              onClick={this.onSubmit}
              disabled={this.props.disableSaveButton}
              bsStyle='primary'
            >
              {this.props.submitButtonText || 'Submit'}
            </Button>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <FormPositioningHelper expandFullWidth={this.props.expandFullWidth} className={this.props.className}>
        {this.constructRows(this.props.inputs)}
        {this.props.children ? this.props.children : null}
        {this.props.hidefooterButtonsGroup? null : this.getFooterButtons()}
      </FormPositioningHelper>
    );
  }
}
