/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {useEffect, useState} from 'react';
import {Row, Col, FormControl, InputGroup} from 'react-bootstrap';
import ValidationOutput from 'containers/ValidationOutput';
import 'styles/inputs/simple/numericInputComponent.less'

const NumericInputComponent = props => {
  const [inputValue, setInputValue] = useState(props.hideValue ? '' : props.value);

  useEffect(() => {
    if(props.value != inputValue){
      setInputValue(props.value);
    }
  }, [props.value]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if(props.onChange){
      props.onChange(event);
    }
  }; 

  const onBlur = (e) => {
    const target = {target:{value:e.target.value, name:props.name}};

    if(props.onChange){
      props.onChange(target);
    }
    if(props.onBlur){
      props.onBlur(target);
    }  
  };
  const onKeyPress = (e) => {
    if(e.key == 'e' || e.key == 'E') {
      e.preventDefault();
    }
  };
  const validationOutput = <ValidationOutput validation={props.validation} />;
  const inputGroup = props.suffixLabel ? <InputGroup.Addon className='data-input-label textEllipsis'>
            {props.suffixLabel}
          </InputGroup.Addon> : null;
  const inputResult = <FormControl
    type={props.hideValue ? 'text' : 'number'}
    className={`input-text margBtm0 numericInput ev-auto-num-input ${props.disabled ? 'disabled-input' : ''}`}
    value={props.hideValue ? '' : inputValue}
    name={props.name}
    step={props.step || 1}
    placeholder={props.placeholder || null}
    disabled={props.disabled || false}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    onChange={handleInputChange}
  />;

  if (props.pureInput) {
    return props.suffixLabel ? ( 
      <InputGroup> 
      {inputResult}
      {inputGroup}
      </InputGroup>
    ) : inputResult;
  }

  return(
    <Col xs={props.size || 6}>
      <div className={`data-input ${props.className || ''}`}>
        <Row>
          {!props.label || props.hideLabel ? null :
            <Col xs={props.labelSize || 3}>
              <label className='data-input-label'>
                {props.label}
              </label>
              { props.infoIcon || '' }
            </Col>
          }
          <Col xs={props.inputSize || 6}>
            {inputResult}
            {inputGroup}
            {validationOutput}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default NumericInputComponent;