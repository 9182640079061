/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import DataInput from 'inputs/simple/DataInput';
import { fromJS } from 'immutable';
import Icons from 'templates/Icons';


/** @deprecated */
class LabelWithListOfDataInput extends React.Component{
  constructor(props){
    super(props);
    this.onChangeValue = :: this.onChangeValue;
    this.deleteField = :: this.deleteField;
    this.setValue = :: this.setValue;

    this.state = {
      values: this.getValues(this.props.inputsvalue),
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      values: this.getValues(nextProps.inputsvalue),
    });
  }

  getValues(inputsvalue){
    const values = inputsvalue? inputsvalue.map((value) => {
      return this.props.getInputValue(value);
    }) : [];
    return values;
  }

  setValue(index, e){
    const values = fromJS(this.state.values).toJS();
    values[index] = e.target.value;
    this.setState({values});
  }

  onChangeValue(index, e){
    this.props.onChangeValue(e.target.value, index);
  }

  deleteField(index){
    this.props.deleteField(index);
  }


  getInputComponent(value, index){
    return(
       <Col xs={12}>
        <Row>
          <Col xs={10} className="paddSides0">
            <DataInput
              className="paddSides0 filterValue-input margBtm0OverRide"
              formControlContainerClassName="paddSides0"
              size={12}
              inputSize={12}
              labelSize={0}
              disabled={this.props.disableComponent}
              onChange={this.setValue.bind(this, index)}
              onBlur={this.onChangeValue.bind(this, index)}
              value={value}
              validation={this.props.validation[index]} />
          </Col>

          <Col xs={2} className="paddSides0">
            <Button className={`col-lg-2 float-none btn-action `}
              disabled={this.props.disableComponent}
              onClick={this.deleteField.bind(this, index)}>
                <i className={Icons.delete}></i>
            </Button>
          </Col>
        </Row>
      </Col>
    );
  }

  getButtonWithAddButton(){
      return(
       <div>
          <label className="marginRight10 margBtm10"> {this.props.label} {this.props.operation}</label>
          <Button className={`col-md-12 float-none btn-action undefined btn btn-default`}
            disabled={this.props.disableComponent}
            onClick={this.props.addField}>
            <i className={Icons.add}></i>
          </Button>
      </div>
    );
  }
  render(){
    const labelWithAddButton = this.getButtonWithAddButton();

    return(
      <Col xs={12} className="expandableArea">
        <div className="row-padding">
          {labelWithAddButton}
          {this.state.values ? this.state.values.map((value, index) => {
            return this.getInputComponent(value, index);
          }) : null}
          </div>
      </Col>
    );
  }
}

export default LabelWithListOfDataInput;
