/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import { ValidationUtils } from 'js-utils';
import * as FFAGDefaults from './ffagDefaults';

const SCHEMA = {
  dataSelectionId: Joi.object().keys({
    dataSelectionId: Joi.number()
      .error(ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.FFAG))
      .when('dataSelectionCreateOption',
        [{
          is: FFAGDefaults.CREATE_OPTION,
          then: Joi.number().optional().allow(null),
          otherwise: Joi.number().required(),
        }]),
    dataSelectionCreateOption: Joi.string().optional(),

  }),
}

export default ValidationUtils.validateSchema(SCHEMA);


