/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {OverlayTrigger, Tooltip, Row, Col} from 'react-bootstrap';
import CollapsableComponent from 'containers/CollapsableComponent';
import * as defaults from 'utils/defaults';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import {AggregationUtils, FieldTypes, UnitConversionUtils} from 'js-utils';
import FgaAttributeSelection from './FgaAttributeSelection';

import MultipleSelect from 'inputs/simple/MultipleSelect';
import PercentileInput from 'inputs/compound/PercentileInput';
import UnitConversionDropdown from 'inputs/compound/UnitConversionDropdown';

const AGGREGATION_LABEL_ATT_NAME = 'name';
const AGGREGATION_VALUE_ATT_NAME = 'value';
const WEIGHTED_AVG_LABEL_ATT_NAME = 'fieldName';
const WEIGHTED_AVG_KEY_ATT_NAME = 'id';

export default class DataFieldInputRow extends React.Component{


  constructor(props){
    super(props);
    this.state = {
      percentileValue: props.percentileValue || null,
      weightedAvgField: props.weightedAvgField || null,
    };
    this.onChangeAttribute = ::this.onChangeAttribute;
    this.deleteField = ::this.deleteField;
    this.showNthPercentileInput = ::this.showNthPercentileInput;
    this.showWeightFieldForWeightedAvgInput = ::this.showWeightFieldForWeightedAvgInput;
    this.onChangeFieldForWeightedAvg = ::this.onChangeFieldForWeightedAvg;
    this.getRowShownAccordingToSelectedAggregation = ::this.getRowShownAccordingToSelectedAggregation;
    this.onChangeAggregation = ::this.onChangeAggregation;
    this.onChangePercentile = ::this.onChangePercentile;
    this.onBlurPercentile = ::this.onBlurPercentile;
    this.onUnitChange = ::this.onUnitChange;
  }

  onChangeAttribute(selectedField){
    this.props.onChangeAttribute(this.props.index, selectedField);
  }

  onChangeAggregation(e){
    const aggregation = e.target.value;
    const aggregationValue = (aggregation == AggregationUtils.NTH_PERCENTILE) ? AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE : null;
    const weightedAvgField = (aggregation == AggregationUtils.WEIGHTED_AVG) ? '' : null;
    const oldAggregationFieldType = AggregationUtils.getAggregationFieldType(this.props.aggregation);
    const initializeField = (
      (AggregationUtils.isCount(this.props.aggregation) 
        || AggregationUtils.isCount(aggregation))
      && this.props.aggregation !== aggregation) 
      || !AggregationUtils.isFieldAcceptedByAggregation(AggregationUtils.getAggregationFieldType(aggregation), oldAggregationFieldType);

    this.setState({
      percentileValue: aggregationValue,
      weightedAvgField,
    });
    this.props.onChangeField(this.props.index, aggregation, aggregationValue, weightedAvgField, initializeField);
  }

  onChangeFieldForWeightedAvg(selectedField){
    this.props.onChangeFieldForWeightedAvg(this.props.index, selectedField);
  }

  onChangePercentile(e){
    this.setState({percentileValue: e.target.value});
  }

  onBlurPercentile(e){
    this.props.onChangeField(this.props.index, this.props.aggregation, e.target.value, null);
  }

  showNthPercentileInput(){
    return(
      <Col xs={12}>
        <PercentileInput
          labelSize={4}
          inputSize={8}
          className="row aggPercentile-input"
          onChange={this.onChangePercentile}
          value={this.state.percentileValue}
          defaultValue={AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE}
          disabled={this.props.disableComponent}
          onBlur={this.onBlurPercentile}
          />
        </Col>
      );
  }

  showWeightFieldForWeightedAvgInput(){
    const filteredFieldList = this.props.fieldsList.filter(
      (item) => ((DataSelectionUtils.hasMatchingFieldOrSubfieldWithType(
        item, FieldTypes.FieldTypes.Numeric)) 
        && item.elasticsearchName !== AggregationUtils.COUNT_ES_FIELDNAME));
    const weightedAvgAtributeOptions = UIConstructionUtils.wrapArrayInToSelectOptions(
      filteredFieldList, 
      WEIGHTED_AVG_LABEL_ATT_NAME, 
      WEIGHTED_AVG_KEY_ATT_NAME, 
      WEIGHTED_AVG_LABEL_ATT_NAME);
    const weightedAvgField = this.props.weightedAvgField;
    const weightedSubFields = DataSelectionUtils.filterFieldsByAggregationType(
      DataSelectionCommonUtils.getSubFields(weightedAvgField,
        this.props.fieldsList, []), this.props.aggregation || '');

    return(
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <FgaAttributeSelection 
              disabled={this.props.disableComponent}
              className='aggWeightAttr-menu'
              placeholder={defaults.SELECT_WEIGHT_ATTR}
              tooltip={defaults.SELECT_WEIGHT_ATTR}
              options={weightedAvgAtributeOptions}
              attribute={weightedAvgField}
              fieldsList={this.props.fieldsList}
              subFields={weightedSubFields}
              onChange={this.onChangeFieldForWeightedAvg}
            />
          </Col>
        </Row>
      </Col>
    );
  }

  deleteField(fieldIndex){
    this.props.onDeleteField(fieldIndex);
  }
  
  getRowShownAccordingToSelectedAggregation(){
    if(this.props.aggregation === AggregationUtils.WEIGHTED_AVG){
      return this.showWeightFieldForWeightedAvgInput();
    }else if(this.props.aggregation === AggregationUtils.NTH_PERCENTILE){
      return this.showNthPercentileInput();
    }else{
      return null;
    }
  }

  onUnitChange(e){
    this.props.onChangeFieldUnit(e.target.value, this.props.index);
  }

  render(){
    const aggregationValue = this.props.aggregation ? this.props.aggregation: '';
    const attributeOptions = DataSelectionUtils.filterFieldsByAggregationType(this.props.attributeList, aggregationValue);
    const optionalAttributes = attributeOptions.filter(item => item.selectedField.optional).map(
      (item) => {
        return <option value={item.selectedField.id} key={item.selectedField.fieldName}>
          {item.selectedField.fieldName}
          </option>;
      });
    const nonOptionalAttributes = attributeOptions.filter(item => (!item.selectedField.optional && !item.selectedField.hidden)).map(
      (item) => {
        return <option value={item.selectedField.id} key={item.selectedField.fieldName}>
          {item.selectedField.fieldName}
          </option>;
      });
    const categorizedAttributes = UIConstructionUtils.getCategorizedAttributesOptions(
      null, null, null, null, optionalAttributes, nonOptionalAttributes);

    const checkDisabled = (aggregation) => {
      if(this.props.isCountExists) {
        return aggregation === AggregationUtils.COUNT;
      }

      return false;
    };
    const aggregationOptions = UIConstructionUtils.wrapArrayInToSelectOptions(
      this.props.aggregations,
      AGGREGATION_LABEL_ATT_NAME,
      AGGREGATION_VALUE_ATT_NAME,
      AGGREGATION_LABEL_ATT_NAME,
      checkDisabled
    );


    const selectedFieldValue = this.props.selectedField ? this.props.selectedField.id : '';
    const selectedUnit = this.props.fieldUnitFactor;
    const shownIndex = this.props.index + 1;
    const collapsedTitle = defaults.FIELD + ' ' + shownIndex;

    const rowShownAccordingToSelectedAggregation = this.getRowShownAccordingToSelectedAggregation();
    const dummyField = {
      selectedField: this.props.selectedField,
      aggregationType: aggregationValue,
    };

    const collapsedContent =
          <div className='evc-container-sys-bg'>
            <Row className="aggregationInput">
              <OverlayTrigger shouldUpdatePosition
                rootClose placement='bottom' trigger={this.props.child ? [] : ['hover', 'focus']}
                overlay={
                  <Tooltip placement='bottom' className='in' >
                    {defaults.SELECT_AGGREGATION}
                  </Tooltip>
                }
                >
                <Col xs={12} className='margBtm5'>
                  <MultipleSelect
                    disabled={this.props.disableComponent}
                    className='aggSelectAggregation-menu'
                    placeholder={defaults.SELECT_AGGREGATION}
                    options={aggregationOptions}
                    value={aggregationValue}
                    onChange={this.onChangeAggregation}/>
                </Col> 
              </OverlayTrigger>
              {rowShownAccordingToSelectedAggregation}
              { aggregationValue == AggregationUtils.COUNT ? null 
                : <Col xs={12}>
                  <FgaAttributeSelection
                    placeholder={defaults.SELECT_ATTR}
                    className='agg'
                    disabled={this.props.disableComponent}
                    options={categorizedAttributes}
                    attribute={this.props.selectedField}
                    tooltip={defaults.SELECT_ATTR}
                    grouped
                    fieldsList={this.props.fieldsList}
                    onChange={this.onChangeAttribute}
                    subFields={this.props.subFields}
                    supportMultiWholeObject={this.props.supportMultiWholeObject}
                  />
                </Col>
              }
              { this.props.hasUnit
                && this.props.selectedField 
                && (
                  FieldTypes.isNumericField(this.props.selectedField) 
                  || AggregationUtils.isUniqueCount(aggregationValue)) ? 
                <Col xs={12} className='margBtm5'>
                  <UnitConversionDropdown
                    unit={selectedUnit || UnitConversionUtils.DEFAULT_UNIT_DIVIDER}
                    key={`${selectedUnit}-${this.props.selectedField.id}`}
                    baseUnit={DataSelectionCommonUtils.getFieldBaseUnit(dummyField)}
                    dataType={DataSelectionCommonUtils.getFieldDataType(dummyField)}
                    onUnitChange={this.onUnitChange}
                    hideLabel
                    inputSize={12}
                  />
                </Col> : null
              }
            </Row>
          </div>;
    const dataFieldRowButtons = [
      ButtonsConstructor.remove(
        this.deleteField, defaults.DELETE_FIELD,
        this.props.disableComponent
      ),
    ];

    return(
      <CollapsableComponent
        title={collapsedTitle}
        collapsableComponentIndex={this.props.index}
        buttons={dataFieldRowButtons}
        validation={this.props.validationOutcome}
        wrapInRow
      >
        {collapsedContent}
      </CollapsableComponent>
    );
  }
}