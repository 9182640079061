/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import LabeledComponent from 'containers/LabeledComponent';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import { Row } from 'react-bootstrap';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import {FieldTypes, MsgDefaults} from 'js-utils';


export default class MappingFilter extends React.Component {

  constructor(props) {
    super(props);

    this.setMappingField = :: this.setMappingField.bind(this);
    this.filterAttributeOptions = :: this.filterAttributeOptions.bind(this);
  }

  filterAttributeOptions(attributeOptions) {
    if (this.props.selectedNEDataStructure) {
      return attributeOptions.filter(field => field.ref && field.refType.id == this.props.selectedNEDataStructure);
    }

    return attributeOptions.filter(field => field.attributeType &&
      field.attributeType.dataType == this.props.filteredAttributeType
      && field.attributeType.name != FieldTypes.DATE_TIME_FIELD_NAME);
  }

  setMappingField(e) {
    const mappingFieldId = e.target.value;
    const selectedMappingField = this.props.attributeOptions.find(field => field.id == mappingFieldId);

    const newdataSelectionArgs = {
      ...this.props.dataSelectionArgs,
      [this.props.keyword]: {
        ...this.props.mappingFilter,
        selectedField: selectedMappingField,
        operation: 'in',
      },
      routingFilter: null,
    };
    this.props.onChange(newdataSelectionArgs, false, false, false);
  }

  render() {
    const availableFields = this.filterAttributeOptions(this.props.attributeOptions);
    const mappingFields = UIConstructionUtils.wrapArrayInToSelectOptions(
      availableFields,
      'id',
      'id',
      'fieldName');
    return (
      <Row>
        <LabeledComponent label={this.props.label} size={12}
          labelSize={this.props.isAssociation ? 12 : 3}
          inputSize={this.props.isAssociation ? 12 : 9}
          className='mappingFieldLabel'>
          <div className={this.props.isAssociation ? 'evc-nesting-indentation' : ''}>
            <MultipleSelect
              disabled={this.props.disableComponent}
              placeholder={MsgDefaults.getSelectMsg('Mapping Field')}
              className='auto-mapping-menu'
              value={!this.props.hideSelectedData && this.props.mappingFilter ?
                this.props.mappingFilter.selectedField.id : null}
              options={mappingFields}
              onChange={this.setMappingField}
            />
          </div>
        </LabeledComponent>
      </Row>
    );
  }
}