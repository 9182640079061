/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import ReactPaginate from 'react-paginate';
import {Col, Row} from 'react-bootstrap';
import {fromJS} from 'immutable';
import Icons from 'templates/Icons';

const ShowingPerPage = props => {
  const values = [10, 25, 50, 100];
  const options =  values.map((element) => {
    return <option key={element.toString()} value={element} >
    {element}
    </option>;
  });
  const style = props.fixed ? {visibility:'hidden'} : {};

  return (
    <div className="showingPerPage paddingRight10" style={style}>
      <span >Show</span>
      <MultipleSelect
          options={options}
          defaultValue={values[0]}
          value={props.perPage.toString()}
          onChange={props.onPerPage} />

      <span>Entries</span>
    </div>

  );
};
const PaginatorFooter =  props => {
  const onPerPage = (e) => {
    const pagination = fromJS(props.pagination).toJS() || {};

    pagination.perPage = parseInt(e.target.value, 10);
    pagination.page = 0;
    props.onPaginationChange(pagination);
  };
  const onPageChange = (page) => {
    if (props.pagination && props.pagination.page !== page.selected){
      const pagination = fromJS(props.pagination).toJS() || {};

      pagination.page = page.selected;
      props.onPaginationChange(pagination);
    }
  };

  const paginatedLength = props.pagination.perPage;
  const paginatedData = props.paginatedData.data;
  const pageNum = props.paginatedData.amount;
  const currentPage = props.paginatedData.page || 0;
  const startIndex = currentPage * paginatedLength + 1;
  let endIndex = (currentPage + 1) * paginatedLength;

  endIndex = (endIndex <= props.totalAmount) ?
   endIndex : props.totalAmount;
  const shown = paginatedData.length > 0 ? 'inline-block' : 'none';

  return (
    <div className='tablePaginatorFooter' style={{display: shown, width:'100%'}}>
      <Row>
        <Col xs={7}>
          {props.hidePaginationControls ? null
          : <ShowingPerPage
            perPage={paginatedLength}
            onPerPage={onPerPage }
            fixed={props.fixedPagination} />
          }
          <div className='pageLabels' >
            Showing {startIndex} to {endIndex} of {props.totalAmount} entries
            {props.numberOfSelectedRows ? <span>
              &nbsp; - <b>{props.numberOfSelectedRows} Row(s) selected</b>
            </span> : null}
          </div>
        </Col>
        <Col xs={5}>
          {props.hidePaginationControls ? null
          : <ReactPaginate
            nextLabel={<i className={Icons.chevronRight}></i>}
            previousLabel={<i className={Icons.chevronLeft}></i>}
            forcePage={currentPage}
            initialPage={currentPage}
            breakLabel={<span>...</span>}
            pageCount={pageNum}
            marginPagesDisplayed={props.onePagePagination ? 0 : 1}
            pageRangeDisplayed={props.onePagePagination ? 0 : (props.noOfDisplayedPages ? props.noOfDisplayedPages : 3)}
            onPageChange={onPageChange}
            disableInitialCallback
            containerClassName={'pagination container'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'} />
          }
        </Col>
      </Row>
    </div>
  );
};

const Paginator = props => {
  const paginatorFooter = props.loading ? null
  : <PaginatorFooter pagination={props.pagination}
                      paginatedData={props.paginatedData}
                      onePagePagination={props.onePagePagination}
                      totalAmount={props.totalAmount} 
                      numberOfSelectedRows={props.numberOfSelectedRows}
                      onPaginationChange={props.onPaginationChange}
                      fixedPagination={props.fixedPagination}
                      noOfDisplayedPages={props.noOfDisplayedPages}
                      hidePaginationControls={props.hidePaginationControls}/>;

  const result = props.paginated ? <div>
  {props.children}
  {paginatorFooter} </div> : <div>{props.children}</div>;

  return result;
};

export {
  Paginator,
};
