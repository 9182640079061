/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import { ValidationUtils, CompareUtils, UnitConversionUtils, FieldTypes } from 'js-utils';
import * as FFAGDefaults from 'utils/ffagDefaults';
import {getNumericInputValidationSchema, getStringValidationSchema} from './CommonValidationSchema';

const Filter = {
  SELECTED_FIELD: 'Attribute',
  OPERATION: 'Operation',
  VALUE: 'Value',
  RELATION_TO_NEXT_FILTER: 'Relation to next filter',
  FILTER: 'Filter',
};

export const filterValidation = Joi.object()
  .keys({
    selectedAttribute: Joi.object().required()
    .error(ValidationUtils.ERR_MSGS.required(Filter.SELECTED_FIELD)),
    operation: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(Filter.OPERATION))
        .required(),
    compareValue: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(Filter.VALUE))
        .required(),
    lastFilter:  Joi.bool().allow(null),
    relationToNext: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(Filter.RELATION_TO_NEXT_FILTER))
        .when('lastFilter',
        [{
            is: true,
            then: Joi.string().optional().allow(null),
            otherwise: Joi.string().required(),
        }]),
  }).unknown();

const filtersValidation = Joi.object().keys({
  minFilters: Joi.number().optional().allow(null),
  filters: Joi.array()
      .label(Filter.FILTER)
      .error(ValidationUtils.getErrMsgArray(Filter.FILTER))
      .min(Joi.ref('minFilters'))
      .when('validate', [
        { is: true,
        then: Joi.array()
        .items(filterValidation),
      }]),
  validate: Joi.bool(),
}).unknown();

export function validateFilterInput(placeholder) {
  return Joi.object()
    .keys({
      selectedField: Joi.object().required()
        .error(ValidationUtils.ERR_MSGS.required(placeholder)),
      operation: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(Filter.OPERATION))
        .required(),
        compareValue: Joi.any()
        .when('operation', {
          is: Joi.valid(CompareUtils.IN, CompareUtils.NOT_IN),
          then: Joi.string().optional().allow(''),
          otherwise: Joi.any()
            .when('selectedField.type', {
              is: FieldTypes.FieldTypes.Numeric,
              then: getNumericInputValidationSchema({ acceptNegativeNumbers: true, precision: UnitConversionUtils.MAX_PRECISION, required: true }),
              otherwise: getStringValidationSchema(Filter.VALUE, 1),
            }),
        }),
      inOperationFilter: Joi.array()
        .label(`${FFAGDefaults.LABELS.LIST_SELECTION} values`)
        .when('operation', {
          is: Joi.valid(CompareUtils.IN, CompareUtils.NOT_IN),
          then: Joi.array()
          .when('selectedField.type', {
            is: FieldTypes.FieldTypes.Numeric,
            then: Joi.array()
            .items(Joi.object()
               .keys({inValue: getNumericInputValidationSchema({ acceptNegativeNumbers: true, precision: UnitConversionUtils.MAX_PRECISION, required: true })})
               .unknown(true))
              .required(),
            otherwise: Joi.array().required()
          })
            .min(1)
            .max(Joi.ref('esQueryMaxTerms'))
            .error(errors => {
              if(errors[0].code === 'any.required'){
                return ValidationUtils.ERR_MSGS.required(FFAGDefaults.LABELS.LIST_SELECTION);
              }
              return ValidationUtils.getErrMsgArray(FFAGDefaults.LABELS.LIST_SELECTION)(errors);
            }),
        }),
      lastFilter:  Joi.bool().allow(null),
      relationToNext: Joi.string()
        .error(ValidationUtils.ERR_MSGS.required(Filter.RELATION_TO_NEXT_FILTER))
        .when('lastFilter',
        [{
            is: true,
            then: Joi.string().optional().allow(null),
            otherwise: Joi.string().required(),
        }]),
    })
    .unknown();
} 

export function validateFilterInputs(placeholder) {  
  return Joi.object()
    .keys({
      minFilters: Joi.number().optional().allow(null),
      filters: Joi.array()
        .label(Filter.FILTER)
        .error(ValidationUtils.getErrMsgArray(Filter.FILTER))
        .min(Joi.ref('minFilters'))
        .when('validate', [{ 
          is: true,
          then: Joi.array()
            .items(validateFilterInput(placeholder)),
        }])
        .unique((filterA, filterB) => {
          return filterA.selectedField && filterB.selectedField 
          && filterA.selectedField.fieldName === filterB.selectedField.fieldName
          && filterA.operation === filterB.operation 
          && filterA.compareValue === filterB.compareValue;
        })
        .error((errors) => {
          if(errors[0].code === 'array.unique'){
            errors[0].local.label = 'Filters';
 
            return ValidationUtils.getErrMsgArray('Filters')(errors);
          }
 
          return ValidationUtils.getErrMsgArray('Filters')(errors);
        }),
      validate: Joi.bool(),
    })
    .unknown();
}

const SCHEMA = {
  filters: filtersValidation,
  filter: filterValidation,
}

function validator(fieldType, value, schema){
  return ValidationUtils.validateSchema(schema ? schema : SCHEMA)(fieldType, value);
}


export function validateAdvancedFilter(advancedFilters, validation, validationOptions, schema){
  let isValid = true;
  if(advancedFilters){
    if (validationOptions.esQueryMaxTerms) {
      advancedFilters.forEach(filter => {
        filter.esQueryMaxTerms = validationOptions.esQueryMaxTerms;
      });
    }

    if(validationOptions.checkValidation){
      validation.filterInput = {};
      validation.filterInput.filterList = [];
      if(advancedFilters.length > 0){
        advancedFilters[advancedFilters.length - 1].lastFilter = true;
      }
      advancedFilters.forEach((filter, index) => {
        validation.filterInput.filterList[index] = validator('filter', filter, schema);
        isValid = isValid && ValidationUtils.isValid(validation.filterInput.filterList[index]);
      });
    }
    
    if(validationOptions.checkListValidation){
      validation.filterInput.filters = {};
      validation.filterInput.filters = validator('filters', {validate: validationOptions.checkListItemsValidation, 
        filters: advancedFilters,
        minFilters: validationOptions.minFilters,
        }, schema);
      isValid = isValid && ValidationUtils.isValid(validation.filterInput.filters);
    }  
  }
  
  return isValid;
}
