/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import Switch from 'react-toggle-switch';
import ResolutionInput from 'inputs/compound/ResolutionInput';
import PercentileInput from 'inputs/compound/PercentileInput';
import { Row, Col } from 'react-bootstrap';
import { fromJS } from 'immutable';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import * as FFAGDefaults from 'utils/ffagDefaults';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import LabeledComponent from 'containers/LabeledComponent';
import {ResolutionUtils} from 'js-utils';

export default class CollectionAggregation extends React.Component {

  constructor(props) {
    super(props);
    this.onPercentileInputBlur = ::this.onPercentileInputBlur;
    this.onPercentileInputChange = ::this.onPercentileInputChange;
    this.showNthPercentileInput = ::this.showNthPercentileInput;
    this.onCollectionTimeResolutionChange = ::this.onCollectionTimeResolutionChange;
    this.onCollectionTimeAggregationChange = ::this.onCollectionTimeAggregationChange;
    this.onCollectionTimeAggregationToggle = ::this.onCollectionTimeAggregationToggle;
  }

  onCollectionTimeAggregationToggle(){
    let newPropsDataSelection = fromJS(this.props.dataSelectionArgs|| {}).toJS();
    
    newPropsDataSelection = {
      ...newPropsDataSelection,
      ...DataSelectionCommonUtils.resetCollectionAggregation(!this.props.dataSelectionArgs.isCollectionTimeAggsEnabled),
    };
    this.props.onChange(newPropsDataSelection, true, true, true);
  }
  onCollectionTimeAggregationChange(e){
    const newPropsDataSelection = fromJS(this.props.dataSelectionArgs|| {}).toJS();

    newPropsDataSelection.collectionTimeAggregationType = e.target.value;
    if(newPropsDataSelection.collectionTimeAggregationType != FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.NTH_PERCENTILE){
      newPropsDataSelection.collectionTimeAggPercentileValue = null;
    }else{
      newPropsDataSelection.collectionTimeAggPercentileValue = FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.PERCENTILE_DEFAULT;
    }
    this.props.onChange(newPropsDataSelection);
  }
  onCollectionTimeResolutionChange(value){
    const newPropsDataSelection = fromJS(this.props.dataSelectionArgs|| {}).toJS();
    if(ResolutionUtils.isResolutionIntervalMonths(value.unit)){
      newPropsDataSelection.collectionTimeAggregationInterval = ResolutionUtils.MONTHLY_DEFAULT_RESOLUTION;
     }
     else{
      newPropsDataSelection.collectionTimeAggregationInterval = value.interval;
     }
    newPropsDataSelection.collectionTimeAggregationUnit = value.unit;
    this.props.onChange(newPropsDataSelection);
  }

  onPercentileInputBlur(e){
    const newPropsDataSelection = fromJS(this.props.dataSelectionArgs|| {}).toJS();

    newPropsDataSelection.collectionTimeAggPercentileValue = e.target.value;
    this.props.onChange(newPropsDataSelection);
  }

  onPercentileInputChange(e){
    const newPropsDataSelection = fromJS(this.props.dataSelectionArgs|| {}).toJS();

    newPropsDataSelection.collectionTimeAggPercentileValue =  e.target.value;
    this.props.onChange(newPropsDataSelection);
  }

  showNthPercentileInput(aggregationType){
    if(aggregationType === FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.NTH_PERCENTILE){
      return(
        <PercentileInput
          labelSize={6}
          inputSize={6}
          defaultValue={FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.PERCENTILE_DEFAULT}
          disabled={this.props.disableComponent}
          onChange={this.onPercentileInputChange}
          value={this.props.dataSelectionArgs.collectionTimeAggPercentileValue != null ? this.props.dataSelectionArgs.collectionTimeAggPercentileValue :
            FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.PERCENTILE_DEFAULT}
          onBlur={this.onPercentileInputBlur}
         />
       );
    }
  }

  render() {
    const labelMinSize = 6;
    const labelMaxSize = 12;
    const labelSize = this.props.isAssociation? labelMaxSize : labelMinSize;
    const aggregationOptions = UIConstructionUtils.wrapArrayInToSelectOptions(
      this.props.aggregations,'name', 'value', 'name');

    return (
      <Fragment> 
        <Row>
          <LabeledComponent  label={FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.COLLECTION_AGGREGATION} 
            size={labelMaxSize} labelSize={labelMinSize} inputSize={labelMinSize} labelSizeSm={labelMinSize} inputSizeSm={labelMinSize}  componentClassName={'lineHeight'}>
            <Switch on={this.props.dataSelectionArgs.isCollectionTimeAggsEnabled}
              className="pull-right collectionAgg-switch"
              enabled={!this.props.disableComponent}
              onClick={this.onCollectionTimeAggregationToggle}>
              <div className='handle'>
                {this.props.dataSelectionArgs.isCollectionTimeAggsEnabled ? <p>Enabled</p> : <p> Disabled </p> }
              </div>
            </Switch>
          </LabeledComponent>
        </Row>

        {this.props.dataSelectionArgs.isCollectionTimeAggsEnabled ?
          <div className="evc-container-sys-bg collectionAggregationEnabled">
            <Row>
              <Col xs={12} className='margBtm10 '>
                <ResolutionInput
                  isNewNumericInput={true}
                  validation={this.props.validation}
                  disableComponent={this.props.disableComponent}
                  disableInterval={ResolutionUtils.isResolutionIntervalMonths(this.props.dataSelectionArgs.collectionTimeAggregationUnit)}
                  resolution={{
                    interval: this.props.dataSelectionArgs.collectionTimeAggregationInterval ||  FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.TIME_INTERVAL_DEFAULT,
                    unit: this.props.dataSelectionArgs.collectionTimeAggregationUnit || FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.UNIT_DEFAULT,
                  }}
                  placeholder={FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.TIME_INTERVAL_PLACEHOLDER}
                  onResolutionChange={this.onCollectionTimeResolutionChange}
                  />
              </Col>
            </Row>
            <Row>
              <LabeledComponent  label={FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.COLLECTION_AGGREGATION} size={labelMaxSize} labelSizeSm={labelSize} inputSizeSm={labelSize} 
                labelSize={labelSize} inputSize={labelSize} componentClassName={'lineHeight'}>
                <MultipleSelect
                  filter={false}
                  disabled={this.props.disableComponent}
                  className='periodicAgg-menu'
                  placeholder={FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.AGGREGATION_TYPE_PLACEHOLDER}
                  value = {this.props.dataSelectionArgs.collectionTimeAggregationType || FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.TYPE_DEFAULT}
                  onChange={this.onCollectionTimeAggregationChange}
                  options={aggregationOptions} />
                {this.showNthPercentileInput(this.props.dataSelectionArgs.collectionTimeAggregationType)}
              </LabeledComponent>
            </Row>
          </div>
          : null
        }
      </Fragment>
    );
  }
}
