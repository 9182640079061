/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';

const LabeledComponent = (props) => {
  return(
    <Col xs={12} smOffset={props.smOffset || 0} sm={props.size || 6} className={props.componentClassName || ''}>
      <Row className={`data-input ${props.className || ''}`} >
        {
          props.hideLabel || !props.label ? null :
          <Col md={props.labelSize || 3} sm={props.labelSizeSm || 3} className={props.labelClassName} xs={props.labelXsSize || 12}>
            <label className='data-input-label'>
                {props.label}
            </label>
            {props.hint ? props.hint : null }
          </Col>
        }
        <Col md={props.inputSize || 9} sm={props.inputSizeSm || 9} xs={props.inputXsSize || 12} lgOffset={props.inputLargeOffset} className={`data-input-field ${props.inputClassName}`}>
          {props.children}
        </Col>
      </Row>
    </Col>
  );
};

export default LabeledComponent;
