
/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import TopNSettings from 'inputs/compound/TopN/TopNSettings';
import SortbyAggregate from 'inputs/compound/TopN/SortByAggregate';
import topNValidationSchema from 'utils/validations/TopNValidationSchema';

export default class DataSelectionTopNSettings extends React.Component {
  constructor(props) {
    super(props);
    this.topNAggregateChangeHandler = :: this.topNAggregateChangeHandler;
    this.onTopNSettingsChanged = :: this.onTopNSettingsChanged;
    this.validateSettings = :: this.validateSettings;
    this.state = {
      validation: {},
    };
  }

  componentDidMount() {
    window.addEventListener('validateSetting', this.validateSettings);
  }

  componentWillUnmount() {
    window.removeEventListener('validateSetting', this.validateSettings);
  }

  topNAggregateChangeHandler(e) {
    const chosenField = this.props.dataSelectionFga.fields
      .filter(aggregation => UIConstructionUtils.getKeyFromId(aggregation) == e.target.value)[0];

    this.onTopNSettingsChanged({
      topNField: chosenField,
    });
  }

  setTopNSettingsValue = (key) => (e) => {
    if (e.target.value != this.props.topNSettings[key]) {
      this.onTopNSettingsChanged({
        [key]: e.target.value,
      });
    }
  };

  onTopNSettingsChanged(newTopNSettings) {
    const updatedTopNSettings = {
      ...this.props.topNSettings,
      ...newTopNSettings,
    };

    const isFFAGValid = this.props.getSettingsValidation(this.props.dataSelectionFga, this.props.dataSelectionOptions).isValid;
    const topNSettingsValidation = this.getValidation(updatedTopNSettings);

    this.setState({ validation: topNSettingsValidation}, () => {
      this.props.onDataSelectionChange({
        [this.props.topNSettingsKeyword]: updatedTopNSettings,
        isFFAGValid,
      }, isFFAGValid);
    });
  }

  validateSettings(){
    const topNSettingsValidation = this.getValidation(this.props.topNSettings);

    this.setState({ validation: topNSettingsValidation});
  }

  getValidation(topNSettings){
    return{
      nValue: topNValidationSchema('nValue', topNSettings.nValue),
      topNField: topNValidationSchema('topNField', topNSettings.topNField),
    };
  }

  render() {
    return (
      <Fragment>
        <div className='horizontal-divider'></div>
        <div className="expandableArea ">
          <div className='paddingLeft10 paddingRight10'>
            <TopNSettings
              nValue={(this.props.topNSettings || {}).nValue}
              nDirection={(this.props.topNSettings || {}).nDirection}
              setTopNDirection={this.setTopNSettingsValue('nDirection')}
              setTopNValue={this.setTopNSettingsValue('nValue')}
              validation={this.state.validation.nValue}
            />
            <SortbyAggregate
              topNAggregateChangeHandler={this.topNAggregateChangeHandler}
              fields={this.props.dataSelectionFga.fields}
              topNField={(this.props.topNSettings || {}).topNField ? UIConstructionUtils.getKeyFromId(this.props.topNSettings.topNField) : null}
              repeatedAggFieldsData={this.props.repeatedAggFieldsData}
              validation={this.state.validation.topNField}
            />
          </div>
        </div>
        <div className='horizontal-divider'></div>
      </Fragment>
    );
  }
}