(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("js-utils"), require("lodash"), require("react-bootstrap"), require("react-router"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("react-template", ["js-utils", "lodash", "react-bootstrap", "react-router", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["react-template"] = factory(require("js-utils"), require("lodash"), require("react-bootstrap"), require("react-router"), require("react"), require("react-dom"));
	else
		root["react-template"] = factory(root["js-utils"], root["lodash"], root["react-bootstrap"], root["react-router"], root["React"], root["ReactDOM"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__73566__, __WEBPACK_EXTERNAL_MODULE__50467__, __WEBPACK_EXTERNAL_MODULE__22636__, __WEBPACK_EXTERNAL_MODULE__45432__, __WEBPACK_EXTERNAL_MODULE__59787__, __WEBPACK_EXTERNAL_MODULE__55156__) => {
return 