/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'containers/Modal';
import * as FFAGDefaults from 'utils/ffagDefaults';

export default class SavingWidgetModel extends Component {
  constructor(props) {
    super(props);
    this.hideModal = :: this.hideModal;
    this.saveWidget = :: this.saveWidget;
  }


  getButton(title, callback) {
    return [
      <Button
        bsStyle="primary"
        onClick={callback}>
        {title}
      </Button>];
  }
  getFooterButtons() {
    const saveButton = this.getButton('Save', this.saveWidget);

    return saveButton;
  }

  saveWidget() {
    this.props.saveWidget();
  }

  hideModal() {
    this.props.hideModal({ showSavingWidgetModal: false });
  }

  getUsedWidgetsMessage() {
    return <div>
      <label>{FFAGDefaults.SAVING_MODEL_HEADER_TITLE}</label>
      {
        Object.keys(this.props.dataSelectionUsages).map(keyData => {
          return (<div className="paddingLeft20">
            <label> {keyData} </label>
            <ul>
              {
                this.props.dataSelectionUsages[keyData].map(keyMessage => {
                  return <li>{keyMessage}</li>;
                })
              }
            </ul>
          </div>);
        })
      }
    </div>;
  }

  render() {
    return (
      <Modal bsSize="medium"
        className='modelHeaderWarning'
        onHide={this.hideModal}
        title={'Warning'}
        show={this.props.showModal}
        footerButtons={this.getFooterButtons()}>
        {this.getUsedWidgetsMessage()}
      </Modal>
    );
  }

}
