/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import SortableTree from 'react-sortable-tree';
import {Row, Col} from 'react-bootstrap';
import Loader from 'templates/Loader';
import 'web-style-guide/css/tree.less';
import * as TreeHelpers from 'dataSelection/trees/baseTree/TreeHelpers';
import DesignSpaceTreeRenderer from './DesignSpaceTreeRenderer';

/** @deprecated */
class DesignSpaceTree extends Component{
  
  constructor(props){
    super(props);
    this.treeIndex = 0;
    this.performExpansion = false;
    this.dataPrepared = false;
    this.expandedIds =  [];
    this.treeNodesChildern = {};
    const tempState = {
      query: '',
      collapsed: true,
    };
    const treeData = props.treeData ? this.adjustTreeData(props.treeData, tempState)
      : undefined;

    tempState.treeData = treeData;
    this.state = tempState;
    this.generateNodeProps = ::this.generateNodeProps;
    this.onVisibilityToggle = ::this.onVisibilityToggle;
    this.reverseCollapse = :: this.reverseCollapse;
    this.addNodeToTreeData = :: this.addNodeToTreeData;
    this.appendChildren = :: this.appendChildren;
    this.onChange = ::this.onChange;
  }

  componentDidUpdate(){
    if(this.ref){
      this.ref.wrappedInstance.recomputeRowHeights() ;
      this.ref.wrappedInstance.scrollToRow(this.treeIndex);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.treeData == undefined){
      this.expandedIds = [];
    }
    if (this.props.treeData == undefined && nextProps.treeData){
      this.performExpansion = true;
    }
    if (nextProps.updatedExpandedIds && this.props.updatedExpandedIds
      && nextProps.updatedExpandedIds.actionType
      && !_.isEqual(nextProps.updatedExpandedIds,  this.props.updatedExpandedIds)){
        this.expandedIds = this.props.updateExpandable(this.expandedIds, nextProps.updatedExpandedIds);
    }
    if(!_.isEqual(this.props.treeData, nextProps.treeData) || !this.dataPrepared){
      const treeData = nextProps.treeData ?
        this.adjustTreeData(nextProps.treeData) : undefined;
      this.setState({
        treeData,
      });
    }
    this.dataPrepared = true;
  }

  componentWillUnmount() {
    if(this.props.saveTreeData){
      this.props.saveTreeData(this.state.treeData, this.expandedIds);
    }
  }

  componentWillMount() {
      const treeData = this.props.savedTreeData;
      if (treeData) {
          this.setState({
              treeData: treeData,
          });
          this.expandedIds = this.props.savedExpandedIds;
          this.dataPrepared = true;
      }
  }


  onVisibilityToggle({treeData, node, expanded, treeIndex}){
    this.treeIndex = treeIndex;

    const index = this.expandedIds.indexOf(node.nodePos);

    if(index > -1){
      this.expandedIds.splice(index, 1);
    } else{
      this.expandedIds.push(node.nodePos);
    }
    const value = this.props.onVisibilityToggle({
      treeData,
      node,
      expanded,
      query: this.state.query
    });

    if(!value){
      this.setState({
        expandedIds : this.expandedIds,
      });
    }else{
      this.dataPrepared = false;
    }
  }

  onChange(treeData){
    if(this.dataPrepared){
      this.setState({treeData});
    }
  }

  generateNodeProps(){
    return {
      id: this.props.id,
      buttons: this.props.buttons,
      create: this.props.create,
      showButton: this.props.showButton,
      onClick: this.props.onNodeClick,
    };
  }


  adjustTreeData(treeNodes, state){
    let treeData = [];

    if(treeNodes){
      treeNodes.forEach((treeNode, index) => {
        this.treeNodesChildern[treeNode.id] = [];
        treeData.push({
          ...treeNode,
          title: TreeHelpers.getNodeTitle(treeNode),
          children:[],
          expanded : this.expandedIds.indexOf('' + index) > -1,
          nodePos: '' + index,
          isLeaf: treeNode.leaf,
          isRoot: true,
        });
        this.appendChildren(treeNode.children,
          '' + index, treeData, state || this.state);
      });
    }
    if(this.performExpansion){
      treeData = this.adjustTreeDataExpandedNodes(treeData, (state || this.state).query, (state || this.state));
      this.performExpansion = false;
    }
    return treeData;
  }


  adjustTreeDataExpandedNodes(treeData, searchQuery, state){
    treeData.forEach((treeNode) => {
      this.expandNodeChildren(treeNode, searchQuery, state);
    });

    return treeData;
  }

  expandNodeChildren(treeNode, searchQuery, state){
    let expanded = false;

    treeNode.children.forEach((child) => {
      if(this.expandNodeChildren(child, searchQuery)){
        treeNode.expanded = true;
        if(this.expandedIds.indexOf(treeNode.nodePos) == -1){
          this.expandedIds.push(treeNode.nodePos);
        }
        expanded = true;
      }
    });
    return expanded;
  }

  appendChildren(treeNodes, nodePos, treeData, state){
    treeNodes.forEach((treeNode, index) => {
      const childPosition = nodePos + '-' + index;

      if(treeNode.children.length != 0 ){
        this.addNodeToTreeData(treeData, treeNode, childPosition);
        this.appendChildren(treeNode.children, childPosition, treeData, state);
      } else {
        this.addNodeToTreeData(treeData, treeNode, childPosition);
      }
    });
  }

  addNodeToTreeData(treeData, treeNode, nodePos){
    const nodePosition = nodePos.split('-');
    let root = treeData[nodePosition[0]];

    let index = 1;

    while(index < nodePosition.length - 1){
      root = root.children[nodePosition[index]];
      index++;
    }
    root.children[nodePosition[index]] = {
      ...treeNode,
      title: TreeHelpers.getNodeTitle(treeNode),
      children:[],
      expanded : this.expandedIds.indexOf(nodePos) > -1,
      nodePos,
      isLeaf: treeNode.leaf != undefined ? treeNode.leaf : this.props.isLeaf(treeNode),
    };
  }

  reverseCollapse() {
    const collapsed = !this.state.collapsed;
    this.setState({
      collapsed
    });
  }

  render(){
    return(
      <Row className='eventumTree designSpacesTree'>
        <Col xs={12}>
            {!this.state.treeData ? <Loader /> :
              this.state.treeData.length == 0 ?
              <div className='empty-search-row' > No Data Available</div> :
            <SortableTree treeData={this.state.treeData}
                          onVisibilityToggle={this.onVisibilityToggle}
                          nodeContentRenderer={DesignSpaceTreeRenderer}
                          generateNodeProps={this.generateNodeProps}
                          scaffoldBlockPxWidth={0}
                          onChange={this.onChange}
                          rowHeight={28}
                          reactVirtualizedListProps={
                            {height:450,
                            scrollToIndex: this.treeIndex,
                            scrollToAlignment: 'auto',
                          ref :  ref => this.ref = ref }
                        } />

            }
        </Col>
      </Row>
    );
  }

}
export default DesignSpaceTree; 
