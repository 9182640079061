/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {OverlayTrigger, Tooltip, Button} from 'react-bootstrap';

const TooltippedButton = props =>
<OverlayTrigger shouldUpdatePosition
  rootClose placement={props.placement ? props.placement : 'bottom'} trigger={props.disabled ? [] : ['hover', 'focus']}
  overlay={
    <Tooltip placement="bottom" className="in" >
    {props.tooltip}
    </Tooltip>
  }
>
  <Button {...props}>
    {props.children}
  </Button>
</OverlayTrigger>;

export default TooltippedButton;
