/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import {Row, Col } from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import Icons from 'templates/Icons';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as filterUtils from 'utils/filterUtils';

const NUMBER_OF_SHOWN_SELECTIONS =  3;

export default class TreeItemView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.renderFilterOfTypeRef = :: this.renderFilterOfTypeRef;
    this.selectedItems = :: this.selectedItems;
    this.getHiddenSelectionsCount = :: this.getHiddenSelectionsCount;
    this.openSelection = :: this.openSelection;
  }

  selectedItems(){
    let shownItems = [];
    const selection = this.props.selection;

    if(selection.treeNodes && selection.treeNodes.length > 0){
      shownItems = selection.treeNodes.filter(treeNode => treeNode.treeNodePath).map(treeNode => {
        return (
          <li className='NE-listItem'>
           {treeNode.treeNodePath}
          </li>
         );
      });
    }else if (selection.organizationGroups && selection.organizationGroups.length > 0) {
      shownItems = selection.organizationGroups.slice(0, NUMBER_OF_SHOWN_SELECTIONS).map(organizationGroup => {
        return (
          <li>
           {organizationGroup.name}
          </li>
         );
      });
    } else if(selection.selectedNodePath){
      shownItems = [(
        <li className='NE-listItem'>
         {selection.selectedNodePath}
        </li>
       )];
    }
    if(this.props.maxShownSelections && this.props.maxShownSelections < shownItems.length ){
      shownItems = shownItems.slice(0, this.props.maxShownSelections);
    }

    return  shownItems;
  }

  getHiddenSelectionsCount(){
    if(this.props.selection){
      const selection = this.props.selection;
      const maxSelections = this.props.maxShownSelections ? this.props.maxShownSelections : NUMBER_OF_SHOWN_SELECTIONS;
      
      if(selection.treeNodes && selection.treeNodes.length > maxSelections){
        return selection.treeNodes.length - maxSelections;
      }else if (selection.organizationGroups && selection.organizationGroups.length > maxSelections) {
        return selection.organizationGroups.length - maxSelections;
      }
    }

    return 0;
  }

  openSelection(){
    this.props.openSelection(this.props.selection);
  }
  
  getNonRefName(isRefPlaceHolder, isRefVariable){
    if(isRefPlaceHolder){
      return this.props.selection.nePlaceholder.name;
    }else if(isRefVariable){
      return this.props.variable?.name;
    }
    return  this.props.componentPlaceholder;
  }

  renderFilterOfTypeRef(){
    const selectionCount = this.getHiddenSelectionsCount();
    const isRefVariable = filterUtils.isVariableFilter(this.props.filterValueType);
    const isRefPlaceHolder = this.props.selection && this.props.selection.nePlaceholder;

    const selectedItemsComp = this.selectedItems();

    const btnContent = UIConstructionUtils.getCounterIcon(
      (isRefPlaceHolder ? Icons.placeholder : Icons.tree), 
      selectionCount, undefined, ' ', 'selectionCount');
    
    return (
        <div className="treeSelections">
          <Row>
          {this.props.showNEDS ?
            <Col xs={12} className="margBtm10">
              <label>Network Element:</label>
              <div className="selectionList NE-list">
                {this.props.selection.networkElementDataStructure.treePath || this.props.selection.networkElementDataStructure.name}
              </div>
            </Col> : null}

            <Col xs={12}>
              {this.props.showSelectionLabel ? <label>Members:</label> : null}
              <ul className="selectionList NE-list">
                {
                  (this.props.selection && selectedItemsComp.length > 0) ? 
                    selectedItemsComp 
                  : <li>
                      {this.getNonRefName(isRefPlaceHolder, isRefVariable)}
                    </li>
                }
              </ul>
            </Col>
          </Row>
          {
            this.props.openSelection ? 
              <TooltippedButton
                className="btn-primary tree-btn filterSelectNE-btn"
                tooltip={this.props.componentPlaceholder}
                disabled={this.props.disableComponent}
                onClick={ this.openSelection}>
                {btnContent}
              </TooltippedButton> 
            : <div className='tree-btn'> {btnContent} </div>
          }
        </div>
    );
  }

  render() {
    return (      
      <Fragment> 
        {this.renderFilterOfTypeRef()}
      </Fragment>
    );
  }
}