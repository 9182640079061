/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import {Row, Col, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import FilterInputRow from './FilterInputRow';
import {fromJS} from 'immutable';
import * as defaults from 'utils/defaults';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import CollapsableComponent from 'containers/CollapsableComponent';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import * as FilterUtils from 'utils/filterUtils';
import {CompareUtils, AggregationUtils, FieldTypes, 
  DateTimeUtils} from 'js-utils';
import { deleteFilter, clearFilterOnChangeAttribute } from 'utils/DataSelectionCommonUtils';
import ValidationOutput from 'containers/ValidationOutput';

export default class FilterInput extends React.Component{

  constructor(props){
    super(props);

    this.addFilter = this.addFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.onChangeAttribute = this.onChangeAttribute.bind(this);
    this.onChangeOperation = this.onChangeOperation.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeVariableName = this.onChangeVariableName.bind(this);
    this.onChangeToggle = this.onChangeToggle.bind(this);
    this.getToggleButton = this.getToggleButton.bind(this);
    this.getFilterInputRow = this.getFilterInputRow.bind(this);
    this.getActiveClassForAnd = this.getActiveClassForAnd.bind(this);
    this.getActiveClassForOr = this.getActiveClassForOr.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.updateDateFilter = this.updateDateFilter.bind(this);
    this.onChangeFilterValueType = this.onChangeFilterValueType.bind(this);
    this.onChangePlaceholder = this.onChangePlaceholder.bind(this);
    this.updateListFilter = this.updateListFilter.bind(this);
    this.updateFilterItem = this.updateFilterItem.bind(this);
  }

  addFilter(){
    const filters = fromJS(this.props.filters || []).toJS();

    if(filters.length > 0){
      filters[filters.length - 1].lastFilter = false;
    }
    filters.push({
      idCounter: DateTimeUtils.getCurrentDateTimeinMillieSeconds(),
      selectedField: null,
      field: null,
      operation: null,
      compareValue: '',
      templatesPlaceholder: null,
      placeholderOptions: [],
      filterValueType: FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value,
      relationToNext:null,
      selectedAttribute: null,
      selection: null,
      lastFilter: true,
    });
    this.props.updateFiltersList({filters}, false, true, false);
  }

  deleteFilter(index){
    let filters = fromJS(this.props.filters).toJS();
    const isPlaceholdersChanged = FilterUtils.isPlaceholderFilter(filters[index].filterValueType);

    filters = deleteFilter(filters, index);
    this.props.updateFiltersList({filters}, true, true, true, {isPlaceholdersChanged});
  }

  onChangeAttribute(attribute, index){
    if(!_.isEqual(this.props.filters[index].selectedAttribute, attribute)){
      const filterUpdates = {};

      if(this.props.selectionAdvancedFilters){
        filterUpdates.selectedAttribute = attribute;
      }else if(this.props.isPostAggregationFilter){
        filterUpdates.field = attribute;
      }else{
        filterUpdates.selectedField = attribute;
      }
      clearFilterOnChangeAttribute(filterUpdates);

      const isPlaceholdersChanged = FilterUtils.isPlaceholderFilter(this.props.filters[index].filterValueType);

      this.updateFilterItem(filterUpdates, index, {isPlaceholdersChanged});
    }
  }

  onChangeOperation(operation, index){
    if(!_.isEqual(this.props.filters[index].operation, operation)){ 
      const filterUpdates = {
        operation,
      };

      if(CompareUtils.LIST_OPERATORS.includes(operation)){
        filterUpdates.compareValue = '';
      } else {
        filterUpdates.inOperationFilter = undefined;
      }
      const extraOptions = {};
      if(CompareUtils.comparatorsNeedsToBeReset.includes(operation) || 
        CompareUtils.comparatorsNeedsToBeReset.includes(filterUpdates.operation)){        
        filterUpdates.filterValueType = FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value;
        filterUpdates.templatesPlaceholder = null;
        filterUpdates.variable = null;
        extraOptions.isPlaceholdersChanged = FilterUtils.isPlaceholderFilter(this.props.filters[index].filterValueType);
      }
    
      this.updateFilterItem(filterUpdates, index, extraOptions);
    }
  }

  onChangeValue(value, index){
    this.updateFilterItem({
      compareValue: value,
      templatesPlaceholder : null,
      variable: null,
      filterValueType: FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value,
    }, index, {isPlaceholdersChanged: true});
  }

  onChangeVariableName(name, index){
    if(!_.isEqual(this.props.filters[index].variable?.name, name)){
      const filterUpdates = {
        variable: {name},
        templatesPlaceholder: null,
        selection: null,
        compareValue: '',
        filterValueType: FilterUtils.FILTER_TYPE_OPTIONS.VARIABLE.value,
      };

      if(this.props.filters[index].selectedField?.ref){
        filterUpdates.operation = defaults.refFilterOperation;
      }
      this.updateFilterItem(filterUpdates, index);
    }
  }

  updateDateFilter(filter, index){    
    filter.operation = defaults.dateFilterOperation;
    this.updateFilterItem(filter, index);
  }

  updateFilterItem(filter, index, extraOptions) {
    const filters = fromJS(this.props.filters).toJS();

    filters[index] = {
      ...filters[index],
      ...filter,
    };
    
    this.props.updateFiltersList({filters}, true, true, true, extraOptions);
  }

  updateListFilter(list, extraParams) {
    this.updateFilterItem({
      inOperationFilter : list,
      operation : extraParams.operation,
    }, extraParams.index);
  }

  updateSelection(selection, index){
    this.updateFilterItem({
      variable: null,
      selection,
      operation : defaults.refFilterOperation,
      filterValueType: FilterUtils.getFilterValueType(selection.selectionType),
    }, index, {isPlaceholdersChanged: true});
  }

  onChangeToggle(value, index){
    if(!this.props.disableComponent){
      this.updateFilterItem({
        relationToNext : value,
      }, index);
    }
  }

  getActiveClassForOr(activeBtn) {
    const className = activeBtn == 'OR' ? 'active' : '';

    return className;
  }

  getActiveClassForAnd(activeBtn) {
    const className = activeBtn == 'AND' ? 'active' : '';

    return className;
  }

  getToggleButton (index, item){
    // https://github.com/react-bootstrap/react-bootstrap/issues/2734
    const activeAnd = this.getActiveClassForAnd(item.relationToNext);
    const activeOr = this.getActiveClassForOr(item.relationToNext);

    const toggleButton = (
      <div className="text-center margTop10">
        <ToggleButtonGroup
          type="radio"
          value={item.relationToNext}
          name="options"
          className="tglBtnGrp ev-auto-tglBtnGrp"
          disabled={this.props.disableComponent} >
          <ToggleButton className={`${activeAnd}`} value={'AND'} disabled={this.props.disableComponent}
            onClick={() => {
              this.onChangeToggle('AND', index); }}
          > And </ToggleButton>
          <ToggleButton className={`${activeOr}`} value={'OR'} disabled={this.props.disableComponent}
            onClick={() => {
              this.onChangeToggle('OR', index); }}
          > Or </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );

    return toggleButton;
  }

  onChangeFilterValueType(index, type){
    const filters = fromJS(this.props.filters).toJS();

    if(filters[index].filterValueType != type){
      filters[index].filterValueType = type ;
      this.props.updateFiltersList({filters}, true, true, true);
    }
  }

  onChangePlaceholder(index, templatesPlaceholder){
    this.updateFilterItem({
      templatesPlaceholder,
      filterValueType: FilterUtils.FILTER_TYPE_OPTIONS.PLACEHOLDER.value,
      compareValue: '',
      variable: null,
    }, index, {isPlaceholdersChanged: true});
  }

  getFilterInputRow (index, item){
    if(!CompareUtils.LIST_OPERATORS.includes(item.operation)){
      item.inOperationFilter = undefined;
    }

    const field = this.props.isPostAggregationFilter ? item.field : item.selectedField;
    const attribute = this.props.selectionAdvancedFilters ? item.selectedAttribute : field;
    const selectedField = this.props.isPostAggregationFilter ? (field ? field.selectedField : null) : field;
    return (
      <Row>
        <FilterInputRow
          selectedAttributePlaceholder={this.props.selectedAttributePlaceholder}
          disableAutoComplete={this.props.disableAutoComplete}
          comparators={this.props.comparators}
          getAutoCompleteSuggestions={this.props.getAutoCompleteSuggestions}
          isPostAggregationFilter={this.props.isPostAggregationFilter}
          key={UIConstructionUtils.getKeyFromId(item)}
          fieldsList={this.props.attributesList}
          index={index}
          deleteFilter={this.deleteFilter}
          onChangeAttribute={this.onChangeAttribute}
          onChangeOperation={this.onChangeOperation}
          onChangeValue={this.onChangeValue}
          updateFilterItem={this.updateFilterItem}
          attribute={attribute}
          operation={item.operation}
          compareValue={item.compareValue}
          relationToNext={item.relationToNext}
          selectionAdvancedFilters={this.props.selectionAdvancedFilters}
          validationOutcome={this.props.validationOutcome && this.props.validationOutcome.filterList ? this.props.validationOutcome.filterList[index] : null}
          getSelectionFinalForm={this.props.getSelectionFinalForm}
          listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
          getRoots={this.props.getRoots}
          refFilterTreeOptions={this.props.refFilterTreeOptions}
          updateSelection={this.updateSelection}
          handleDateFilterChange={this.updateDateFilter}
          handleListFilter={this.updateListFilter}
          selection={item.selection}
          timeRanges={item.timeRanges}
          resolution={item.resolution}
          selectedDays={item.selectedDays}
          dayOfMonth={item.dayOfMonth}
          isRefFilter={field && !AggregationUtils.isUniqueCount(field.aggregationType) && selectedField ? selectedField.ref : false}
          isDateFilter={FieldTypes.isDateField(selectedField)}
          filterBasicType={FilterUtils.getFilterBasicType(selectedField)}
          networkElementDataStructure={selectedField ? selectedField.refType : null}
          disableDelete={item.disableDelete}
          disableChange={item.disableChange}
          disableComponent={this.props.disableComponent}
          isQuickFilter={this.props.isQuickFilter}
          listPlaceholdersByTypeAction={this.props.placeholderOptions}
          onChangePlaceholder={this.onChangePlaceholder}
          onChangeFilterValueType={this.onChangeFilterValueType}
          filterValueType={item.filterValueType}
          templatePlaceholder={item.templatesPlaceholder}
          isTemplate={this.props.isTemplate}
          getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
          inOperationFilter={item.inOperationFilter}
          serverTimeZone={this.props.serverTimeZone}
          variable={item.variable}
          hasVariables={this.props.hasVariables}
          onChangeVariableName={this.onChangeVariableName}/>
      </Row>
    );
  }

  getUncollapsableFilters(fieldsFilter, buttons) {
    return (
      <React.Fragment>
        <div className='ev-auto-addFilterWrapper'>
          {ButtonsConstructor.renderTooltippedButtons(buttons)}
          <ValidationOutput validation={this.props.validationOutcome.filters}
                            className='margLft5 ev-auto-addFilterValidation' />
        </div>
        <Row>
          <Col xs={12}>
            {fieldsFilter}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render(){
    const fieldsFilter = this.props.filters?.map((item, index) => {
      if(index != this.props.filters.length - 1){
        return(
          <Fragment>
            {this.getFilterInputRow(index, item)}
            {this.getToggleButton(index, item)}
          </Fragment>
        );
      } 

      return  this.getFilterInputRow(index, item);
     });
     const buttons = [
      ButtonsConstructor.add(
        this.addFilter,          
        defaults.ADD_FILTER,
        this.props.disableComponent || (!this.props.disableComponent && (this.props.maxFilters && fieldsFilter.length === this.props.maxFilters)),
      ),
    ];

    if (this.props.isFormFormatted) {
      return this.getUncollapsableFilters(fieldsFilter, buttons);
    }

    return (
      <CollapsableComponent
        title={this.props.selectionAdvancedFilters ? defaults.ADVANCED_FILTER :  `Filters`}
        validation={this.props.validationOutcome ? this.props.validationOutcome.filters : {}}
        wrapInRow
        buttons={buttons}
      >
        {fieldsFilter}
      </CollapsableComponent>
    );
  }
}
