/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import CommonTreeNodeRenderer from './CommonTreeNodeRenderer';
import SortableTree from 'react-sortable-tree';
import Searchbar from 'dataVisualization/Table/utils/Searchbar';
import TooltippedButton from 'buttons/TooltippedButton';
import {Row, Col} from 'react-bootstrap';
import Loader from 'templates/Loader';
import 'web-style-guide/css/systemTree.less';
import * as TreeNodesStructureHelper from './TreeNodesStructureHelper';
import { fromJS } from 'immutable';
import ValidationOutput from 'containers/ValidationOutput';
import Icons from 'templates/Icons';

export default class CommonTree extends Component{

  constructor(props){
    super(props);
    this.treeIndex = 0;
    this.performExpansion = false;
    this.dataPrepared = !props.entityOptions.lazyLoading ;
    this.expandedIds =  [];
    this.treeNodesChildern = {};
    this.treeNodesPaths = {};

    const selectedTreeData = this.getSelectedTreeData(props);
    const stateTemp = {
      ...selectedTreeData,
      searchQuery: props.searchQuery || '',
      collapsed: true,
      triggerSearch: false,
    };

    this.state = this.loadState(stateTemp, props);
 
    this.generateNodeProps = ::this.generateNodeProps;
    this.onSearch = :: this.onSearch;
    this.onVisibilityToggle = ::this.onVisibilityToggle;
    this.onSelect = ::this.onSelect;
    this.onChange = ::this.onChange;
    this.onNodeClick = ::this.onNodeClick;
    this.getLeafChildrenNodesIds = :: this.getLeafChildrenNodesIds;
    this.reverseCollapse = :: this.reverseCollapse;
    this.getCaret = :: this.getCaret;
    this.getNodeIdentifier = :: this.getNodeIdentifier;
  }

  componentWillReceiveProps(nextProps){
    const stateTemp = {};
    if(!_.isEqual(this.props.selectedNetworkElementName, nextProps.selectedNetworkElementName)
        || this.props.selectedGroup !== nextProps.selectedGroup
        || !_.isEqual(this.props.advancedFilters, nextProps.advancedFilters)){
      this.treeNodesChildern = {};
      this.treeNodesPaths = {};
    }

    if(!_.isEqual(this.props.searchQuery, nextProps.searchQuery)){
      this.treeNodesChildern = {};
    }
    if(nextProps.treeData == undefined){
      this.expandedIds = [];
    }else if (this.props.treeData == undefined && nextProps.treeData){
      this.performExpansion = true;
    }

    let isStateChanged = false;

    if(!_.isEqual(this.state.dynamicallySelectedTreeNodes, nextProps.dynamicallySelectedTreeNodes)
    || !_.isEqual(this.state.staticallySelectedTreeNodes, nextProps.staticallySelectedTreeNodes)
    || !_.isEqual(this.state.selectedOrganizationGroups, nextProps.selectedOrganizationGroups)){
      Object.assign(stateTemp, this.getSelectedTreeData(nextProps));
      isStateChanged = true;
    }
    
    if(!_.isEqual(this.props.treeData, nextProps.treeData) || !this.dataPrepared){
      this.loadState(stateTemp, nextProps);
      isStateChanged = true;
    }

    if(isStateChanged){
      this.setState(stateTemp);
    }

    this.dataPrepared = true;
  }

  componentDidUpdate(){
    if(this.ref){
      this.ref.wrappedInstance.recomputeRowHeights();
      this.ref.wrappedInstance.scrollToRow(this.treeIndex);
    }
  }
  
  getSelectedTreeData(props){
    return {
      staticallySelectedTreeNodes: props.staticallySelectedTreeNodes,
      dynamicallySelectedTreeNodes: props.dynamicallySelectedTreeNodes,
      selectedOrganizationGroups: props.selectedOrganizationGroups,
      selectedOrganizationGroupsData: props.selectedOrganizationGroupsData,
    };
  }

  adjustSelectionTreeData(props, stateTemp){
    let treeInfo = {};

    if(props.treeDataRoot){
      treeInfo.treeData = fromJS(this.state.treeData).toJS();
      const root = TreeNodesStructureHelper.getNode(treeInfo.treeData, props.treeDataRoot.nodePos);

      TreeNodesStructureHelper.appendChildren(this, props.treeData, root,
        props.treeDataRoot.nodePos, this.expandedIds, root.path);
      // Build tree data to can handle static selections
      treeInfo = TreeNodesStructureHelper.buildTreeDataWithSelections(this, props.staticallySelectedTreeNodes, treeInfo.treeData);
    }else {
      treeInfo = TreeNodesStructureHelper.buildTreeDataWithSelections(this, props.staticallySelectedTreeNodes, props.treeData);
    }   
    stateTemp.staticallySelectedTreeNodes = treeInfo.staticallySelectedTreeNodes || stateTemp.staticallySelectedTreeNodes || [];

    return treeInfo.treeData;
  }
  
  adjustTreeData(props){
    let treeData;

    if(!props.treeDataRoot){
      treeData = TreeNodesStructureHelper.buildTreeData(this, props.treeData);
    }else{
      treeData = fromJS(this.state.treeData).toJS();
      const root = TreeNodesStructureHelper.getNode(treeData, props.treeDataRoot.nodePos);
  
      TreeNodesStructureHelper.appendChildren(this, props.treeData, root,
        props.treeDataRoot.nodePos, this.expandedIds, root.path);
    }

    return treeData;
  }

  loadState(stateTemp, props){
    if(!!props.entityOptions.lazyLoading || props.entityOptions.forceAdjustTreeData){
      let treeData;
      
      if(props.treeData){
        if(!!props.entityOptions.dynamicOrStaticSelection){
          treeData = this.adjustSelectionTreeData(props, stateTemp);
        }else{
          treeData = this.adjustTreeData(props);
        }
      }
      stateTemp.treeData = treeData;
    }else {
      stateTemp.treeData = props.treeData;
    }
    if((this.performExpansion || props.forceExpand) && props.entityOptions.expandOnLoad){
      stateTemp.treeData = TreeNodesStructureHelper.adjustTreeDataExpandedNodes(this, stateTemp.treeData,
        props, stateTemp.staticallySelectedTreeNodes);
      this.performExpansion = false;
      if(props.forceExpand){
        this.props.resetForceExpand();
      }
    }
    return stateTemp;
  }

  onSearch(searchQuery){
    this.treeIndex = 0;
    this.setState({searchQuery});
    this.props.onSearch(searchQuery);
  }

  onVisibilityToggle({node, expanded, treeIndex, path}){
    this.treeIndex = treeIndex;
    const nodePos = node.path || path.join('-');
    const index = this.expandedIds.indexOf(nodePos);

    if(index > -1){
      this.expandedIds.splice(index, 1);
    } else{
      this.expandedIds.push(nodePos);
    }

    const value = this.props.onVisibilityToggle({node, expanded});

    if(value){
      this.dataPrepared = false;
    }
  }

  onNodeClick(node, treeIndex, path){
    this.treeIndex = treeIndex;
    this.props.onClick(node, path);
  }

  generateNodeProps(){
    return {
      id: this.props.entityOptions.nodeId,
      searchQuery: this.state.searchQuery,
      onSelect: this.onSelect,
      canBeHighlighted: this.props.canHighlight || this.props.entityOptions.treeHelper.canBeHighlighted,
      getTitle: this.props.getTitle,
      getIcon: this.props.getIcon,
      getNodeIdentifier: this.getNodeIdentifier,
      getLeafChildrenNodesIds: this.getLeafChildrenNodesIds,
      dynamicallySelectedTreeNodes: this.state.dynamicallySelectedTreeNodes,
      staticallySelectedTreeNodes: this.state.staticallySelectedTreeNodes,
      treeNodesPaths: this.treeNodesPaths,
      selectedOrganizationGroups: this.state.selectedOrganizationGroups,
      onClick: this.onNodeClick,
      isNE: this.props.entityOptions.treeHelper.isNE,
      isOG: this.props.entityOptions.treeHelper.isOG,
      isSelected: this.props.isSelected,
      selectedGroup: this.props.selectedGroup,
      getNodeActions: this.props.getNodeActions,
      dynamicOrStaticSelection: this.props.entityOptions.dynamicOrStaticSelection,
      disabled: this.props.disabled,
    };
  }

  getLeafChildrenNodesIds(id){
    return TreeNodesStructureHelper.getLeafChildrenNodesIds(this.treeNodesChildern, id);
  }

  filterNoTreeNodesChildren(selectedNodes, nodePath){
    return selectedNodes.filter(nodeId => {
      return !TreeNodesStructureHelper.filterParentToSelectedPaths(this.treeNodesPaths[nodeId], nodePath);
    });
  }

  handleDynamicNodeSelection(stateTemp, id, nodePath){
    stateTemp.dynamicallySelectedTreeNodes = this.filterNoTreeNodesChildren(stateTemp.dynamicallySelectedTreeNodes, 
      nodePath);
    stateTemp.staticallySelectedTreeNodes = this.filterNoTreeNodesChildren(stateTemp.staticallySelectedTreeNodes, 
      nodePath);
    // Add node to dynamicallySelectedTreeNodes
    stateTemp.dynamicallySelectedTreeNodes.push(id);
  }

  handleStaticNodeSelection(stateTemp, nodePath, childTreeNodes){
    stateTemp.dynamicallySelectedTreeNodes = this.filterNoTreeNodesChildren(stateTemp.dynamicallySelectedTreeNodes, 
      nodePath);
    stateTemp.staticallySelectedTreeNodes = [...new Set([...stateTemp.staticallySelectedTreeNodes, ...childTreeNodes])];
  }

  handleNewStaticNodeSelection(stateTemp, nodePath, childTreeNodes, id){
    if(childTreeNodes.includes(id) && childTreeNodes.length == 1){
      stateTemp.staticallySelectedTreeNodes = this.filterNoTreeNodesChildren(stateTemp.staticallySelectedTreeNodes, 
        nodePath);
    }
    this.handleStaticNodeSelection(stateTemp, nodePath, childTreeNodes);
  }


  onSelectOG(stateTemp, id, nodePath, title){
    const index =  stateTemp.selectedOrganizationGroups.indexOf(id);

    if(index > -1){// In case node was selected 
      stateTemp.selectedOrganizationGroups.splice(index, 1);
      stateTemp.selectedOrganizationGroupsData = stateTemp.selectedOrganizationGroupsData.filter(og => 
        og.organizationGroupId != id);
    } else{
      stateTemp.selectedOrganizationGroups = this.filterNoTreeNodesChildren(stateTemp.selectedOrganizationGroups, 
        nodePath);
      stateTemp.selectedOrganizationGroupsData = stateTemp.selectedOrganizationGroupsData.filter(og => 
        stateTemp.selectedOrganizationGroups.includes(og.organizationGroupId));

      stateTemp.selectedOrganizationGroups.push(id);
      stateTemp.selectedOrganizationGroupsData.push({
        organizationGroupId: id,
        name: title,
        dynamic: true,
      });
    }
  }

  onSelectNewNodes(stateTemp, id, isDynamic, childTreeNodes, treeNode, nodePath){
    if(isDynamic){ // In case node will be dynamic
      this.handleDynamicNodeSelection(stateTemp, id, nodePath);
    }else{ // In case node will be static
      if(treeNode.dataStructureName === this.props.selectedNetworkElementName){
        stateTemp.staticallySelectedTreeNodes.push(id);
      }else{
        this.handleNewStaticNodeSelection(stateTemp, nodePath, childTreeNodes, id);
      }
    }
  }

  onSelectPreviouslyDynamicNodes(stateTemp, id, isDynamic, indexDynamic, childTreeNodes, treeNode){
    // Remove it from dynamially selected nodes
    stateTemp.dynamicallySelectedTreeNodes.splice(indexDynamic, 1);
    if(!isDynamic){ // In case node will be static
      // Add this node and its children 
      if(treeNode.dataStructureName === this.props.selectedNetworkElementName){
        stateTemp.staticallySelectedTreeNodes.push(id);
      }else{
        stateTemp.staticallySelectedTreeNodes = [...new Set([...stateTemp.staticallySelectedTreeNodes, ...childTreeNodes])];
      }
    }
  }

  onSelectPreviouslyStaticNodes(stateTemp, id, isDynamic, index, childTreeNodes, selectedChildTreeNodes, nodePath){
    if(isDynamic){ // In case node will be dynamic
      // Remove node if it was static selected
      if(index > -1){
        stateTemp.staticallySelectedTreeNodes.splice(index, 1);
      }
      this.handleDynamicNodeSelection(stateTemp, id, nodePath);
    }else{ // In case node will be static
      if(index > -1){
        stateTemp.staticallySelectedTreeNodes.splice(index, 1);
      }else if(selectedChildTreeNodes.length < childTreeNodes.length){
        this.handleStaticNodeSelection(stateTemp, nodePath, childTreeNodes);
      }else if(selectedChildTreeNodes.length === childTreeNodes.length){
        stateTemp.staticallySelectedTreeNodes = stateTemp.staticallySelectedTreeNodes.filter(id => !childTreeNodes.includes(id));
      }
    }
  }

  onSelect = (id, treeIndex, treeNode, isDynamic) => () => {
    this.treeIndex = treeIndex;
    const stateTemp = {};
    const nodePath = treeNode.path;

    if(this.props.entityOptions.treeHelper.isOG(treeNode.nodeType)){
      stateTemp.selectedOrganizationGroups = fromJS(this.state.selectedOrganizationGroups).toJS();
      stateTemp.selectedOrganizationGroupsData = fromJS(this.state.selectedOrganizationGroupsData).toJS();
      this.onSelectOG(stateTemp, id, nodePath, treeNode.title);
    }else{
      stateTemp.staticallySelectedTreeNodes = this.props.groupChanged ? [] : fromJS(this.state.staticallySelectedTreeNodes).toJS();
      stateTemp.dynamicallySelectedTreeNodes = this.props.groupChanged ? [] : fromJS(this.state.dynamicallySelectedTreeNodes).toJS();
      const indexDynamic = stateTemp.dynamicallySelectedTreeNodes.indexOf(id);
      const childTreeNodes = this.getLeafChildrenNodesIds(id);

      // In case node was selected dynamic
      if(indexDynamic > -1){
        this.onSelectPreviouslyDynamicNodes(stateTemp, id, isDynamic, indexDynamic, childTreeNodes, treeNode);
      }else{
        const index = stateTemp.staticallySelectedTreeNodes.indexOf(id);
        const selectedChildTreeNodes = indexDynamic < 0 ?
          stateTemp.staticallySelectedTreeNodes.filter(selectedNodeId => childTreeNodes.includes(selectedNodeId)) : [];

        if(index > -1 || selectedChildTreeNodes.length > 0){ //In case node or any of its children were static
          this.onSelectPreviouslyStaticNodes(stateTemp, id, isDynamic, index, childTreeNodes, selectedChildTreeNodes, nodePath);
        }else{ // In case node wasn't selected
          this.onSelectNewNodes(stateTemp, id, isDynamic, childTreeNodes, treeNode, nodePath);
        }
      } 
    }
    this.props.onSelect(stateTemp);
  }
  
  onChange(treeData){
    if(this.dataPrepared){
      this.props.onChangeState({treeData, treeDataRoot: undefined});
    }
  }

  getCaret(collapsed) {
    return collapsed ? Icons.caretLeft : Icons.caretDown;
  }

  reverseCollapse() {
    const collapsed = !this.state.collapsed;
    this.setState({
      collapsed
    });
  }

  getNoDataAvailable(){
    return (
      <h6 className='col-xs-12 empty-search-row'>
        {this.props.noDataAvailableMessage ? this.props.noDataAvailableMessage : this.props.entityOptions.treeHelper.NO_TREE_NODES_MESSAGE}
      </h6>
    );
  }

  getNodeIdentifier(node){
    if(this.props.entityOptions.treeHelper.getNodeIdentifier){
      return this.props.entityOptions.treeHelper.getNodeIdentifier(this.props.entityOptions, node, this.props.selectedGroup);
    }

    return undefined;
  }
  
  render(){
    const caret = this.getCaret(this.state.collapsed);
    const stylingOptions = this.props.entityOptions.stylingOptions;
    const hasAdvancedFilter = this.props.entityOptions.showAdvancedFilters;
    let treeComponent;

    if(!this.props.isShowTree){
      treeComponent = this.getNoDataAvailable();
    }else if(!this.state.treeData){
      treeComponent = (
        <Col xs={12}> 
          <Loader />        
        </Col>
      );
    }else if(this.state.treeData.length == 0){
      treeComponent = this.getNoDataAvailable();
    }else {
      treeComponent = (
        <Col xs={12} className='treeComponentCol'>
          <ValidationOutput validation={this.props.validation} />
          <SortableTree treeData={this.state.treeData}
            onVisibilityToggle={this.onVisibilityToggle}
            onChange={this.onChange}
            nodeContentRenderer={CommonTreeNodeRenderer}
            generateNodeProps={this.generateNodeProps}
            getNodeKey={this.props.getNodeKey}
            scaffoldBlockPxWidth={0}
            rowHeight={stylingOptions.rowHeight}
            reactVirtualizedListProps={
              {height: this.props.height,
              scrollToIndex: this.treeIndex,
              scrollToAlignment: 'auto',
            ref :  ref => this.ref = ref }
          } />
        </Col>
      );
    }
    
    return(
      <div className={`mainTree ${this.props.entityOptions.highlightOnHover? 'highlighted-TreeNodes':''}`}>
        {this.props.entityOptions.showSearch?
        <Row>
          <Col xs={12}>
            <div className={`mainTreeSearchBar ${hasAdvancedFilter  ? 'withFilter' : ''}`} onKeyDown={(evt) => {
              if(evt.which == 13 ){
                this.setState({triggerSearch: !this.state.triggerSearch});
              }
            }}>
              <Searchbar hideLabel placeholder='Search in Tree'
              parentId='mainTreeSearchBar'
              onSearch={this.onSearch} query={this.state.searchQuery}
              triggerSearch={this.state.triggerSearch}/>
            </div>
            {hasAdvancedFilter ?
              <div className='dashboard-action pull-right' >
                <TooltippedButton tooltip='Advanced search'
                  className="btn-action pull-right"
                  onClick={this.reverseCollapse}>
                  <i className={Icons.styles.evLg + ' ' + caret}></i>
                </TooltippedButton>
              </div> 
            : null}
          </Col>
        </Row>
        : null}
        
        <Row>
          <Col xs={12}>
            {this.props.entityOptions.showAdvancedFilters && ! this.state.collapsed ? 
                this.props.getCustomSearchComponent() : null
            }
          </Col>
        </Row>
        <Row>
            {treeComponent}
        </Row>
      </div>
    );
  }

}