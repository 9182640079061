/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import IntervalValidator from 'utils/IntervalValidations';
import MultipleSelect from '../simple/MultipleSelect';
import NumericInput from '../simple/NumericInput';
import {fromJS} from 'immutable';
import {ResolutionUtils, Utils} from 'js-utils';
import _ from 'lodash';
import NumericInputComponent from '../simple/NumericInputComponent';


export default class ResolutionInput extends React.Component{

  constructor(props){
    super(props);
    let interval = this.props.min || ResolutionUtils.INITIAL_TIME_INTERVAL;

    if(this.props.resolution && !Utils.isBlank(this.props.resolution.interval)){ 
      interval = this.props.resolution.interval;
    } else if(this.props.allowEmptyEntry){
      interval = null;
    }
    this.state = {
      interval,
      validation : {},
    };
  }

  componentWillReceiveProps(nextProps){
    const resolution = nextProps.resolution;

    if(resolution && !_.isEqual(resolution, this.props.resolution)){
      if(!Utils.isBlank(resolution.interval)){
        this.setState({interval: resolution.interval});
      }
      else if(nextProps.allowEmptyEntry){
        this.setState({interval: null});
      }
    }
  }

  isAutoResolution(unit){
    return unit.toUpperCase() == ResolutionUtils.AUTO_RESOLUTION.toUpperCase();
  }

  render(){
    const timeResolutionOptions = (this.props.resolutionOptions || ResolutionUtils.TIME_RESOLUTION_OPTIONS).map(
      (trOption) => <option value={trOption} key={trOption} className='time-interval'>{trOption}</option>
    );
    const showInput =  !this.props.showAutoResolution || (this.props.resolution
                      && this.props.resolution.unit
                      && !this.isAutoResolution(this.props.resolution.unit));
    const NumericInputComponentChosen = this.props.isNewNumericInput ? NumericInputComponent : NumericInput;

    if(this.props.showAutoResolution){
      timeResolutionOptions.unshift(<option value= {ResolutionUtils.AUTO_RESOLUTION.toUpperCase()} key={ResolutionUtils.AUTO_RESOLUTION}>
      {ResolutionUtils.AUTO_RESOLUTION}
      </option>);
    }
    let defaultInterval = this.props.min || ResolutionUtils.INITIAL_TIME_INTERVAL;

    if(this.props.allowEmptyEntry){
      defaultInterval = null;
    }
    const interval = !Utils.isEmpty(this.state.interval) ? Math.floor(this.state.interval) : defaultInterval;
    const validation = this.props.isNewNumericInput ? this.props.validation 
    : (this.props.stopValidation ? null : this.state.validation);
    
    return(
      <Row className='tree-data-resolution'>
        <NumericInputComponentChosen
          step={1}
          size={6}
          inputSize={12}
          min={this.props.min || 1}
          className='timeInterval-input'
          label={"Interval"}
          hideLabel
          disabled={this.props.disableComponent || this.props.disableInterval || (!this.props.disableComponent && !showInput)}
          onChange={(e) => {
            const validation = IntervalValidator(e.target.value);

            this.setState({
              validation,
              interval: isNaN(e.target.value)
                || e.target.value.toString().length === 0 ?
                e.target.value : parseFloat(e.target.value),
            });
           }}
           validation={validation}
           stopValidation={this.props.stopValidation}
           value={showInput ? this.state.interval : ''}
           allowEmptyEntry
           inputType ={'Integer'}
           onBlur={() => {
             const resolution = fromJS(this.props.resolution
              || {}).toJS();

             resolution.interval = interval;
             if(this.state.interval === interval){
               this.props.onResolutionChange(resolution);              
             } else {
               this.setState({
                 interval,
                 validation: IntervalValidator(interval),
                }, () => {
                 this.props.onResolutionChange(resolution);
               });
             }             
           }}
          hideValue={(typeof this.state.interval) !== 'number'}
         />
        <Col xs={6}>
          <MultipleSelect
            filter={false}
            placeholder={this.props.placeholder}
            value = {this.props.resolution.unit? this.props.resolution.unit.toUpperCase() : -1}
            
             onChange={({target: {value: unit}}) => {
               const resolution = fromJS(this.props.resolution
                  || {}).toJS();

               if(this.isAutoResolution(unit)){
                 this.setState({interval: this.props.min || ResolutionUtils.INITIAL_TIME_INTERVAL});
                 resolution.interval = this.props.min || ResolutionUtils.INITIAL_TIME_INTERVAL;
                }else if(!resolution.interval){
                 resolution.interval = this.props.min || ResolutionUtils.INITIAL_TIME_INTERVAL;
                }
               resolution.unit = unit;

               this.props.onResolutionChange(resolution);
             }}
             className={`time-interval ${this.props.className}`}
             options={timeResolutionOptions}
             disabled={this.props.disableComponent} />
         </Col>
       </Row>
    );
  }


}
