/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Col} from 'react-bootstrap';
import Checkbox from '../simple/Checkbox';
import {fromJS} from 'immutable';
import ReactTags from 'react-tag-autocomplete';
import 'styles/inputs/compound/groupSharing.less';
import Icons from 'templates/Icons';

export default class GroupSharing extends React.Component{

  constructor(props){
    super(props);
    this.editableKeyword = props.editableSharedGroupsKeyword;
    this.viewableKeyword = props.viewableSharedGroupsKeyword;
    this.notifyGroupsKeyword = props.notifyGroupsKeyword;
    this.notificationMessageKeyword = props.notificationMessageKeyword;

    this.onChooseGroups = ::this.onChooseGroups;
    this.onChangeNotify = ::this.onChangeNotify;
    this.onDeleteGroups = ::this.onDeleteGroups;
  }

  onDeleteGroups(i, keyWord){
    const groupSharingObject = fromJS(this.props.groupSharingObject || {}).toJS();
    const currGroups = groupSharingObject[keyWord].slice(0);
    currGroups.splice(i, 1);
    groupSharingObject[keyWord] = currGroups;
    if(groupSharingObject[keyWord].length < 1){
      groupSharingObject[this.notifyGroupsKeyword] = false;
      groupSharingObject[this.notificationMessageKeyword] = '';
    }

    this.props.onChangeGroupSharing(this.props.caller, groupSharingObject);
  }

  onChooseGroups(e, keyWord){
    const groupSharingObject = fromJS(this.props.groupSharingObject || {}).toJS();
    groupSharingObject[keyWord] = groupSharingObject[keyWord].concat(e);
    this.props.onChangeGroupSharing(this.props.caller, groupSharingObject);
  }

  onFilterSuggestions(suggestion, query) {
    return suggestion.name.toLowerCase().includes(query.toLowerCase());
  }

  onChangeNotify(value, keyWord){
    const groupSharingObject = fromJS(this.props.groupSharingObject || {}).toJS();
    groupSharingObject[keyWord] = value;
    this.props.onChangeGroupSharing(this.props.caller, groupSharingObject);
  }

  render(){
    const showNotify =  !this.props.disableNotifingUsers && (this.props.groupSharingObject[this.editableKeyword].length > 0 ||
      this.props.groupSharingObject[this.viewableKeyword].length > 0);
    return (
      <div>
        <Col xs={12} className="react-tags">
          <i className={`${Icons.edit} tagsIcon`}></i>
          <ReactTags
            tags ={this.props.groupSharingObject[this.editableKeyword]}
            autoresize={false}
            minQueryLength={0}
            maxSuggestionsLength={1000}
            suggestions={this.props.suggestions}
            autofocus={false}
            placeholder="Choose groups that can edit"
            suggestionsFilter={(suggestion, query) => this.onFilterSuggestions(suggestion, query)}
            handleDelete={(i) => this.onDeleteGroups(i, this.editableKeyword)}
            handleAddition={(e) => this.onChooseGroups(e, this.editableKeyword)}/>
        </Col>
        <Col xs={12} className="react-tags">
          <i className={`${Icons.view} tagsIcon`}></i>
          <ReactTags
            tags ={this.props.groupSharingObject[this.viewableKeyword]}
            autoresize={false}
            minQueryLength={0}
            maxSuggestionsLength={1000}
            suggestions={this.props.suggestions}
            autofocus={false}
            placeholder="Choose groups that can view only"
            suggestionsFilter={(suggestion, query) => this.onFilterSuggestions(suggestion, query)}
            handleDelete={(i) => this.onDeleteGroups(i, this.viewableKeyword)}
            handleAddition={(e) => this.onChooseGroups(e, this.viewableKeyword)}/>
        </Col>
        {
        showNotify ?
            <div>
              <br/>
              <Checkbox
                label='Notify groups'
                checked={this.props.groupSharingObject[this.notifyGroupsKeyword]}
                onChange={(e) => this.onChangeNotify(e.target.checked, this.notifyGroupsKeyword)}/>
            </div>
            : null
        }
        { showNotify && this.props.groupSharingObject[this.notifyGroupsKeyword] ?
            <textarea className="form-control"
                  onChange={(e) => this.onChangeNotify(e.target.value, this.notificationMessageKeyword)}/>
          : null
        }
      </div>
    );
  }

}
