/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils, DateTimeUtils, MsgDefaults, Utils} from 'js-utils';
import moment from 'moment';
import * as CommonValidationSchema from 'utils/CommonValidationSchema';
import * as defaults from 'utils/defaults';

const VALID_START_END_TIME_MESSAGE = 'End time should be after start time';

const isValidMomentTime = (time) => {
  return moment(time, DateTimeUtils.TIME_TO_MOMENT_FORMAT, true).isValid();
};

const earlier = (value, helpers) => {
  const startTime = value.startTime;
  const endTime = value.endTime;

  if(isValidMomentTime(startTime) && isValidMomentTime(endTime)){
    return moment(endTime, DateTimeUtils.TIME_TO_MOMENT_FORMAT).isAfter(moment(startTime, DateTimeUtils.TIME_TO_MOMENT_FORMAT))	? 
    value : helpers.error('number.greaterOrEqual');
  }

  return helpers.error('any.invalid');
};

const isValidDay = (value, helpers) => {
  if(CommonValidationSchema.isValidDay(value)){
    return value;
  }

  return helpers.error('string.invalidDay');
};

function getTimeSchema(label){
  return Joi.string()
        .label(label)
        .required()
        .error(ValidationUtils.getErrMsgString);
}

export const selectedDays = Joi.array()
    .label('Selected Days')
    .min(1)
    .required()
    .error(ValidationUtils.getErrMsgArray('Selected Day'));  

export const startEndTimeSchema = Joi.object()
  .keys({
    startTime : getTimeSchema('Start Time'),
    endTime : getTimeSchema('End Time'),   
  })
  .unknown()
  .prefs({abortEarly: true})
  .label('Time')
  .custom(earlier, VALID_START_END_TIME_MESSAGE)
  .error(errors => {
    if(errors[0].code == 'number.greaterOrEqual'){
      return new Error(VALID_START_END_TIME_MESSAGE);
    }
    
    return errors;
  });

export const timeRangesSchema = Joi.array()
  .label('Time Ranges')
  .items(startEndTimeSchema)
  .min(1)
  .unique((a, b) => {
    return a.startTime === b.startTime 
      && a.endTime === b.endTime;
  })
  .required()     
  .error(ValidationUtils.getErrMsgArray('Times'));

export const resolution = Joi.object()
    .keys({
      selectedDays: Joi.array()
        .error(ValidationUtils.getErrMsgArray('Day'))
        .label('Day')
        .when('resolution',
          {
            is: defaults.WEEKLY,
            then: Joi.array().min(1).required(),
            otherwise: Joi.array().optional().allow(null),              
          }),
      dayOfMonth: Joi.number()
        .label('Day')
        .when('resolution',
          {
            is: defaults.MONTHLY,
            then: Joi.number().required().custom(isValidDay, 'Validating day of month')
                .error(errors => {
                  if(Utils.isEmpty(errors[0].value)){
                    return ValidationUtils.getErrMsgString(errors);
                  } 

                  return new Error(MsgDefaults.getNotValidMessage('day'));
                }),
            otherwise: Joi.number().optional().allow(null),              
          }),
    }).unknown();

const SCHEMA = { 
  time : startEndTimeSchema,
  timeRanges: timeRangesSchema,
  resolution,
  selectedDays,
};
  
export const validationFunction = ValidationUtils.validateSchema(SCHEMA);