/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import Highlighter from 'react-highlight-words';
import Checkbox from 'inputs/simple/Checkbox';
import {filterChildToSelectedPaths, filterParentToSelectedPaths} from './TreeNodesStructureHelper';
import {Utils} from 'js-utils';


class CommonTreeNodeRenderer extends Component {
  constructor(props){
    super(props);
    this.filterChildToSelectedNode = ::this.filterChildToSelectedNode;
    this.filterParentToSelectedNode = ::this.filterParentToSelectedNode;
    this.getStaticSelectionStatus = ::this.getStaticSelectionStatus;
    this.getDynamicSelectionStatus = ::this.getDynamicSelectionStatus;
    this.isNodeDisabled = ::this.isNodeDisabled;
  }

  filterChildToSelectedNode(selectedNodeId){
    return filterChildToSelectedPaths(this.props.treeNodesPaths[selectedNodeId], this.props.node.path);
  }
  
  filterParentToSelectedNode(selectedNodeId){
    return filterParentToSelectedPaths(this.props.treeNodesPaths[selectedNodeId], this.props.node.path);
  }

  isNodeDisabled(disabled){
    if(disabled){
      return disabled;
    }else if(this.props.isNE(this.props.selectedGroup)){
      return this.props.dynamicallySelectedTreeNodes.filter(this.filterChildToSelectedNode).length > 0;
    }else if(this.props.isOG(this.props.selectedGroup)) {
      return this.props.selectedOrganizationGroups.filter(this.filterChildToSelectedNode).length > 0;
    }

    return false;
  }

  getStaticSelectionStatus(isDynamicallyChecked, node){
    const canStaticSelect = !isDynamicallyChecked && this.props.isNE(this.props.selectedGroup);
    const childTreeNodes = canStaticSelect && this.props.isNE(node.nodeType) ? 
                this.props.getLeafChildrenNodesIds(node[this.props.id]) : [];

    const selectedChildTreeNodes = canStaticSelect ?
      this.props.staticallySelectedTreeNodes.filter(nodeId => childTreeNodes.includes(nodeId)) : [];
    const selectedChildTreeNodesPaths = this.props.staticallySelectedTreeNodes.filter(this.filterParentToSelectedNode);

    const staticallyFullyChecked = canStaticSelect
      && (this.props.staticallySelectedTreeNodes.indexOf(node[this.props.id]) > -1
            || (selectedChildTreeNodes.length > 0 && selectedChildTreeNodes.length === childTreeNodes.length
              && selectedChildTreeNodesPaths.length > 0 && selectedChildTreeNodesPaths.length === childTreeNodes.length));

    const staticallypartiallyChecked = !staticallyFullyChecked 
      && ((selectedChildTreeNodes.length > 0 && selectedChildTreeNodes.length < childTreeNodes.length) 
          || (selectedChildTreeNodesPaths.length > 0 && selectedChildTreeNodesPaths.filter(x => !childTreeNodes.includes(x)).length > 0));

    return {partial: staticallypartiallyChecked, full: staticallyFullyChecked};
  }

  getDynamicSelectionStatus(includedInOG, node){
    const includedInDynamicNE = this.props.dynamicallySelectedTreeNodes
      && this.props.dynamicallySelectedTreeNodes.indexOf(node[this.props.id]) > -1;

    const dynamicallyChecked = ( this.props.isNE(this.props.selectedGroup) && includedInDynamicNE)
          || (this.props.isOG(this.props.selectedGroup) && includedInOG);

    return dynamicallyChecked;
  }

  getNodeSelectionActions(node, otherProps, treeIndex){
    let actions = null;
    if(!!this.props.dynamicOrStaticSelection){
      const includedInOG = (otherProps.selectedOrganizationGroups
        && otherProps.selectedOrganizationGroups.indexOf(node[otherProps.id]) > -1);
  
      const isDynamicallyChecked = this.getDynamicSelectionStatus(includedInOG, node);
      const staticSelectionStatus = this.getStaticSelectionStatus(isDynamicallyChecked, node);
      const disabled = this.isNodeDisabled(otherProps.disabled);  
  
      actions = <span>
        { (node.dynamicallySelectable) ?
            <Checkbox
              checkState={"dynamic"}
              disabled={disabled}
              checked={isDynamicallyChecked}
              bsClass="checkbox-container"
              onChange={otherProps.onSelect(node[otherProps.id], treeIndex, node, true)}
              name="notifications"
              wrapperClassName="col-xs-12" /> : null
        }
        {node.staticallySelectable ?
          <Checkbox
            disabled={disabled}
            checked={staticSelectionStatus.full || staticSelectionStatus.partial}
            checkState={staticSelectionStatus.partial ? 'partial' : 'full'}
            bsClass="checkbox-container"
            onChange={otherProps.onSelect(node[otherProps.id], treeIndex, node, false)}
            name="notifications"
            wrapperClassName="col-xs-12" /> : null
        }
      </span>;
    }

    return actions;
  }

  getTitle(node){
    if(!Utils.isBlank(node.title)){
      return node.title;
    }else if(this.props.getTitle){
      return this.props.getTitle(node);
    }
  }

  getIcon(node){
    if(!(Utils.isEmpty(node.iconName))){
      const nodeIconStyle = node.iconName.substring(0, 2) == 'fa' ? 'fa' : '';

      return nodeIconStyle + ' ' + node.iconName;
    }else if(this.props.getIcon){
      return this.props.getIcon(node);
    }
  }

  getNodeTitle(node, otherProps, treeIndex){
    const iconName = this.getIcon(node);
    const iconNameBlank = Utils.isBlank(iconName);
    const folderIcon = node.expanded ?
     'sysTree-iconEle sysTree-icon__open' : 'sysTree-iconEle sysTree-icon__close';
    const nodeTitleClassName = node.expanded ? 'expanded-tree-node' : '';
    const canBeHighlighted = otherProps.canBeHighlighted(node, otherProps.selectedGroup);
    const hightlightClassName =  canBeHighlighted ?
     node.expanded ? 'expanded-tree-node matched-word' : 'matched-word' : '';

    return <a title={this.getTitle(node)}
              className={otherProps.isSelected(node) ? 'item-active-bg-color' : 'item-inactive-bg-color'}
              onClick={() => otherProps.onClick(node, treeIndex, this.props.path)}>
            {iconNameBlank ?
              <span className={folderIcon} ></span>
              : null }
            <span className={`sysTree-title ${nodeTitleClassName}`} >
              <i className={`${iconName} ${nodeTitleClassName}`}/>
                <Highlighter
                  autoEscape
                  highlightClassName={hightlightClassName}
                  className={iconNameBlank ? '' : 'tree-node-title'}
                  searchWords={[canBeHighlighted ? otherProps.searchQuery  : '']}
                  textToHighlight={this.getTitle(node)}
                />
            </span>
          </a>;
  }


  render(){
    const {
      toggleChildrenVisibility,
      node,
      path,
      treeIndex,
      ...otherProps
    } = this.props;
    
    const nodeLevel = node.path ? node.path.split('-').length : path.length;
    const marginLeft = 20 * (nodeLevel - 1);
    const inlineStyle = marginLeft > 0 ? {marginLeft} : {};

    const switcherClassName = node.expanded ?
     'sysTree-noline_open' : 'sysTree-noline_close';

    return (
      <ul className={`sysTree ${node[otherProps.id]}_${nodeLevel}`}
          role="tree-node"
          unselectable="true"
          style={inlineStyle}>
        <li className="" >
          {
            node.isLeaf ? null :
            <span className={`sysTree-switcher ${switcherClassName}`}
              onClick={() => toggleChildrenVisibility({node, path, treeIndex})}>
            </span>
          }
          {this.getNodeSelectionActions(node, otherProps, treeIndex)}
          {this.getNodeTitle(node, otherProps, treeIndex)}
          <div className="pull-right nodeActions">
            {this.props.getNodeActions(node, {path})}
          </div>
        </li>
      </ul>
    );
  }
}

export default CommonTreeNodeRenderer;
