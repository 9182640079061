/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import SendEmailModal from './SendEmailModal.js';
import AnnotationModal from './AnnotationModal.js';
import {ImageConversionUtils} from 'js-utils';

const PADDING = 0;

export default class InPlaceAnnotation extends Component{

  constructor(props){
    super(props);
    this.sendEmail = ::this.sendEmail;
    this.renderChildren = ::this.renderChildren;
    this.state = {};
    this.style = {
      zIndex: '2',
    };
    this.isiPad = navigator.userAgent.match(/iPad/i) != null;

    if(this.isiPad){
      this.style = {display:'block'};
    }
  }

  enterEmailMode = annotationCanvas =>
    this.getResultURL(annotationCanvas).then(
      resultURL => {
        this.resultURL = resultURL;
        this.setState({
          inSendEmailMode: true,
        });
      }
    );
  leaveEmailMode = () => this.setState({inSendEmailMode: false});


  getResultURL = annotationCanvas =>
    new Promise( resolve => {
      const svgDom = this.ref.querySelector('svg');
      const svgString = new XMLSerializer().serializeToString(svgDom);
      const size = svgDom.getBoundingClientRect();
      const dataURL = ImageConversionUtils.svgToBase64URL(svgString);

      
      const resultCanvas = document.createElement('canvas');
      const width = annotationCanvas.width / window.devicePixelRatio;

      resultCanvas.width = width;
      resultCanvas.height = annotationCanvas.height / window.devicePixelRatio;
      const ctx = resultCanvas.getContext('2d');
      const widgetImg = new Image();
      const padding = (width - size.width) / 2;

      widgetImg.onload = () => {
        ctx.drawImage(
          widgetImg,
          padding,
          0,
          widgetImg.width,
          widgetImg.height
        );
        ctx.drawImage(
          annotationCanvas,
          0,
          0,
          resultCanvas.width,
          resultCanvas.height
        );
        resolve(resultCanvas.toDataURL());
      };
      widgetImg.src = dataURL;
    });

  save = (annotationCanvas, name) => {
    this.getResultURL(annotationCanvas).then(
      resultURL => {
        this.props.download(resultURL, name);
      }
    );
  }


  sendEmail(options){
    this.setState({
      sendingEmail: true,
    });

    options.body = `<html><body>${options.body}</body></html>`;
    options.inlineImages = {
      image: this.resultURL.split(',')[1],
    };
    this.props.sendEmail(options).then(
      () => {
        this.props.NotificationManager.success('Email Successfully Sent!');
        this.setState({
          inSendEmailMode: false,
        });
      }
    )
    .catch(
      () => {
        this.props.NotificationManager.error('Email sending failed!', 'Internal Server Error', 10000);
      }
    )
    .then(
      () => this.setState({
        sendingEmail: false,
      })
    );
  }

  renderChildren() {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        inAnnotationMode: true,
      });
    });
  }

  render = () =>
    <div style={{height: '100%'}} ref={ ref => this.ref = ref } >
      <AnnotationModal show
        onHide={this.leaveAnnotationMode}
        imageSize={{
          height:this.props.height,
          width: this.props.width,
          size:this.props.size,
        }}
        height={this.props.height}
        width={this.props.width}
        colors={this.props.colors}
        padding={PADDING}
        initialized={this.props.inAnnotationMode}
        onExitAnnotation={this.props.onExitAnnotation}
        onSaveClick={this.save}
        onEmailClick={this.enterEmailMode}
      >
        {this.props.children}
      </AnnotationModal> 
      
      {
        this.state.inSendEmailMode ?
        <SendEmailModal show
                        onHide={this.leaveEmailMode}
                        imageURL={this.resultURL}
                        title='Send Annotation'
                        onSendEmail={this.sendEmail}
                        sendingEmail={this.state.sendingEmail}
                        /> : null
      }
      </div>;
}
