/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const TagsInputComponent = props => {
	return(
		<TagsInput {...props}/>
	);
};

export default TagsInputComponent;

