/* Copyright 2019 Greyskies. All Rights Reserved. */

import {Utils} from 'js-utils';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import { is } from 'bpmn-js/lib/util/ModelUtil';

const PREFIX = 'workflow-xml-name';

export function getWorkflowGeneratedName(workflowName){
  let nameWithoutSpecialCharOrNum = workflowName.replace(/[^a-zA-Z0-9_-]/g, "").replace(/^[0-9]+(\S*)/,'$1');
  
  if(Utils.isBlank(nameWithoutSpecialCharOrNum) || nameWithoutSpecialCharOrNum == ''){
    nameWithoutSpecialCharOrNum = PREFIX;
  }
  
  return  nameWithoutSpecialCharOrNum + "-" + Date.now();
}

export function isAnEmailTask(element) {
  return (is(element, CommonWorkflowDefaults.TASK_NAMESPACE) && 
  element.businessObject.taskAction == CommonWorkflowDefaults.EMAIL_TASK_NAMESPACE);
}

export function isAnAnsibleTask(element) {
  return (
    is(element, CommonWorkflowDefaults.TASK_NAMESPACE)
    && element.businessObject.taskAction == CommonWorkflowDefaults.ANSIBLE_TASK_NAMESPACE
  );
}

export function isSequenceFlow(element) {
  return (
    is(element, CommonWorkflowDefaults.SEQUENCE_FLOW_NAMESPACE)
    && is(element.businessObject.sourceRef, CommonWorkflowDefaults.EXCLUSIVE_GATEWAY_NAMESPACE)
  );
}

export function isAnApprovalMail(element){
 return (
    is(element, CommonWorkflowDefaults.TASK_NAMESPACE)
    && element.businessObject.taskAction == CommonWorkflowDefaults.APPROVAL_MAIL_TASK
  );
}

export function isAReportDesigner(element){
  return (
     is(element, CommonWorkflowDefaults.TASK_NAMESPACE)
     && element.businessObject.taskAction == CommonWorkflowDefaults.REPORT_DESIGNER_TASK
   );
 }
