import React from 'react';
import ValidationOutput from 'containers/ValidationOutput';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import TooltippedButton from 'buttons/TooltippedButton';
import {MsgDefaults} from 'js-utils';
import 'styles/buttons/selectionButtonWithIcon.less';
import Icons from 'templates/Icons';

const SelectionButtonWithIcon = props => (
  <React.Fragment>
    <div className="treeSelections selectionBtnWzIcon">
      <div className={`selectionList selectionLabel ${props.isResetBtnAvailable ? 'selectionListWithTwoIcons' : ""}`}>
        <span>
        {props.displayName}
        </span>
        {props.isResetBtnAvailable ? 
          <TooltippedButton
          className="reset-btn"
          tooltip={MsgDefaults.getResetTitle(props.entityName)}
          disabled={props.disable || props.isResetBtnDisabled}
          onClick={props.onResetClick}>
          {UIConstructionUtils.getIcon(Icons.ban)}
        </TooltippedButton> : null}
      </div>
      <TooltippedButton
        className="btn-primary tree-btn addFGA-btn"
        tooltip={MsgDefaults.getAddNewTitle(props.entityName)}
        disabled={props.disable}
        onClick={props.onClick}>
        {props.notificationIcon ?
          <span className='btnNotification'>
            <i className={`notificationIcon ${Icons.styles.evSm} ${props.notificationIcon}`}></i>
          </span> : null}
        {UIConstructionUtils.getIcon(props.icon)}
      </TooltippedButton> 
    </div>
    <ValidationOutput validation={props.validation}/>
  </React.Fragment>
);

export default SelectionButtonWithIcon;