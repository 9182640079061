/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import ModalDialog from 'react-bootstrap/lib/ModalDialog';
import Draggable from 'react-draggable';

const DraggableModalDialog = (props) => {
  return(
    <Draggable handle=".modal-header">
      <ModalDialog {...props} />
    </Draggable>
  );
};

export default DraggableModalDialog;