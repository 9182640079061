/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import LabeledComponent from 'containers/LabeledComponent';
import ButtonWrapper from 'containers/ButtonWrapper';
import StartEndTime from './StartEndTime';
import {DataInput, Checkbox, Radio, RadioGroup} from '../simple/SimpleInputs';
import ValidationOutput from 'containers/ValidationOutput';
import {DATE_FILTER_TIME_ZONE_MSG} from 'utils/ffagDefaults';
import {MsgDefaults, DateTimeUtils} from 'js-utils';
import Icons from 'templates/Icons';
import {getKeyFromId} from 'utils/UIConstructionUtils';
import {fromJS} from 'immutable';
import * as defaults from 'utils/defaults';

let idTimesCounter = 0;

const onResolutionChange = (props) => (e) => {
  const newProps = fromJS(props).toJS();

  if(props.resolution != e.target.value){
    newProps.resolution = e.target.value;
    newProps.selectedDays = [];
    newProps.dayOfMonth = null;
    newProps.validation.resolution = {};
  }

  props.updateSpecificPeriod(newProps);
}

const addTimeRange = (props) => {
  const times = fromJS(props.timeRanges || []).toJS();

  times.push({
    idCounter: idTimesCounter,
    validation: {},
    startTime: defaults.START_TIME_DEFAULT,
    endTime: defaults.END_TIME_DEFAULT,
  });
  
  idTimesCounter ++;

  const propsTemp = {
    timeRanges: times,
  };

  props.updateSpecificPeriod(propsTemp);
};

const deleteTimeRange = (props, index) => {
  const times = fromJS(props.timeRanges || []).toJS();

  times.splice(index, 1);
  const propsTemp = {
    timeRanges: times,
    validation: {
      timeRanges: props.validationFunction('timeRanges', times || []),
    },
  };

  props.updateSpecificPeriod(propsTemp);
};

const onTimesChange = (time, key, props) => {
  const propsTemp = {timeRanges: props.timeRanges || []};
  
  propsTemp.timeRanges[key] = time;
  const timeRangesValidation = props.validationFunction('timeRanges', propsTemp.timeRanges || []);

  propsTemp.validation = {
    ...props.validation,
    timeRanges: timeRangesValidation,
  };

  props.updateSpecificPeriod(propsTemp);
};

const addNewTimeRangeButton = (props) => {
  return (
    <Row>
      <Col xs={12}>
        <ButtonWrapper
          type='TooltippedButton'
          className="btn-action"
          tooltip={MsgDefaults.getAddNewTitle('Time Range')}
          icon={Icons.add}
          onClick={() => addTimeRange(props)}/>
        <ValidationOutput validation={props.validation.timeRanges} />
      </Col>
    </Row>
  );
};

const onSelectedDaysChange = (props, checkboxIndex = -1) => (e) => {
  const newProps = fromJS(props).toJS();
  let selectedDayIndex = -1;

  if((newProps.selectedDays.length > 0)){
    newProps.selectedDays.map((selectedDay, index) => {
      if(checkboxIndex == selectedDay.day){
        selectedDayIndex = index;
      }
    });
  }

  if(e.target.checked){
    if(selectedDayIndex == -1){
      newProps.selectedDays.push({day: checkboxIndex});
    }
  } else{
    newProps.selectedDays.splice(selectedDayIndex, 1);
  }

  newProps.validation.resolution = props.validationFunction('resolution', newProps);
  props.updateSpecificPeriod(newProps);
}

const getWeekDaysOptions = props => {
  let weekDaysOptions;

  if(props.resolution == defaults.WEEKLY){
    const selectedDays = props.selectedDays.map((selectedDay) => {
      return selectedDay.day;
    });

    weekDaysOptions = DateTimeUtils.WEEK_DAYS_DEFAULT.map((day, index) => {
      const dayIndex = index + 1;

      return (
        <Checkbox bsClass="checkbox-container" 
          label={day} value={dayIndex}
          checked={selectedDays.indexOf( dayIndex ) != -1}
          onChange={onSelectedDaysChange(props, dayIndex)}/>
        );
    });
  }

  return weekDaysOptions;
};

const onDayOfMonthBlur = (props) => {
  const newProps = {
    validation: {
      dayOfMonth: props.validationFunction('resolution', props),
    },
  };

  props.updateSpecificPeriod(newProps);
};

const onDayOfMonthChange = (props) => (e) => {
  const newProps = fromJS(props).toJS();

  newProps.dayOfMonth = e.target.value;
  newProps.validation.dayOfMonth = props.validationFunction('resolution', newProps);
  props.updateSpecificPeriod(newProps);
};

const getRecurrencePatternOptions = props => {
  const recurrencePatterns = [];

  for(const resolution in defaults.RESOLUTIONS) {
    recurrencePatterns.push({
      key: resolution,
      label: defaults.RESOLUTIONS[resolution],
    });
  }

  return (
    <RadioGroup>
      <Row>
        {recurrencePatterns.map(recurrencePattern => {
          if(recurrencePattern){
            return (
                <Radio className="margBtm10 col-sm-4 col-xs-12" 
                  id={recurrencePattern.key} 
                  label={recurrencePattern.label} 
                  value={recurrencePattern.key}
                  checked={props.resolution == recurrencePattern.key}
                  onChange={onResolutionChange(props)} />
            );
          }
        })}
      </Row>
    </RadioGroup>
  );
};

const renderTimes = (props) => {
  return (props.timeRanges || []).map((time, index) => {
    const key = getKeyFromId(time);
    const userTime = fromJS(time).toJS();

    return (
      <StartEndTime 
        key={key}
        order = {index} 
        time={userTime}
        validationFunction={props.validationFunction}
        deleteEntity = {() => deleteTimeRange(props, index)} 
        onChange={(time, key) => 
          onTimesChange(time, key, props)}/>  
    );
  });
};

const SpecificPeriodComponent = props => {
  return (
    <div className='specificPeriodWrapper'>
      {props.isServerZoneTime &&
        <div className='margBtm20'>
          <i className={Icons.info}></i>
          <span className='margLft5 bold-text'>
            {`${DATE_FILTER_TIME_ZONE_MSG} (${props.serverTimeZone})`}
          </span>
        </div>
      }
      <Row>
        <LabeledComponent label='Recurrence Pattern' size={12} inputSize={9} >
            {getRecurrencePatternOptions(props)}
          <Row className='marg0Auto'>
            {(props.resolution == defaults.WEEKLY) ? 
              <Col xs={12}>
                <hr className="nmsDivider" />
                <ValidationOutput validation={props.validation.resolution}/>
                <Col xs={12} className="grid-3">
                  {getWeekDaysOptions(props)}
                </Col>
              </Col> 
            : null}
            {(props.resolution == defaults.MONTHLY) ? 
              <Col xs={12}>
                <hr className="nmsDivider" />
                <Row>
                  <DataInput label="On day*" xsSize={12} size={12} value={props.dayOfMonth}
                    inputSize={9} labelSize={3}
                    inputXsSize={12} labelXsSize={12}
                    onChange={onDayOfMonthChange(props)}
                    validation={props.validation.resolution}
                    onBlur={() => onDayOfMonthBlur(props)}/>
                </Row>
              </Col> : null}
            </Row>
        </LabeledComponent>
        <LabeledComponent label="Specific Time" size={12} labelSize={3} inputSize={9}>
          {addNewTimeRangeButton(props)}      
          {renderTimes(props)}
        </LabeledComponent>
      </Row>
    </div>
  );
};

export default SpecificPeriodComponent;