/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {TwitterPicker} from 'react-color'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'styles/inputs/simple/colorPicker.less';


class ColorPicker extends Component {

  static propTypes = {
    colors: PropTypes.array.isRequired,
  };

  constructor(props){
    super(props);
    this.handleClick = ::this.handleClick;
    this.handleClose = ::this.handleClose;
    this.handleChange = ::this.handleChange;
    this.handleKeyDown = ::this.handleKeyDown;
    this.onClickTwitterPickerDiv = ::this.onClickTwitterPickerDiv;

    this.state = {
      displayColorPicker: false,
      color: this.props.color ? this.props.color : this.props.colors[0],
    };
  }


  componentWillReceiveProps(newProps){
    this.setState({
      color: newProps.color? newProps.color : newProps.colors[0],
    });
  }

  handleClick(){
    if(!this.props.disabled){
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  }

  handleClose(){
    this.setState({ displayColorPicker: false });
  }

  handleChange(color){
    this.props.onChange(color.hex);
    this.setState({ 
      color: color.hex,
    });
  }

  handleKeyDown(e){
    if (e.key === 'Enter') {
      this.setState({ 
        displayColorPicker: false,
      });
    }
  }

  onClickTwitterPickerDiv(e){
    e.stopPropagation();
  }

  render() {
    const colorpicker = <OverlayTrigger shouldUpdatePosition
      rootClose placement="bottom" trigger={this.props.tooltiped ? ['hover', 'focus'] : []}
      disabled={this.props.disabled}
      overlay={
        <Tooltip placement="bottom" className="in" >
          {this.props.tooltip ? this.props.tooltip : 'Color Picker'}
        </Tooltip>
      }
    >
    <div className={`color-picker ${this.props.removeInput ? 'removeInput' : ''} ${this.props.className || ''}`} style={{cursor: this.props.disabled ? 'not-allowed':''}}>
     { this.props.customDisplay ? this.props.customDisplay(this.handleClick, this.state.color) 
      : <div onClick={ this.handleClick }>
          <div className='color-button' style={{background: this.state.color}}/>
        </div>
      }
      { this.state.displayColorPicker ?
        <div className='color-popover' style={{
          left: this.props.openleft ? '50%' : null,
          transform:'translate(-80%,10px)'}}>
          <div onClick={this.handleClose} className='color-cover'/>
          <div onClick={this.onClickTwitterPickerDiv} onKeyDown={this.handleKeyDown}> 
            <TwitterPicker
              triangle={this.props.openleft ? 'top-right' : 'top-left'}
              color={ this.state.color}
              onChange={ this.handleChange}
              colors={this.props.colors}/>
          </div>
        </div> : null }
    </div>
    </OverlayTrigger>;

    return (
    this.props.inMenu ?
    <li onClick={ this.handleClick }
      className='color-picker-panel-action panel-action'>
        {colorpicker}
        {this.props.children}
    </li>
    : colorpicker
    );
  }
}

export default ColorPicker;
