/* Copyright 2019 Greyskies. All Rights Reserved. */

function subStringExpandedId(expandedId, parentPos, indexDash){
  let nextIndexDash = expandedId.indexOf('-', indexDash + 1);
  nextIndexDash = nextIndexDash > -1 ? nextIndexDash : expandedId.length;
  const currExpandedId = expandedId.substring(indexDash + 1, nextIndexDash);
  const restExpandedId = expandedId.substring(nextIndexDash);
  return {
    currExpandedId, restExpandedId,
  };
}
export function updateExpandedBetweenTwoIds(expandedIds, newExpandedIds, removedIds, isAdvancePositionsNeeded, fromChildIndex,
  toChildIndex, parentPos, indexDash){
  expandedIds.forEach(expandedId => {
    const {currExpandedId, restExpandedId} = subStringExpandedId(expandedId, parentPos, indexDash);
    if(fromChildIndex == currExpandedId){
      newExpandedIds.push(parentPos + toChildIndex + restExpandedId);
      removedIds.push(expandedId);
    }else{
      const isUpdated = isAdvancePositionsNeeded ? (currExpandedId < fromChildIndex && currExpandedId >= toChildIndex) 
          : (currExpandedId >= fromChildIndex && currExpandedId <= toChildIndex);
      if(expandedId.startsWith(parentPos) && isUpdated){
        removedIds.push(expandedId);
        const newExpandedId = isAdvancePositionsNeeded ?  (parseInt(currExpandedId) + 1) : (parseInt(currExpandedId) - 1);
        newExpandedIds.push(parentPos + newExpandedId + restExpandedId);
      }

    }
  });
}

export function updateExpandedToEnd(expandedIds, newExpandedIds, removedIds, isAdvancePositionsNeeded, fromChildIndex, parentPos, indexDash){
  let isUpdated;
  expandedIds.forEach(expandedId => {
    const {currExpandedId, restExpandedId} = subStringExpandedId(expandedId, parentPos, indexDash);

    if(expandedId.startsWith(parentPos)){
      if(isAdvancePositionsNeeded){
        isUpdated = currExpandedId >= fromChildIndex;
      }else{
        isUpdated = currExpandedId > fromChildIndex;
        if(currExpandedId == fromChildIndex){
          removedIds.push(expandedId);
        }
      }
      if(isUpdated){
        removedIds.push(expandedId);
        const newExpandedId = isAdvancePositionsNeeded ? (parseInt(currExpandedId) + 1) : (parseInt(currExpandedId) - 1);
        newExpandedIds.push(parentPos + newExpandedId + restExpandedId);
      }
    }
  });
}
