import React from 'react';
import {DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';


function getOverlayTrigger(button, node){
  const menuItems = button.getMenuItems(node);
  const overlayTrigger = (menuItems.length > 0) ?
    <OverlayTrigger
      rootClose placement="bottom" trigger={['hover', 'focus']}
      overlay={
        <Tooltip placement="left" className="in" >
          {button.tooltip}
        </Tooltip>
      }>
      <DropdownButton noCaret className={`btn-action treeActIco openLeft ${button.className}`}
        title={
          <span>
            <i className={button.iconName}></i>
          </span>
        }>
          {menuItems}
      </DropdownButton>
    </OverlayTrigger>
  : null;

  return overlayTrigger;
}

function getTooltippedButton(button, node, otherProps){
  if(!(button.isHide && button.isHide(node))){
    return ButtonsConstructor.getTooltippedButton(button.tooltip, 
      button.iconName, `btn-action treeActIco ${button.className}`, false, 
      () => button.onClick(node, otherProps));
  }

  return null;
}

export function getNodeActions(buttonsJson, node, otherProps){
  return buttonsJson.map((button) => {
    switch(button.type){
      case 'OverlayTrigger':
        return getOverlayTrigger(button, node);
      case 'TooltippedButton':
        return getTooltippedButton(button, node, otherProps);
      default: 
        return null;
    }
  });
}