/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component, Fragment } from 'react';
import {InputGroup, OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import 'styles/inputs/simple/fileUploader.less';
import { FileDownloadUtils, ValidationUtils, FileTransformer} from 'js-utils';
import ValidationOutput from 'containers/ValidationOutput';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import  Icons  from 'templates/Icons';

export default class FileUploader extends Component {

  constructor(props){
    super(props);

    this.clearUploadedFile = ::this.clearUploadedFile;
    this.onUploadFile = ::this.onUploadFile;
    this.parseFileData = ::this.parseFileData;

    this.state = {selectedFile: props.selectedFile ? props.selectedFile : null};
  }

  clearUploadedFile(){

    this.setState({ selectedFile: null}, () => this.props.onChange(null, this.props.extraParams));
  }

  onUploadFile(e) {
    e.preventDefault();
    const file = e.target.files[0];
    const fileDescriptor = file ? {
      size: file.size,
      extension: FileDownloadUtils.getFileExtension(file.name),
      name: file.name,
      type: file.type,
    } : undefined;

    const validation = this.props.validationSchema ? ValidationUtils.validateSingleSchema(this.props.validationSchema, fileDescriptor) : {} ;

    if (!ValidationUtils.isValid(validation)) {
        this.setState({validation});
    } else {
      this.parseFileData(file);
      // Update the state
      this.setState({ selectedFile: file, validation});
    }

    e.target.value = null;
  }

  parseFileData(file) { 
    const transformParams = this.props.extraParams.transformParams;

    FileTransformer.parseFile(file, transformParams.fileType, transformParams)
    .then((res) => {
      this.props.onChange(res.data, this.props.extraParams);
    });
  }
 
  renderExtraRightButtons(buttons){
    return buttons.map(button => {
      return (
        <InputGroup.Addon className="addonReset">
          <TooltippedButton 
              bsStyle='primary' 
              className={`${button.icon} removeUploaded`}
              tooltip={button.tooltip}
              onClick={button.onClick}
              disabled={button.disabled || (button.disabledIfInvalid && !this.props.validFile)}
          >
          </TooltippedButton>
        </InputGroup.Addon>
      );
    });
  }
  renderUploadButton(){
      return (
        <OverlayTrigger
          rootClose placement="bottom" trigger={['hover', 'focus']}
          overlay={<Tooltip className="in" >Upload</Tooltip>}>
          <InputGroup.Addon className="addonReset" disabled={this.props.disabled}>
            <label className={`${this.props.disabled? 'disabled': ''} upload-btn-wrapper btn btn-primary`}>
              <i className={Icons.upload}></i>
              <input type="file" onChange={this.onUploadFile} disabled={this.props.disabled}/>
            </label>
          </InputGroup.Addon>
        </OverlayTrigger>
      );
  }

  render() {
    return (
      <div className='file-uploader-wrapper-fuw'>
        <InputGroup>
          <div className='fuw-right-side'>
            <label className='fuw-file-name'>
              {
                this.state.selectedFile ? (
                  this.props.showFileName ? 
                    this.state.selectedFile.name
                    : 
                    <Fragment>
                      <i className={`${Icons.checkMarkCircle} fuw-check-icon`}></i> File Imported        
                    </Fragment>
                ) : (this.props.fileUploadedPlaceholder || 'Choose a file')
              }
            </label>
            {this.props.uploadInfo ?
              <OverlayTrigger rootClose
                placement="right"
                overlay={<Popover
                  id='reservedVariables'
                  className='reservedWords-popover'>
                    {this.props.uploadInfo}
                </Popover>
              }>
                <i className={`${Icons.info} margLft10`}></i>
              </OverlayTrigger>
              : null
            }
            {
              this.props.clear &&
                <InputGroup.Addon className="addonReset clearUploaded">
                  {ButtonsConstructor.getTooltippedButton( 'Clear', Icons.close, "tootltip-btn", this.props.disabled, this.clearUploadedFile )}
                </InputGroup.Addon> 
            }
          </div>
          <div>
            {this.renderExtraRightButtons(this.props.extraRightButtons)}
            {this.props.hideUploadInput ? null : this.renderUploadButton()}
          </div>
        </InputGroup>
        <ValidationOutput validation={this.state.validation} />
      </div>
    );
  }
}
