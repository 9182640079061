/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import $ from 'jquery';

export default class ColumnSearch extends React.Component {
  componentDidMount(){
    $(`.reactable.${ this.props.tableId}
      .${this.props.property.replace(/\./g, '_')}-column-filter .filter .ms-choice`).append(
      '<i class="ev ev-filter ev-sm" aria-hidden="true"></i>');
  }

  render() {
    const uniques = this.props.uniques || [];
    const optionsList = uniques.map((value) => {
      let key = value;
      let output = value;

      if(key == '' || key == ' ' || key == null){
        key = 'empty';
        output = '';
      }


      return(<option key={key} className={key == 'empty' ? 'emptySearchValue' : ''}
        value={value}>
        {output}
        </option>);
    }
    );
    const filtered = (this.props.filter
      && this.props.filter.length != 0
      && this.props.filter.length != optionsList.length) ?
       'filtered' : '';

    return (
        <div key={`${this.props.property.replace(/\./g, '_')}-column-filter`}
        onClick={(e) => {e.stopPropagation();}}
            className={`${this.props.property.replace(/\./g, '_')}-column-filter
             column-filter ${filtered}`}>
          <MultipleSelect
          className='filter'
              options={optionsList}
              name={this.props.property}
              value={(this.props.filter ) ?
                 this.props.filter
                 : uniques.map((option) => {
                   return option[this.props.property];
                 })
               }
               placeholder='Select Metric'
               multiple={!this.props.singleFilter}
               onClose={this.props.onQueryChange} />
        </div>
    );
  }
}
