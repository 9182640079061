/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {fromJS} from 'immutable';
import CommonTree from './CommonTree';
import {NETWORK_ELEMENT}  from 'utils/defaults';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import NetworkElementSelectionRadioGroup from 'dataSelection/NetworkElementSelectionRadioGroup';
import {CompareUtils, Utils} from 'js-utils';
import FilterInput from 'dataSelection/records/FilterInput';
import * as CommonTreeValidation from './CommonTreeValidation';
import ValidationOutput from 'containers/ValidationOutput';
import * as TreeNodeActionsHelper from './TreeNodeActionsHelper';

const INPUT_ID_ATT_NAME = 'id';
const INPUT_TREEPATH_ATT_NAME = 'treePath';
const SELECT_NETWORK_ELEMENT = 'Select Network Element';
const FIXED_WRAPPER_PADDING = 40;

export default class CommonTreeWrapper extends React.Component{
  constructor(props){
    super(props);
    this.groupChanged = false;
    this.previousSelection = [];
    this.previousOGSelection = [];

    this.onSelect = ::this.onSelect;
    this.onSearch = ::this.onSearch;
    this.setGroupSelection = ::this.setGroupSelection;
    this.loadSelection = ::this.loadSelection;
    this.onVisibilityToggle = ::this.onVisibilityToggle;
    this.onQueryChange = ::this.onQueryChange;
    this.getNetworkElementComponent = ::this.getNetworkElementComponent;
    this.handleNetworkLevelChange = ::this.handleNetworkLevelChange;
    this.updateAdvancedFilters = ::this.updateAdvancedFilters;
    this.getCustomSearchComponent = ::this.getCustomSearchComponent;
    this.getAdvancedFilterOptions = ::this.getAdvancedFilterOptions;
    this.onClick = ::this.onClick;
    this.isSelected = ::this.isSelected;
    this.getAdvancedFilterValidation = ::this.getAdvancedFilterValidation;
    this.getNodeActions = ::this.getNodeActions;
    this.onChangeState = ::this.onChangeState;

    this.state = this.loadSelection(props);
  }
  
  componentDidMount() {
    if(this.props.listAllNetworkElementTypes){
      this.props.listAllNetworkElementTypes().then(networkElementTypes => {
        this.setState({
          networkElementTypes,
        });
      });
    }else if(this.props.networkElementTypes){
      this.setState({
        networkElementTypes: this.props.networkElementTypes,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
  }

  componentWillReceiveProps(nextProps){
    if((!_.isEqual(nextProps.selection, this.props.selection) && 
      !_.isEqual(nextProps.selection, this.getSelection().selection)) || 
      !_.isEqual(nextProps.treeData, this.props.treeData)){
      const stateTemp = this.loadSelection(nextProps);

      this.setState(stateTemp);
    }else if(!_.isEqual(nextProps.attributesFilterOptions, this.props.attributesFilterOptions)
    || !_.isEqual(nextProps.networkElementTypes, this.state.networkElementTypes)){
      this.setState({attributesFilterOptions: nextProps.attributesFilterOptions,
        networkElementTypes: nextProps.networkElementTypes});
    }
  }

  isNEOptionsValid(){
    return !this.props.entityOptions.enableNESelection || this.isNEValid;
  }

  isAdvancedFilterOptionsValid(){
    return !this.props.entityOptions.showAdvancedFilters || this.isAdvancedFilterValid;
  }

  onChangeState(newState){
    this.setState(newState);
  }

  loadSelection(props){
    const selection = props.selection || {};

    const isValid = this.validateOptions(selection).isValid;
    const stateTemp = {
      validation: {isValid},
      searchQuery: (this.state && this.state.searchQuery) || '',
    };

    if(this.props.entityOptions.enableGroupElementSelection){
      stateTemp.selectedGroup = selection.selectionType || NETWORK_ELEMENT;
    }

    if(this.props.entityOptions.dynamicOrStaticSelection){
      stateTemp.selectedTreeNodes =  selection.treeNodes || [];
      stateTemp.selectedOrganizationGroups = selection.organizationGroups || [];
    }else if(this.props.entityOptions.singleSelection){
      if(selection.selectedNodeId){
        stateTemp.selectedNodePath = selection.selectedNodePath;
        stateTemp.selectedNodeId = selection.selectedNodeId;
        stateTemp.selectedTreeNodes = [{treeNodeId: selection.selectedNodeId}];
        stateTemp.selectedDataStructureId = selection.selectedDataStructureId || undefined;
      }else if(selection.treeNodes && selection.treeNodes.length > 0){
        stateTemp.selectedTreeNodes = selection.treeNodes;
        stateTemp.selectedNodePath = selection.treeNodes[0].treeNodePath;
        stateTemp.selectedNodeId = selection.treeNodes[0].treeNodeId;
      }else{
        stateTemp.selectedDataStructureId = selection.selectedDataStructureId || undefined;
        stateTemp.selectedTreeNodes =  [];
        stateTemp.selectedNodeId = null;
        stateTemp.selectedNodePath = null;
      }
      stateTemp.selectedOrganizationGroups = [];
    }

    if(this.props.entityOptions.enableNESelection || this.props.entityOptions.showNESelection){
      stateTemp.selectedNetworkElement = selection.networkElementDataStructure || {};
    }

    if(this.props.entityOptions.showAdvancedFilters){
      stateTemp.advancedFilters = selection.advancedFilters || [];
    }
    if(props.attributesFilterOptions){
      stateTemp.attributesFilterOptions = props.attributesFilterOptions;
    }
    const query = this.getQuery({}, stateTemp);

    if(this.isNEOptionsValid()){
      if(this.props.getTreeNodesAction){
        stateTemp.treeData = undefined;
        this.getTreeData(query, stateTemp.selectedTreeNodes, stateTemp.selectedOrganizationGroups);
      }else{
        stateTemp.treeData = props.treeData;
      }
      this.getAdvancedFilterOptions(stateTemp.selectedNetworkElement);
    }
    this.previousSelection = stateTemp.selectedTreeNodes;
    this.previousOGSelection = stateTemp.selectedOrganizationGroups;

    return stateTemp;
  }

  validateOptions(selection){
    const validation = {};
    let isValid = true;
    this.isAdvancedFilterValid = true;
    if(this.props.entityOptions.showAdvancedFilters && selection.advancedFilters){
      this.isAdvancedFilterValid = CommonTreeValidation.validateAdvancedFilter(selection.advancedFilters, validation, 
        {checkValidation: true, checkListValidation: true});
      isValid = this.isAdvancedFilterValid;
    }
    
    this.isNEValid = true;
    if(this.props.entityOptions.enableNESelection){
      this.isNEValid = CommonTreeValidation.validateNELevel(selection.networkElementDataStructure, validation);
      isValid = isValid && this.isNEValid;
    }
  
    if(this.props.entityOptions.dynamicOrStaticSelection){
      isValid = isValid && CommonTreeValidation.validateSelection(selection.treeNodes,
        selection.organizationGroups, validation, this.props.entityOptions.isOptionalSelection);
    }else if(this.props.entityOptions.singleSelection){
      isValid = isValid && CommonTreeValidation.validateSingleSelection(selection, validation);
    }
    validation.isValid = isValid;
    
    return validation;
  }

  saveSelection(){
    const selection = this.getSelection().selection;
    const validation = this.validateOptions(selection);

    if(validation.isValid){
      if(this.props.entityOptions.dynamicOrStaticSelection && this.props.getSelectionFinalForm 
        && this.props.entityOptions.treeHelper.isNE(this.state.selectedGroup)){
        this.props.getSelectionFinalForm(selection).then(selectionleafs => {
          this.props.updateSelection(selectionleafs);
        });
      }else if(this.props.entityOptions.singleSelection && this.props.getNodePath){
        this.props.getNodePath(this.state.selectedNodeId).then(selectedNodePath => {
          this.props.updateSelection({...selection, selectedNodePath});
        });
      }else if(this.props.returnSelection){
        return selection;
      }else{
        this.props.updateSelection(selection);
      }
    }else {
      this.setState({validation});
    }

    return validation.isValid;
  }

  getSelection(){
    const selection = {
      networkElementDataStructure: this.state.selectedNetworkElement,
      selectionType: this.state.selectedGroup,
      advancedFilters: this.state.advancedFilters,
    };

    if(this.props.entityOptions.dynamicOrStaticSelection){
      selection.treeNodes = this.state.selectedTreeNodes;
      selection.organizationGroups = this.state.selectedOrganizationGroups;
    }else if(this.props.entityOptions.singleSelection){
      selection.selectedNodeId = this.state.selectedNodeId;
      selection.selectedDataStructureId = this.state.selectedDataStructureId;
    }

    return {selection, isValid: this.state.validation.isValid};
  }

  onSearch(searchQuery) {
    const state  = {searchQuery,
      selectedTreeNodes: this.state.selectedTreeNodes,
      selectedOrganizationGroups: this.state.selectedOrganizationGroups,
    };
    const validationTmp = this.getAdvancedFilterValidation(this.state.advancedFilters, true, true);

    state.validation = validationTmp;

    this.onQueryChange(state);
  }

  onSelect(selectedNodes) {
    this.groupChanged = false;
    const selectedOrganizationGroups = selectedNodes.selectedOrganizationGroupsData || this.state.selectedOrganizationGroups;
    let selectedTreeNodes = this.state.selectedTreeNodes;

    if(selectedNodes.staticallySelectedTreeNodes){
      const newStaticallySelectedTreeNodes = selectedNodes.staticallySelectedTreeNodes.map(id => {
        return {treeNodeId: id, dynamic: false};
      });
      const newdynamicallySelectedTreeNodes = selectedNodes.dynamicallySelectedTreeNodes.map(id => {
        return {treeNodeId: id, dynamic: true};
      });

      selectedTreeNodes = [...newdynamicallySelectedTreeNodes, ...newStaticallySelectedTreeNodes];
    }

    const validation = fromJS(this.state.validation).toJS();

    validation.isValid = CommonTreeValidation.validateSelection(selectedTreeNodes, selectedOrganizationGroups, validation, this.props.entityOptions.isOptionalSelection);

    this.setState({ selectedTreeNodes, selectedOrganizationGroups, validation}, () => {
      if(this.props.onSelect){
        this.props.onSelect(this.getSelection());
      }
    });
  }

  onClick(node, path){
    const selection = {};
    if(this.props.canClick && this.props.canClick(node)){
      if(this.props.entityOptions.singleSelection){
        selection.selectedNodeId = node[this.props.entityOptions.nodeId];
        selection.selectedDataStructureId = node[this.props.entityOptions.dataStructureId];
        selection.advancedFilters = this.state.advancedFilters;
        this.setState(selection, () => {
          if(this.props.onClick){
            this.props.onClick(node, this.getSelection(), path);
          }
        });
      }else if(this.props.onClick){
        this.props.onClick(node, selection, path);
      }
    }
  }

  isSelected(node){
    return this.state.selectedNodeId === node[this.props.entityOptions.nodeId]
      && this.state.selectedDataStructureId == node[this.props.entityOptions.dataStructureId];
  }

  onVisibilityToggle({node, expanded}){
    if(expanded && node.children == 0){
      if(this.props.getTreeNodesAction){
        const query = this.getQuery({}, this.state);

        this.props.getTreeNodesAction(query, node).then(treeNodes => {
          this.setState({treeData: treeNodes, treeDataRoot: node});
        });

        return true;
      }
    }

    return false;
  }

  onQueryChange(state) {
    const query = this.getQuery(state, this.state);
    const selectedTreeNodes = state.selectedTreeNodes || null;
    const selectedOrganizationGroups = state.selectedOrganizationGroups || null;

    this.setState({...state, treeData: undefined}, () => {
      if(this.isNEOptionsValid() && this.isAdvancedFilterOptionsValid()){
        this.getTreeData(query, selectedTreeNodes, selectedOrganizationGroups);
      }
    });
  }

  getQuery(newQuery, currentState){
    const query = {};

    query.searchQuery = Utils.isBlank(newQuery.searchQuery) ? currentState.searchQuery : newQuery.searchQuery;
    if(newQuery.selectedGroup){
      query.selectedGroup = newQuery.selectedGroup;
    }else if(currentState.selectedGroup){
      query.selectedGroup = currentState.selectedGroup;
    }

    if(newQuery.advancedFilters){
      query.selectionFilters = newQuery.advancedFilters;
    }else if(currentState.advancedFilters){
      query.selectionFilters = currentState.advancedFilters;
    }

    if(newQuery.selectedNetworkElement){
      query.networkElementDataStructureId = newQuery.selectedNetworkElement.id;
    }else if(currentState.selectedNetworkElement){
      query.networkElementDataStructureId = currentState.selectedNetworkElement.id;
    }

    return query;
  }

  getTreeData(query, selectedTreeNodes, selectedOrganizationGroups){
    const loadedQuery = fromJS(query).toJS();
    if(selectedTreeNodes || selectedOrganizationGroups){
      loadedQuery.selectedTreeNodes = selectedTreeNodes.map(treeNode => treeNode.treeNodeId);
      loadedQuery.selectedOrganizationGroups = selectedOrganizationGroups.map(og => og.organizationGroupId);
    }
    this.props.getTreeNodesAction(loadedQuery).then(treeNodes => {
      this.setState({treeData: treeNodes, treeDataRoot: undefined});
    });
  }
  
  setGroupSelection(e) {
    if (e.target.value !== this.state.selectedGroup) {
      this.groupChanged = !this.groupChanged;
      const state = {
        selectedGroup: e.target.value,
        selectedTreeNodes: this.groupChanged ? [] : this.previousSelection,
        selectedOrganizationGroups: this.groupChanged ?  [] : this.previousOGSelection,
        advancedFilters: this.groupChanged ?  [] : this.previousAdvancedFilters,
      };
      const validationTmp = this.getAdvancedFilterValidation(state.advancedFilters, true);

      state.validation = validationTmp;
      this.previousSelection = fromJS(this.state.selectedTreeNodes).toJS();
      this.previousOGSelection = fromJS(this.state.selectedOrganizationGroups).toJS();
      if(this.state.advancedFilters){
        this.previousAdvancedFilters = fromJS(this.state.advancedFilters).toJS();
      }
      this.onQueryChange(state);
    }
  }

  handleNetworkLevelChange(e){
    if(e.target.value != (this.state.selectedNetworkElement || {}).id){
      const selectedNetworkElement = this.state.networkElementTypes
              .filter(NE => NE.id == e.target.value)[0];

      const state = {selectedNetworkElement, 
        selectedTreeNodes: [],
        selectedOrganizationGroups: [],
        advancedFilters: [],
        selectedNodeId: null,
        selectedDataStructureId: null};
        
      this.isAdvancedFilterValid = true;
      this.isNEValid = true;
      state.validation = {};
      this.onQueryChange(state);
      this.getAdvancedFilterOptions(selectedNetworkElement);
    }
  }

  getNetworkElementComponent(){
    if(this.props.entityOptions.showNESelection && this.props.entityOptions.enableNESelection){
      const options =  UIConstructionUtils.wrapArrayInToSelectOptions(this.state.networkElementTypes,
        INPUT_ID_ATT_NAME,
        INPUT_ID_ATT_NAME,
        INPUT_TREEPATH_ATT_NAME);
      const NESelectComponent = <Col xs={12}>
        <MultipleSelect placeholder={SELECT_NETWORK_ELEMENT}
          options={options}
          disabled={this.props.disabled}
          value={this.state.selectedNetworkElement ? this.state.selectedNetworkElement.id : null}
          onChange={this.handleNetworkLevelChange}/>
        <ValidationOutput validation={ this.state.validation.NELevelOption } />
      </Col>;

      return NESelectComponent;
    }
    
    return null;
  }

  getAdvancedFilterOptions(selectedNetworkElement){
    if(this.props.entityOptions.showAdvancedFilters && selectedNetworkElement && selectedNetworkElement.id 
      && this.props.listAttributesOfAdvancedFiltersComponent){
      this.props.listAttributesOfAdvancedFiltersComponent(selectedNetworkElement.id).then( attributesFilterOptions => {
        this.setState({attributesFilterOptions});
      });
    }
  }
  getAdvancedFilterValidation(advancedFilters, checkValidation, checkListValidation){
    const validation = {};
    this.isAdvancedFilterValid = CommonTreeValidation.validateAdvancedFilter(advancedFilters, validation, 
        {checkValidation: true});
    const validationTmp = fromJS(this.state.validation).toJS();

    if(checkListValidation || checkValidation){
      validationTmp.filterInput = validation.filterInput;
    }
    validationTmp.isValid = this.isAdvancedFilterValid;

    return validationTmp;
  }
  updateAdvancedFilters(newAdvancedFilters, checkValidation, checkListValidation){
    const advancedFilters = newAdvancedFilters.filters;
    const state = {
      advancedFilters,
      selectedTreeNodes : [],
      selectedOrganizationGroups : [],
      selectedNodeId: null,
      selectedDataStructureId: null,
    };
    const validationTmp = this.getAdvancedFilterValidation(advancedFilters, checkValidation, checkListValidation);

    state.validation = validationTmp;
    if(!_.isEqual(this.state.advancedFilters, advancedFilters)){
      if(this.isAdvancedFilterValid){
        this.onQueryChange(state);
      }else{
        this.setState(state);
      }
    }
  }

  getCustomSearchComponent(){
    const operations = UIConstructionUtils.wrapArrayInToSelectOptions(CompareUtils.COMPARATORS, 
      'value', 'value', 'name');

    return <FilterInput
      operations={operations}
      updateFiltersList={this.updateAdvancedFilters}
      attributesList={this.state.attributesFilterOptions}
      selectionAdvancedFilters
      validationOutcome={this.state.validation.filterInput || {}}
      filters={this.state.advancedFilters} 
      disableComponent={this.props.disabled}
      
      />;
  }

  getNodeActions(node, otherProps){
    return this.props.buttonsJson? TreeNodeActionsHelper.getNodeActions(this.props.buttonsJson, node, otherProps) : null;
  }

  getDynamicTree(isShowTree, height){
    const staticallySelectedTreeNodes = [];
    const dynamicallySelectedTreeNodes = [];

    if(this.state.selectedTreeNodes){
      this.state.selectedTreeNodes.forEach( node => {
        (node.dynamic ? dynamicallySelectedTreeNodes : staticallySelectedTreeNodes).push(node.treeNodeId);
      });
    }
    const selectedOrganizationGroups = this.state.selectedOrganizationGroups ? 
      this.state.selectedOrganizationGroups.map(og => og.organizationGroupId) : [];
    
    const dynamicTree = (
      <CommonTree
        validation={this.state.validation.selectedNodes}
        onVisibilityToggle={this.onVisibilityToggle}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
        onClick={this.onClick}
        isSelected={this.isSelected}
        staticallySelectedTreeNodes={staticallySelectedTreeNodes}
        dynamicallySelectedTreeNodes={dynamicallySelectedTreeNodes}
        selectedOrganizationGroups={selectedOrganizationGroups}
        selectedOrganizationGroupsData={this.state.selectedOrganizationGroups}
        selectedNetworkElementName={(this.state.selectedNetworkElement || {}).name}
        advancedFilters={this.state.advancedFilters}
        treeData={this.state.treeData}
        treeDataRoot={this.state.treeDataRoot}
        isShowTree={isShowTree}
        selectedGroup={this.state.selectedGroup}
        searchQuery={this.state.searchQuery}
        entityOptions={this.props.entityOptions}
        getCustomSearchComponent={this.getCustomSearchComponent}
        getNodeActions={this.getNodeActions}
        onChangeState={this.onChangeState}
        getTitle={this.props.getTitle}
        getIcon={this.props.getIcon}
        canHighlight={this.props.canHighlight}
        getNodeKey={this.props.getNodeKey}
        noDataAvailableMessage={this.props.noDataAvailableMessage}
        treeHelper={this.props.treeHelper}
        height={height}
        forceExpand={this.props.forceExpand}
        resetForceExpand={this.props.resetForceExpand}
        disabled={this.props.disabled}
      />);
        
    return dynamicTree;
  }

  render(){
    const isNEValid = this.isNEOptionsValid();
    const isShowTree = isNEValid && this.isAdvancedFilterOptionsValid();
    
    const stylingOptions = this.props.entityOptions.stylingOptions;

    const height =  stylingOptions.isFixedHeight ? stylingOptions.treeHeight : 
    (document.documentElement.clientHeight - stylingOptions.pagePadding);
    
    return (
      <Fragment>
        <Row>{this.getNetworkElementComponent()}</Row>
        <Row>
        {
          isNEValid ?
          <Col xs={12}>
            {this.props.entityOptions.showGroupElementSelection ? 
            <NetworkElementSelectionRadioGroup
              disabled={this.props.disabled}
              selectedGroup={this.state.selectedGroup}
              setGroupSelection={this.setGroupSelection}
            /> : null}
            <Row>
              <Col xs={12} style={{height: height + FIXED_WRAPPER_PADDING}}>
                {this.getDynamicTree(isShowTree, height)}
              </Col>
            </Row>
          </Col>
          : null
        }
        </Row>
      </Fragment>
    );
  }
}

CommonTreeWrapper.propTypes = {
  getIcon: PropTypes.func, // geticon for each node if iconName in node is null
  getTitle: PropTypes.func, // get title for each node if title in node is null
  canHighlight: PropTypes.func, // canHighlight return true if this node can highlight when searchword match
  entityOptions: PropTypes.object, // contain features for tree check ExportedTreeOptions.js
};
