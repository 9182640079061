/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {ControlLabel} from 'react-bootstrap';

export default (props)=>{
  return(
    <div className={`${props.className || ''}`}>
      {
        props.label?
        <ControlLabel className={props.labelClassName}>
          {props.label}

        </ControlLabel> : null
      }
      {
        props.hint ? props.hint : null
      }
      <div className={`radiobutton-group ${props.childrenClassName || ''}`}>
        {props.children}
      </div>
    </div>
  )
}
