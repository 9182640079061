/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import {Row, Col, Button} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';

import DataInput from 'inputs/simple/DataInput';
import MultipleSelect from 'inputs/simple/MultipleSelect';
import DatePicker from 'inputs/simple/DatePicker';
import Icons from 'templates/Icons';

const Options = ['YEARS', 'MONTHS', 'WEEKS', 'DAYS', 'HOURS', 'MINUTES', 'SECONDS'];
export default class ResolutionAndDatePickerPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolution: this.props.colAttrResolution || 0,
      resType: this.props.colAttrRestype || 'MINUTES',
      startTime: this.props.colAttrStartTime || null,
    };
    this.onResolutionChange = :: this.onResolutionChange;
    this.onDatePickerChange = :: this.onDatePickerChange;
    this.onResTypeChange = :: this.onResTypeChange;

  }
  onResolutionChange(e){
     this.setState({resolution: e.target.value});
  }

  onDatePickerChange(e){
    this.setState({startTime: e.date});
  }

  onResTypeChange(e){
    this.setState({resType: e.target.value});
  }
  render(){
    const title = <div className="downloadCenter">
      <div className='pull-left' style={{wordWrap: 'break-word'}}>
        Edit Resolution
      </div>
      <div className='pull-right'>

        <TooltippedButton tooltip='Cancel'
        className="btn-action"
        onClick={() => {
          this.overlay.hide();
        }}>
          <i className={Icons.close}></i>
        </TooltippedButton>

        <TooltippedButton tooltip='Save'
        className="btn-action"
        onClick={() => {
          this.props.onSaveResolution(this.state.resolution, this.state.resType, this.state.startTime);
          this.overlay.hide();
        }}>
          <i className={Icons.checkMark}></i>
        </TooltippedButton>
      </div>
    </div>;
      return(
      <OverlayTrigger
        placement="bottom" trigger="click" show='true'
        ref={ ref => this.overlay = ref}
        overlay={
          <Popover title={title} className="systemMood">
             <Row>
                 <DataInput
                   type='number'
                   step='1'
                   size={5}
                   inputSize={12}
                   onChange={this.onResolutionChange}
                   value={
                     this.state.resolution
                   }
                  />
                 <Col xs={7}>
                   <MultipleSelect
                     className="input-text"
                     value={this.state.resType}
                     multiple={false}
                     options={Options.map(type => {
                       return (
                         <option key={type} value={type}>
                           {type}
                         </option>
                       );
                     })}
                     onChange={this.onResTypeChange}
                   />
                 </Col>
               </Row>
               <Row>
                <Col xs={12}>
                  <DatePicker
                    timePicker
                    timePicker24Hour
                    onChange={this.onDatePickerChange}
                    date={this.state.startTime}
                    placeholder='Start Date'
                    />
                  </Col>
                </Row>
          </Popover>
        }
        >
          <OverlayTrigger
            rootClose placement="bottom" trigger={['hover', 'focus']}
            overlay={
              <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                Edit Resolution
              </Tooltip>
            }
            >
            <Button className="btn-action"  onClick={(e) => {
                e.stopPropagation();
              }}>
              <i className={Icons.edit}></i>
            </Button>
          </OverlayTrigger>
      </OverlayTrigger>
    );
  }
}
