/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Col} from 'react-bootstrap';
import CollapsableComponent from 'containers/CollapsableComponent';
import {fromJS} from 'immutable';
import * as defaults from 'utils/defaults';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import DataFieldInputRow from './DataFieldInputRow';
import {AggregationUtils, UnitConversionUtils, DateTimeUtils, DataModelTypeUtils} from 'js-utils';
import * as DataSelectionCommonUtils from 'utils/DataSelectionCommonUtils';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';

export default class DataFieldInput extends React.Component{

  static getAttributeList(fields, attributeListProps){
    const selectedFields = [];

    fields.forEach((field) => {
      if(field.selectedField != null){
        selectedFields.push(field.selectedField.id);
      }
    });

    const attributeList = [];

    if(attributeListProps){
      attributeListProps.forEach((attributeItem ) => {
        if(selectedFields.includes(attributeItem.id)){
          attributeList.push({selectedField: attributeItem, selected: true});
        }else{
          attributeList.push({selectedField: attributeItem, selected: false});
        }
      });
    }
    return {attributeList};
  }

  static getDerivedStateFromProps(props, state) {
    return DataFieldInput.getAttributeList(props.fields, props.attributeList);
  }

  constructor(props){
    super(props);

    this.addField = ::this.addField;
    this.onChangeAttribute = ::this.onChangeAttribute;
    this.onChangeFieldForWeightedAvg = ::this.onChangeFieldForWeightedAvg;
    this.onChangeField = ::this.onChangeField;
    this.onDeleteField = ::this.onDeleteField;
    this.prepareAttributeField = ::this.prepareAttributeField;
    this.onChangeFieldUnit = ::this.onChangeFieldUnit;
  }

  addField(){
    const fields = fromJS(this.props.fields).toJS();

    fields.push({
      idCounter: DateTimeUtils.getCurrentDateTimeinMillieSeconds(),
      aggregationType: null, 
      selectedField: null});
    
    this.props.onChange({fields}, false, true, false);
  }

  onChangeFieldForWeightedAvg(index, weightedAvgField){
    const fields = fromJS(this.props.fields).toJS();
    fields[index].weightedAvgField = weightedAvgField;

    this.props.onChange({fields}, true, true, true);
  }

  onChangeAttribute(index, selectedField){
    const fields = fromJS(this.props.fields).toJS();
    fields[index].selectedField = selectedField;
    const fieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([fields[index]]);
    const oldFieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([this.props.fields[index]]);
    const resetUnit = DataSelectionCommonUtils.shouldResetUnit(fieldTypeDescriptor, oldFieldTypeDescriptor);

    if(resetUnit)
      fields[index].fieldUnitFactor = UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
  
    this.props.onChange({fields}, true, true, true);
  }

  onChangeField(index, aggregation, percentileValue, weightedAvgField, initializeField){
    const fields = fromJS(this.props.fields).toJS();
    fields[index] = this.prepareAttributeField(fields[index], aggregation, percentileValue, weightedAvgField, initializeField);

    const fieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([fields[index]]);
    const oldFieldTypeDescriptor = DataSelectionCommonUtils.getFieldsTypeDescriptor([this.props.fields[index]]);
    const resetUnit = DataSelectionCommonUtils.shouldResetUnit(fieldTypeDescriptor, oldFieldTypeDescriptor);
    if(resetUnit){
      fields[index].fieldUnitFactor = UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
    }
    this.props.onChange({fields}, true, true, true);
  }

  prepareAttributeField(attributeField, aggregation, percentileValue, weightedAvgField, initializeField){
    attributeField.aggregationType = aggregation;
    attributeField.percentileValue = percentileValue;
    attributeField.weightedAvgField = weightedAvgField;
    if(initializeField){
      attributeField.selectedField = null;
    }

    if(aggregation == AggregationUtils.COUNT){
      this.state.attributeList.forEach((item) => {
        if (item.selectedField.elasticsearchName == AggregationUtils.COUNT_ES_FIELDNAME){
          attributeField.selectedField = item.selectedField;
        }
      });
    }

    return attributeField;
  }

  onDeleteField(index){
    const fields = fromJS(this.props.fields).toJS();
    fields.splice(index, 1);

    this.props.onChange({fields}, true, true, true);
  }

  onChangeFieldUnit(unit, index){
    const fields = fromJS(this.props.fields).toJS();

    fields[index].fieldUnitFactor = unit;
    this.props.onChange({fields}, true, true);
  }

  render(){
    const validation = this.props.validationOutcome;
    const isCountExists = this.props.fields.length > 0 && this.props.fields.some(field => field.aggregationType === AggregationUtils.COUNT);

    const dataFieldInputRows = this.props.fields.map((attributeField, index) => {
      const attributeFieldKey = UIConstructionUtils.getKeyFromId(attributeField);
      const selectedField = attributeField.selectedField ? attributeField.selectedField: null;

      const aggregation = attributeField.aggregationType == null ? 
        '' : attributeField.aggregationType.toUpperCase();
      const subFields = DataSelectionUtils.filterFieldsByAggregationType(
        DataSelectionCommonUtils.getSubFields(selectedField, 
          this.props.fieldsList, this.props.groupByFieldIds), aggregation);
      const supportMultiWholeObject = !AggregationUtils.isNumeric(
        AggregationUtils.getAggregationFieldType(aggregation));

      const filteredAttributeList = [];
      const attributeList = this.state.attributeList;

      for(let i = 0; i < attributeList.length; i++){
        const field = attributeList[i].selectedField;

        if(!DataModelTypeUtils.isObjectFieldType(field)
          || supportMultiWholeObject || DataSelectionCommonUtils.hasUnusedSubFields(
          DataSelectionUtils.filterFieldsByAggregationType(
            field?.subFields || [], aggregation), this.props.groupByFieldIds)){
          filteredAttributeList.push(attributeList[i]);
        }
      }

      return (
        <DataFieldInputRow
          key = {`${attributeFieldKey}-${isCountExists}`}
          attributeList={filteredAttributeList}
          aggregations={this.props.aggregations}
          selectedField={selectedField}
          subFields={subFields}
          supportMultiWholeObject={supportMultiWholeObject}
          weightedAvgField={attributeField.weightedAvgField !== (undefined || null)?  attributeField.weightedAvgField : {}}
          aggregation={attributeField.aggregationType != null ? attributeField.aggregationType.toUpperCase() : null}
          percentileValue={attributeField.percentileValue !== (undefined || null)? attributeField.percentileValue : AggregationUtils.NTH_PERCENTILE_DEFAULT_VALUE}
          index={index}
          onChangeAttribute={this.onChangeAttribute}
          onChangeFieldForWeightedAvg={this.onChangeFieldForWeightedAvg}
          onChangeField={this.onChangeField}
          onDeleteField={this.onDeleteField}
          validationOutcome= {validation && validation.fieldList? validation.fieldList[index] : null}
          fieldsList={this.props.fieldsList}
          disableComponent={this.props.disableComponent}
          isCountExists={isCountExists}
          onChangeFieldUnit={this.onChangeFieldUnit}
          fieldUnitFactor={attributeField.fieldUnitFactor}
          hasUnit={this.props.hasUnit}
        />
      );
    });

    const collapsedTitle = defaults.FIELD + '*';
    const dateFieldButtons = [
      ButtonsConstructor.add(
        this.addField, defaults.ADD_Field,
        this.props.disableComponent || (!this.props.disableComponent && (this.props.maxFields && dataFieldInputRows.length >= this.props.maxFields))
      ),
    ];

    return (
      <Fragment>
        <CollapsableComponent
          title={collapsedTitle}
          buttons={dateFieldButtons}
          validation={validation ? validation.fields : {}}
          wrapInRow
          className='margBtm10' /* add space below aggregation */
        >
          {dataFieldInputRows}
        </CollapsableComponent>
      </Fragment>
    )
  }
}