/* Copyright 2019 Greyskies. All Rights Reserved. */

import { isArray } from 'lodash';

const exact = queryTerm => ({
  evaluate(searchText = '') {
    if (!searchText) {
      return false;
    }

    if (isArray(searchText)) {
      return searchText.some(v => this.evaluate(v));
    }

    return searchText == queryTerm;
  },
  matches(searchText = '') {
    if (!searchText) {
      return [];
    }

    if (isArray(searchText)) {
      return searchText.reduce(
        (result, text, index) => {
          const search = this.matches(text);

          if (search.length) {
            result[index] = search; // eslint-disable-line no-param-reassign
          }

          return result;
        },
        new Array(searchText.length)
      );
    }

    if (searchText == queryTerm) {
      return [
        {
          startIndex: 0,
          length: queryTerm.length,
        },
      ];
    }

    return [];
  },
});

export default {
  exact,
};
