/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col, ControlLabel} from 'react-bootstrap';
import MultipleSelect from './MultipleSelect';

const WEEK_INITIAL_INDEX = 1 ;
const WEEK_MAX_INDEX = 52 ;

export default class WeekPicker extends React.Component {
  constructor(props){
    super(props);
    this.state={
      years:[2017, 2016, 2015],
      weeks: [],
      selectedWeeks: [],
      onChange:{} ,
    }
    this.initializeWeeks();
  }

  initializeWeeks(){
    var weeksIndices = [];
    for (var i = WEEK_INITIAL_INDEX; i <= WEEK_MAX_INDEX; i++) {
      weeksIndices[i] = i;
    }
    this.state.weeks = weeksIndices.map(function(index){
      var week={};
      week.value=index;
      week.label="W" + index;
      return week;
    });
  }

  getWeeksFromSelection(e){
    var selection = e.target.value;
    var selectedWeeks = selection.map(function(element){
      return Number(element);
    });
    this.setState({selectedWeeks});
    return selectedWeeks;
  }

  getYearFromEvent(e){
    var selectedYear = e.target.value;
    this.setState({selectedYear});
    return selectedYear;
  }

  render() {
    var options = this.state.weeks.map((element)=>{
      return (
        <option key={element.value} value={element.value}>
          {element.label}
        </option>
      );
    });
    var selectWeekField =
      <div>
        <ControlLabel>Select Weeks</ControlLabel>
        <MultipleSelect id="week-multiselect"
            placeholder="Select Weeks"
            multiple={true}
            limit={10}
            unit="weeks"
            options={options}
            onChange= { e => this.props.onChange({
              weeks: this.getWeeksFromSelection(e),
              year: this.state.selectedYear,
            })} />
      </div>;

    var yearsInputField = null;
    if (this.state.years){
      var years =
        this.state.years.map((year) =>{
          return <option key={year} value={year}>
                  {year}
                </option>;
        });
      yearsInputField = <div>
        <ControlLabel>Select Year</ControlLabel>
        <MultipleSelect options={years}
        placeholder="Year" filter={false}
        onChange= { e => this.props.onChange({
          weeks: this.state.selectedWeeks,
          year: this.getYearFromEvent(e)
        })}/>
      </div>;
    }

    return (
      <div>
        <Row>
          <Col md={6} style={{marginBottom:"10px"}}>
            {selectWeekField}
          </Col>
          <Col md={6} style={{marginBottom:"10px"}}>
            {yearsInputField}
          </Col>
        </Row>
      </div>
    )
  }
}
