/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as PropertiesHelper from '../PropertiesHelper';
import {ResolutionUtils} from 'js-utils';

export const ansibleProps = {
  className: 'com.eventumsolutions.nms.workflow.manager.listeners.AnsibleListener',
  id: 'ansibleTab',
  label: 'Ansible',
  isUserTask: false,
  inputsInfo: {
    id: 'ansibleInput',
    label: 'Inputs',
    inputs: {
      ansibleName: {
        id: 'ansibleName',
        label: 'Ansible Project*',
        modelProperty: 'val',
        index: 0,
        inputTag: 'ansibleName',
        defaultValue: null,
        type: 'selectBox',
        inputOptions: {
          getSelectOptions: (reactHandler) => {
            return reactHandler.ansibleProjects;
          },
          indexClearedOnChange: [1],
        },
      },
      entryPoint: {
        id: 'entryPoint',
        label: 'Entry Point*',
        index: 1,
        inputTag: 'entryPoint',
        modelProperty: 'val',
        defaultValue: null,
        getCustomId: (element) => {
          const selectedAnsible = PropertiesHelper.getInputParamValueAtIndex(element, 0);
  
          return `entryPoint_${selectedAnsible}`;
        },
        type: 'selectBox',
        inputOptions: {
          getSelectOptions: (reactHandler, element) => {
            const selectedAnsible = PropertiesHelper.getInputParamValueAtIndex(element, 0);
  
            return selectedAnsible && reactHandler.ansibleProjectsDetails[selectedAnsible] ? reactHandler.ansibleProjectsDetails[selectedAnsible].files : [];
          },
          indexClearedOnChange: [1],
        },
      },
      ansibleParams: {
        id: 'ansibleParams',
        label: 'Parameters',
        modelProperty: 'val',
        index: 2,
        inputTag: 'ansibleParams',
        defaultValue: '',
        type: 'textBox',
      },
    },
  },
  outputsInfo: {
    id: 'output-parameters',
    label: 'Outputs',
    outputs:{
      outputParameters: {
        type: 'collapsible',
        hasAddBtn: true,
        defaultValues: {
          exitCode: {
            id: 'exitCode',
            index: 0,
            label: 'Exit_Code',
            name: 'Exit_Code_',
            value: '${exitCode}',
          },
        },
        collapsibleItems: {
          name: {
            id: 'parameterName',
            label: 'Variable Name',
            modelProperty: 'name',
            type: 'validationTextField',
          },
          value: {
            id: 'parameterType-text',
            label: 'Value',
            modelProperty: 'value',
            type: 'textField',
          },
        },
      },
    },
    
  },

};

export const emailInputs = {
  emailTo: {
    id: 'emailTo',
    label: 'To*',
    index: 0,
    inputTag: 'to',
    type: 'textField',
  },
  emailCc: {
    id: 'emailCc',
    label: 'Cc',
    index: 1,
    inputTag: 'cc',
    type: 'textField',
  },
  emailBcc: {
    id: 'emailBcc',
    label: 'Bcc',
    index: 2,
    inputTag: 'bcc',
    type: 'textField',
  },
  emailSubject: {
    id: 'emailSubject',
    label: 'Subject',
    inputTag: 'subject',
    type: 'textField',
    index: 3,
  },
  emailBody: {
    id: 'emailBody',
    label: 'Body',
    inputTag: 'body',
    type: 'textBox',
    index: 4,
  },
  attachments: {
    id: 'attachments',
    label: 'Attachments',
    inputTag: 'attachments',
    index: 5,
    addLabel: 'Attachment',
    type: 'table',
  },
};
export const notificationEmailProps = {
  className: 'com.eventumsolutions.nms.workflow.manager.listeners.NotificationEmailListener',
  id: 'emailTab',
  label: 'Email',
  isUserTask: false,
  inputsInfo: {
    id: 'emailInput',
    label: 'Inputs',
    inputs: emailInputs,
  },
  outputsInfo: {
    id: 'emailConstants',
    label: 'Constants',
  },
};

export const approvalEmailProps = {
  className: 'com.eventumsolutions.nms.workflow.manager.listeners.ApprovalEmailListener',
  isUserTask: true,
  id: 'emailTab',
  label: 'Email',
  inputsInfo: {
    id: 'emailInput',
    label: 'Inputs',
    inputs: {
      ...emailInputs,
      expiryFactor: {
        id: 'expiryFactor',
        label: 'Expires After*',
        inputTag: 'expiryFactor',
        defaultValue: '1',
        type: 'textField',
        index: 6,
      },
      expiryUnit: {
        id: 'expiryUnit',
        label: 'Unit*',
        inputTag: 'expiryUnit',
        index: 7,
        type: 'selectBox',
        defaultValue: ResolutionUtils.TIME_RESOLUTION_OPTIONS[2],
        inputOptions: {
          getSelectOptions: () => {
            return ResolutionUtils.TIME_RESOLUTION_OPTIONS.map(unit => {
              return {
                name: unit,
                value: unit,
              };
            });
          },
        },
      },
    },
  },
  outputsInfo: {
    id: 'emailConstants',
    label: 'Constants',
  },
};

export const reportDesignerProps = {
  className: 'com.eventumsolutions.nms.workflow.manager.listeners.ReportDesignerListener',
  id: 'reportDesignerTab',
  label: ' Report',
  isUserTask: true,
  inputsInfo: {
    id: 'reportDesignerInput',
    label: 'Inputs',
    inputs: {
      reportId: {
        id: 'reportId',
        label: 'Reports *',
        modelProperty: 'val',
        index: 0,
        inputTag: 'reportId',
        defaultValue: null,
        type: 'selectBox',
        inputOptions: {
          getSelectOptions: (reactHandler) => {
            return reactHandler.reportsOptions;
          },
          indexClearedOnChange: [],
          deleteInputsOnChangeStartIndex: 1,
        },
      },

    },

  },
  outputsInfo: {
    id: 'reportDesignerConstants',
    label: 'Outputs',
    outputs:{
      outputParameters: {
        type: 'collapsible',
        hasAddBtn: false,
        defaultValues: {
          reportPath: {
            id: 'reportPath',
            index: 0,
            label: 'Report_Path',
            name: 'Report_Path_',
            value: '${reportPath}',
          },
        },
        collapsibleItems: {
          name: {
            id: 'reportPath',
            label: 'Variable Name',
            modelProperty: 'name',
            type: 'validationTextField',
          },
          value: {
            id: 'reportPath-text',
            label: 'Value',
            modelProperty: 'value',
            type: 'textField',
          },
        },
      },
    },
  },
};

export const sequenceFlowProps = {
  inputs: {
    condition: {
      id: 'condition',
      label: 'Condition',
      modelProperty: 'val',
      type: 'textField',
    },
  },
};