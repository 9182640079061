import React from 'react';
import {Handle, Range} from 'rc-slider';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ArrayUtils} from 'js-utils';
import 'rc-slider/assets/index.css';
import 'styles/inputs/simple/slider.less';
export default class SliderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.sliderHandle = ::this.sliderHandle;
    this.onMouseDown = ::this.onMouseDown;
    this.onMouseUp = ::this.onMouseUp;
    this.onMove = ::this.onMove;
    this.state = {
    };
  }

  componentDidMount(){
    if(this.rangeRef){
      if(!this.props.draggableTrack) {
        if(!this.rangeRef.originalMouseDown){
          this.rangeRef.originalMouseDown = this.rangeRef.onMouseDown;
        }
        this.rangeRef.onMouseDown = this.onMouseDown ;
  
        if(!this.rangeRef.originalMouseUp){
          this.rangeRef.originalMouseUp = this.rangeRef.onMouseUp;
        }
        this.rangeRef.onMouseUp = this.onMouseUp;
      }else if(!this.rangeRef.originalOnMove 
        && this.props.hiddenHandlesIndices 
        && this.props.hiddenHandlesIndices.length > 0){//Need to enable selecting the track when draggableTrack is true
        this.rangeRef.originalOnMove = this.rangeRef.onMove;
        this.rangeRef.onMove = this.onMove;
      }
      if(this.props.tracks){
        if(this.rangeRef.sliderRef.children ){
          for(const element in this.rangeRef.sliderRef.children) {
            const htmlElemet = this.rangeRef.sliderRef.children[element];
            if(htmlElemet.className && htmlElemet.className.indexOf("track") > -1){
              const index = new RegExp(/\d+/).exec(htmlElemet.className)[0]
              htmlElemet.ariaLabel = this.props.tracks[~~index-1].label;
            }
          };
        }
      }
    }
    this.forceUpdate();
  }

  onMouseDown(e){
    if(e.target.className.indexOf('handle') == -1){
      return;
    }
    this.rangeRef.originalMouseDown(e);
  }

  onMouseUp(e){
    if(e.target.className.indexOf('handle') == -1){
      return;
    }
    this.rangeRef.originalMouseUp(e);
  }

  onMove(e, position, dragTrack, startBounds){
    const newStartBounds = [...startBounds];

    // remove value of hidden indices to enable track to move beyond the hidden index track
    this.props.hiddenHandlesIndices.forEach(index => newStartBounds.splice(index, 1));
    this.rangeRef.originalOnMove(e, position, dragTrack, newStartBounds);
  }

  preventMouseEvent = (index) => (e) => {
    if(ArrayUtils.hasItem(this.props.disabledHandlesIndices, index)){
      e.preventDefault(); 
      e.stopPropagation();
    }    
  }

  sliderHandle(handleProps){
    const isHandleHidden = ArrayUtils.hasItem(this.props.hiddenHandlesIndices,handleProps.index);
    const isHandleDisabled = ArrayUtils.hasItem(this.props.disabledHandlesIndices, handleProps.index);

    return (
      <div
      onMouseDown={this.preventMouseEvent(handleProps.index)}
      onMouseUp={this.preventMouseEvent(handleProps.index)}
      >
        <OverlayTrigger shouldUpdatePosition
          rootClose placement="bottom" trigger={['hover']}
          overlay={
            <Tooltip placement="bottom" className="in" >
              {this.props.tooltipFormatter ? this.props.tooltipFormatter(handleProps.value, handleProps.index) : handleProps.value}
            </Tooltip>
          }
        >
          <Handle 
            {...handleProps} 
            disabled={handleProps.disabled || isHandleHidden || isHandleDisabled}
            style={{...handleProps.style, 
              visibility: isHandleHidden ? 'hidden' : 'visible',
            }}
          />
        </OverlayTrigger>
      </div>

    );
  }

  getDefaultMarkStyle(label){
    return {
      label: <strong>{label}</strong>,
    } 
  }
  
  render() {
    const marks = !this.props.noMarks ?  {
      [this.props.min]: this.props.minMarkStyle ? this.props.minMarkStyle(this.props.min) : this.getDefaultMarkStyle(this.props.min),
      [this.props.max]: this.props.minMarkStyle ? this.props.minMarkStyle(this.props.max) : this.getDefaultMarkStyle(this.props.max),
      ...this.props.extraMarks,
    } : {};
    
    return (
    <div className="range-utils">
      <Range
        {...this.props.valueProps}
        ref ={ref => this.rangeRef = ref}
        min={this.props.min}
        max={this.props.max}
        count={this.props.count}
        allowCross={this.props.allowCross || false}
        defaultValue={this.props.values}
        disabled={this.props.disabled}
        onChange={this.props.onChange}
        onAfterChange={this.props.onAfterChange}
        handle={this.sliderHandle}
        railStyle={this.props.railStyle}
        trackStyle={this.props.tracks || []}
        marks={marks}
        step= {this.props.step || 1}
        draggableTrack={this.props.draggableTrack || false}
        pushable={this.props.pushable || false}
        tipFormatter={this.props.tipFormatter}
      />
      {this.props.labels}
    </div>
    );
  }
} 
