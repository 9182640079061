/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
 
export const SCHEMA = {
  interval: Joi.number()
  .min(1)
  .max(Math.pow(2,31))
  .integer()
  .required()
  .label('Interval')
  .error(ValidationUtils.getErrMsgNumber),
};

const validate = (value) => {
  const inputValidator =  ValidationUtils.validateSchema(SCHEMA);

  return inputValidator('interval', value);
};

const intervalValidator = validate;
  
export default intervalValidator;
