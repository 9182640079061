/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {fromJS} from "immutable";
import _ from 'lodash'
import Reactable from './Reactable';
import Checkbox from 'inputs/simple/Checkbox';


class SelectTable extends Component{
  constructor(props){
    super(props);
    this.triggerSearch = ::this.triggerSearch;
    this.dataFilter = ::this.dataFilter;
    this.selectAll = ::this.selectAll;
    this.selectRow = ::this.selectRow;
    this.getSelectedIds = ::this.getSelectedIds;
    this.getDisabledIds = ::this.getDisabledIds;
    this.loadBackendData = ::this.loadBackendData;
    this.rowKey = props.rowKey;

    this.state = {
      filteredData: props.tableData,
      selectAll: this.setSelectAll(props.selected, props.disabled, props.tableData),
      disableAll: this.setDisableAll(props.disabled, props.tableData),
      rowStatus: this.setRowStatus(props.selected, props.tableData, props.shouldDisableSelection),
    };
    this.updateState(this.state.rowStatus)
  }

  setSelectAll(selected, disabled, tableData){
    return !!selected && selected.length > 0 &&
      selected.length + (disabled || []).length === tableData.length
  }

  setDisableAll(disabled, tableData){
    return !!disabled && disabled.length === tableData.length;
  }

  componentWillMount(){
    const columns = fromJS(this.props.columns).toJS();

    columns.reverse().push({
      header: {
        label: '',
        props: {
          style: {
            width: this.props.selectColumnWidth,
          },
        },
        format: () => (
          <div className='header-checkbox'>
            <Checkbox
              bsClass="checkbox-container"
              checked={this.state.selectAll}
              disabled={this.state.disableAll}
              onChange={this.selectAll}
            />
          </div>
        ),
      },
      hidden: this.props.hideSelect,
      cell: {
        format: (id, {rowData}) => {
          return (
            <Checkbox
              bsClass='checkbox-container'
              checked={this.state.rowStatus[rowData[this.rowKey]].isSelected}
              disabled={this.state.rowStatus[rowData[this.rowKey]].isDisabled}
              onChange={(e) => {
                this.selectRow(rowData[this.rowKey], e);
              }}
            />
          );
        },
      },
    });
    columns.reverse();
    this.setState({columns});
  }

  componentWillReceiveProps(nextProps){
    const newState = {}
    const tableDataChanged = !_.isEqual(this.props.tableData, nextProps.tableData)
    if(tableDataChanged
      || !_.isEqual(this.props.selected, nextProps.selected)
      || !_.isEqual(this.props.disabled, nextProps.disabled)
      || !_.isEqual(this.props.shouldDisableSelection, nextProps.shouldDisableSelection)){
      newState.filteredData = tableDataChanged ? nextProps.tableData : this.state.filteredData
      newState.rowStatus = this.setRowStatus(nextProps.selected, nextProps.tableData, nextProps.shouldDisableSelection)
      newState.selectAll = this.setSelectAll(nextProps.selected, nextProps.disabled, newState.filteredData)
      newState.disableAll = this.setDisableAll(nextProps.disabled, newState.filteredData)
      this.setState(newState);
      this.updateState(newState.rowStatus)
    }
  }

  triggerSearch(){
    this.refReactTable.triggerSearch();
  }

  updateState(rowStatus){
    this.setState({rowStatus}, () => {
      if(typeof this.props.onSelectChange === 'function'){
        this.props.onSelectChange(this.getSelectedIds());
      }
      if(typeof this.props.onDisableChange === 'function'){
        this.props.onDisableChange(this.getDisabledIds())
      }
    });
  }

  setRowStatus(selected, tableData, shouldDisableSelection){
    const rowStatus = {};
    tableData.forEach((row) => {
      rowStatus[row.id] = {
        isDisabled: shouldDisableSelection ? shouldDisableSelection(row) : false,
        isSelected: false,
      }
    })
    selected.forEach((id) => {
      if(rowStatus[id])
        rowStatus[id].isSelected = true
    })
    return rowStatus
  }

  getSelectedIds(){
    const result = []
    const rowStatus = fromJS(this.state.rowStatus).toJS();
    Object.keys(rowStatus).forEach((id) => {
      if(rowStatus[id].isSelected)
        result.push(id)
    })
    return result;
  }

  getDisabledIds(){
    const result = []
    const rowStatus = fromJS(this.state.rowStatus).toJS();
    Object.keys(rowStatus).forEach((id) => {
      if(rowStatus[id].isDisabled)
        result.push(id)
    })
    return result;
  }

  dataFilter(filteredData){
    const rowStatus = this.state.rowStatus
    const selectAll =
      filteredData.every((data) => rowStatus[data[this.rowKey]].isDisabled || rowStatus[data[this.rowKey]].isSelected) &&
      filteredData.length > 0
    const disableAll = filteredData.every((data) => rowStatus[data[this.rowKey]].isDisabled)
    this.setState({
      filteredData, selectAll, disableAll
    });
  }

  selectRow(rowId, e){
    const rowStatus = fromJS(this.state.rowStatus).toJS();
    rowStatus[rowId].isSelected = e.target.checked;
    this.updateState(rowStatus)
  }

  selectAll(e){
    const rowStatus = fromJS(this.state.rowStatus).toJS();
    const filteredData = this.state.filteredData;

    filteredData.forEach((data) => {
      if(rowStatus[data[this.rowKey]])
        rowStatus[data[this.rowKey]].isSelected = !this.state.selectAll && !rowStatus[data[this.rowKey]].isDisabled
    })
    this.updateState(rowStatus)
  }

  loadBackendData(query){
    this.refReactTable.loadBackendData(query);
  }

  render(){
    if(!this.state.columns){
      return null;
    }

    return (
      <Reactable
        {...this.props}
        columns={this.state.columns}
        onAfterSearch={(filteredData) => {
          if(typeof this.props.onAfterSearch === 'function'){
            this.props.onAfterSearch();
          }
          this.dataFilter(filteredData);
        }}
        ref={ref => this.refReactTable = ref}
      />
    );
  }
}

SelectTable.propTypes = {
  onAfterSearch: PropTypes.func,
  tableData: PropTypes.array,
  columns: PropTypes.array,
  onSelectChange: PropTypes.func,
  onDisableChange: PropTypes.func,
  selectColumnWidth: PropTypes.string,
  selected: PropTypes.array,
  rowKey: PropTypes.string,
  hideSelect: PropTypes.boolean,
};

export default SelectTable;
