/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import {DropdownButton, MenuItem, OverlayTrigger,
  Tooltip} from 'react-bootstrap';
import TooltippedButton from 'buttons/TooltippedButton';
import {USER_RIGHT}  from 'utils/defaults';
import { FixedSizeList as List } from 'react-window';
import Icons from 'templates/Icons';
import 'styles/inputs/compound/itemsListComponent.less'


const ItemsListComponent = props => {

  const itemStructure = (userGroup, index, style) => {
    return (
      <div className='itemWrapper' style={style || {}} key={index} >
        <div className='listItemWrapper'>
          <i className={Icons.users}></i>
          <OverlayTrigger
              rootClose placement="bottom" trigger={['hover', 'focus']}
              overlay={
                <Tooltip placement="bottom" className="in" >
                  {userGroup.userGroup.path}
                </Tooltip>
              }>
            <span className="groupName">
              {userGroup.userGroup.path}
            </span>
          </OverlayTrigger>
        </div>
        <div className='itemActions dashboard-action'>
          <OverlayTrigger
            rootClose placement="bottom" trigger={['hover', 'focus']}
            overlay={
              <Tooltip placement="bottom" className="in" >
                Group Permission
              </Tooltip>
            }>

            <DropdownButton noCaret className="btn-action"
            title={
                <i className={userGroup.userGroupRight === USER_RIGHT.EDITOR ?
                Icons.edit
                : Icons.view}></i>
              }>
              <MenuItem
                className='canEdit-menuItem'
                onClick={ () => props.changeGroupRight(index, USER_RIGHT.EDITOR)}>
                <i className={Icons.edit}></i>
                  Can Edit
              </MenuItem>
              <MenuItem
                className='canView-menuItem'
                onClick={ () => props.changeGroupRight(index, USER_RIGHT.VIEWER)}>
                  <i className={Icons.view}></i>
                  Can View
              </MenuItem>
            </DropdownButton>
          </OverlayTrigger>
          <TooltippedButton className="btn-action deleteGrp-btn" tooltip='Delete Group'
            onClick={ () => props.removeGroup(index)}>
            <i className={Icons.delete} ></i>
          </TooltippedButton>
        </div>
      </div>
    )
  }

  let item = null;
  if(props.userGroups){
    item = ({index, style}) => {
      const userGroup = props.userGroups[index];
      return itemStructure(userGroup, index, style);
    };
  }
  
  const itemSize = 45;
  const maxShownItems = 4;
  const maxHeight = 175;
  const height = props.userGroups.length < maxShownItems ? 
    itemSize * props.userGroups.length : maxHeight;
  const hasBorders = props.userGroups.length > 0? 'hasBorders':'';

  return(
      <div className={`${hasBorders} itemsListComponentWrapper`}>
        {props.userGroups.length > maxShownItems ? 
          <List
            height={height}
            itemSize={itemSize}
            itemCount={props.userGroups.length}
            width={'100%'}
          >
            {item}
          </List>
        : props.userGroups.map((item, index) => itemStructure(item, index, {height: itemSize}))
        }
      </div>
  );
};

export default ItemsListComponent;
