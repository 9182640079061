/* Copyright 2019 Greyskies. All Rights Reserved. */

import EventumBrandLogo from 'web-style-guide/img/eventum-brand-logo.png';
import React from 'react';
import 'web-style-guide/css/footer.less';

export default (props) => {
  let eventumText = null;

  if(props.showEventumLogo){
    eventumText = (
    <div className="footer-copyright row">
      <img src={EventumBrandLogo}/>
      <h6>Powered by&nbsp;
        <span className="bold-text">Eventum IT Solutions</span>
      </h6>
    </div>
  );}

  return (
    <footer className = "container-fluid footer">
        {props.footerProps}
        {eventumText}
    </footer>
  );
}
