/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as ElementHelper from 'bpmn-js-properties-panel/lib/helper/ElementHelper';
import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
import {MsgDefaults} from 'js-utils';
import utils from 'bpmn-js-properties-panel/lib/Utils';

export function createInputParamTag(paramName, bpmnFactory, defaultValue, definition) {
  return ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.INPUT_PARAMETER,
    {name: paramName, value: defaultValue, definition}, null, bpmnFactory);
}

export function createOutputParamTag(paramName, bpmnFactory, defaultValue) {
  return ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.OUTPUT_PARAMETER, 
    { name: paramName, value: defaultValue}, null, bpmnFactory);
}

export function createListParamTag(paramName, bpmnFactory){
  const definition = ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.LIST, undefined, null, bpmnFactory);

  return createInputParamTag(paramName, bpmnFactory, null, definition);
}

export function getInputParamValueAtIndex(element, index) {
  const value = getBusinessObject(element).get('extensionElements').values[1].inputParameters[index].value;

  return value ? value : null;
}

export function setInputParamValueAtIndex(element, index, value) {
  getBusinessObject(element).get('extensionElements').values[1].inputParameters[index].value = value;
}

export function createListPropertyOptions(propertyObject, element, bpmnFactory){
  const inputParam = getBusinessObject(element).get('extensionElements')
    .values[1].inputParameters[propertyObject.index];

  return({
    id: propertyObject.id,
    modelProperties: ['value'],
    labels: [propertyObject.label],
    addLabel: MsgDefaults.getAddNewTitle(propertyObject.addLabel),  
    getElements: function(_, node) {
      return inputParam.definition.items;
    },      
    updateElement: function(_, values, node, idx) {
      const item = inputParam.definition.items[idx];

      return cmdHelper.updateBusinessObject(element, item, values);
    },
    addElement: function(_, node) {
      const newValue = ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.VALUE,
        {value: undefined}, inputParam.definition, bpmnFactory);

      return cmdHelper.addElementsTolist(element, inputParam.definition, 'items', [ newValue ]);
    },
    removeElement: function(_, node, idx) {
      return cmdHelper.removeElementsFromList(element, inputParam.definition, 'items', null,
        [inputParam.definition.items[idx]]);
    },
    editable: function(_, node, prop, idx) {
      return true;
    },
    show: function(_, node) {
      return true;
    },  
  });
}

export function createPropertybyOptionsAndFunctions(propertyObject, propertyFunctions = {}, businessObject) {
  return ({
    id: propertyObject.id,
    label: propertyObject.label,
    modelProperty: propertyObject.modelProperty || 'val',
    set: function (element, values, node) {
      const newValue = values.val;
      const oldValue = getInputParamValueAtIndex(element, propertyObject.index);

      if( oldValue != newValue && propertyObject.inputOptions 
        && propertyObject.inputOptions.indexClearedOnChange){
        propertyObject.inputOptions.indexClearedOnChange.forEach(index => {
          setInputParamValueAtIndex(element, index, null);
        });
      }
      if(oldValue != newValue && propertyObject.inputOptions
        && propertyObject.inputOptions.deleteInputsOnChangeStartIndex){
        const inputParamsArray = businessObject.get('extensionElements').values[1].inputParameters;
       
        (inputParamsArray || []).splice(propertyObject.inputOptions.deleteInputsOnChangeStartIndex, inputParamsArray.length - 1);
      }
      setInputParamValueAtIndex(element, propertyObject.index, newValue);
    },
    get: function (element, node) {
      const val = getInputParamValueAtIndex(element, propertyObject.index);
      
      return { val };
    },
    ...propertyFunctions,
  });
}

export function getEntry(input, translate, element, reactHandler, bpmnFactory, inputFunctions, businessObject) {

  switch(input.type){
    case 'textBox':
      return entryFactory.textBox(translate, createPropertybyOptionsAndFunctions(input, inputFunctions));
    case 'selectBox':
      let options = createPropertybyOptionsAndFunctions(input, inputFunctions, businessObject);
      if(input.inputOptions && input.inputOptions.getSelectOptions){
        options.selectOptions = input.inputOptions.getSelectOptions(reactHandler, element);
      }
      if(input.getCustomId){
        options.id = input.getCustomId(element);
      }
      return entryFactory.selectBox(translate, options);
    case 'validationTextField':
      return entryFactory.validationAwareTextField(translate, createPropertybyOptionsAndFunctions(input, inputFunctions));
    case 'textField':
      return entryFactory.textField(translate, createPropertybyOptionsAndFunctions(input, inputFunctions));
    case 'table':
      return entryFactory.table(translate, createListPropertyOptions(
        input, element, bpmnFactory));    
  }
}
export function prepareExtensionElements(businessObject, className, inputsInfo, outputsInfo, bpmnFactory, isUserTask) {
  const extensionElements = businessObject.get('extensionElements');

  if (!extensionElements) {
    const inputParams = prepareInputParams(bpmnFactory, inputsInfo.inputs);
    const outputParams = prepareOutputParams(bpmnFactory, outputsInfo);
    const listener  = isUserTask ? CommonWorkflowDefaults.CAMMUNDA_TAGS.TASK_LISTENER 
      : CommonWorkflowDefaults.CAMMUNDA_TAGS.EXECUTION_LISTENER;
    const event = isUserTask ? "create" : "start";
    const executionListener = ElementHelper.createElement(listener,
      { "class": className, "event": event }, null, bpmnFactory);
    const inputOutput = ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.INPUT_OUTPUT, {}, null, bpmnFactory);

    inputOutput.inputParameters = inputParams;
    inputOutput.outputParameters = outputParams;
    businessObject.extensionElements = ElementHelper.createElement(CommonWorkflowDefaults.EXTENSTION_ELEMENTS_NAMESPACE,
      { values: [executionListener, inputOutput] }, businessObject, bpmnFactory);
  }
}
export function getReactHandler(parentElementId, reactHandlerKey) {
  const propview =  document.getElementById(parentElementId);
  const reactHandlerKeyItems = Object.keys(propview).filter(function(item){
    return item.indexOf(reactHandlerKey) >= 0;
  });

 return propview[reactHandlerKeyItems[0]];
}
function isListInput(inputType) {
  return inputType == 'table';
}
export function prepareInputParams(bpmnFactory, inputs) {
  const inputParamsArray = [];

  Object.values(inputs).forEach(input => {
    let definition = null;

    if(isListInput(input.type)){
      definition = ElementHelper.createElement(CommonWorkflowDefaults.CAMMUNDA_TAGS.LIST, undefined, null, bpmnFactory);
    }
    inputParamsArray.splice(input.index, 0, 
      createInputParamTag(input.inputTag, bpmnFactory, input.defaultValue, definition));
    
  });

  return inputParamsArray;
}

export function prepareOutputParams(bpmnFactory, outputsInfo){
  const outputParamsArray = [];

  if(outputsInfo.outputs){
    const outputs = outputsInfo.outputs.outputParameters.defaultValues;
  
    Object.values(outputs).forEach(output => 
      outputParamsArray.splice(output.index, 0, 
        createOutputParamTag(`${utils.nextId(output.name)}`, bpmnFactory, output.value))
    );
  }

  return outputParamsArray;
}