/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {DateTimeUtils, MsgDefaults} from 'js-utils';
import {fromJS} from 'immutable';
import LabeledComponent from 'containers/LabeledComponent';
import CollapsableComponent from 'containers/CollapsableComponent';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import ValidationOutput from 'containers/ValidationOutput';
import * as defaults from 'utils/defaults';

export default class StartEndTime extends Component {
  constructor(props) {
    super(props);
    this.setField = :: this.setField;
  }
  setField = (field) => (e) => {
    const propsTemp = fromJS(this.props).toJS();

    if(typeof e === 'string'){
      propsTemp.time[field] = e;   
    }else{
      propsTemp.time[field] = DateTimeUtils.dateToTimeFormat(e);   
    }
    propsTemp.time.validation = this.props.validationFunction('time', propsTemp.time);

    this.props.onChange(propsTemp.time, this.props.order);
  }

  render(){
    return (
      <CollapsableComponent
        className='startEndTime'
        title = {`${defaults.TIME_RANGE} ${this.props.order + 1}`}
        initiallyCollapsed={false}
        wrapInRow
        buttons={[
          ButtonsConstructor.remove(
            this.props.deleteEntity, MsgDefaults.getDeleteTitle(defaults.TIME_RANGE)
          ),
        ]}>
        <div className='evc-container-sys-bg'>
          <Row className="timeRange ">
            <LabeledComponent label= {defaults.START_TIME} size={6} labelSize={5} inputSize={7} labelClassName="paddingRight0">
              <DateTime dateFormat={false} defaultValue={DateTimeUtils.timeFormatToMoment(this.props.time.startTime)} onBlur={this.setField('startTime')} />
            </LabeledComponent>
            <LabeledComponent label={defaults.END_TIME} size={6} labelSize={5} inputSize={7} labelClassName="paddingRight0">
              <DateTime dateFormat={false} defaultValue={DateTimeUtils.timeFormatToMoment(this.props.time.endTime)} onBlur={this.setField('endTime')}/>
            </LabeledComponent>
            <Col xs={12}>
              <ValidationOutput validation={this.props.time.validation} />
            </Col>
          </Row>
        </div>         
      </CollapsableComponent>
    );
  }
}
