/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import 'styles/containers/panels.less';
import Icons from 'templates/Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Panel extends Component{
  constructor(props){
    super(props);
    this.onClick = ::this.onClick;
    this.state = {
      open: props.collapsable ? (props.defaultOpen ? true : false) : true,
    };
  }

  onClick(e){
    e.preventDefault();
    this.setState({
      open : !this.state.open,
    });
  }

  getCollapseBtnClassName(open){
    let classname = '';

    if(open){
      classname = Icons.caretDown;
    }else{
      classname = Icons.caretRight;
    }

    return classname;
  }

  render(){
    let buttons = [];

    if(this.props.toolbarButtons){
      buttons = this.props.toolbarButtons.map( (button, index) => {
        return   <a href="#" key={button.name || index} onClick={(e) => {
                    e.preventDefault();
                    button.onClick();
                  }} className={`panel-action ${button.className}`}
                  disabled={button.disabled}>
                    <i className={button.iconClassname}></i>
                  </a>;
      });
    }

    return(
      <div className={`panel panel-default ${this.props.classes ? this.props.classes.panel || '' : ''} ${this.props.classes.panelIdentifier}-panel`}>
        <div className={`panel-heading ${this.props.classes ? this.props.classes.heading || '' : ''}`}>
          <div className='panel-left-action-container'>
            { this.props.collapsable ?
              <a href="#" key="collapse"
                onClick={this.onClick}
                className="panel-action">
                <i className={this.getCollapseBtnClassName(this.state.open)}>
                </i>
              </a> : null
            }
            {this.props.toolbarLeftButtons}
          </div>
          
          <div className={`panel-title ${this.props.classes ? this.props.classes.title || '' : ''}`}>
            {this.props.panelTitle ?
              this.props.tooltippedTitle ? 
                <OverlayTrigger 
                    overlay={
                      <Tooltip className="in" >
                      {this.props.panelTitle}
                      </Tooltip>
                    }
                    placement={this.props.tooltipPlacement || 'top'}
                    shouldUpdatePosition
                    rootClose>
                    <span className='panelTooltippedTitle'>
                      {this.props.panelTitle}
                    </span>
                </OverlayTrigger>
              : 
                <h6 className="title-text">
                  {this.props.panelTitle}
                </h6>
              : null
            }

            {this.props.panelHeaderContent?
              <div className="panel-header-content">
                {this.props.panelHeaderContent}
              </div>
            :null}
          </div>
          
          <div className={`panel-action-container pull-right ${this.props.classes ?
               this.props.classes.action || '' : ''}`}>
            {buttons}
          </div>
        </div>
        {this.state.open ?
          <div className={`panel-body ${this.props.classes ? this.props.classes.body || '' : ''} ${this.props.classes.panelIdentifier}-panelBody`}>
            {this.props.children}
          </div>
          : null
        }
      </div>
    );
  }
}
