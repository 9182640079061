/* Copyright 2019 Greyskies. All Rights Reserved. */

import {ValidationUtils, Utils, DataModelTypeUtils, WidgetTypes,
  AggregationUtils, FieldTypes, UnitConversionUtils} from 'js-utils';
import topNValidationSchema from 'utils/validations/TopNValidationSchema';
import * as DataSelectionUtils from 'utils/DataSelectionUtils';
import * as FFAGDefaults from './ffagDefaults';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import * as FilterUtils from 'utils/filterUtils';
import {fromJS} from 'immutable';
import {validateUniquenessInArray, getNumericInputValidationSchema} from 'utils/CommonValidationSchema';
import Icons from 'templates/Icons';

export const RECORDS_FOR_ATTRIBUTES = 'RECORDS_FOR_ATTRIBUTES';
export const ATTRIBUTES = 'ATTRIBUTES';
export const RECORDS = 'RECORDS';
export const FGA_TYPE_RAW = 'RAW';
export const FGA_TYPE_AGGREGATE = 'AGGREGATE';
export const FGA_TYPE_TOPN = 'TOP_N';
export const FGA_TYPE_SINGLE_VALUE = 'SINGLE_VALUE';

export const FFAG_TYPE_OPTIONS = [
  {value: FGA_TYPE_AGGREGATE, content: Icons.table + Icons.styles.evLg, contentType: 'icon', name: 'Table'},
  {value: FGA_TYPE_RAW, content: Icons.rawData + Icons.styles.evLg, contentType: 'icon', name: 'Raw Data Table'},
  {value: FGA_TYPE_TOPN, content: Icons.topNTable + Icons.styles.evLg, contentType: 'icon', name: 'TopN Table'},
];


export function getGroupByFieldsInfo(groupByFields){
  const refFieldsNames = new Set();

  groupByFields.forEach(field => {
    if(field.selectedField){
      if(field.selectedField.fieldName != FieldTypes.NETWORK_ELEMENT && field.selectedField.ref){
        refFieldsNames.add(field.selectedField.fieldName);
      }
    }
  });

  return {
    refFieldsNames,
  };
}

export function isMixedBaseUnitFields(fields){
  const comparingUnitKey = getFieldsBaseUnit(fields);

  for(let i = 0; i < (fields || []).length; i++) {
    const fieldDataTypeKey = getFieldBaseUnit(fields[i]);

    if(fieldDataTypeKey != comparingUnitKey){
      return true;
    }
  }

  return false;
}

export function isAllAggregationsTypeNumeric(fields){
  for(let i = 0; i < fields.length; i++){
    if(AggregationUtils.isNonNumericAggregationType(fields[i].aggregationType)){
      return false;
    }
  }

  return true;
}


export function getFieldsTypeDescriptor(fields){
  const isMixedBaseUnit = isMixedBaseUnitFields(fields);
  const isMixedDataType = isMixedDataTypeFields(fields);
  const isMixedFieldType = isMixedFieldTypeFields(fields);
  const isMixedAttributeDataType = isMixedAttributeDataTypeFields(fields);
  const baseUnit = isMixedBaseUnit ? '' : getFieldsBaseUnit(fields);
  const fieldsDataType = !isMixedDataType || !isMixedFieldType ? getFieldsDataType(fields) : '';
  const dataType = isMixedDataType ? '' : fieldsDataType;
  const fieldType = isMixedFieldType ? '' : fieldsDataType;
  const isAllAggregationsNumeric = isAllAggregationsTypeNumeric(fields);
  const isAllAggregationsNonNumeric = !isAllAggregationsNumeric && !Utils.isEmpty(fieldType);

  return {
    isMixedBaseUnit,
    isMixedDataType,
    isMixedFieldType,
    isMixedAttributeDataType,
    isAllAggregationsNumeric,
    isAllAggregationsNonNumeric,
    baseUnit,
    dataType,
    fieldType,
  };
}

export function isMixedDataTypeFields(fields){
  const comparingDataTypeKey = DataModelTypeUtils.getDataTypeKey(getFieldsDataType(fields));

  for(let i = 0; i < (fields || []).length; i++) {
    const fieldDataType = getFieldDataType(fields[i]);
    const fieldDataTypeKey = DataModelTypeUtils.getDataTypeKey(fieldDataType);

    if(fieldDataTypeKey != comparingDataTypeKey){
      return true;
    }
  }

  return false;
}

export function isMixedFieldTypeFields(fields){
  const comparingDataTypeKey = DataModelTypeUtils.isNumericType(getFieldsDataType(fields));

  for(let i = 0; i < (fields || []).length; i++) {
    const fieldType = getFieldDataType(fields[i]);
    const fieldTypeKey = DataModelTypeUtils.isNumericType(fieldType);

    if(fieldTypeKey != comparingDataTypeKey){
      return true;
    }
  }

  return false;
}

export function isMixedAttributeDataTypeFields(fields){
  let hasTheSameAttributeType = true;
  if (fields.length > 0 && fields[0].selectedField) {
    const firstFieldAttributeType = fields[0].selectedField.attributeType.id;
    hasTheSameAttributeType = (field) => field.selectedField && field.selectedField.attributeType.id == firstFieldAttributeType;
    return !fields.every(hasTheSameAttributeType);
  }
  return !hasTheSameAttributeType;
}

export function getFieldsBaseUnit(fields){
  return fields && fields[0] && fields[0].selectedField ?
    getFieldBaseUnit(fields[0]) : '';
}

export function getFieldsDataType(fields){
  return fields && fields[0] ? getFieldDataType(fields[0]) : '';
}

export function getFieldBaseUnit(field){
  if(AggregationUtils.isUniqueCount(field.aggregationType)){
    return ' ';
  }else if(field.selectedField){
    return field.selectedField.attributeType.unit;
  }

  return '';
}

export function getFieldDataType(field){
  if(AggregationUtils.isUniqueCount(field.aggregationType)){
    return DataModelTypeUtils.COUNT_DATA_TYPE;
  }else if(field.selectedField){
    return field.selectedField.attributeType.dataType;
  }

  return '';
}

export function isDataSourceRecordsForAttributes(selectedDataSource){
  return selectedDataSource === RECORDS_FOR_ATTRIBUTES;
}

function setObligatoryRefFilter(routingFilter, sourceType) {
  if(routingFilter){
    const obligatoryRefFilter = {
      ...routingFilter,
      disableDelete: true,
      disableChange: true,
      routingFilterForAttributes: true,
    };

    if(sourceType.recordDataStructure){
      obligatoryRefFilter.selectedField = {
        ref: true,
        refType: sourceType.recordDataStructure,
      };
    }
    
    return obligatoryRefFilter;
  }

  return null;
}

export function getDataSource(isRecord){
    return isRecord ? RECORDS : RECORDS_FOR_ATTRIBUTES;
}

export function hasDateInGrouping(groupByFields){
    return !!groupByFields.find(field => FieldTypes.isDateField(field.selectedField));
}

export function getDateInGrouping(groupByFields){
  return groupByFields.find(field => FieldTypes.isDateField(field.selectedField));
}

function adaptGroupByFields(groupByFields, widgetType, customUnitsValues){
    const options = {hasDateInGrouping: false};
    groupByFields.forEach((groupByField) => {
        if(!options.hasDateInGrouping && FieldTypes.isDateField(groupByField.selectedField)){
            options.hasDateInGrouping = true;
            // Clear resolution if not matched with ml config (reused and edited it from different widget type)
            if(WidgetTypes.isMLForecast(widgetType)){
                const resolutionMatchCustomUnit = !!Object.values(customUnitsValues).find(customUnit =>
                    groupByField.resolution == customUnit.interval && groupByField.resolutionUnit == customUnit.unit );

                if(!resolutionMatchCustomUnit){
                    groupByField.resolutionUnit = undefined;
                    groupByField.resolution = undefined;
                }
            }
        }
    });
    return options;
}

function adabtRoutingFilter(routingFilter, sourceType, isDataSourceRecordsForAttributes, hasAutoNEMapping){
  if(isDataSourceRecordsForAttributes){
    return setObligatoryRefFilter(routingFilter, sourceType);
    // Special case to fix if old widgets have not routing field saved
  }else if(Utils.isBlank(routingFilter) && !hasAutoNEMapping){
    return DataSelectionUtils.getObligatoryRoutingFilter(sourceType.routingField, isDataSourceRecordsForAttributes);
  }else if(!Utils.isBlank(routingFilter) && !Utils.isBlank(sourceType.routingField)){
    routingFilter.selectedField = sourceType.routingField;
  }

  return routingFilter;
}

export function resetCollectionAggregation(enabled){
  if(enabled){
    return {
      collectionTimeAggPercentileValue: null,
      collectionTimeAggregationInterval: FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.TIME_INTERVAL_DEFAULT,
      collectionTimeAggregationType: FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.TYPE_DEFAULT,
      collectionTimeAggregationUnit: FFAGDefaults.COLLECTION_AGGREGATION_CONSTANTS.UNIT_DEFAULT,
      isCollectionTimeAggsEnabled: enabled,
    };
  }else{
    return {
      collectionTimeAggPercentileValue: null,
      collectionTimeAggregationInterval: null,
      collectionTimeAggregationType: null,
      collectionTimeAggregationUnit: null,
      isCollectionTimeAggsEnabled: enabled,
    };
  }
}

export function getDataSelectionState(value, isAssociation, widgetType, options = {}, placeholderOptions, isFirstLoading){
  const state = {
    id: null,
    name: '',
    sourceType: null,
    dataSource: RECORDS_FOR_ATTRIBUTES,
    routingFilter: null,
    filters: [],
    fields: [],
    groupByFields: [],
    columns: [],
    topologyNodes: [],
    version: null,
    hasDateInGrouping: false,
    ffagUserGroups: [],
    ...resetCollectionAggregation(false),
    postAggregationFilterEnabled: false,
    postAggregationFilters: [],
  };


  if(value){

      state.id = value.id;
      state.name = value.name;
      state.sourceType = value.recordType;
      state.dataSource = getDataSource(value.record);
      const isDataSourceRecordForAttributes = isDataSourceRecordsForAttributes(state.dataSource);

      state.filters = value.filters;
      state.mappingFilter = value.mappingFilter;
      state.sourceMappingFilter = value.sourceMappingFilter; 
      state.destinationMappingFilter = value.destinationMappingFilter; 

      state.routingFilter = adabtRoutingFilter(value.routingFilter, state.sourceType, isDataSourceRecordForAttributes, !!(state.mappingFilter));
      state.fields = value.fields;
      const groupingOptions = adaptGroupByFields(value.groupByFields, widgetType, options.customUnitsValues);
      state.groupByFields = value.groupByFields;
      state.columns = value.columns;
      state.topologyNodes = value.topologyNodes;

      state.template = value.template;
      state.hasDateInGrouping = groupingOptions.hasDateInGrouping;
      state.version = value.version;
      state.ffagUserGroups = value.ffagUserGroups;
      state.userRight = value.userRight;
      state.collectionTimeAggPercentileValue = value.collectionTimeAggPercentileValue;
      state.collectionTimeAggregationInterval = value.collectionTimeAggregationInterval;
      state.collectionTimeAggregationType = value.collectionTimeAggregationType;
      state.collectionTimeAggregationUnit = value.collectionTimeAggregationUnit;
      state.isCollectionTimeAggsEnabled = value.isCollectionTimeAggsEnabled;
      state.postAggregationFilterEnabled = value.postAggregationFilterEnabled;
      state.postAggregationFilters = getPostAggregationFilters(value.postAggregationFilters, value.fields, isFirstLoading);
      state.signalGroup = value.signalGroup;
      state.limitSize = value.limitSize;
      if(value.unpublishedName){
        state.unpublishedName = value.unpublishedName;
        state.name = state.unpublishedName;
      }
  }
  if(isAssociation && (Utils.isBlank(state.name) || state.name == '')){
      delete state.name;
  }

  return state;
}

export function getPostAggregationFilters(filters, fields, isFirstLoading) {
  if(filters && fields) {
    const attributesList = getPostAggregationFilterAggregatesList(fields);
    const deleteFilters = [];

    filters.forEach((filter, index) => {    
      if(filter.field && attributesList) {
        const field = attributesList.find(attribute => UIConstructionUtils.getKeyFromId(attribute) == UIConstructionUtils.getKeyFromId(filter.field));
        if(!field) {
          deleteFilters.push(filter);
        } else if(!isFirstLoading) {
          const stillOfTypeCount = AggregationUtils.isOfTypeCount(field.aggregationType) && AggregationUtils.isOfTypeCount(filter.field.aggregationType);
          const becameUniqueCount = AggregationUtils.isUniqueCount(field.aggregationType) && !AggregationUtils.isUniqueCount(filter.field.aggregationType);
          if ((!field.selectedField) || (!stillOfTypeCount && field.selectedField && filter.field.selectedField
              && filter.field.selectedField.attributeType.id != field.selectedField.attributeType.id) || becameUniqueCount) {
            clearFilterOnChangeAttribute(filter);
          }
          filter.field = field;
        }
      }
    });
    deleteFilters.forEach(filter => {
      filters = deleteFilter(filters, filters.indexOf(filter));
    });
  }
  return filters;
}

export function getWidgetConfigs(esQueryMaxTerms, widget = {dataSelectionOptions: FFAGDefaults.defaultDataSelectionOptions}) {
  const validationObject = {
    fields: {
      min: widget.dataSelectionOptions.fields.min,
      checkUniqueFieldType: widget.dataSelectionOptions.fields.checkUniqueFieldType,
      preventCountMixing: widget.dataSelectionOptions.fields.preventCountMixing,
      preventCount: widget.dataSelectionOptions.fields.preventCount,
      preventKpiMultipleAggregate: widget.dataSelectionOptions.fields.preventKpiMultipleAggregate,
      maxFieldTypes: widget.dataSelectionOptions.fields.maxFieldTypes,
      preventAttributeTypeMixing: widget.dataSelectionOptions.fields.preventAttributeTypeMixing,
      canHaveObjectSubField: widget.dataSelectionOptions.fields.canHaveObjectSubField,
      canHaveWholeObject: widget.dataSelectionOptions.fields.canHaveWholeObject,
    },
    groupByFields: {
      min: widget.dataSelectionOptions.groupByFields.min,
      mandatoryFieldType: widget.dataSelectionOptions
        .groupByFields.mandatoryFieldType,
      checkBoundsByFieldsTypes: widget.dataSelectionOptions
        .groupByFields.checkBoundsByFieldsTypes,    
      preventGroupByOnNonNumeric: widget.dataSelectionOptions.groupByFields.preventGroupByOnNonNumeric,
      atLeastOneNonDate: widget.dataSelectionOptions.groupByFields.atLeastOneNonDate,
      checkDateIsMultipleOfResolution: widget.dataSelectionOptions.groupByFields.isDateMultipleFromResolution,
      canHaveObjectSubField: widget.dataSelectionOptions.groupByFields.canHaveObjectSubField,
    },
    columns: {
      min: widget.dataSelectionOptions.columns.min,
      canHaveObjectSubField: widget.dataSelectionOptions.columns.canHaveObjectSubField,
      canHaveWholeObject: widget.dataSelectionOptions.columns.canHaveWholeObject,
    },
    topologyNodes: {
      min: widget.dataSelectionOptions.topologyNodes.min,
      canHaveObjectSubField: widget.dataSelectionOptions.topologyNodes.canHaveObjectSubField,
      canHaveWholeObject: widget.dataSelectionOptions.topologyNodes.canHaveWholeObject,
    },
    filters:{
      esQueryMaxTerms,
      hasVariables: widget.dataSelectionOptions.hasVariables,
    },
    postAggregationFilters: {
      showPostAggregationFilter: widget.dataSelectionOptions.showPostAggregationFilter,
    }
  };
  if(widget.dataSelectionOptions.fields.max){
    validationObject.fields.max = widget.dataSelectionOptions.fields.max;
  }
  const mandatoryGroupByFields = widget.dataSelectionOptions.mandatoryGroupByFields;
  if(mandatoryGroupByFields && mandatoryGroupByFields.length > 0){
      validationObject.mandatoryGroupByFields = mandatoryGroupByFields;
  }

  return validationObject;
}

export function isPublished(dataSelectionName){
  return dataSelectionName !== undefined;
}

export function getClearedFFAG(newArgs){
  return {
    routingFilter: newArgs.routingFilter,
    fields: [],
    filters: newArgs.filters,
    mappingFilter: newArgs.mappingFilter,
    sourceMappingFilter: newArgs.sourceMappingFilter,
    destinationMappingFilter: newArgs.destinationMappingFilter,
    groupByFields: newArgs.groupByFields,
    columns: [],
    topologyNodes: [],
    sourceType: newArgs.sourceType,
    hideSelectedData: false,
    hideValidation: false,
    groupableFields: newArgs.groupableFields,
    sourceFieldsHash: newArgs.sourceFieldsHash,
    ...resetCollectionAggregation(false),
    postAggregationFilterEnabled: newArgs.postAggregationFilterEnabled,
    postAggregationFilters: newArgs.postAggregationFilters,
    signalGroup: null,
  };
}
export function getDataSelectionValidationState(){
    return {
        selectionInput: {
            routingList: [],
            selection: {},
        },
        sourceTypeInput: {},
        fieldInput: {
            fieldList: [],
        },
        filterInput: {
            filterList: [],
        },
        groupByInput: {
            groupByList: {
              groupByFields: [],
              columns: [],
              topologyNodes: [],
            },
        },
        collectionTimeAggregation: {
            style: 'success',
        },
        collectionAggregationValidation: {},
        postAggregationFilter: {
            filterList: [],
        },
        advancedSection: {},
        isValid: false,
    };
}

export function getDataSelectionRecordType(value){
  return value.recordType || value.sourceType;
}

export function isDataSourceRecord(value){
  return value.record || !isDataSourceRecordsForAttributes(value.dataSource);
}

export function getDataSelectionServerObj(value) {
  return {
    id: value.id,
    name: value.name,
    recordType: getDataSelectionRecordType(value),
    record: isDataSourceRecord(value),
    routingFilter: value.routingFilter,
    mappingFilter: value.mappingFilter,
    sourceMappingFilter: value.sourceMappingFilter,
    destinationMappingFilter: value.destinationMappingFilter,
    filters: value.filters,
    fields: value.fields,
    groupByFields: value.groupByFields,
    columns: value.columns,
    topologyNodes: value.topologyNodes,
    version: value.version,
    template: value.template,
    collectionTimeAggPercentileValue: value.collectionTimeAggPercentileValue,
    collectionTimeAggregationInterval: value.collectionTimeAggregationInterval,
    collectionTimeAggregationType: value.collectionTimeAggregationType,
    collectionTimeAggregationUnit: value.collectionTimeAggregationUnit,
    isCollectionTimeAggsEnabled: value.isCollectionTimeAggsEnabled,
    postAggregationFilterEnabled: value.postAggregationFilterEnabled,
    postAggregationFilters: value.postAggregationFilters,
    signalGroup: value.signalGroup,
    limitSize: value.limitSize,
  };
}

export function getDataSelectionServerObjWithUnbublishedName(value){
  const serverObj = getDataSelectionServerObj(value);
  serverObj.unpublishedName = serverObj.name;
  serverObj.name = null;
  return serverObj;
}

export function prepareNEPlaceholderObj(selectionPlaceholder){
  return {
    ...selectionPlaceholder.nePlaceholder,
    advancedFilters: selectionPlaceholder.advancedFilters,
    selection: {
      networkElementDataStructure: selectionPlaceholder.networkElementDataStructure,
    },
  };
}

function preparePlaceholder(placeHolders, filter){
  if(filter.templatesPlaceholder){
    placeHolders[filter.templatesPlaceholder.id] = filter.templatesPlaceholder;
  }else if(filter.selection && filter.selection.nePlaceholder){
    placeHolders[filter.selection.nePlaceholder.id] = prepareNEPlaceholderObj(filter.selection);
  }
}

export function getPlaceHoldersFromDataSelection({filters = [], postAggregationFilters = [], routingFilter, 
  dataSelectionOptions}){
  const placeHolders = {};

  if(dataSelectionOptions?.showPostAggregationFilter && postAggregationFilters){
    postAggregationFilters.forEach(filter => {
      preparePlaceholder(placeHolders, filter);
    });
  }

  if(filters){
    filters.forEach(filter => {
      preparePlaceholder(placeHolders, filter);
    });
  }

  if(routingFilter && routingFilter.selection && routingFilter.selection.nePlaceholder){
    preparePlaceholder(placeHolders, routingFilter);
  }

  return placeHolders;
}

function validateFiltersVariables(inputValidator, filters, routingFilter){
  const allFilters = {
    filters,
    routingFilter,
  };

  return inputValidator('filtersVariables', allFilters);
}

export function validate(dataSelectionArgs, dataSelectionValidation, inputValidator,
  validationOptions, validationConfigs, ffagNames = [], selectedFGAResolution = [], dataSelectionOptions = {}){
  if(Utils.isBlank(dataSelectionArgs)){
    return {isValid: false};
  }
  let isValid = true;

  Object.keys(dataSelectionArgs).forEach( key => {
    switch(key) {
    case 'name':
      if(validationOptions.checkValidation){
        dataSelectionValidation.name = inputValidator('name', dataSelectionArgs.name);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.name);
        if(isValid){
          const names = fromJS(ffagNames).toJS();

          names.push(dataSelectionArgs.name.trim());
          dataSelectionValidation.name = validateUniquenessInArray('Name', names);
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.name);
        }
      }
      break;
    case 'sourceType':
      if(validationOptions.checkValidation){
        const sourceType = dataSelectionArgs.hideSelectedData? null : dataSelectionArgs.sourceType;

        dataSelectionValidation.sourceTypeInput = inputValidator('sourceType', sourceType);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.sourceTypeInput);
      }
      break;
    case 'sourceMappingFilter':
      if (validationOptions.checkValidation) {
        const sourceMappingFilter = dataSelectionArgs.hideSelectedData ? null : dataSelectionArgs.sourceMappingFilter;
        const destinationMappingFilter = dataSelectionArgs.hideSelectedData ? null : dataSelectionArgs.destinationMappingFilter;

        const srcAndDestMappingFields = {
          sourceMappingField: sourceMappingFilter?.selectedField,
          destinationMappingField: destinationMappingFilter?.selectedField,
        };
        dataSelectionValidation.distinctSrcAndDestMappingFields= inputValidator('distinctSrcAndDestMappingFields', srcAndDestMappingFields);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.distinctSrcAndDestMappingFields);
      }
      break;
    case 'routingFilter':
      if(validationOptions.checkValidation && dataSelectionArgs.routingFilter && !dataSelectionArgs.routingFilter.routingFilterForAttributes){
        dataSelectionArgs.routingFilter.routingValues.forEach((routingValue, index) => {
          dataSelectionValidation.selectionInput.routingList[index] = inputValidator('routingValue', routingValue);
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.selectionInput.routingList[index] );
        });
      }
      if(dataSelectionArgs.routingFilter && dataSelectionArgs.routingFilter.routingFilterForAttributes){
        dataSelectionValidation.selectionInput.selection = inputValidator('routingFilter', dataSelectionArgs.routingFilter);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.selectionInput.selection);
      }
      if(dataSelectionArgs.routingFilter && validationConfigs?.filters?.hasVariables){
        const filters = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs.filters)? fromJS(dataSelectionArgs.filters).toJS() : [];

        dataSelectionValidation['filterInput'].filters =  validateFiltersVariables(inputValidator, filters, dataSelectionArgs.routingFilter);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation['filterInput'].filters);
      }
      break;
    case 'filters':
    case 'postAggregationFilters':
      let filters;

      if(key == 'filters') {
        filters = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs.filters)? fromJS(dataSelectionArgs.filters).toJS() : [];
      }
      else {
        filters = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs.postAggregationFilterEnabled && dataSelectionArgs.postAggregationFilters
          && (validationConfigs[key] && validationConfigs[key].showPostAggregationFilter)) ? fromJS(dataSelectionArgs.postAggregationFilters).toJS() : [];
      }

      if(filters.length > 0){
        filters[filters.length - 1].lastFilter = true;
      }

      const filterKey = key == 'filters' ? 'filterInput' : 'postAggregationFilter';

      filters.forEach((filter) => {
        if(validationConfigs && validationConfigs.filters){
          filter.esQueryMaxTerms = validationConfigs.filters.esQueryMaxTerms;
        }
        if(key == 'postAggregationFilters' && filter.field) {
          filter.selectedField = filter.field.selectedField;
          if(filter.selectedField && AggregationUtils.isUniqueCount(filter.field.aggregationType)) {
            filter.selectedField.ref = false;
          }
        } else if (key == 'filters'){
          filter.isObjectFilter = DataModelTypeUtils.isObjectFieldType(filter.selectedField);
        }
        filter.isPostAggregationFilter = (key == 'postAggregationFilters');
      });

      if(validationOptions.checkListValidation){
        dataSelectionValidation[filterKey].filters = {};
        const validationInput = {
          validate: validationOptions.checkListItemsValidation,
          filters,
          postAggregationFilterEnabled: (key == 'filters') ? false : dataSelectionArgs.postAggregationFilterEnabled && validationConfigs[key].showPostAggregationFilter,
        }
        dataSelectionValidation[filterKey].filters = inputValidator('filters', validationInput);

        if(key == 'filters' && validationConfigs?.filters.hasVariables){
          dataSelectionValidation[filterKey].filters = validateFiltersVariables(inputValidator, filters, dataSelectionArgs.routingFilter);
        }

        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation[filterKey].filters);
      }

      if(validationOptions.checkValidation){
        dataSelectionValidation[filterKey].filterList = [];
        filters.forEach((filter, index) => {
          dataSelectionValidation[filterKey].filterList[index] = inputValidator('filter', {
            ...filter,
            esQueryMaxTerms: validationConfigs && validationConfigs.filters ?
              validationConfigs.filters.esQueryMaxTerms : {},
          });
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation[filterKey].filterList[index]);
        });
      }
      break;
    case 'fields':
      const fields = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs.fields)? fromJS(dataSelectionArgs.fields).toJS() : [];

      fields.forEach((field) => {
        const isWholeObjectSelected = DataModelTypeUtils.isObjectFieldType(field.selectedField);
        const isSubFieldSelected = field.selectedField?.parentFieldId != null;

        const isBasicNonNumericAggregation = AggregationUtils.isNonNumericAggregationType(field.aggregationType);
        const isCountAggregation = AggregationUtils.isOfTypeCount(field.aggregationType);
        const isWeightedAverageAggregation = AggregationUtils.isWeightedAverage(field.aggregationType);        
        const isAcceptedWholeObjectAggregation = (isBasicNonNumericAggregation && validationConfigs?.fields?.canHaveWholeObject) || isCountAggregation;

        field.objectKeyRequired = isWholeObjectSelected && !isAcceptedWholeObjectAggregation;
        field.wholeObjectAndNotAllowed = isBasicNonNumericAggregation && (isWholeObjectSelected && !validationConfigs?.fields?.canHaveWholeObject);
        field.objectSubFieldsAndNotAllowed = (isSubFieldSelected && !validationConfigs?.fields?.canHaveObjectSubField);

        if(isWeightedAverageAggregation){
          const isWeightedWholeObjectSelected = DataModelTypeUtils.isObjectFieldType(field.weightedAvgField);
          const isWeightedSubFieldSelected = field.weightedAvgField?.parentFieldId != null;

          field.weightedObjectKeyRequired = isWeightedWholeObjectSelected;
          field.weightedObjectSubFieldsAndNotAllowed = (isWeightedSubFieldSelected && !validationConfigs?.fields?.canHaveObjectSubField);
        }
      });

      if(validationOptions.checkListValidation){
        dataSelectionValidation.fieldInput.fields = {};
        dataSelectionValidation.fieldInput.fields = inputValidator('fields',
        {validate: validationOptions.checkListItemsValidation,
            fields,
            minFields: validationConfigs.fields.min,
            maxFields: validationConfigs.fields.max,
	          checkUniqueFieldType: validationConfigs.fields.checkUniqueFieldType,
            preventCountMixing: validationConfigs.fields.preventCountMixing,
            preventCount: validationConfigs.fields.preventCount,
            preventKpiMultipleAggregate: validationConfigs.fields.preventKpiMultipleAggregate,
            maxFieldTypes: validationConfigs.fields.maxFieldTypes,
            preventAttributeTypeMixing: validationConfigs.fields.preventAttributeTypeMixing,
        });
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.fieldInput.fields);
      }
      if(validationOptions.checkValidation){
        dataSelectionValidation.fieldInput.fieldList = [];
        fields.forEach((field, index) => {
          dataSelectionValidation.fieldInput.fieldList[index] = inputValidator('field', field);
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.fieldInput.fieldList[index]);
        });
      }
      break;
    case 'groupByFields':
    case 'columns':
    case 'topologyNodes':
      const groupByFields = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs[key])? fromJS(dataSelectionArgs[key]).toJS() : [];
      const wholeFieldsChosen = {};

      groupByFields.forEach((groupByField) => {
        const isWholeObjectSelected = DataModelTypeUtils.isObjectFieldType(groupByField.selectedField);
        const isSubFieldSelected = groupByField.selectedField?.parentFieldId != null;

        groupByField.objectKeyRequired = isWholeObjectSelected && !(validationConfigs && validationConfigs[key]?.canHaveWholeObject && !wholeFieldsChosen[groupByField.selectedField?.id]) ;
        groupByField.wholeObjectAndNotAllowed = !groupByField.objectKeyRequired && (isWholeObjectSelected && !(validationConfigs && validationConfigs[key]?.canHaveWholeObject));
        groupByField.objectSubFieldsAndNotAllowed = (isSubFieldSelected && !(validationConfigs && validationConfigs[key]?.canHaveObjectSubField));

        if(isWholeObjectSelected && !groupByField.objectKeyRequired){
          wholeFieldsChosen[groupByField.selectedField?.id] = true;
        }
      });

      if(validationOptions.checkListValidation){
        const aggregationFields = (!dataSelectionArgs.hideSelectedData && dataSelectionArgs.fields)? dataSelectionArgs.fields : [];
        const fieldsTypeDescriptor = getFieldsTypeDescriptor(aggregationFields);
        const hasNonNumericInFields = aggregationFields.length == 1 && !Utils.isBlank(aggregationFields[0].selectedField) && !fieldsTypeDescriptor.isAllAggregationsNumeric;
        dataSelectionValidation.groupByInput[key] = {};
        dataSelectionValidation.groupByInput[key] = inputValidator(key, {
          validate: validationOptions.checkListItemsValidation, groupByFields,
            minGruopBy: Math.max(validationConfigs[key].min, key=='groupByFields' && dataSelectionArgs.postAggregationFilterEnabled ? 1 : 0),
            hasNonNumericInFields,
            preventGroupByOnNonNumeric: validationConfigs[key].preventGroupByOnNonNumeric,
            mandatoryGroupByFields: validationConfigs.mandatoryGroupByFields,
            mandatoryFieldType: validationConfigs[key].mandatoryFieldType,
            notOnlySpecificField: validationConfigs[key].atLeastOneNonDate,
            checkDateIsMultipleOfResolution:validationConfigs[key].checkDateIsMultipleOfResolution, 
            selectedFGAResolution, 
            fields: aggregationFields,
            checkBoundsByFieldsTypes: validationConfigs[key].checkBoundsByFieldsTypes,
          });
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.groupByInput[key]);
      }

      if(validationOptions.checkValidation){
        dataSelectionValidation.groupByInput.groupByList[key] = [];
        groupByFields.forEach((groupByField, index) => {
          dataSelectionValidation.groupByInput.groupByList[key][index] = inputValidator('groupByField', groupByField);
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.groupByInput.groupByList[key][index]);
        });
      }
      break;
    case 'isCollectionTimeAggsEnabled':
      if(dataSelectionArgs.isCollectionTimeAggsEnabled){
        dataSelectionValidation.advancedSection = inputValidator('advancedSection', {collectionAggregation :dataSelectionArgs.collectionTimeAggregationInterval});
        dataSelectionValidation.collectionAggregationValidation = inputValidator('collectionAggregation', dataSelectionArgs.collectionTimeAggregationInterval);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.collectionAggregationValidation);
      }else{
        dataSelectionValidation.advancedSection = {};
        dataSelectionValidation.collectionAggregationValidation = {};
      }
      break;
    case 'topNSettings':
      if(dataSelectionArgs.ffagType === FGA_TYPE_TOPN){
        dataSelectionValidation.topNSettings = topNValidationSchema('topNSettings', dataSelectionArgs.topNSettings);
        isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.topNSettings);
      }
      break;
    case 'limitSize':
      if(dataSelectionOptions.limitSize?.showLimitSize){
        dataSelectionValidation.limitSize = ValidationUtils.validateAttributeSchema(
          getNumericInputValidationSchema({
            min: 1,
            label: 'Limit',
            required: true,
            max: dataSelectionOptions.limitSize.maxLimitSize,
            precision: 0,
          }), dataSelectionArgs.limitSize);
          isValid = isValid && ValidationUtils.isValid(dataSelectionValidation.limitSize);
      }
      break;
    default:
      break;
    }
  });
  dataSelectionValidation.isValid = isValid;

  return dataSelectionValidation;
}

export function validateDataSelection(dataSelectionFga, dataSelectionOptions, 
  validationOptions, ESQueryMaxTerms, dataSelectionValidation, FFAGValidationSchema, ffagNames = [], selectedFGAResolution = []){
  const validationConfigs = getWidgetConfigs(ESQueryMaxTerms, {dataSelectionOptions});
  const dataSelectionValidationState = Utils.isBlank(dataSelectionValidation) ? 
    getDataSelectionValidationState() : dataSelectionValidation;

  if(dataSelectionFga && !dataSelectionOptions.hasFFAGName){
    delete dataSelectionFga.name;
  }
  return validate(dataSelectionFga, dataSelectionValidationState, 
    FFAGValidationSchema, validationOptions, validationConfigs, ffagNames, 
    selectedFGAResolution, dataSelectionOptions);
}

export function getInitializedQuickFilter(){
  return {
    selectedField: null,
    field: null,
    operation: null,
    compareValue: '',
    relationToNext:'AND',
    selectedAttribute: null,
    selection: null,
    lastFilter: true,
    disableComponent: false,
    idCounter: 1,
    filterValueType: FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value,
    templatesPlaceholder: null,
  };
}

export function getResolutionFromSelectionArgs(dataSelectionArgs){
  const resolution = {resolution: null, resolutionUnit: null};

  if (dataSelectionArgs.hasDateInGrouping){
    dataSelectionArgs.groupByFields.forEach(groupByField => {
      if (groupByField.resolution != undefined){
        resolution.resolution = groupByField.resolution;
        resolution.resolutionUnit = groupByField.resolutionUnit;
      }
    });
  }

  return resolution;
}

export function isValidAggregations(agg) {
  return (agg.selectedField && agg.aggregationType);
}

export function getValidAggregations(aggregations) {
  return aggregations.filter(isValidAggregations);
}

export function shouldResetUnit(fieldsTypeDescriptor, oldFieldsTypeDescriptor){
  return fieldsTypeDescriptor.isMixedFieldType
  || fieldsTypeDescriptor.isMixedFieldType != oldFieldsTypeDescriptor.isMixedFieldType
  || fieldsTypeDescriptor.baseUnit != oldFieldsTypeDescriptor.baseUnit
  || fieldsTypeDescriptor.dataType != oldFieldsTypeDescriptor.dataType;
}

export function shouldHaveSeriesAggregation(dataSelectionArgs, dataSelectionOptions = {}){
  // Value ag gregation will not be applied if there is no group by
  // or more than 1 aggregate.

  const groupbyFieldsLimitForFinalAgg = dataSelectionOptions.finalAggLimits ? 
    dataSelectionOptions.finalAggLimits.groupByFields : 0;
  const fieldsLimitForFinalAgg = dataSelectionOptions.finalAggLimits ? 
      dataSelectionOptions.finalAggLimits.fields : 1;

  return dataSelectionArgs.groupByFields.length > groupbyFieldsLimitForFinalAgg
    || dataSelectionArgs.fields.length > fieldsLimitForFinalAgg;
}

export function getCompoundAggregateFieldName(field, repeatedAggFieldsData){
  if(Utils.isBlank(field)){
    return null;
  }

  const fullFieldName = getFullFieldName(field.selectedField);

  if(repeatedAggFieldsData && !repeatedAggFieldsData.multipleAggregateOnSameKpi){
    return fullFieldName;
  }
  const aggregationObject = AggregationUtils.getAggregation(field.aggregationType);

  if(AggregationUtils.isCount(field.aggregationType)){
    return aggregationObject.name;
  }
  let aggregationDisplayName;

  if(AggregationUtils.isNTHPercentile(field.aggregationType)) {
    aggregationDisplayName = `${UnitConversionUtils.formatNumberToString(field.percentileValue)}%`;
  } else if(AggregationUtils.isWeightedAverage(field.aggregationType)){
    const avgWeightedFieldName = getFullFieldName(field.weightedAvgField);
      
    aggregationDisplayName = `${AggregationUtils.getAggregationDisplayName(aggregationObject)}(${avgWeightedFieldName})`;
  } else {
    aggregationDisplayName = AggregationUtils.getAggregationDisplayName(aggregationObject);
  }

  if(repeatedAggFieldsData && repeatedAggFieldsData.showAggregationLabelOnly){
    return aggregationDisplayName;
  }

  return `${aggregationDisplayName}-${fullFieldName}`;
}

export function getPostAggregationFilterAggregatesList(fields) {
  return fields.filter(field => !AggregationUtils.isLatest(field.aggregationType) && !(!field.aggregationType && !field.selectedField));
}

export function deleteFilter(filters, index) {
  if (index != 0 && filters.length > 1){
    if (index == filters.length - 1){ // last one in the list
      filters[index - 1].relationToNext = null;
      filters[index - 1].lastFilter = true;
    } else {
      filters[index - 1].relationToNext
      = index > 1 && filters[index].relationToNext != null ?
      filters[index].relationToNext
       : filters[index - 1].relationToNext;
    }
  }
  filters.splice(index, 1);
  return filters
}

export function clearFilterOnChangeAttribute(filter) {
  filter.compareValue = '';
  filter.operation = '';
  filter.selection = null;
  filter.resolution = null;
  filter.selectedDays = [];
  filter.timeRanges = [];
  filter.inOperationFilter = undefined;
  filter.filterValueType = FilterUtils.FILTER_TYPE_OPTIONS.VALUE.value;
  filter.templatesPlaceholder = null;
  filter.variable = null;
}

export function getRepeatedAggFieldsData(fields){
  const repeatedAggFieldsMap = {};
  let multipleAggregateOnSameKpi = false;

  fields.forEach((field) => {
    if(field && field.selectedField){
      const fieldName = getFullFieldName(field.selectedField);

      if(fieldName in repeatedAggFieldsMap){
        repeatedAggFieldsMap[fieldName] = true;
        multipleAggregateOnSameKpi = true;
      } else {
        repeatedAggFieldsMap[fieldName] = false;
      }
    }  
  });

  return {
    multipleAggregateOnSameKpi,
    showAggregationLabelOnly: Object.keys(repeatedAggFieldsMap).length == 1,
  };
}

export function isLatestNetworkElementField(field){
  return field.selectedField && field.selectedField.fieldName == FieldTypes.NETWORK_ELEMENT 
    && AggregationUtils.isLatest(field.aggregationType);
}

export function getDistinguishedFieldsNames(fields, fieldsMap){
  const distinguishedFieldsNames = [];
  const repeatedAggFieldsData = getRepeatedAggFieldsData(fields);

  fields.map(field => {
    if(field && field.selectedField){
      const selectedField = field.selectedField;
      const displayedFieldName = isLatestNetworkElementField(field) ? FieldTypes.NETWORK_ELEMENT 
        : getCompoundAggregateFieldName(field, repeatedAggFieldsData);

      distinguishedFieldsNames.push(displayedFieldName);
      fieldsMap[displayedFieldName] = {...selectedField, aggregationType: field.aggregationType};
    }
  });

  return distinguishedFieldsNames;
}

function checkAggregatesEquality(newAggregate, oldAggregate){
  const equalFields = newAggregate.aggregationType == oldAggregate.aggregationType
    && newAggregate.selectedField && oldAggregate.selectedField
    && newAggregate.selectedField.id == oldAggregate.selectedField.id;

  if(equalFields && AggregationUtils.isNTHPercentile(oldAggregate.aggregationType)){
    return newAggregate.percentileValue == oldAggregate.percentileValue;
  }

  if(equalFields && AggregationUtils.isWeightedAverage(oldAggregate.aggregationType)){
    return newAggregate.weightedAvgField && oldAggregate.weightedAvgField
      && newAggregate.weightedAvgField.id == oldAggregate.weightedAvgField.id;
  }

  return equalFields;
}

export function getDeletedAggregate(oldAggregates, newAggregates){    
  return oldAggregates.filter((oldAggregate) => {
    return !newAggregates.some((newAggregate) => {
      return checkAggregatesEquality(oldAggregate, newAggregate);
    });
  })[0];
}

export function getDetailsDataSelectionOption(settingsType) {
  let dataSelectionOptions = fromJS(FFAGDefaults.defaultDataSelectionOptions).toJS()
  dataSelectionOptions.hideReuse = true;

  if (settingsType == FGA_TYPE_RAW) {
    dataSelectionOptions = {
      ...dataSelectionOptions,
      fields: {
        hide: true,
        min: 0,
        max: null,
      },
      groupByFields: {
        ...dataSelectionOptions.groupByFields,
        hide: true,
        min: 0,
        max: null,
      },
      columns: {
        hide: false,
        hideResolutionInterval: true,
        min: 1,
        max: null,
        canHaveWholeObject: true,
      },
      showCollectionAggregation: false,
      showAdvancedOptions: false,
    };
  } else if (settingsType == FGA_TYPE_AGGREGATE) {
    dataSelectionOptions = {
      ...dataSelectionOptions,
      fields: {
        ...dataSelectionOptions.fields,
        canHaveWholeObject: true,
      },
    };
  }

  return dataSelectionOptions;
}

export function getPlaceholderEsNames(placeHolder, filters){
  const placeholderEsNamesMap = [];

  if(placeHolder){
    filters.forEach(filter => {
      const templatesPlaceholder = filter.templatesPlaceholder;

      if(templatesPlaceholder && templatesPlaceholder.id == placeHolder.id){
        placeholderEsNamesMap.push(filter.selectedField.elasticsearchName);
      }
    });
  }

  return placeholderEsNamesMap;
}

export function getParentField(selectedField, fieldsList){
  const parentFieldId = selectedField?.parentFieldId ?? selectedField?.id;

  return fieldsList?.find((field) => field.id == parentFieldId);
}

export function getSubFields(selectedField, attributeList, filteringList){
  const parentField = getParentField(selectedField, attributeList);

  return (parentField?.subFields)?.filter(item => !filteringList.includes(item.id)) || [];
}

export function hasUnusedSubFields(subFields, filteringList){
  for(let i = 0; i < subFields.length; i++){
    if(!filteringList.includes(subFields[i].id)){
      return true;
    }
  }

  return false;
}

export function getFfagAndOptions(fga, options, extraOptions = {}){
  return {
    ffag: fga,
    dataSelectionOptions: options,
    ...extraOptions,
  };
}

export function isRecordTypeSelected(ffag){
  return !Utils.isBlank(ffag?.sourceType);
}

export function getFullFieldName(selectedField){
  const parentFieldName = selectedField?.parentFieldName;
  const fieldName = selectedField?.fieldName || '';

  return parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName;
}