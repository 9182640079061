/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {ToggleButtonGroup, ToggleButton, Tooltip, OverlayTrigger} 
from 'react-bootstrap';
export default class ToggleButtonsWithCustomContent extends React.Component {
  
  constructor(props) {
    super(props);
    this.constructButtons = ::this.constructButtons;
  }

  constructContentComponent(content, contentType){
    switch(contentType){
    case 'icon': 
      return <i className={content}/>;
    case 'image':
      return <img src={content}/>;
    default:
      return content;
    }
  }

  constructButtons() {
    return this.props.options.map(option => {
      return (
        <OverlayTrigger
          placement="bottom" trigger={['hover']}
          overlay={
            <Tooltip placement="bottom" className="in" >
              {option.name}
            </Tooltip>
          }
        >
          <ToggleButton 
            type="radio"
            className={`${option.value == this.props.selectedOption ? 'active' : ''}`}
            value={option.value}
            disabled={this.props.disabled}
            onClick={() => { 
              if(!this.props.disabled && option.value !== this.props.selectedOption) {
                this.props.onChangeToggle(option.value); 
              }
            }}
          > 
           {this.constructContentComponent(option.content, option.contentType)}
          </ToggleButton>
        </OverlayTrigger>);
    });
  }

  render() {
    return(
      <ToggleButtonGroup
        type="radio"
        name="options"
        className="tglBtnGrp">
        {this.constructButtons()}
      </ToggleButtonGroup>
  );}
  
}


