/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import DataInput from 'inputs/simple/DataInput';
import Checkbox from 'inputs/simple/Checkbox';
import { fromJS } from 'immutable';
import ValidationOutput from 'containers/ValidationOutput';
import LabeledComponent from 'containers/LabeledComponent';
import { ValidationUtils, ArrayUtils, MsgDefaults} from 'js-utils';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import * as Icons from 'templates/Icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'styles/inputs/compound/dynamicInputsList.less';
import * as UIConstructionUtils from 'utils/UIConstructionUtils';
import CollapsableComponent from 'containers/CollapsableComponent';
import MultipleSelect from 'inputs/simple/MultipleSelect';

export default class DynamicInputsList extends React.Component{
  constructor(props){
    super(props);
    this.onChangeValue = :: this.onChangeValue;
    this.onBlur = :: this.onBlur;
    this.deleteField = :: this.deleteField;
    this.onEdit = :: this.onEdit;
    this.setValue = :: this.setValue;
    this.addField = ::this.addField;
    this.getInputsProps = ::this.getInputsProps;
    this.getLabeledComponent = :: this.getLabeledComponent;
    this.getComponentByType = :: this.getComponentByType;
    this.initializeIdCounter = :: this.initializeIdCounter;
    this.idCounter = this.initializeIdCounter();
  }

  initializeIdCounter(){
    return this.props.initialIdCounter != null ? this.props.initialIdCounter : 0;
  }
  setValue(keyword, index, e){
    const values = fromJS(this.props.inputsvalue).toJS();
    values[index][keyword] = e.target.value;
    this.setState({values});
  }

  onChangeValue(keyword, type, index, e){
    const value = type == 'checkbox' ? e.target.checked : e.target.value;
    if(this.props.onChangeArray){
      const result = fromJS(this.props.inputsvalue).toJS();
  
      result[index] = {
        ...result[index],
        [keyword]: value,
      };

      this.props.onChangeArray(result);
    }else{
      this.props.onChangeValue(value, keyword, index);
    }
  }

  onBlur(keyword, type, index, e) {
    if (this.props.onBlur) {
      this.props.onBlur(e.target.value, keyword, index);
    }else {
      this.onChangeValue(keyword, type, index, e);
    }
  }

  secureValue(keyword, index){
    if(this.props.onChangeArray){
      const result = fromJS(this.props.inputsvalue).toJS();
      result[index][keyword] = !result[index][keyword];
      this.props.onChangeArray(result);
    }
  }

  deleteField(index){
    if(this.props.onChangeArray){
      const result =  ArrayUtils.deleteItem(this.props.inputsvalue, index);

      this.props.onChangeArray(result);
    }else{
      this.props.deleteField(index);
    }
  }

  onEdit(index) {
    // check if this.props.editField -> this.props.editField(index);
    if(this.props.editField) {
      this.props.editField(index);
    }
  }

  addField(index){
    if(this.props.onChangeArray){
      const result =  fromJS(this.props.inputsvalue).toJS();
      
      result.push({
        idCounter: this.idCounter++,
        ...this.props.initialParamtersOnadd,
      });
      this.props.onChangeArray(result);
    }else{
      this.props.addField(index);
    }
  }

  getCopyComponent(input, val, index, keyDisabled, isValid){
    if(input.forceEnableCopy || (input.hasCopy && keyDisabled)){
      const value = this.props.inputsvalue[index][this.props.copyValueKeyword? this.props.copyValueKeyword : 'key'];
      const copyTooltip = MsgDefaults.getCopyTitle(this.props.enableTooltipMessages ? this.props.label : '');
      
      return (
        <CopyToClipboard text={`${this.props.inputPrefix}${value}${this.props.inputSuffix}`} 
          onCopy={() => this.props.onCopyHandler(input.showCopyValue ? val : index)}>
          {ButtonsConstructor.getTooltippedButton(copyTooltip, Icons.copy, "btn-action",
          input.showCopyValue ? isValid : false, ()=>{})}
        </CopyToClipboard>
      );
    }

    return null;
  }

  getInputsProps(input, index){
    const inputProps = {};

    if(this.props.inputsvalue[index] instanceof Object){
      inputProps.val = this.props.inputsvalue[index][input.keyword];
      inputProps.key = `${input.keyword}-${UIConstructionUtils.getKeyFromId(this.props.inputsvalue[index])}`;
      inputProps.keyDisabled = this.props.inputsvalue[index].disabled;
      inputProps.isSecured = this.props.inputsvalue[index].secured;
      inputProps.SecureTooltip = inputProps.isSecured ? 'Unsecure': 'Secure';
    }else{
      inputProps.val = this.props.inputsvalue[index];
      inputProps.key = this.props.inputsvalue.length + '_' + index;
    }

    return inputProps;
  }

  constructText(input, index, isValid) {
    const {key, val, keyDisabled, isSecured, SecureTooltip} = this.getInputsProps(input, index);

    return(
      <Col key={key} xs={input.size} className={` ${input.class || ''} ${keyDisabled? 'savedVariable': ''}`}>
        <DataInput
          key={key}
          className={input.class}
          disabled={keyDisabled && input.keyword == 'key' || input.disabled}
          name={input.keyword}
          inputPrefix={this.props.inputPrefix}
          inputSuffix={this.props.inputSuffix}
          value={val}
          autocomplete={input.autocomplete}
          placeholder={input.placeholder}
          onChange={this.onChangeValue.bind(this, input.keyword, input.type, index)}
          onBlur={this.onBlur.bind(this, input.keyword, input.type, index)}
          inputSize={input.inputSize}
          size={input.size}
          pureInput
          password={isSecured && input.keyword == 'value'}
        /> 
        {input.showSecure && !keyDisabled ? 
          ButtonsConstructor.getButtonWrapper('TooltippedButton', SecureTooltip, '',
          `${isSecured ? Icons.unLock : Icons.lock } btn-action`, this.secureValue.bind(this, 'secured', index), index, this.props.disableComponent)
          : null}
        {this.getCopyComponent(input, val, index, keyDisabled, isValid)}
      </Col>
    );
  }

  constructMultipleSelect(input, index){
    const {key, val} = this.getInputsProps(input, index);

    return (
      <Col xs={12}>
        <MultipleSelect
          key={key}
          onChange={this.onChangeValue.bind(this, input.keyword, input.type, index)}
          className={'col-type-formElement'}
          value={val}
          placeholder={input.placeholder}
          options={input.options}/>
        </Col>
    );
  }

  constructCheckbox(input, index) {
    const key = `${input.keyword}-${UIConstructionUtils.getKeyFromId(this.props.inputsvalue[index])}`;
    const val = this.props.inputsvalue[index][input.keyword];
    return (
      <Col key={key} xs={input.size} className={input.class}>
        <Checkbox
          checked={val}
          defaultChecked={input.defaultChecked}
          disabled={input.disabled}
          label={input.label}
          onChange={this.onChangeValue.bind(this, input.keyword, input.type, index)}
        />
      </Col>
    );
  }

  getComponentByType(input, index, isValid){
    switch (input.type) {
      case 'multipleSelect':
        return this.constructMultipleSelect(input, index);
      case 'checkbox':
        return this.constructCheckbox(input, index);
      case 'text':
      default:
        return this.constructText(input, index, isValid);
    }
  }

  getLabeledComponent(input, index, isValid){
    return (
      <LabeledComponent
          label={input.label}
          size={input.size}
          labelSize={input.labelSize}
          inputSize={input.inputSize}>
          <Row>
            {this.getComponentByType(input, index, isValid)}
          </Row>
        </LabeledComponent>
    );
  }

  getInputComponent(index){
    const deleteTooltip = MsgDefaults.getDeleteTitle(this.props.enableTooltipMessages ? this.props.label : '');

    const deleteButton = ButtonsConstructor.getButtonWrapper('TooltippedButton', 
      deleteTooltip,
      Icons.delete, `float-right btn-action`,
      this.deleteField, index, this.props.disableComponent);
    const editButton = ButtonsConstructor.getButtonWrapper('TooltippedButton', MsgDefaults.getEditTitle(this.props.label), Icons.edit, `float-right btn-action`, this.onEdit, index, this.props.disableComponent);
    const key = UIConstructionUtils.getKeyFromId(this.props.inputsvalue[index]);
    const isValid = this.props.validation && !ValidationUtils.isValid(this.props.validation[index]);

    return(
        <Row className={`dynamicListItem ${this.props.classes}`} key={key}>
          <Col xs={this.props.colSize ? this.props.colSize : 10} className={this.props.colSize ? 'padRight5' : ''}>
            <Row>
              {
                this.props.inputs.map(input => {
                  return input.showLabelComponent ? this.getLabeledComponent(input, index, isValid) 
                    : this.getComponentByType(input, index, isValid);
                })
              }
            
            {
            isValid ? 
              <Col xs={12}>
                <ValidationOutput validation={this.props.validation[index]}/>
              </Col> : null
            }
            </Row>
          </Col>
          {this.props.editField ? 
            <Col xs={1} className='paddingLeft5 padRight5'>
              {editButton}
            </Col>
           : null }
          {this.props.disableDeleteOption ? null : 
            <Col xs={1} className='paddingLeft5 padRight5 float-right'>
              {deleteButton}
            </Col>
          }
        </Row>
      
    );
  }

  getInputsComponent(){
    return this.props.inputsvalue ? this.props.inputsvalue.map((value, index) => {
      return this.getInputComponent(index);
    }) : null;
  }

  getLabelWithAddButton(){
    const addTooltip = MsgDefaults.getAddTitle(this.props.enableTooltipMessages ? this.props.label : '');

    const addButton = ButtonsConstructor.getButtonWrapper('TooltippedButton', addTooltip,
        Icons.add, `float-none btn-action btn btn-default`,
        this.addField, null, this.props.disableComponent);
    return(
      <div>
        {this.props.label && !this.props.hideLabel? <label className="marginRight10 margBtm10"> {this.props.label}</label> : null}
        {!this.props.hideAddButton ? addButton : null}
      </div>
    );
  }

  getComponent(){
    const labelWithAddButton = this.getLabelWithAddButton();

    return(
      <Fragment>
        <div className={this.props.className}>
            {labelWithAddButton}
            {this.getInputsComponent()}
        </div>
        <ValidationOutput validation={this.props.overallValidation}/>
      </Fragment>
    );
  }

  getCollapsableComponent(){
    const buttons = this.props.disableAddOption ? [] : [
      ButtonsConstructor.add(
        this.addField,
        MsgDefaults.getAddTitle(this.props.label),
      ),
    ];

    return (
      <Row className={this.props.className}>
        <Col xs={12} >
          <CollapsableComponent
            title={this.props.collapseLabel} 
            initiallyCollapsed={(this.props.inputsvalue || []).length < 1} 
            validation={this.props.overallValidation}
            buttons={buttons}
          >
            <div className={`${(this.props.inputsvalue || []).length > 0 ?  'expandableArea' : '' }`}>
            {this.getInputsComponent()}
            </div>
          </CollapsableComponent>
        </Col>
      </Row>
    );
  }

  render(){
    return this.props.isCollapsable ? this.getCollapsableComponent() : this.getComponent();
  }
}