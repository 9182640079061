/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import EmailComponent from 'email/EmailComponent';
import Modal from 'containers/Modal';
import 'quill/dist/quill.snow.css';
import 'react-tagsinput/react-tagsinput.css';
import ReactDom from 'react-dom/server';

export default class SendEmailModal extends Component {

  constructor(props){
    super(props);
    var annotationImageClass = 'img-responsive';
    this.state = {
      toMail: [],
      cc: [],
      bcc: [],
      subject: '',
      body : `<br/>
              <br/>
              ${props.imageURL ? `<img class="${annotationImageClass}"
                  src="${props.imageURL}" width="100%"/>` : ''}
              <br/>`,
      canSend: false,
    };
    this.sendEmail = ::this.sendEmail;
    this.validateSendingEmail = ::this.validateSendingEmail;
  }

  sendEmail(){
    const newBody = this.state.body.replace(`src="${this.props.imageURL}"`, 'src="cid:image"');
    const {subject, toMail, cc, bcc} = this.state;
    const bodyEl
      = <div dangerouslySetInnerHTML={{__html: newBody}}/>;

    this.props.onSendEmail({
      subject,
      toMail,
      cc,
      bcc,
      body: ReactDom.renderToString(bodyEl),
    });
  }

  validateSendingEmail(saving){
    this.setState({canSend: saving});
  }
  

  render = () =>
    <Modal  bsSize="large"
          onHide={this.props.onHide}
          footerButtons={[
                  <Button bsStyle="primary"
                    onClick={this.sendEmail}
                    disabled={!this.state.canSend && !this.props.sendingEmail} >
                    { this.props.sendingEmail ? 'Sending..' : 'Send' }
                  </Button>]} 
          show={this.props.show}
          title={ this.props.title}>

        <EmailComponent 
          inputs={this.state}
          validationFunction={this.validateSendingEmail}
          onChange={(result) => {
              this.setState({
                ...this.state,
                ...result
              });
          }}/>
    </Modal>;
}
