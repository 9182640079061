/* Copyright 2019 Greyskies. All Rights Reserved. */

import CustomPropertiesProvider from './CustomPropertiesProvider';

export default {
  __depends__: [
    require('bpmn-js-properties-panel/lib/provider/camunda/element-templates/index'),
  ],
  __init__: [ 'propertiesProvider' ],
  propertiesProvider: [ 'type', CustomPropertiesProvider ],
};