/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Row, Col, FormControl} from 'react-bootstrap';

function checkValidationOutput(numericInputValidation){
  if(numericInputValidation.invalidInteger){
    return <span className='warning-span'>
            {numericInputValidation.invalidIntegerMessage}
           </span>;
  } else if(numericInputValidation.invalidNumber){
    return <span className='warning-span'>
            {numericInputValidation.invalidInputMsg}
           </span>;
  }else if (numericInputValidation.numericInputRangeValidation){
    return <span className='warning-span'>
            {numericInputValidation.numericInputRangeValidation}
           </span>;
  }else if (numericInputValidation.validationMessage){
    return <span className='warning-span'>
            {numericInputValidation.validationMessage}
           </span>;
  }else if (numericInputValidation.hasError){
    return <span className='warning-span'>
            {numericInputValidation.validation.label}
           </span>;
  }else if (numericInputValidation.invalidPrecision){
    return <span className='warning-span'>
            {numericInputValidation.invalidPrecisionMsg}
           </span>;
  }

  return null;
}

const INTEGERS_REGEX = new RegExp(/^[+\-]?\d+$/);

/** @deprecated */
const NumericInput = props => {
  const acceptNegativeNumericInput = props.acceptNegativeValues ? props.acceptNegativeValues : false;
  const MAX_SAFE_NUMERIC_INPUT = props.max || Number.MAX_SAFE_INTEGER;
  const MIN_SAFE_NUMERIC_INPUT = props.min ? props.min : (acceptNegativeNumericInput ? Number.MIN_SAFE_INTEGER : 0);
  const value = props.value;
  const validEmptyEntry
  = props.allowEmptyEntry && (value == null || value == '');
  const numericInputRangeValidation
  = ((value != null && value != '' )
    && (value > MAX_SAFE_NUMERIC_INPUT || value < MIN_SAFE_NUMERIC_INPUT) ) ?
    `${props.label || ''} value should be a number from ${MIN_SAFE_NUMERIC_INPUT} to
    ${MAX_SAFE_NUMERIC_INPUT}` : null;
  const invalidInteger
  = ((!validEmptyEntry) && props.inputType == 'Integer' && !INTEGERS_REGEX.test(value));
  const invalidIntegerMessage = `${props.label} value should be an integer`;
  const validationMessage = props.validationMessage;
  const invalidNumber = (!validEmptyEntry) && isNaN(parseFloat(value));
  const invalidInputMsg = 'Invalid Number';
  const validation = props.validation || null;
  const hasError = validation && validation.style === 'error';
  const precision = props.precision ? Math.pow(10,props.precision) : null;
  const negativeAndPositiveInputRegex = new RegExp('^[-+]?\\d+(\\.\\d{0,'+props.precision+'})?$')
  const positiveOnlyInputRegex = new RegExp('^\\d+(\\.\\d{0,'+props.precision+'})?$');
  const precisionValidationRegex
  = acceptNegativeNumericInput ? negativeAndPositiveInputRegex : positiveOnlyInputRegex;
  const invalidPrecision
  = props.precision && value
    && value.toString().match(precisionValidationRegex) === null;
  const invalidPrecisionMsg
  = `Precision is valid for ${props.precision} digit/s only`;
  const numericInputValidation = {
    invalidInteger,
    invalidIntegerMessage,
    invalidNumber,
    invalidInputMsg,
    numericInputRangeValidation,
    validationMessage,
    hasError,
    validation,
    invalidPrecision,
    invalidPrecisionMsg
  }
  const validationOutput = props.stopValidation ? null : checkValidationOutput(numericInputValidation);
  const autoChangeValue = (value) => {
    if(value === '' && props.defaultValue != null &&  props.defaultValue != undefined){
      return props.defaultValue;
    }else if((value != null && value != '' && value < MIN_SAFE_NUMERIC_INPUT) ||
      ((value == null || value == '') && !props.allowEmptyEntry) ||
      invalidInteger || validationMessage){
      return MIN_SAFE_NUMERIC_INPUT;
    }else if(value > MAX_SAFE_NUMERIC_INPUT){
      return MAX_SAFE_NUMERIC_INPUT;
    }else if(precision){
      return Math.round(value * precision) / precision;
    }

    return value;
  };

  const inputResult = <FormControl
      type={props.hideValue ? 'text' : 'number'}
      className={`input-text ${props.disabled ? 'disabled-input' : ''}`}
      value={props.hideValue ? '' : value}
      min={props.min}
      max={props.max}
      step={props.step || 1}
      name={props.name || null}
      placeholder={props.placeholder || null}
      disabled={props.disabled || false}
      onBlur={() => {
        const value = autoChangeValue(props.value);

        props.onChange({target:{value}});
        if(props.onBlur){
          props.onBlur({target:{value}});
        }      }}
      onKeyPress={(e)=>{
        if(e.key == 'e' || e.key == 'E') {
          e.preventDefault()
        }
      }}
        onChange={props.onChange || null}
    />;

  if(props.pureInput){
    return inputResult;
  }
  
  return(
    <Col xs={props.size || 6}>
      <div className={`data-input ${props.className || ''}`}>
        <Row>
          {!props.label || props.hideLabel ? null :
            <Col xs={props.labelSize || 3}>
              <label className='data-input-label'>
                {props.label}
              </label>
            </Col>
          }
          <Col xs={props.inputSize || 6}>
            {inputResult}
            {validationOutput}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default NumericInput;