/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Navbar as BsNavbar} from 'react-bootstrap';
import 'styles/templates/navbar.less';

const {Toggle, Collapse} = BsNavbar;

const Navbar = (props) => {
  return (
    <BsNavbar inverse fixedTop>
      <div className="navbar-header">
          <Toggle/>
          <a className="navbar-brand" href="#">
            {props.logo}
          </a>
          <div className="navbar-title headerEllipsis">
            {props.appName}
          </div>
          <div className="navbar-export navbar-button">
            {props.downloadCenter}
          </div>

      </div>
      <Collapse>
        {props.sidebar}
        {props.navbarButtons}
      </Collapse>
    </BsNavbar>
  );
};

export default Navbar;
