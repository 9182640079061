/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import * as Table from 'reactabular-table';
import * as search from 'searchtabular';
import * as sort from 'sortabular';
import * as resolve from 'table-resolver';
import * as tree from 'reactabular-tree';

import Searchbar from './utils/Searchbar';
import {Paginator} from './utils/Paginator';
import ColumnSearch from './utils/ColumnSearch';
import searchStrategies from './utils/searchStrategies';

import $ from 'jquery';
import {compose} from 'redux';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
import 'reactabular-tree/style.css';
import Scrollbar from 'react-scrollbar';
import Loader from 'templates/Loader';
import 'bootstrap/js/modal';
import bootbox from 'bootbox';
import ReactResizeDetector from 'react-resize-detector';
import {fromJS} from 'immutable';
import 'styles/dataVisualization/table/reactable.less';
import{Utils} from 'js-utils';


const RESIZE_REFRESH_RATE = 250;

const sortingOrder = {
  FIRST: 'asc',
  asc: 'desc',
  desc: 'asc',
};

const NO_DATA_MESSAGE = 'No data available in table';
const NO_COLUMNS_MESSAGE = 'No Columns chosen to be shown';

export default class Reactable extends React.Component {
  static propTypes = {
    idx: PropTypes.string.isRequired,
    tableData:PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,

    onePagePagination: PropTypes.bool, //To make pagination display only the current page and navigation buttons (next and previous)
    forcedPage: PropTypes.number, //Force pagination to go to this page. Must be unset from parent component.
    serverSide: PropTypes.bool,
    fixedHeaderHeight: PropTypes.string,
    fetchedCount: PropTypes.number,
    fixedPagination: PropTypes.number,
    searchable: PropTypes.bool,
    paginated: PropTypes.bool,
    filterable: PropTypes.bool,
    fixedHeader: PropTypes.bool,
    tree: PropTypes.bool,
    changed: PropTypes.bool,
    onLoad: PropTypes.func,
    onAfterSearch: PropTypes.func,
    initiateTableRequest: PropTypes.func,
    saveSorting: PropTypes.func,
    sortingColumn: PropTypes.number,
    sortingDirection: PropTypes.string,
    filters: PropTypes.object,
    filterComponent: PropTypes.func,
    footerMessage: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.triggerSearch = ::this.triggerSearch;
    this.prepareTableColumns = ::this.prepareTableColumns;
    this.prepareColumnFilters = ::this.prepareColumnFilters;
    this.initiateTable = ::this.initiateTable;
    this.prepareBackendData = ::this.prepareBackendData;
    this.prepareTableData = ::this.prepareTableData;
    this.prepareHeaders = ::this.prepareHeaders
    this.earlyFormatData = ::this.earlyFormatData;

    this.onSearch = ::this.onSearch;
    this.onSort = ::this.onSort;
    this.onQueryChange = ::this.onQueryChange;
    this.buildQueryParams = ::this.buildQueryParams;
    this.highlightMatches = ::this.highlightMatches;
    this.onPaginationChange = ::this.onPaginationChange;
    this.paginate = ::this.paginate;

    this.handleResize = ::this.handleResize;
    this.onRow = ::this.onRow;
    this.getEmptyRow = ::this.getEmptyRow;
    this.onSelectRow = ::this.onSelectRow;
    this.columns = props.columns;
    this.uniques = {};
    this.search = {
      filter:{},
    };
    this.pagination = {
      perPage: props.fixedPagination || props.perPagePagination || 10,
      page: props.forcedPage || 0,
    };
  }

  state = {
    data: [],
    selectedRowIndex: this.props.defaultSelectedIndex,
  };

  componentWillMount() {
    this.prepareTableColumns();


    if (this.props.sortingColumn !== undefined && this.columns[this.props.sortingColumn]){
      const sortingColumn =   sort.byColumn({
        sortingColumns: this.sortingColumn,
        selectedColumn: this.props.sortingColumn,
      });
      sortingColumn[this.props.sortingColumn].direction = this.props.sortingDirection;
      this.sortingColumn = sortingColumn;
      this.sortingColumnName =  this.columns[this.props.sortingColumn].property;
      this.sortingColumnProperty =  this.columns[this.props.sortingColumn].initialProperty;
      this.sortingColumnDirection =  sortingColumn[this.props.sortingColumn].direction;
      this.sortingColumnIndex = this.props.sortingColumn;
    }

    if(this.props.serverSide){
      this.initiateTable(fromJS(this.state).toJS()).then( (newState) => {
        if (this.props.filters){
          const keys = Object.keys(this.props.filters);
          keys.forEach(key => {
            if (this.search.filter.hasOwnProperty(key)){
              this.search.filter[key] = this.props.filters[key];
            }
          });
        }
        if(!this.props.immediateLoad){
          this.prepareTableData(null, newState);
        }else{
          this.prepareBackendData(this.props.tableData,
             this.props.fetchedCount, this.state);
        }
      });
    }else{
      this.prepareColumnFilters(this.columns, this.props.tableData, () => {
        this.prepareTableData(this.props.tableData, fromJS(this.state).toJS());
      });
    }
  }

  componentDidMount() {
    this.fixHeaderWidths(this.props.idx);
  }

  componentWillReceiveProps(nextProps){
    this.columns = nextProps.columns;
    this.prepareTableColumns();
    if(this.props.sortingColumn !== nextProps.sortingColumn){
      if(nextProps.sortingColumn !== undefined && this.columns[nextProps.sortingColumn]){
        const sortingColumn =   sort.byColumn({
          sortingColumns: this.sortingColumn,
          selectedColumn: nextProps.sortingColumn,
          sortingOrder,
        });
        sortingColumn[nextProps.sortingColumn].direction = nextProps.sortingDirection;
        this.sortingColumn = sortingColumn;
        this.sortingColumnName =  this.columns[nextProps.sortingColumn].property;
        this.sortingColumnProperty =  this.columns[nextProps.sortingColumn].initialProperty;
        this.sortingColumnDirection =  nextProps.sortingDirection;
        this.sortingColumnIndex = nextProps.sortingColumn;
      }else{
        this.sortingColumn = null;
        this.sortingColumnName =  null;
        this.sortingColumnProperty =  null;
        this.sortingColumnDirection =  null;
      }
    }
    if(this.props.forcedPage !== nextProps.forcedPage 
      && this.pagination.page !== nextProps.forcedPage){
      this.pagination.page = nextProps.forcedPage || 0;
      this.prepareTableData(this.props.tableData, this.state);
    }
    if(_.isEqual(this.props.tableData, nextProps.tableData)){
      this.setState({
        loading: false,
      }, () => {
        if(this.props.serverSide){
          this.prepareBackendData(nextProps.tableData,
             nextProps.fetchedCount, this.state);
        }
      });
    }
    if(this.props.serverSide){
      if (this.props.filters !== nextProps.filters){
        this.initiateTable(fromJS(this.state).toJS()).then( (newState) => {
          this.prepareTableData(null, newState);
        });
      }
      if(_.isEqual(this.props.tableData, nextProps.tableData)){
        this.setState({
          loading: false,
        }, () => {
          if(this.props.serverSide){
            this.prepareBackendData(nextProps.tableData,
               nextProps.fetchedCount, this.state);
          }
        });
      }else{
        this.prepareBackendData(nextProps.tableData,
          nextProps.fetchedCount, this.state);
      }
    }else{
      this.prepareColumnFilters(nextProps.columns,
        nextProps.tableData, () => {
          let preventScroll = false;

          if(this.props.tableData.length == nextProps.tableData.length){
            preventScroll = true;
          }
          this.prepareTableData(nextProps.tableData, this.state, null, preventScroll);
        });
    }
  }

  componentDidUpdate(){
    this.fixHeaderWidths(this.props.idx);
  }
  triggerSearch(){
    this.onSearch(this.refSearch.state.value);
  }
  prepareTableColumns(){
    const columns = fromJS(this.columns).toJS();

    columns.forEach(column => {
      //formating column headers
      column.header.formatters = column.header.format ?
        [column.header.format] : column.header.formatters || [];
      if(column.sortable){
        column.header.props = column.header.props || {};
        column.header.originalclassName = column.header.props.className || '';
        column.header.props.className
          = `${column.header.originalclassName} sort_none`;
      }
      // fromating column cells
      column.cell = column.cell || {};
      column.cell.formatters = column.cell.format ?
        [column.cell.format] : column.cell.formatters || [];

      if(column.highlighted){
        column.cell.formatters.push(search.highlightCell);
      }
      if(this.props.tree && column.tree){
        column.cell = {
          format : (device, extra) => {
            const func = tree.toggleChildren(column.tree);

            if(column.tree.getVal){
              return func(column.tree.getVal(device, extra), extra);
            }

            return func(device, extra);
          },
        };
      }
    });
    this.columns = columns;
  }

  prepareColumnFilters(columns, tableData, callback){
    const search = fromJS(this.search).toJS();
    const uniques = {};

    columns.forEach(column => {
      if(column.searchable){
        if(column.filters){
          uniques[column.property] = column.filters;
        } else{
          const uniquesSet = new Set();
          
          tableData.forEach(option => uniquesSet.add(Utils.getObjectValueByPropertyPath(option, column.property)));
          uniques[column.property] = Array.from(uniquesSet);
        }
        if((search.filter[column.property]
         && search.filter[column.property].length == 0)
        || (!search.filter[column.property])
        || ( search.filter[column.property]
          && this.uniques[column.property]
          && search.filter[column.property].length
            == this.uniques[column.property].length)){
          search.filter[column.property]
            = fromJS(uniques[column.property]).toJS();
        }
      }
    });
    this.search = search;
    this.uniques = uniques;
    callback();
  }

  initiateTable(state){
    return this.props.initiateTableRequest().then( receivedData => {
      const keys = Object.keys(receivedData);

      keys.forEach(key => {
        if(key == 'count'){
          state.fetchedCount = receivedData.count;
        }else{
          this.uniques[key] = receivedData[key];
          this.search.filter[key] = receivedData[key];
        }
      });

      return state;
    });
  }

  loadBackendData = (query) => {
    if(!this.props.stopLoading){
      this.setState({
        loading: !this.props.isHideInternalLoader,
      }, () => {
        if(this.props.immediateLoad){
          this.props.onLoad(query);
        }else{
          this.props.onLoad(query).then((response) => {
            if(response && response.count > 0 && response.data && response.data.length == 0){
              this.pagination.page = this.pagination.page - 1;
              const newQuery = this.buildQueryParams();
    
              this.loadBackendData(newQuery);
            }else{
              this.setState({loading: false});
            }
          });
        }
      });
    }
  }

  prepareTableData(tableData, state, cb, preventScroll){
    const columns =  this.columns;

    if(this.props.serverSide){
      const query = this.buildQueryParams();

      if(this.props.changed){
        bootbox.confirm({
          message: 'Unsaved changes will be discarded, continue ?',
          buttons: {
            confirm: {
              label: 'Yes',
              className: 'btn-primary btn-sm',
            },
            cancel: {
              label: 'No',
              className: 'btn-cancel',
            },
          },
          callback: (result) => {
            if(result){
              this.loadBackendData(query);
            }else{
              const newState = state;

              this.setState(newState);
            }
          },
        });
      }else{
        this.loadBackendData(query);
      }
    }else{
      const filter = this.search.filter;

      let data = this.resolveAndFromulateData(tableData, columns, this.props.resolve);

      if(Object.keys(filter).length > 0){
        data = this.filterColumns(filter, data, columns);
      }

      data = this.sortData(data);
      let paginatedData = this.props.paginated ? this.paginate(data) : null;

      if(filter.all){
        paginatedData.data = this.highlightMatches(paginatedData.data, columns);
      }
      this.setState({
        paginatedData,
        data,
      }, () => {
        if(typeof cb === 'function'){
          cb();
        }
      });
    }
    if(this.ScrolBoundRef && !preventScroll){
      this.ScrolBoundRef.scrollArea.scrollTop();
      this.ScrolBoundRef.scrollArea.refresh();
    }
  }

  prepareBackendData(tableData, fetchedCount, state){
    let data = this.resolveAndFromulateData(tableData, this.columns,
       this.props.resolve);

    if(this.search.filter.all){
      data = this.highlightMatches(data, this.columns);
    }
    state.fetchedCount = fetchedCount;

    if(this.props.paginated){
      state.paginatedData = {
        data,
        amount: Math.ceil(state.fetchedCount / this.pagination.perPage),
        page: this.pagination.page,
      };
    }
    state.data = data;
    this.setState(state);
  }

  buildQueryParams(){
    const stateFilters = fromJS(this.search.filter).toJS();

    delete stateFilters.all;
    const filtersKeys = Object.keys(stateFilters);

    filtersKeys.forEach(filterKey => {
      if(stateFilters[filterKey].length == this.uniques[filterKey].length){
        stateFilters[filterKey] = [];
      }
    });
    const selectedFilters = stateFilters; //using filters directly as a json object
    const filters = JSON.stringify(stateFilters);

    const query = {
      sortingColumnName: this.sortingColumnName,
      sortingColumnProperty: this.sortingColumnProperty,
      sortingColumnDirection: this.sortingColumnDirection,
      sortingColumnIndex: this.sortingColumnIndex,
      paginationRange : this.pagination.perPage,
      paginationNumber: this.pagination.page || 0,
      searchQuery: this.search.filter.all,
      filters,
      selectedFilters,
    };

    return query;
  }

  fixHeaderWidths(idx){
    let marginTop = 0;
    if($(`.${idx} .mainTable thead`).length > 0){
      marginTop = -$(`.${idx} .mainTable thead`).height();
    }
    $(`.${idx} .maintable-container`).css('margin-top', marginTop);

    if($(`.${idx} .mainTable tbody`)[0]){
      const headerWidthsLength = $(`.${idx} .mainTable th`).length;
      const scrollwidth
      = $(`.${idx} .fixedHeader tbody`)[0].offsetWidth
        - $(`.${idx} .mainTable tbody`)[0].offsetWidth;

      for(let i = 0; i < headerWidthsLength; i++){
        let width = parseInt($(`.${idx} .mainTable th:eq(${i})`).css(
          'width'), 10);

        if( i == headerWidthsLength - 1 ){
          width = width + scrollwidth;
        }

        $(`.${idx} .fixedHeader tr:eq(0) th:eq(${i})`).css('width',
         width);
        $(`.${idx} .fixedHeader tr:eq(1) th:eq(${i})`).css('width',
         width);
      }
    }
  }

  onPaginationChange(pagination){
    this.pagination = fromJS(pagination).toJS();
    this.prepareTableData(this.props.tableData, this.state);
  }

  onSort(selectedColumn) {
    if( this.columns[selectedColumn].sortable && !this.props.disableSorting){
      const sortingColumn =   sort.byColumn({
        sortingColumns: this.sortingColumn,
        selectedColumn,
        sortingOrder,
      });
      this.sortingColumnName =  this.columns[selectedColumn].property;
      this.sortingColumnProperty =  this.columns[selectedColumn].initialProperty;
      this.sortingColumnDirection =  sortingColumn[selectedColumn].direction;
      this.sortingColumn = sortingColumn;
      this.sortingColumnIndex = selectedColumn;
      if(this.props.saveSorting){
        this.props.saveSorting(selectedColumn, this.sortingColumnDirection, this.sortingColumnProperty);
      }
      this.prepareTableData(this.props.tableData, this.state);
    }
  }

  onSearch(query) {
    const search = fromJS(this.search).toJS();

    search.filter.all = query;
    this.pagination.page = 0;
    this.search = search;
    this.prepareTableData(this.props.tableData, this.state, () => {
      if(this.props.onAfterSearch){
        this.props.onAfterSearch(this.state.data);
      }
    });
  }

  onQueryChange(e) {
    const search = fromJS(this.search).toJS();

    if(e.target.value && e.target.value.length > 0){
      const value = e.target.value;
      search.filter[e.target.name] = typeof value == "string" ? [value] : value;
    }
    this.pagination.page = 0;
    this.search = search;
    this.prepareTableData(this.props.tableData, this.state, () => {
      if(this.props.onAfterSearch){
        this.props.onAfterSearch(this.state.data);
      }
    });
  }

  filterColumns(filter, data, columns){
    const filteredColumns = Object.keys(filter);
    let newsortedData = data;
    const newColumns = columns.map(column => {
      if(this.props.searchFormated){
        return {
          ...column,
          property: column.property + '_formatted',
        };
      }

      return column;
    });
    //filtering by search query first
    if(filter.all && filter.all !== ''){
      newsortedData =  search.singleColumn({
        columns: newColumns.filter(column => column.property),
        searchColumn: 'all',
        query: filter.all}
      )(newsortedData);
    }

    //filtering by column header filters om searched data
    filteredColumns.forEach( column => {
      let dummysorted = [];

      if(column !== 'all' && filter[column].length > 0){
        filter[column].forEach( filter1 => {
          let tempsorted = [];

          if(filter1 == ''){
            tempsorted = newsortedData.filter(row => row[column] == '');
          }else{
            const searchOptions = { columns: newColumns,
                                    searchColumn: `${column}${this.props.searchFormated ? '_formatted' : ''}`,
                                    query : filter1,
                                  };

            if(!column.preventExactFilter){
              searchOptions.strategy = searchStrategies.exact;
            }
            tempsorted = search.singleColumn(searchOptions)(newsortedData);
          }
          const a = new Set(dummysorted);
          const b = new Set(tempsorted);

          dummysorted = [...a, ...b];
        });

        const y = new Set(dummysorted);
        const z = new Set(newsortedData);

        newsortedData = [...z].filter(x => y.has(x));
      }
    });

    return newsortedData;
  }

  paginate(data) {
    const validatedData = data || [];
    const page = this.pagination.page || 0;
    const perPage = this.pagination.perPage;
    const amountOfPages = Math.ceil(validatedData.length / perPage);
    const startPage = page < amountOfPages ? page : 0;
    const newData = validatedData.slice(startPage * perPage,
       startPage * perPage + perPage);

    return {
      amount: amountOfPages,
      data: newData,
      page: startPage,
    };
  }

  sortData(data, sortingcolumn){
    return sort.sorter({
      columns: this.columns,
      sortingColumns: sortingcolumn || this.sortingColumn,
      sort: orderBy,
    })(data);
  }

  highlightMatches(data, columns){
    let newData = data;

    newData = search.highlighter(
                                { columns: columns.map(column => {
                                  if(this.props.searchFormated){
                                    return {
                                      ...column,
                                      property: column.property + '_formatted'
                                    };
                                  }

                                  return column;
                                }),
                                  matches: search.matches,
                                  query: {all :this.search.filter.all},
                                })(newData);
    newData = newData.map(row => {
      Object.keys(row._highlights).forEach(key => {
        row._highlights[key.replace('_formatted', '')] = row._highlights[key];
      });

      return row;
    });

    return newData;
  }

  earlyFormatData(data){
    const newsortedData = data;

    if(this.props.searchFormated){
      newsortedData.forEach(row => {
        this.columns.forEach(column => {
          if((column.cell || {}).formatters){
            row[column.property + '_formatted']
            = Table.evaluateFormatters(((column.cell || {}).formatters || []).filter(formatter => formatter.name !== 'highlightCell'))(row[column.property], {rowData : row});
          }
        });
      });
    }

    return newsortedData;
  }

  resolveAndFromulateData(data, columns, resolveType){
    let newData = fromJS(data).toJS();

    //resolve data and flatten it if resolve type is not 'basic'
    const columnsToBeResolved = columns.filter(column => !column.unresolvable);

    if(resolveType !== 'basic' && columnsToBeResolved.length > 0){
      newData =  resolve.resolve({
        columns: columnsToBeResolved,
        method: (extra) => compose(
          resolve.byFunction('cell.resolve')(extra),
          resolve.nested(extra)
        ),
      })(data);
    }

    //formulate data by transfering nulls to empty strings
    newData.forEach(row => {
      const keys = Object.keys(row);

      columns.forEach(column => {
        if(column.unresolvable){
          row[column.initialProperty+ '.value'] = row[column.initialProperty] ? row[column.initialProperty].value : '';
        }

      });
      for(let i = 0; i < keys.length;i++){
        if(row[keys[i]] == null || row[keys[i]] == undefined){
          row[keys[i]] = '';
        }
      }
    });

    return this.earlyFormatData(newData);
  }

  onSelectRow(row, rowIndex){
    this.setState({selectedRowIndex: rowIndex}, () => {
      this.props.onSelectRow(row);
    });
  }

  onRow(row, {rowIndex}) {
    const onClick = this.props.onSelectRow ? () => this.onSelectRow(row, rowIndex) :  () => {};
    if(this.props.tree){
      return {
        className: row.parent ? 'child-row' : 'parent-row',
      };
    }

    return {
      className: `${rowIndex % 2 ? 'odd-row' : 'even-row'} `
        + `${rowIndex === this.state.selectedRowIndex && 'selected-row'}`,      
      onClick,
    };
  }

  handleResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout( () =>  {
      this.fixHeaderWidths(this.props.idx);
      if(this.ScrolBoundRef ){
        this.ScrolBoundRef.scrollArea.scrollTop();
        this.ScrolBoundRef.scrollArea.refresh();
      }
    }, RESIZE_REFRESH_RATE);
  }

  prepareHeaders(addFilter){
    const columns = fromJS(this.columns).toJS()
                                        .filter(column => !column.hidden);

    columns.forEach((column, index) => {
      if(column.sortable){
        if(this.sortingColumn){
          const sortDirection = ((this.sortingColumn[index]) && this.sortingColumn[index].direction ?
          this.sortingColumn[index].direction
          : 'none');

          column.header.props.className
          = `${column.header.originalclassName} sort_${sortDirection}`;
        }
      }
      const singleFilter = column.singleFilter;
      const filter = column.searchable && addFilter ? <ColumnSearch
        tableId={this.props.idx}
        onQueryChange={this.onQueryChange}
        property={column.property}
        filter={singleFilter && this.search.filter[column.property]? 
          this.search.filter[column.property][0] : this.search.filter[column.property]}
        uniques={this.uniques[column.property]}
        singleFilter={singleFilter}
        /> : null;
      const sortableHeader = (child) => (
          <div >
            <div className={`sorting-field ${column.searchable ?
               'filterable' : ''}`}
              onClick={() => this.onSort(index)}>
              {filter}
              {child}
            </div>
          </div>
        );

      column.header.formatters.unshift(sortableHeader);
      if(column.header.sortableFormatedHeader){
        column.header.formatters.unshift(column.header.sortableFormatedHeader);
      }
    });

    return columns;
  }
  getEmptyRow(message){
    return <div className='emptyTableRow'>
    <span> {message} </span>
    </div>;
  }
  render(){
    const columns = this.prepareHeaders(false);
    let totalAmount = this.props.serverSide ? this.state.fetchedCount
    : this.state.data ? this.state.data.length : 0;
    let emptyRow = null;
    let table = null;
    let  paginatedData = this.props.paginated ?
    this.state.paginatedData || {data: []} : {data : this.state.data};
    if ((!totalAmount || totalAmount < 1) && !this.state.loading && !this.props.externalLoading){ 
      emptyRow = this.props.emptyMessage? this.getEmptyRow(this.props.emptyMessage) : this.getEmptyRow(NO_DATA_MESSAGE);
    }else if(columns.length < 1){
      emptyRow = this.getEmptyRow(NO_COLUMNS_MESSAGE);
    }else{
      let rowsData = paginatedData.data;

      if(this.props.footerMessage){
        emptyRow = this.getEmptyRow(this.props.footerMessage);
      }
      if(this.props.tree){
        rowsData = compose(
          tree.filter('showingChildren'),
          tree.sort({
            columns,
            sortingColumns: this.sortingColumn,
          }),
          tree.search({ columns, query: this.search.filter })
        )(rowsData);
      }
      const BodyWrapper = props => <tbody {...props} />;
      const RowWrapper = props => <tr {...props} />;

      BodyWrapper.shouldComponentUpdate = true;
      RowWrapper.shouldComponentUpdate = true;
      columns.forEach(column => {
        if(column.valueWrapper){
          column.cell.formatters.push(column.valueWrapper);
        }
      });
      const innerTable
        = <Table.Provider className={`mainTable ${(this.props.fixedHeader) ?
           'fixed' : ''}`}
            components={{
              body: {
                wrapper: BodyWrapper,
                row: RowWrapper,
              },
            }}
            columns={columns} >
            <Table.Header style={{visibility: this.props.hideHeader ? 'collapse' : ''}} />
            <Table.Body onRow={this.onRow} rows={rowsData}
                rowKey={this.props.rowKey} />
          </Table.Provider> ;

      table = innerTable;
      if(this.props.fixedHeader){
        table
        =  <Scrollbar
            ref={(ref) => this.ScrolBoundRef = ref}
            speed={0.8}
            className='table-scrollable-area'
            style={{maxHeight:this.props.fixedHeaderHeight}}
            contentClassName="content"
            smoothScrolling
            horizontal={this.props.fixedHeader} >
            {innerTable}
          </Scrollbar>;
      }
    }
    if(this.state.loading|| this.props.externalLoading){
      table = <div className='emptyTableRow'>
              <Loader />
        </div>;
    }

    return (
      <div>
        <div className={`reactable ${ this.props.idx} ${this.props.staticHeight? '': 'dynamicHeight'}`}>
          {
          columns && columns.length > 0 ?
          <Paginator
            loading={this.state.loading}
            noOfDisplayedPages={this.props.noOfDisplayedPages}
            onPaginationChange={this.onPaginationChange}
            onePagePagination={this.props.onePagePagination}
            fixedPagination={this.props.fixedPagination}
            paginated={this.props.paginated}
            pagination={this.pagination}
            paginatedData={paginatedData}
            totalAmount={totalAmount}
            numberOfSelectedRows={this.props.numberOfSelectedRows}
            hidePaginationControls={this.props.hidePaginationControls}
          >
            <div className='RTSearchWrapper'>
              <div className='pull-left reactable-searchbar'>
                {
                  this.props.searchable ?
                  <Searchbar columns={columns} parentId={this.props.idx}
                  onSearch={this.onSearch} query={this.search.filter.all}
                  ref={ ref => this.refSearch = ref} />
                  : null
                }
                { this.props.filterComponent? this.props.filterComponent() : null }
              </div>
              <div className='pull-right'>
                {
                  this.props.extraButtons
                }
              </div>
            </div>
            <div className='fixedHeader'>
              <Table.Provider columns={this.prepareHeaders(true)} >
                 <Table.Header style={{visibility: this.props.hideHeader ? 'collapse' : ''}}/> 
                <tbody/>
              </Table.Provider>
            </div>
            <div className='maintable-container'>
              {table}
            </div>
            {emptyRow}
          </Paginator > :
          emptyRow
        }
        </div>
        <ReactResizeDetector handleWidth onResize={this.handleResize}/>
      </div>
    );
  }
}
