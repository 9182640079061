/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { fromJS } from 'immutable';
import {UnitConversionUtils, Utils as JSUtils, FieldTypes} from 'js-utils';
// import cytoscape from 'cytoscape';
import * as defaults from 'utils/defaults';
import * as ThemeUtils from 'utils/ThemeUtils';
import Highlighter from 'react-highlight-words';
import { renderToString } from 'react-dom/server';

const TOPOLOGY_ICON_PATH = 'res/assets/img/topologyIcons/';

export const LINK_COLUMN_WIDTH = 6;
export const COLUMNS_TOTAL_WIDTH = 94;
export const STATIC_COLUMNS_COUNT = 3;

export const AVAILABLE_TOPOLOGY_LAYOUTS = {
  concentric: {
    value: 'concentric',
    text: 'Concentric',
    availableForChoice: true,
  },
  klay: {
    value: 'klay',
    text: 'Directed Flow',
    availableForChoice: true,
  },
  fcose: {
    value: 'fcose',
    text: 'Network',
    availableForChoice: true,
  },
  random: {
    value: 'random',
    text: 'Saved',
    availableForChoice: false,
  },
};

export const TOPOLOGY_LAYOUTS = {
  TOPOLOGY_DEFAULT_LAYOUT : AVAILABLE_TOPOLOGY_LAYOUTS.concentric.value,
  TOPOLOGY_SAVED_LAYOUT : AVAILABLE_TOPOLOGY_LAYOUTS.random.value,
};

const generateNodeRangeStyle = (value, ranges = [], applyRange, preferedTheme, layoutTheme) => { 
  if(!JSUtils.isBlank(value) && applyRange){
    const currentRange = ranges.filter(range => range.from <= value
      && range.to >= value);

    if(currentRange && currentRange.length > 0 ){
      return {
        nodeColor: currentRange[0].color,
        borderAvailable: true,
        backgroundColor: ThemeUtils.getTopologyNodeBgColor(preferedTheme, layoutTheme),
        backgroundOpacity: 1,
      };
    }
  }
  
  return {
    nodeColor: '#000',
    borderAvailable: false,
    backgroundColor: ThemeUtils.getTopologyNodeBgColor(preferedTheme, layoutTheme),
    backgroundOpacity: 0,
  };
};

const generateEdgeRangeStyle = (value, ranges, applyRange, preferedTheme) => {
  if(!JSUtils.isBlank(value) && applyRange){
    const currentRange = ranges.filter(range => range.from <= value
      && range.to >= value);

    if(currentRange && currentRange.length > 0 ){
      return {
        lineColor: currentRange[0].color,
        labelWidth: '3',
      };
    }
  }

  return {
    lineColor: ThemeUtils.getTopologyEdgeColor(preferedTheme),
    labelWidth: '2',
  };
};

const generateImageURL = (icon) => {
  return `url("${TOPOLOGY_ICON_PATH}${icon}.svg")`;
};

export const getUnitValueAndAppliedRange = (datum, fieldsDescriptor) => { 
  const unit = (fieldsDescriptor || {}).unit || UnitConversionUtils.DEFAULT_UNIT_DIVIDER;
  const baseUnit = (fieldsDescriptor || {}).baseUnit;
  const dataType = (fieldsDescriptor || {}).dataType;
  const isValueNotBlank = !JSUtils.isBlank(datum.value)  && !JSUtils.isBlank(datum.value.value);
  const convertedUnitValue = isValueNotBlank ? UnitConversionUtils.getFinalValueForm(datum.value.value, unit, baseUnit, 2, dataType) : null;
  const valueType =  isValueNotBlank ? datum.value.type : null;
  const applyRange = valueType && (valueType == FieldTypes.FieldTypes.Numeric);
 
  return {
    convertedUnitValue,
    applyRange,
    unit,
    baseUnit,
    dataType,
  };
};

const generateCytoscapeData = (format, data, topologyNodePositions, 
  ranges, preferedTheme, layoutTheme, topologyDataConfig = {}, 
  linkLabelFieldsDescriptor, isTopologyTypeRecord) => {
  let newData = [];
  const nodeTooltipTopologyMappings = topologyDataConfig.nodeTooltipTopologyMappings || {};
  const linkLabelTopologyMappings = topologyDataConfig.linkLabelTopologyMappings || {};

  if(data !== undefined && data.length > 0){
    newData = data.map((datum) => {
      const topologyItem = {};
        
      if(datum.componentType == defaults.COMPONENT_NODE_TYPE){ 
        const dataIdentifier = datum.dataIdentifier;

        let tooltipFieldsDescriptor = null;

        let dataConfig = {};

        if(dataIdentifier && nodeTooltipTopologyMappings.hasOwnProperty(dataIdentifier)){
          dataConfig = nodeTooltipTopologyMappings[dataIdentifier];
          tooltipFieldsDescriptor = dataConfig.fieldsDescriptor;
          tooltipFieldsDescriptor.unit = dataConfig.unitConversion;        
        }
        const unitAndAppliedRange = getUnitValueAndAppliedRange(datum, tooltipFieldsDescriptor);
        const nodeRangeStyle = generateNodeRangeStyle(unitAndAppliedRange.convertedUnitValue,
          dataConfig.colorRanges, unitAndAppliedRange.applyRange, preferedTheme, layoutTheme);
        const searchWord = format.searchWord;
        const hasSearchWord = !JSUtils.isEmpty(searchWord) 
          &&  datum.label.toLowerCase().includes(searchWord.toLowerCase());
        const highlighterDom = document.createElement('div');

        highlighterDom.innerHTML = renderToString(
          <React.Fragment><div className={`node-background ${hasSearchWord ? 'matched': ''}`}></div>
            {
              format.showNodeLabel ? <div className={`topology-label ${hasSearchWord ? 'matched': ''}`}>
                <Highlighter 
                  autoEscape
                  highlightClassName='matched-word' 
                  searchWords={[searchWord]} 
                  textToHighlight={datum.label}
                />
              </div> : null
            }
          </React.Fragment>
        );
        
        datum = { 
          ...datum,
          ...nodeRangeStyle,
          tooltipAttributeOptions: dataConfig.tooltipAttributeOptions,
          dom: highlighterDom,
        };

        if(!JSUtils.isBlank(unitAndAppliedRange.convertedUnitValue)){
          datum.nodeTooltipFinal = `${unitAndAppliedRange.convertedUnitValue} ${UnitConversionUtils.getUnitLabel(unitAndAppliedRange.unit, unitAndAppliedRange.baseUnit, unitAndAppliedRange.dataType)}`;
        }else if(JSUtils.isBlank(datum.nodeTooltipFinal)){
          datum.nodeTooltipFinal = null;
        } 
      } else { 
        if(isTopologyTypeRecord){
          const dataIdentifier = datum.dataIdentifier;

          let dataConfig = {};

          if(dataIdentifier && linkLabelTopologyMappings.hasOwnProperty(dataIdentifier)){
            dataConfig = linkLabelTopologyMappings[dataIdentifier];
            linkLabelFieldsDescriptor = dataConfig.fieldsDescriptor;
            linkLabelFieldsDescriptor.unit = dataConfig.unitConversion;
            ranges = dataConfig.colorRanges;        
          }
        }

        const unitAndAppliedRange = getUnitValueAndAppliedRange(datum, linkLabelFieldsDescriptor);
        const rangeStyle = generateEdgeRangeStyle(unitAndAppliedRange.convertedUnitValue, 
          ranges, unitAndAppliedRange.applyRange, preferedTheme);

        datum = { 
          ...datum,
          ...rangeStyle,
        };
        if(!JSUtils.isBlank(unitAndAppliedRange.convertedUnitValue)){
          datum.label = `${unitAndAppliedRange.convertedUnitValue} ${UnitConversionUtils.getUnitLabel(unitAndAppliedRange.unit, unitAndAppliedRange.baseUnit, unitAndAppliedRange.dataType)}`;
        }else if(JSUtils.isBlank(datum.label)){
          datum.label = '';
        } 
      }
   
      topologyItem.data = datum;
      if(!JSUtils.isBlank(topologyNodePositions) && topologyNodePositions[datum.id]){
        topologyItem.position = topologyNodePositions[datum.id];
      }

      return topologyItem;
    });
  }

  return newData;
};

const generateNodeStyle = (format, backColor, textColor) => {
  return {
    label: ele => {
      if(format.showNodeLabel){
        return ele.data('label');
      }
      
      return '';
    },
    width: '70px',
    height: '70px',
    'background-image': ele => {
      return [generateImageURL(ele.data('icon'))];
    },
    'border-color': ele => {
      if(ele.data('borderAvailable')){
        return ele.data('nodeColor');
      }else{
        return 'transparent';
      }
    },
    'border-width': ele => {
      if(ele.data('borderAvailable')){
        return 4;
      }
      
      return 0;
    },
    'background-opacity': ele => {
      return ele.data('backgroundOpacity');
    },
    'background-clip': 'none',
    'background-color': ele => {
      return ele.data('backgroundColor');
    },
    color: textColor,
    'text-background-shape': 'rectangle',
    'font-family': 'Arial, "Helvetica Condensed", Helvetica, sans-serif',
    'text-opacity': 0,
  };
};

const generateEdgeStyle = (format, backColor, textColor) => {
  return {
    'text-background-color': backColor,
    'text-background-opacity': 0.7,
    'text-background-shape': 'rectangle',
    'text-background-padding': '5px',
    'font-size': '12px',
    'curve-style': 'bezier',
    'control-point-step-size': 40,
    'edge-text-rotation': 'autorotate',
    'overlay-padding': '3px',
    'source-endpoint': 'inside-to-node',
    'mid-target-arrow-color': ele => {
      return ele.data('lineColor');
    },
    'mid-target-arrow-shape': format.showDirectionArrow ? 'triangle' : 'none',
    'arrow-scale': 1.5,
    'target-endpoint': 'inside-to-node',
    color: textColor,
    'line-color': ele => {
      return ele.data('lineColor');
    },
    width: ele => {
      return ele.data('labelWidth');
    },
    label: ele => {
      if(format.showLinkLabel){
        return ele.data('label');
      }
      
      return '';
    },
  };
};


export const generateTopologyProps = (data, format, width, height, disableMovingNodes, topologyNodePositions, 
  ranges, layout, isInDashboard, preferedTheme, layoutTheme, topologyDataConfig, linkLabelFieldsDescriptor, isTopologyTypeRecord) => {
  const backColor = ThemeUtils.getTopologyLabelbgColor(preferedTheme, layoutTheme);
  const textColor = ThemeUtils.getTopologyLabelColor(preferedTheme, layoutTheme);
  const nodeCount = (data || []).filter(node => node.componentType == 'NODE').length;
  const layoutSpecificProps = getLayoutSpecificProps(layout, 
    format.topologyLayoutDirection, width, height);

  const topologyProps = {
    id: 'cy',
    className: 'Cytoscape topology',
    style: {
      width: `${width-30}px`,
      height: `${height-30}px`,
    },
    nodeCount,
    global: 'cy',
    minZoom: 0.00003,
    maxZoom: 5000,
    boxSelectionEnabled: false,
    userZoomingEnabled: true,
    userPanningEnabled: true,
    autoungrabify: disableMovingNodes,
    wheelSensitivity: 0.4,
    elements: fromJS(generateCytoscapeData(format, data, topologyNodePositions, 
      ranges, preferedTheme, layoutTheme, topologyDataConfig, 
      linkLabelFieldsDescriptor, isTopologyTypeRecord)).toJS(),
    layout: {
      name: layout || TOPOLOGY_LAYOUTS.TOPOLOGY_DEFAULT_LAYOUT,
      avoidOverlap: true,
      animate: false,
      fit: false,
      ...layoutSpecificProps,
    },
    stylesheet :
    [
      {
        selector: 'edge',
        style: generateEdgeStyle(format, backColor, textColor),
      },
      {
        selector: 'node',
        style: generateNodeStyle(format, backColor, textColor),
      },
    ],
  };

  return topologyProps;
};

const getLayoutSpecificProps = (layout, layoutDirection, width, height) => {
  switch(layout){
  case AVAILABLE_TOPOLOGY_LAYOUTS.random.value:
    return {
      transform: (node, position ) => { 
        if(node.position().x !== 0 && node.position().y !== 0){
          return node.position();
        }

        return position;
      },
    };
  case AVAILABLE_TOPOLOGY_LAYOUTS.fcose.value:
    return {
      nodeDimensionsIncludeLabels: true,
      numIter: 10000,
      initialEnergyOnIncremental: 1,
    };
  case AVAILABLE_TOPOLOGY_LAYOUTS.klay.value:
    return {
      nodeDimensionsIncludeLabels: true, 
      fit: false,
      klay: {
        direction: layoutDirection || 'RIGHT',
        thoroughness: 20,
        aspectRatio: width / height,
        fixedAlignment: 'BALANCED',
        spacing: 50,
      },
    };
  case AVAILABLE_TOPOLOGY_LAYOUTS.concentric.value:
  default:
    return {
      concentric: ( node ) => { // returns numeric value for each node, placing higher nodes in levels towards the centre
        if(node.data('level') > 0){
          return node.data('level');
        }
 
        return node.degree();
      },
      minNodeSpacing: 100,
      levelWidth: () => 1,
    };  
  }
};
