/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import Icons from './Icons';
const errors = {
  403 : {
    description: 'HTTP Error 403 - Access Forbidden',
    message : 'Access is denied because you are not authorized to access this page.',
    hasBack: true,
  },
  404 : {
    description: 'HTTP Error 404 -  Page Not Found',
    message : 'This page was not found.',
    hasBack: true,
  },
  1000 : {
    description: 'Access Denied',
    message : 'You do not have permission to access the system.',
    hasBack: false,
  },
  1001 : {
    description: 'Invalid URL',
    message : 'The URL you are trying to use is not valid.',
    hasBack: false,
  },
};
const ErrorPage = class ErrorPage extends Component {
  render(){
    let errorNumber
      = this.props.location && this.props.location.query ?
       this.props.location.query.code
      : this.props.params.error;

    errorNumber = ~~(errorNumber);
    if(!errors[errorNumber] || isNaN(errorNumber)){
      errorNumber = 404;
    }
    const error = errors[errorNumber];

    return(
      <div className="textCenter virticalAlign">
        <div>          
            <h1>           
                <i className={Icons.lock + Icons.styles.ev3x}></i>
            </h1>
            <h2 className=" evc-text-danger">{error.description}</h2>
            <h4>{error.message}</h4>
            {
            error.hasBack && window.history.length > 1 ?
            <Button
              bsStyle="primary"
              onClick={() => {window.history.go(-1);}}>
              <i className={Icons.arrowLeft}></i> Back
            </Button> : null}
        </div>
      </div>
    );
  }
};

ErrorPage.errors = errors;

export default ErrorPage;
