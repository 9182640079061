/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import 'react-tagsinput/react-tagsinput.css';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import "ace-builds/src-noconflict/mode-json";


const CodeEditor = (props) => {

  return (
    <AceEditor
      {...props}
    />
  );
}

export default CodeEditor;
