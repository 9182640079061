/* Copyright 2020 Greyskies. All Rights Reserved. */

import elementHelper from 'bpmn-js-properties-panel/lib/helper/ElementHelper';
import inputOutputHelper from 'bpmn-js-properties-panel/lib/helper/InputOutputHelper';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import utils from 'bpmn-js-properties-panel/lib/Utils';
import {query} from 'min-dom';
import * as OutputParameter from './OutputParameter';
import * as CommonWorkflowDefaults from 'utils/CommonWorkflowDefaults';
// import OutputParameter from './OutputParameter';

function append(array, items) {
  Array.prototype.push.apply(array, items);
}

function flatten(arrays) {
  return Array.prototype.concat.apply([], arrays);
}

function generateElementId(prefix) {
  prefix = prefix + '_';
  return utils.nextId(prefix);
}

function getInputOutput(element, insideConnector) {
  return inputOutputHelper.getInputOutput(element, insideConnector);
}

function getOutputParameters(element, insideConnector) {
  return inputOutputHelper.getOutputParameters(element, insideConnector);
}

function createParameter(type, parent, bpmnFactory, properties) {
  return elementHelper.createElement(type, properties, parent, bpmnFactory);
}

function createElement(element, type, bpmnFactory, prefix, prop) {
  const commands = [];
  const inputOutput = getInputOutput(element, false);
  const newElem = createParameter(type, inputOutput, bpmnFactory, { name: generateElementId(prefix) });

  commands.push(cmdHelper.addElementsTolist(element, inputOutput, prop, [newElem], []));

  return commands;
}

function getParametersHeading(element, bpmnFactory, options) {
  const prefix = options.prefix;
  const type = options.type;
  const prop = options.prop;

  const entry = {
    id: prefix + '-heading',
    cssClasses: [ 'bpp-input-output' ],
    html: '<div class="bpp-field-wrapper">' 
      + '<button type="button" class="bpp-input-output__add add action-button" ' 
      + 'data-action="createElement">' 
      + '</button><input name="hidden" type="hidden">' 
      + '</div>',
  };

  entry.createElement = function(_, entryNode) {
    entry._commands = createElement(element, type, bpmnFactory, prefix, prop);
    // @barmac: hack to make properties panel call `set`
    const input = query('input[type="hidden"]', entryNode);
    input.value = 1;
    return true;
  };


  entry.set = function() {
    const commands = entry._commands;
    
    if (commands) {
      delete entry._commands;
      return commands;
    }
  };

  return entry;
}

function getOutputParametersEntries(element, bpmnFactory, options, translate, outputs) {
  const inputOutput = getInputOutput(element, options.insideConnector);
  const params = getOutputParameters(element, options.insideConnector);
  const outputParameters = params.map(function(param, index) {
    function onRemove() {
      const commands = [];

      commands.push(cmdHelper.removeElementsFromList(element, inputOutput, 'outputParameters', null, [param]));

      return commands;
    }

    return OutputParameter.getOutputParameterEntry(param, bpmnFactory,
      {
        idPrefix: 'output-parameter-' + index,
        onRemove: index == 0 ? null : onRemove,
        onToggle: onToggle,
        isDisabled: index == 0,
      }, translate, outputs);
  });

 // Close remaining collapsible entries within group.
  function onToggle(value, entryNode) {
    if (!value) {
      return;
    }

    const currentEntryId = entryNode.dataset.entry;

    outputParameters.forEach(function(outputParameter) {
      if (outputParameter.entries[0].id === currentEntryId) {
        return;
      }
      outputParameter.setOpen(false);
    });
  }

  const entries = outputParameters.map(function(input) {
    return input.entries;
  });

  return flatten(entries);
}

export function getOutputParametersEntry(element, bpmnFactory, translate, outputsEntriesMap) {
  const insideConnector = false;
  const entries = [];
  const parametersOptions = {
    insideConnector,
  };
  const outputs = Object.values(outputsEntriesMap.outputs)[0];
  // header and plus button
  if(outputs.hasAddBtn){
    entries.push(
      getParametersHeading(element, bpmnFactory, {
        insideConnector: insideConnector,
        type: CommonWorkflowDefaults.CAMMUNDA_TAGS.OUTPUT_PARAMETER,
        prop: 'outputParameters',
        prefix: 'Output',
      }));   
  }
 

  //hide plus and show outputs
  append(entries,
    getOutputParametersEntries(element, bpmnFactory, parametersOptions, translate, outputs)
  );

  return {entries};
};
