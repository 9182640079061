/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import TooltippedButton from 'buttons/TooltippedButton';

/** @deprecated */
class DesignSpaceTreeRenderer extends Component {
  
  constructor(props){
    super(props);
    this.filterChildToSelectedNode = ::this.filterChildToSelectedNode;
  }

  filterChildToSelectedNode(selectedNode){
    return (this.props.node.nodePos.startsWith(selectedNode.pos)
      && this.props.node.nodePos.split('-').length > selectedNode.pos.split('-').length);
  }



 getActionButtons(buttons, node){
   if(!node.leaf && buttons && buttons.length > 0){
     return buttons.map(button => {
       if(this.props.showButton(node, button)){
         return(
          <TooltippedButton
            className="btn-action treeActIco"
            tooltip={button.tooltip}
            onClick={() => button.onClick(node, button.create)}
           >
             <i className={button.icon}></i>
           </TooltippedButton>
         );
       }
     });
   }
   return null;
 }

  render(){
    const {
      toggleChildrenVisibility,
      node,
      path,
      treeIndex,
      ...otherProps
    } = this.props;
    const nodePos = node.nodePos.split('-');
    const marginLeft = 20 * (nodePos.length - 1);
    const inlineStyle = marginLeft > 0 ? {marginLeft} : {};

    const switcherClassName = node.expanded ?
     'rc-tree-noline_open' : 'rc-tree-noline_close';
    const folderIcon = node.expanded ?
     'rc-tree-iconEle rc-tree-icon__open'
     : 'rc-tree-iconEle rc-tree-icon__close';
    const nodeTitleClassName = node.expanded ? 'expanded-tree-node' : '';
    const nodeIconStyle = 'ev ev-lg';
    const actionButtons = this.getActionButtons(this.props.buttons, node);

    return (
      <ul className="rc-tree"
          role="tree-node"
          unselectable="true"
          style={inlineStyle}>
        <li className="" >{
          node.isLeaf ? null :
          <span className={`rc-tree-switcher ${switcherClassName}`}
          onClick={() => toggleChildrenVisibility({node, path, treeIndex})}>
          </span>
        }
          <a title={node.title}
              className={otherProps.selectedAttributeId === node[otherProps.id] ? 'item-active-bg-color' : 'item-inactive-bg-color'}
              onClick={() => {
                if(otherProps.onClick){
                  otherProps.onClick(node);
                }
              }}>
            {node.isLeaf ?
                null
               : <span className={folderIcon} ></span>
           }
            <span className={`rc-tree-title ${nodeTitleClassName}`} >
              <i className={`${nodeIconStyle} ${node.iconName} ${nodeTitleClassName}`}/>
                <Highlighter
                   autoEscape
                   searchWords={['']}
                   className={node.isLeaf ? 'tree-node-title' : ''}
                   textToHighlight={node.title}
                />
            </span>
          </a>
          <div className="pull-right nodeActions">
            {
                actionButtons
            }
          </div>
        </li>
      </ul>
    );
  }
}
DesignSpaceTreeRenderer.propTypes = {
  node:          PropTypes.object.isRequired,
  path:          PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])).isRequired,
  treeIndex:     PropTypes.number.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,

  toggleChildrenVisibility: PropTypes.func,
  buttons:                  PropTypes.arrayOf(PropTypes.node),
  className:                PropTypes.string,
  style:                    PropTypes.object,

};

export default DesignSpaceTreeRenderer;
