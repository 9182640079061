/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import ReactQuill from 'react-quill';
import * as EmailDefaults from 'utils/EmailDefaults';
import { fromJS } from 'immutable';
import {Row} from 'react-bootstrap';
import {validationFunction} from 'utils/validations/EmailValidationSchema';
import {ValidationUtils} from 'js-utils';
import LabeledComponent from 'containers/LabeledComponent';
import DataInput from 'inputs/simple/DataInput';
import ValidationOutput from 'containers/ValidationOutput';

const pasteSplit = (emails) => {
  return emails.split(new RegExp(EmailDefaults.EMAILS_PASTE_SEPARATORS.join('|')))
    .map(email => email.trim());
}

const defaultTagProps = {
  addOnBlur: true,
  addOnPaste: true,
  addKeys: [9, 13, 32],
  onlyUnique: true,
  validationRegex: EmailDefaults.Email_VALIDATION_REGEX,
  pasteSplit,
};
const toMailConst = 'toMail';
const ccConst = 'cc';
const bccConst = 'bcc';
const subjectConst = 'subject';

export default class EmailComponent extends Component {

  constructor(props){
    super(props);
    const stateTemp = this.getEmailAttributes(this.props.inputs);

    stateTemp.validation =  {
      subject:{},
      toMail:{},
      cc:{},
      bcc:{},
    };
    this.state = stateTemp;

    this.getEmailAttributes = ::this.getEmailAttributes;
    this.onSubjectBlur = ::this.onSubjectBlur;
    this.setEmailField = ::this.setEmailField;
    this.validationReject = ::this.validationReject;
    this.validateEmailFields = ::this.validateEmailFields;
    this.checkComponentDefaultValidationAndUpdate = ::this.checkComponentDefaultValidationAndUpdate;
    this.canSend = ::this.canSend;
    this.isFormValid = ::this.isFormValid;
    this.isValidated = :: this.isValidated;
  }

  isValidated(){
    this.setState({
      validation: this.checkComponentDefaultValidationAndUpdate(),
    });
  }

  checkComponentDefaultValidationAndUpdate(){
    const validation = {};

    validation.subject = validationFunction('subject', this.state.subject);
    validation.toMail = this.validateEmailFields(toMailConst, this.state.toMail);
    validation.cc = this.validateEmailFields(ccConst, this.state.cc);
    validation.bcc = this.validateEmailFields(bccConst, this.state.bcc);  

    return validation;
  }

  getEmailAttributes(result){
    const newResult = {};

    newResult.toMail = result.toMail;
    newResult.cc = result.cc;
    newResult.bcc = result.bcc;
    newResult.subject = result.subject;
    newResult.body = result.body;

    return newResult;
  }

  validateEmailFields(fieldKeyword, fieldValue){
    let validation = fromJS(this.state.validation[fieldKeyword]).toJS();

    if(ValidationUtils.isValid(validation)){
      validation = validationFunction('mails', {fieldKeyword, mails: fieldValue});
    }

    return validation;
  }

  isFormValid(){
    const validation = this.checkComponentDefaultValidationAndUpdate();

    return ValidationUtils.isValid(validation.subject) 
      && ValidationUtils.isValid(validation.toMail)
      && ValidationUtils.isValid(validation.cc) 
      && ValidationUtils.isValid(validation.bcc);
  }

  canSend(){
    const isValid = this.isFormValid();

    this.props.validationFunction(isValid);
  }

  setEmailField = (inputType, fieldKeyword) => (e) => {
    const stateTemp = fromJS(this.state).toJS();
    let value = null;

    if (inputType == 'input') {
      value = e.target.value;
      stateTemp.validation.subject = validationFunction('subject', value);
    }else if (inputType == 'tagsInput') {
      value = e;
      if(value.length > 0){
        value[value.length - 1] = value[value.length - 1].trim();
      }
      stateTemp.validation[fieldKeyword] = validationFunction('mails', {fieldKeyword, mails:value});
    } else if (inputType == 'ReactQuill') {
      value = e.replace(new RegExp('<img src="http://">', 'g'), '');
    }
    stateTemp[fieldKeyword] = value;

    this.setState(stateTemp, () => {
      if (this.props.onChange) {
        const attributes = this.getEmailAttributes(stateTemp);

        attributes.canSend = this.isFormValid();
        this.props.onChange(attributes);
      }
    });

  }

  validationReject = (fieldKeyword) => (evt) => {
    const validation = fromJS(this.state.validation).toJS();
    const newMail = evt[0].trim();
    const mails = fromJS(this.state[fieldKeyword] || []).toJS();

    if(newMail){
      mails.push(newMail);
    }
    validation[fieldKeyword] = validationFunction('mails',{fieldKeyword, mails});
    if(ValidationUtils.isValid(validation[fieldKeyword])){
      validation[fieldKeyword] = validationFunction('mail', newMail);
    } 
    this.setState({validation}, () => {
      this.canSend();
    });
  }

  onSubjectBlur() {
    let validation = fromJS(this.state.validation).toJS();

    validation.subject = validationFunction('subject', this.state.subject);
    this.canSend();
    this.setState({validation});
  }

  render(){
    return(
      <Row className={this.props.className}>
        <LabeledComponent label="To*" size={12} componentClassName='emailTo'>
          <TagsInput value={this.state.toMail}
                      onChange={this.setEmailField('tagsInput', toMailConst)}
                      onValidationReject={this.validationReject(toMailConst)}
                      inputProps={{placeholder:  'Add an Email'}}
                                {...defaultTagProps}/>
          <ValidationOutput validation={this.state.validation.toMail}/>

        </LabeledComponent>

        <LabeledComponent label="Cc" size={12} componentClassName='emailCC'>
          <TagsInput value={this.state.cc}
                      onChange={this.setEmailField('tagsInput', ccConst)}
                      onValidationReject={this.validationReject(ccConst)}
                      inputProps={{placeholder:  'Add an Email'}}
                                {...defaultTagProps}/>
          <ValidationOutput validation={this.state.validation.cc}/>
        </LabeledComponent>

        <LabeledComponent label="Bcc" size={12} componentClassName='emailBCC'>
          <TagsInput value={this.state.bcc}
                      onChange={this.setEmailField('tagsInput', bccConst)}
                      onValidationReject={this.validationReject(bccConst)}
                      inputProps={{placeholder:  'Add an Email'}}
                                {...defaultTagProps}/>
          <ValidationOutput validation={this.state.validation.bcc}/>
        </LabeledComponent>

        <DataInput
              label="Subject*" size={12} formControlClassName='email-subject'
              value={this.state.subject}
              onChange={this.setEmailField('input', subjectConst)}
              placeholder= "Enter Subject"
              validation={this.state.validation.subject}
              onBlur={this.onSubjectBlur}/>

        <LabeledComponent label="Body" size={12} componentClassName='emailBody'>
          <ReactQuill value={this.state.body} onChange={this.setEmailField('ReactQuill', 'body')}
                                        theme="snow" />
        </LabeledComponent>
      </Row>
    );
  }
}
