/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {FormControl} from 'react-bootstrap';

export default class Searchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:props.query,
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.triggerSearch != this.props.triggerSearch){
      document.querySelector(
        `.${this.props.parentId} .search-container input`).blur();
    }
  }

  render() {
    return (
        <div className='search-container'>
        { this.props.hideLabel ? null :
          <div className='search-label-wrapper'>
            <span className='search-label'>Search:</span>
          </div>
        }
          <div>
            <FormControl
            type="text"
            autoFocus={false}
            placeholder={this.props.placeholder || ''}
            className={`input-text ${ this.props.parentId}-search-input`}
              onChange={(e) => {
                this.setState({value:e.target.value});
              }}
              value={this.state.value}
              onBlur={() =>  this.props.onSearch(this.state.value) }
              onKeyPress = {(e) => {
                e.stopPropagation();
                if(e.charCode == 13) {
                  e.preventDefault();
                  document.querySelector(
                    `.${this.props.parentId} .search-container input`).blur();
                }
              }}
            />
          </div>
        </div>
    );
  }
}
