/* Copyright 2019 Greyskies. All Rights Reserved. */

export function prepareFilteringQuery(filterQuery){
  const query = {};
  const {searchQuery, paginationRange, paginationNumber,
    sortingColumnName, sortingColumnDirection} = filterQuery;

  if(searchQuery){
    query.search = searchQuery;
  }

  if(paginationRange){
    query.paginationRange = paginationRange;
  }
  if(paginationNumber){
    query.paginationNumber = paginationNumber;
  }
  if(sortingColumnDirection){
    query.sortingColumnDirection = sortingColumnDirection;
  }
  if(sortingColumnName){
    query.sortingColumnName = sortingColumnName;
  }
  return query;
}