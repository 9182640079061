/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Collapse, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link, withRouter} from 'react-router';

export default withRouter(
  class SidebarElement extends Component {
    state = {
      collapsed: false,
    };

    constructor(props){
      super(props);
      this.toggleCollapse = this.toggleCollapse.bind(this);
      if(props.element.children.length > 0){
        props.element.children.forEach( element => {
          if(props.selectedPage === element.id){
            this.state.collapsed = true;
          }
        });
      }
    }

    toggleCollapse(e){
      e.preventDefault();
      if(this.props.inNavbar){
        this.setState({
          collapsed: !this.state.collapsed,
        });
        if(this.props.onSideBarEelementChange
          && typeof this.props.onSideBarEelementChange == 'function'){
          this.props.onSideBarEelementChange();
        }
      }
    }

    isCurrentPageActive(path) {
      return this.props.selectedPage.includes(path.substring(1));
    }

    render(){
      const classname = this.props.element.icon;
      let componentName = 'sidebar';
      let active = '';
      const toURL = this.props.context ?
       `/${this.props.context}/${this.props.element.id}` : `/${this.props.element.id}`;
      
      if(this.props.inNavbar){
        componentName = 'navbar';
      }

      if(this.props.element.id && this.isCurrentPageActive(toURL)){
        active = 'active';
      }

      if(!this.props.inNavbar && this.props.element.children.length > 0){
        const basePath = this.props.context ? `/${this.props.context}` : "";
        this.props.element.children.forEach( element => {
          const pagePath = basePath + '/' + element.id;
          if(this.isCurrentPageActive(pagePath)){
            active = 'active';
          }
        });
      }
      if(this.props.element.children.length === 0){

        return(
          <li>
            <OverlayTrigger shouldUpdatePosition
              rootClose placement='right' trigger={this.props.child ? [] : ['hover', 'focus']}
              overlay={
                <Tooltip placement='right' className='in' >
                {this.props.element.name}
                </Tooltip>
              }
            >
              <Link to = {toURL} className = {active} >
                <i className = {classname} ></i>
                <span className = {`${componentName}-item-title`}>
                  {this.props.element.name}
                </span>
              </Link>
            </OverlayTrigger>
          </li>
        );
      }
      //toggleParentElement is set to the first child id so when we open the
      //parent node in new tab it should open the first element in the children
      //nodes.
      const toggleParentElement = '#' + this.props.element.children[0].id;
      const children = this.props.element.children.map((element) => {
        return(
             <SidebarElement key = {element.name} element = {element} child
               selectedPage = {this.props.selectedPage}
               context = {this.props.context}/>
        );
      });
      const navbarElements = <Collapse in={this.state.collapsed}>
             <ul id={this.props.element.href}>
               {children}
             </ul>
           </Collapse>;

      return(
        <li className='list-group'>
          <a href={toggleParentElement} className={active} onClick={this.toggleCollapse}>
            <i className={classname}/>
            <span className={`${componentName}-item-title`}>
              {this.props.element.name}
            </span>
          </a>    
          {
            this.props.inNavbar ? navbarElements :
              <ul id={this.props.element.href}>
                {children}
              </ul>
            }
        </li>
      );
    }
  }
);
