/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Fragment } from 'react';
import Switch from 'react-toggle-switch';
import { Row } from 'react-bootstrap';
import * as FFAGDefaults from 'utils/ffagDefaults';
import LabeledComponent from 'containers/LabeledComponent';
import FilterInput from './FilterInput';
import TooltippedButton from 'buttons/TooltippedButton';
import 'web-style-guide/css/postAggregationFilter.less';
import Icons from 'templates/Icons';

export default class PostAggregationFilter extends React.Component {

  constructor(props) {
    super(props);
    this.getPostAgregationFilterComponent = ::this.getPostAgregationFilterComponent;
    this.onPostAggregationFilterToggle = ::this.onPostAggregationFilterToggle;
    this.updateFilters = ::this.updateFilters;
    this.isEnabled = ::this.isEnabled;
  }

  updateFilters({filters}, checkValidation, checkListValidation, checkListItemsValidation, extraOptions) {
    this.props.updateFiltersList({postAggregationFilters: filters}, checkValidation, 
      checkListValidation, checkListItemsValidation, extraOptions);
  }

  getPostAgregationFilterComponent(){
    return (
      <FilterInput 
        operations={this.props.operations}
        updateFiltersList={this.updateFilters}
        attributesList={this.props.attributesList}
        filters={this.props.filters}
        validationOutcome={this.props.validationOutcome}
        listAttributesOfAdvancedFiltersComponent={this.props.listAttributesOfAdvancedFiltersComponent}
        getRoots={this.props.getRoots}
        refFilterTreeOptions={this.props.refFilterTreeOptions}
        getSelectionFinalForm={this.props.getSelectionFinalForm}
        disableComponent={this.props.disableComponent}
        placeholderOptions={this.props.placeholderOptions}
        isTemplate={this.props.isTemplate}
        getNEPlaceholderOptions={this.props.getNEPlaceholderOptions}
        serverTimeZone={this.props.serverTimeZone}
        isPostAggregationFilter
      />
    );
  }

  onPostAggregationFilterToggle() {
    this.props.updateFiltersList({
      postAggregationFilterEnabled: !this.props.postAggregationFilterEnabled,
      postAggregationFilters: [],
    }, true, true, true, {isPlaceholdersChanged: true});
  }

  isEnabled() {
    return !this.props.disableComponent && this.props.postAggregationFilterEnabled;
  }

  render() {
    const labelMinSize = 6;
    const labelMaxSize = 12;

    return this.props.showPostAggregationFilter ? (
      <Fragment> 
        <Row className='postAggregationWrapper'>
          <LabeledComponent  
            label={FFAGDefaults.LABELS.POST_AGGREGATION} 
            size={labelMaxSize} labelSize={labelMinSize} inputSize={labelMinSize} labelSizeSm={labelMinSize} inputSizeSm={labelMinSize}  componentClassName={'lineHeight'}
            hint={
              <TooltippedButton  className="btn-action" tooltip={FFAGDefaults.POST_AGGREGATION_TOOLTIP_MESSAGE} >
                <i className={Icons.info}/>
              </TooltippedButton>
            }
          >
            <Switch on={this.isEnabled()}
              className="pull-right collectionAgg-switch"
              enabled={!this.props.disableComponent}
              onClick={this.onPostAggregationFilterToggle}>
              <div className='handle'>
                {this.isEnabled() ? <p>Enabled</p> : <p> Disabled </p> }
              </div>
            </Switch>
          </LabeledComponent>
        </Row>

        {this.isEnabled() ? this.getPostAgregationFilterComponent() : null}
      </Fragment>
    ) : null;
  }
}
