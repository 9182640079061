/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {FormGroup, InputGroup, OverlayTrigger, Tooltip, DropdownButton, 
  MenuItem} from 'react-bootstrap';
import 'styles/containers/addonLabeledComponent.less';

function getMenuItems(elements, eventKey, name){
  return (
    elements.map((element) => {
      return <MenuItem key={element[eventKey]} eventKey={element[eventKey]}>{element[name]}</MenuItem>;
    })
  );
}

const AddonLabeledComponent = (props) => {
  return(
    <FormGroup className="margBtm0OverRide">
      <InputGroup className='addon-input-group'>
        <OverlayTrigger
          rootClose placement="bottom" trigger={props.labelTooltip ? ['hover', 'focus'] : []}
          overlay={<Tooltip placement="bottom" className="in">{props.labelTooltip}</Tooltip>}
        >
          {props.dropdownLabel ? (
            <DropdownButton 
              className="inputFilterBtn"
              disabled={props.disabled}
              componentClass={InputGroup.Button}
              id='input-dropdown-addon'
              title={props.label}
              onSelect={(eventKey, event) => props.onLabelSelect(eventKey, event)}
            >
            {getMenuItems(props.dropdownOptions, props.dropdownValueField, props.dropdownTextField)}
          </DropdownButton>
          ) : (
          <InputGroup.Addon className={`data-input-label textEllipsis ${props.hasNormalLabel ? 'normalLabel' : '' }`}>
            {props.icon}
            {props.label}
            {props.hint ? props.hint : null }
          </InputGroup.Addon>)
          }
        </OverlayTrigger>
        {props.children}
        {props.suffixLabelAddon ? 
          <InputGroup.Addon className="data-input-label textEllipsis">
            {props.suffixLabelAddon}
          </InputGroup.Addon> 
        : null}
      </InputGroup>
    </FormGroup>
  );
};

export default AddonLabeledComponent;
