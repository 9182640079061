/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import ValidationOutput from './ValidationOutput';
import * as ButtonsConstructor from 'utils/ButtonsConstructor';
import {fromJS} from 'immutable';
import 'styles/containers/collapsibleComponent.less';
import Icons from 'templates/Icons';


export default class CollapsableComponent extends React.Component{

  constructor(props){
    super(props);

    this.state = {collapsed: this.props.initiallyCollapsed || false};

    this.reverseCollapse = ::this.reverseCollapse;
    this.getCaret = ::this.getCaret;
    this.renderTooltippedButtons = ::this.renderTooltippedButtons;
    this.renderLeftSideContainer = ::this.renderLeftSideContainer;
    this.getButtonOnClick = ::this.getButtonOnClick;
    this.renderRightSideContainer = ::this.renderRightSideContainer;
  }

  componentWillReceiveProps(newProps) {
    if(newProps.initiallyCollapsed !== this.props.initiallyCollapsed) {
      this.setState({collapsed: newProps.initiallyCollapsed || false})
    }
  }

  getCaret(collapsed) {
    return collapsed ? Icons.caretRight : Icons.caretDown;
  }

  reverseCollapse(e) {
    if(!this.props.hideCollapsingArrow){
      const collapsed = !this.state.collapsed;
      this.stopPropagation(e);
      this.setState({collapsed}, () => {
        if(this.props.onReversingCollapse){
          this.props.onReversingCollapse(collapsed);
        }
      });
    }
  }

  showCollapsableComponent(){
    if(this.props.collapseUsingStyles){
      return (
        <div style = {{display: this.state.collapsed ? 'none' : ''}}>
          {this.props.collapsableComponent || this.props.children}
        </div>
      );
    }else if(!this.state.collapsed){
      if(this.props.wrapInRow){
        return(
          <Row className='collapsable-component-body'>
            <Col
              xs={12}
              className={this.props.bodyClassname || ''}
            >
              {this.props.collapsableComponent || this.props.children}
            </Col>
          </Row>
        );
      }

      return(
        <Fragment>
          {this.props.collapsableComponent || this.props.children}
        </Fragment>
      );
    }

    return null;
  }

  pullButtonRight =(button, pullRight) => {
    if(pullRight){
      return (
        <div className='pull-right'>
          {button}
        </div>
      );
    }

    return button;
  }

  getButtonOnClick(button){
    const that = this;
    return () => {
      button.initialOnClick(that.props.collapsableComponentIndex);
      if(button.expandOnClick){
        that.setState({collapsed: false});
      }
    }
  }
  renderTooltippedButtons(buttons){
    if(buttons && buttons.length > 0){
      const readyButtons =  buttons.map(button => {
        button.initialOnClick = button.onClick;
        button.onClick = this.getButtonOnClick(button);

        return button;
      });

      return ButtonsConstructor.renderTooltippedButtons(readyButtons);
    }

    return null;
  }

  stopPropagation(e){
    e.stopPropagation();
  }

  renderLeftSideContainer(){
    const children = this.props.leftSideContainerChildren;

    if(children){
      return (
        <div className='collapsible-left-side-container' onClick={this.stopPropagation} >
          {this.props.leftSideContainerChildren}
        </div>
      );
    }

    return null;
  }

  renderRightSideContainer(){
    return (this.props.rightSideContainer ? this.props.rightSideContainer : null);
  }

  render(){
    const caret = this.getCaret(this.state.collapsed);
    const collapsableBody = this.showCollapsableComponent();

    return (
      <Fragment>
        <Row className={`collapsible-component ${this.props.className || ''}`}>
          <Col 
            xs={12}
            onClick={this.reverseCollapse}
            className={`pointer-cursor collapsible-col ${this.props.labelClassName || ''}`}
          >
            <i className={`${caret} ${this.props.hideCollapsingArrow ? 'vHidden' : '' } collapsible-arrow`}></i>

            { this.renderLeftSideContainer()}
            
            <label className='collapsible-label'>{this.props.title}</label>
            
            {this.renderTooltippedButtons(fromJS(this.props.buttons || []).toJS())}
            
            {this.renderRightSideContainer()}
          </Col>
          {this.props.validation ? 
          <Col xs={12} className='evc-nesting-indentation'>
            <ValidationOutput validation={this.props.validation} />
          </Col>
          : null}

          <Col xs={12} className='body-col-wrapper evc-nesting-indentation'>
            {collapsableBody}
          </Col>

        </Row>
      </Fragment>
    );
  }
}