/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {Modal as ModalBS, Button, ButtonGroup, Row, Col} from 'react-bootstrap';
import DraggableModalDialog from './DraggableModalDialog';
import * as Icons from 'templates/Icons';
import 'styles/containers/modal.less';

const Modal = (props) => {
  let {title, ...newProps} = props;

  return(
    <ModalBS 
      dialogComponentClass={DraggableModalDialog} 
      {... newProps} 
      bsSize={props.bsSize} 
      backdrop={props.backdrop == undefined ? 'static' : props.backdrop}
      className={props.className || ''}
      enforceFocus={false}
      keyboard
      backdropClassName={props.backdropClassName || ''}
      onHide={props.onHide}
    >
      <ModalBS.Header>
        <ModalBS.Title>
          {title}
          <button className="close" type="button" onClick={props.onHide}>
            <i className={`${Icons.close}`}/>
          </button>
        </ModalBS.Title>
      </ModalBS.Header>
      <ModalBS.Body>
        <Row>
          <Col xs={12} sm={11} className={`block-center modal-body-wrapper ${props.autoHeight ? 'autoHeight' : ''}`}>
            {props.children}
          </Col>
        </Row>
      </ModalBS.Body>
      {
        props.noFooter ? null
        : <ModalBS.Footer>
            <Row>
              <Col xs={12} sm={11} className='block-center'>
                <div className='evc-btns-footer'>
                  { props.footerLeftButtons && 
                      <ButtonGroup className='pull-left'>
                        {props.footerLeftButtons}
                      </ButtonGroup>
                  }
                  <div>
                    <Button onClick={props.onHide} {...props.cancelProps}
                      bsStyle="cancel">
                        {props.closeButtonLabel || 'Close'}
                    </Button>
                    {props.footerButtons}
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBS.Footer>
      }
    </ModalBS>
  );
};

export default Modal;
